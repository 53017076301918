import { useEffect, useRef, useState } from 'react'
import { ActivityIndicator, StyleSheet, ViewStyle } from 'react-native'
import InstagramLogin from 'react-native-instagram-login'
import { IResolveParams, LoginSocialInstagram } from 'reactjs-social-login'
import * as Linking from 'expo-linking'

import { Button } from '@components'
import { isWeb } from '@components/utils'
import { AntDesign } from '@expo/vector-icons'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { SocialAuth, SocialProvider } from '@types'

const INSTAGRAM_APP_ID = '391310980409511'

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#fff',
    paddingHorizontal: 0,
    width: 60
  }
})

interface InstagramAuthButtonProps {
  mode?: 'login' | 'connect'
  isDisabled?: boolean
  buttonStyles?: ViewStyle
}

export const InstagramAuthButton = ({ mode = 'login', isDisabled, buttonStyles }: InstagramAuthButtonProps) => {
  const insRef = useRef<any>(null)
  const [isAuthInProgress, setIsAuthInProgress] = useState(false)
  const [currentUrl, setCurrentUrl] = useState<string>('')
  const { registration, connectSocial } = useDataLayer()
  const { navigation } = useNavigator()

  useEffect(() => {
    Linking.getInitialURL().then(url => setCurrentUrl(url || ''))
  }, [])

  const onLoginSuccess = (code: string) => {
    setIsAuthInProgress(false)

    if (!code) {
      return
    }

    const params: SocialAuth = {
      provider: SocialProvider.INSTAGRAM,
      token: code
    }

    if (isWeb) {
      params.redirectUrl = currentUrl
    }

    if (mode === 'connect') {
      connectSocial(params)
      return
    }

    registration({ socialAuth: params }, () => navigation.navigate('Tabs'))
  }

  const onResolve = ({ data }: IResolveParams) => {
    if (!data?.code || data.error) {
      setIsAuthInProgress(false)
      return
    }

    onLoginSuccess(data.code)
  }

  const onReject = (err: any) => {
    setIsAuthInProgress(false)
    console.warn(err)
  }

  const Icon = <AntDesign name='instagram' size={30} color='black' />

  if (isDisabled) {
    return (
      <Button isView style={[styles.button, buttonStyles]}>
        {Icon}
      </Button>
    )
  }

  if (isWeb) {
    return (
      <LoginSocialInstagram
        isOnlyGetCode
        onLoginStart={() => setIsAuthInProgress(true)}
        onResolve={onResolve}
        onReject={onReject}
        client_id={INSTAGRAM_APP_ID}
        client_secret=''
        redirect_uri={currentUrl}
      >
        <Button isView style={[styles.button, buttonStyles]}>
          {isAuthInProgress ? <ActivityIndicator /> : Icon}
        </Button>
      </LoginSocialInstagram>
    )
  }

  return (
    <>
      <Button onPress={() => insRef.current?.show()} style={[styles.button, buttonStyles]}>
        {isAuthInProgress ? <ActivityIndicator /> : Icon}
      </Button>
      <InstagramLogin
        ref={insRef}
        appId={INSTAGRAM_APP_ID}
        redirectUrl='https://connectapp.me/'
        scopes={['user_profile', 'user_media']}
        onLoginSuccess={onLoginSuccess}
        onLoginFailure={(data: any) => console.log({ data })}
      />
    </>
  )
}
