import { combineReducers } from 'redux'

import {
  allDeletedUsersReducer,
  allUsersReducer,
  authReducer,
  channelReducer,
  chatsReducer,
  checkCodeReducer,
  connectRequestsReducer,
  currentUserReducer,
  feedReducer,
  fullChatUserReducer,
  notificationHistoryReducer,
  notificationReducer,
  receiveMessagesForChatReducer,
  usersReducer
} from '@redux/reducers'
import {
  AllDeletedUsersState,
  AllUsersState,
  AuthState,
  ChannelState,
  CurrentUserState,
  DiscussionState,
  FeedState,
  FullChatUserState,
  GetMyChatsState,
  GetMyLikesState,
  NotificationState,
  ReceiveMessagesForChatState,
  RESET_STORE,
  UserChatsState,
  UsersState
} from '@redux/types'

const initialState: RootState = {
  allDeletedUsers: [],
  allUsers: [],
  auth: {
    facebookAuth: null,
    googleAuth: null,
    instagramAuth: null,
    phone: '',
    status: ''
  },
  channel: null,
  chatMessages: { messages: [] },
  chats: [],
  code: '',
  connectRequests: [],
  currentUser: null,
  discussion: null,
  feed: null,
  fullChatUser: null,
  notification: null,
  notificationHistory: { counter: 0, data: [] },
  users: []
}

export interface RootState {
  auth: AuthState
  users: UsersState
  currentUser: CurrentUserState
  connectRequests: GetMyLikesState
  notificationHistory: GetMyChatsState
  chats: UserChatsState
  channel: ChannelState
  feed: FeedState
  discussion: DiscussionState
  allUsers: AllUsersState
  allDeletedUsers: AllDeletedUsersState
  chatMessages: ReceiveMessagesForChatState
  notification: NotificationState
  code: string
  fullChatUser: FullChatUserState
}

const combinedReducer = combineReducers({
  allDeletedUsers: allDeletedUsersReducer,
  allUsers: allUsersReducer,
  auth: authReducer,
  channel: channelReducer,
  chatMessages: receiveMessagesForChatReducer,
  chats: chatsReducer,
  code: checkCodeReducer,
  connectRequests: connectRequestsReducer,
  currentUser: currentUserReducer,
  feed: feedReducer,
  fullChatUser: fullChatUserReducer,
  notification: notificationReducer,
  notificationHistory: notificationHistoryReducer,
  users: usersReducer
})

export const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_STORE) {
    return initialState
  }

  return combinedReducer(state, action)
}
