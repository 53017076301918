export { default as ArrowOff } from './ArrowOff.png'
export { default as ArrowOn } from './ArrowOn.png'
export { default as Close } from './close.png'
export { default as CompatibilityBackground } from './compatibilityBackground.png'
export { default as DefaultImage } from './defaultImage.png'
export { default as FirstScreenBackground } from './firstScreenBackground.png'
export { default as HideEye } from './HideEye.png'
export { default as Plus } from './Plus.png'
export { default as ScaleArrow } from './scaleArrow.png'
export { default as SendIcon } from './sendIcon.png'
export { default as ShowEye } from './ShowEye.png'
export { default as StartSelectionBackground } from './startSelectionBackground.png'
export { default as UserDeleted } from './userDeleted.png'
