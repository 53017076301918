import { useEffect } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Plus } from '@assets/images'
import { Button, ChatList, ConnectRequests, EmptyMessage } from '@components'
import { useChatList } from '@hooks'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectMyLikes } from '@redux/selectors'

const styles = StyleSheet.create({
  addChat: {
    bottom: 10,
    position: 'absolute',
    right: 30,
    width: 60
  },
  plus: {
    height: 30,
    tintColor: 'white',
    width: 30
  }
})

export const MyTelegram = () => {
  const { navigation } = useNavigator<'Message'>()
  const { getMyLikes, getAllDeletedUsers } = useDataLayer()
  const requests = useSelector(selectMyLikes)
  const { chats } = useChatList()

  useEffect(() => {
    getMyLikes()
    getAllDeletedUsers()
  }, [])

  if (!requests.length && !chats.length) {
    return (
      <EmptyMessage
        mainTitle='Начните общение в Connect!'
        subTitle='Отправьте запрос пользователю и общайтесь в Connect'
      />
    )
  }

  return (
    <View style={{ flex: 1, padding: 24 }}>
      <ConnectRequests requests={requests} />
      <ChatList chats={chats} />
      {/* <Button style={styles.addChat} onPress={() => navigation.navigate('AddChat')}>
        <Image source={Plus} style={styles.plus} />
      </Button> */}
    </View>
  )
}
