import { useEffect, useState } from 'react'
import { Image, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { BackSvg, LogoSvg } from '@assets/icons'
import { Button, Typography } from '@components'
import { screenHeight } from '@components/utils'
import { useNavigator } from '@navigation'
import { selectChatsState, selectCurrentUserData } from '@redux/selectors'
import { SERVER_URL } from '@services/apiService'
import { ChatTypes, UserChat } from '@types'

const styles = StyleSheet.create({
  centerWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 43
  },
  iconWrapper: {
    flexDirection: 'row',
    left: 10,
    marginBottom: 5
  },
  mainContainer: {
    backgroundColor: '#f3f2f9'
  },
  topWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 30,
    paddingTop: 40
  }
})

export const Match = () => {
  const { route, navigation } = useNavigator<'Match'>()
  const { user } = route.params
  const currentUser = useSelector(selectCurrentUserData)
  const chatsState = useSelector(selectChatsState)
  const [chat, setChat] = useState<UserChat | null>(null)

  const goToMessage = () => navigation.navigate('Message')

  const goToChat = () => {
    if (!chat) {
      return alert('Чат не найден. Пожалуйста, попробуйте позже.')
    }

    if (!currentUser) {
      return alert('Данные пользователя не загружены. Пожалуйста, попробуйте позже.')
    }

    navigation.navigate('Chat', {
      chat,
      userId: currentUser.userChatId
    })
  }

  useEffect(() => {
    const ch = chatsState.find(
      el =>
        el.chatType === ChatTypes.PRIVATE &&
        ((el.creator.username === currentUser?.login && el.participants[0].username === user.login) ||
          (el.participants[0].username === currentUser?.login && el.creator.username === user.login))
    )

    setChat(ch || null)
  }, [chatsState])

  return (
    <View style={styles.mainContainer}>
      <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />
      <View style={[styles.topWrapper, { marginBottom: screenHeight * 0.22 }]}>
        <TouchableOpacity onPress={goToMessage}>
          <BackSvg />
        </TouchableOpacity>
        <Typography f17 semibold>
          {user.name}
        </Typography>
        <View style={{ height: 40, width: 40 }} />
      </View>
      <View style={styles.centerWrapper}>
        <Typography f27 extraBold marginBottom={12}>
          Совпадение!
        </Typography>
        <Typography f16 semibold textAlign='center' marginBottom={32}>
          Поздравляем, Вы и {user.name} лайкнули друг друга
        </Typography>
        <View style={styles.iconWrapper}>
          <Image
            source={{
              uri: `${SERVER_URL}/${currentUser?.profileImage[0]?.name}`
            }}
            style={{ borderRadius: 60, height: 105, right: 10, width: 105 }}
          />
          <Image
            source={{ uri: `${SERVER_URL}/${user?.profileImage[0]?.name}` }}
            style={{ borderRadius: 60, height: 105, right: 10, width: 105 }}
          />
        </View>
        <View style={{ marginBottom: screenHeight * 0.2 }}>
          <LogoSvg width={89} height={58} />
        </View>
        <Button disabled={!chat} onPress={goToChat} text='Перейти в чат' />
      </View>
    </View>
  )
}
