import { useEffect, useRef } from 'react'
import { StyleSheet } from 'react-native'
import {
  GooglePlaceData,
  GooglePlaceDetail,
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef
} from 'react-native-google-places-autocomplete'
import { DefaultTheme, TextInput } from 'react-native-paper'

import { AUTOCOMPLETE_INPUT_HEIGHT } from '@components/utils'
import { SERVER_URL } from '@services/apiService'

const GOOGLE_PLACES_API_KEY = 'AIzaSyDH2I3KGfapZgaLOzZfuq3oxOKlr3mNnLk'

const styles = StyleSheet.create({
  input: {
    backgroundColor: '#F3F2F9',
    fontSize: 14,
    height: AUTOCOMPLETE_INPUT_HEIGHT,
    width: '100%'
  }
})

const autocompleteStyles = {
  listView: {
    backgroundColor: '#F3F2F9',
    borderColor: '#A3A3A3',
    borderRadius: 20,
    borderWidth: 0.3,
    maxHeight: 230,
    width: '100%'
  },
  poweredContainer: {
    backgroundColor: 'red',
    display: 'none'
  },
  row: {
    backgroundColor: 'transparent'
  },
  textInputContainer: {
    backgroundColor: '#f3f2f9',
    height: AUTOCOMPLETE_INPUT_HEIGHT,
    width: '100%'
  }
}

const inputTheme = {
  colors: {
    ...DefaultTheme.colors,
    primary: '#2F2F2F'
  },
  roundness: 29
}

interface MapAutocompleteProps {
  location?: string
  onPlaceSelect: (data: GooglePlaceData, details: GooglePlaceDetail | null) => void
  placeholder: string
  types?: string
  clearInputOnSelect?: boolean
}

export const MapAutocomplete = ({
  onPlaceSelect,
  location,
  placeholder,
  types,
  clearInputOnSelect
}: MapAutocompleteProps) => {
  const ref = useRef<GooglePlacesAutocompleteRef | null>(null)

  useEffect(() => {
    if (!location) {
      return
    }

    if (clearInputOnSelect) {
      ref.current?.clear()
    } else {
      ref.current?.setAddressText(location)
    }
  }, [location])

  return (
    <GooglePlacesAutocomplete
      ref={ref}
      placeholder={placeholder}
      onPress={onPlaceSelect}
      debounce={200}
      query={{
        key: GOOGLE_PLACES_API_KEY,
        language: 'ru',
        types
      }}
      requestUrl={{
        headers: {
          Authorization: `${GOOGLE_PLACES_API_KEY}`
        },
        url: `${SERVER_URL}/google-places-autocomplete`,
        useOnPlatform: 'web'
      }}
      fetchDetails
      textInputProps={{
        InputComp: TextInput,
        mode: 'outlined',
        outlineStyle: { borderWidth: 1 },
        style: styles.input,
        theme: inputTheme
      }}
      styles={autocompleteStyles}
    />
  )
}
