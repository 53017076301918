/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getCurrentUserSuccess, notificationAction } from '@redux/actions'
import { CREATE_PASSWORD_REQUEST, CreatePasswordRequestAction, CreatePasswordResponse } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* createPassword(action: CreatePasswordRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.createPassword], action.payload.phone, action.payload.password)
    const data: CreatePasswordResponse = response.data
    yield put(getCurrentUserSuccess(data.user))
  } catch (error: any) {
    yield put(
      notificationAction({
        image: '',
        text: error.response.data.message,
        type: 'error'
      })
    )
  }
}

export function* createPasswordSaga() {
  yield takeLatest<CreatePasswordRequestAction>(CREATE_PASSWORD_REQUEST, createPassword)
}
