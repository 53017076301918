import { useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { AcceptGreenSvg, RejectRedSvg } from '@assets/icons'
import { Colors } from '@components/utils'
import { BottomSheetFooter, BottomSheetFooterProps } from '@gorhom/bottom-sheet'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserId, selectUserChatId } from '@redux/selectors'
import { UserProfile } from '@types'

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#e8e7ed',
    borderColor: '#f00',
    borderRadius: 31,
    borderWidth: 2,
    height: 58,
    justifyContent: 'center',
    width: 160
  },
  footer: {
    alignItems: 'center',
    backgroundColor: Colors.BackgroundModal,
    columnGap: 18,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 40,
    paddingTop: 8
  }
})

interface CustomFooterProps extends BottomSheetFooterProps {
  close?: () => void
  openConnection?: () => void
  goBack: () => void
  user: UserProfile
}

export const ModelBottomSheetFooterAccept = ({ animatedFooterPosition, user }: CustomFooterProps) => {
  const { navigation } = useNavigator<'Message' | 'Match'>()

  const { sendLike, getMyLikes, getUserChats } = useDataLayer()
  const currentUserId = useSelector(selectCurrentUserId)
  const currentUserChatId = useSelector(selectUserChatId)
  const [redirect, setRedirect] = useState('')

  const handleClick = async (type: string) => {
    setRedirect(type)
    sendLike({
      liked: user._id,
      message: { from: currentUserId, text: '', time: new Date().toString() },
      sender: currentUserId,
      type
    })
  }

  useEffect(() => {
    if (!redirect) {
      return
    }

    getMyLikes()
    getUserChats(currentUserChatId)
    setRedirect('')

    if (redirect === 'rejected') {
      navigation.navigate('Message')
    } else {
      navigation.navigate('Match', { user })
    }
  }, [redirect])

  return (
    <BottomSheetFooter animatedFooterPosition={animatedFooterPosition}>
      <View style={styles.footer}>
        <TouchableOpacity style={styles.button} onPress={() => handleClick('rejected')}>
          <RejectRedSvg />
        </TouchableOpacity>
        <TouchableOpacity style={[styles.button, { borderColor: '#1dd200' }]} onPress={() => handleClick('like')}>
          <AcceptGreenSvg />
        </TouchableOpacity>
      </View>
    </BottomSheetFooter>
  )
}
