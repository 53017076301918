/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { checkCodeSuccess, notificationAction } from '@redux/actions'
import { CHECK_CODE_REQUEST, CheckCodeRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* checkCode(action: CheckCodeRequestAction): Generator<SagaEffects, void, any> {
  try {
    const res = yield call([apiService, apiService.checkCode], action.payload.phone, action.payload.code)
    yield put(checkCodeSuccess(res.data.status))
    if (res.data.status !== 'OK') {
      yield put(
        notificationAction({
          image: '',
          text: 'Wrong code',
          type: 'error'
        })
      )
    }
  } catch (error: any) {
    yield put(
      notificationAction({
        image: '',
        text: 'Wrong code',
        type: 'error'
      })
    )
  }
}

export function* checkCodeSaga() {
  yield takeLatest<CheckCodeRequestAction>(CHECK_CODE_REQUEST, checkCode)
}
