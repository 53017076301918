import {
  CHILDREN,
  EDUCATION,
  FINANCIAL,
  GENRES,
  GOALS,
  HOBBIES,
  LANGUAGES,
  LGBT_TOLERANT,
  ORIENTATION,
  PETS,
  RELATIONSHIP,
  RELIGION,
  SEX,
  VALUABLES
} from '@utils'

interface DictionaryItem {
  id: number | string
  slug: string
  label: string
}

const formatDict = (dict: DictionaryItem[]) =>
  dict.reduce(
    (initial, { id, label }) => ({
      ...initial,
      [id]: label
    }),
    {}
  )

export enum DictionaryEnum {
  cinema = 'cinema',
  literature = 'literature',
  music = 'music',
  religion = 'religion',
  valuable = 'valuable',
  hobby = 'hobby',
  language = 'language',
  education = 'education',
  orientation = 'orientation',
  lgbtTolerant = 'lgbtTolerant',
  goal = 'goal',
  relationship = 'relationship',
  children = 'children',
  sex = 'sex',
  pets = 'pets',
  financial = 'financial'
}

type FormattedDictionary = {
  [key in DictionaryEnum]: {
    [x: string]: string
  }
}

interface ErrorDict {
  [key: string]: { ru: string }
}

export const errorDict: ErrorDict = {
  'Bad date of birth': {
    ru: 'Некорректная дата рождения'
  },
  'Bad file': {
    ru: 'Некорректный файл'
  },
  'Bad login only lower case letters, dot, and dash are alowed': {
    ru: 'Некорректный логин, логин должен состоять из: латинских малельких букв, точка, нижнее подчеркивание'
  },
  'Bad name': {
    ru: 'Некорректное имя пользователя'
  },
  'Cant delete file': {
    ru: 'Невоозможно удалить видео'
  },
  'Cant upload video': {
    ru: 'Невоозможно загрузить видео'
  },
  'File is empty': {
    ru: 'Файл пустой'
  },
  'Invalid image': {
    ru: 'Некорректное изображение'
  },
  'Invalid new password': {
    ru: 'Некорректный новый пароль'
  },
  'Only 3 attempts per day': {
    ru: 'Доступно только три запроса в день'
  },
  'Password validation': {
    ru: 'Некорректный пароль'
  },
  'User doesn`t exist': {
    ru: 'Пользователя не существует'
  },
  'User with this nickname already exist': {
    ru: 'Пользователь с таким никнеймом уже существует'
  },
  'Wrong code': {
    ru: 'Неправильный код'
  },
  'Wrong old password': {
    ru: 'Неправильный старый пароль'
  },
  'Wrong password': {
    ru: 'Неправильный пароль'
  }
}

const formattedDictionaries: FormattedDictionary = {
  children: formatDict(CHILDREN),
  cinema: formatDict(GENRES.Cinema),
  education: formatDict(EDUCATION),
  financial: formatDict(FINANCIAL),
  goal: formatDict(GOALS),
  hobby: formatDict(
    HOBBIES.map(({ children, ...rest }) => {
      return children ? [rest, ...children] : [rest]
    }).flat()
  ),
  language: formatDict(LANGUAGES),
  lgbtTolerant: formatDict(LGBT_TOLERANT),
  literature: formatDict(GENRES.Literature),
  music: formatDict(GENRES.Music),
  orientation: formatDict(ORIENTATION),
  pets: formatDict(PETS),
  relationship: formatDict(RELATIONSHIP),
  religion: formatDict(RELIGION),
  sex: formatDict(SEX),
  valuable: formatDict(VALUABLES)
}

export const useLabels = () => {
  // we'll add useTranslation here in future
  // thats why we create it like a hook not util function
  return (id: number | string, dict: DictionaryEnum): string => {
    return id !== -1 ? formattedDictionaries[dict][id] : ''
  }
}
