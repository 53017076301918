import { ReactNode } from 'react'
import { Pressable, StyleSheet, ViewStyle } from 'react-native'

import { Typography } from '@components'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    alignSelf: 'flex-start',
    borderRadius: 50,
    flexDirection: 'row',
    gap: 10,
    maxHeight: 40,
    paddingVertical: 10
  }
})

interface ChipProps {
  label?: string
  rightIcon?: ReactNode
  leftIcon?: ReactNode
  onPress?: () => void
  style?: ViewStyle
  bgColor?: string
  textColor?: string
  fontSize?: number
  fontWeight?: 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'
  paddingHorizontal?: number
}

export const Chip = ({
  label,
  rightIcon,
  leftIcon,
  onPress,
  bgColor = '#E8E7ED',
  textColor = '#2C2C2C',
  paddingHorizontal = 12,
  fontWeight = '400',
  fontSize = 14,
  style
}: ChipProps) => (
  <Pressable
    onPress={onPress}
    style={({ pressed }) => [
      styles.container,
      { backgroundColor: bgColor, opacity: onPress && pressed ? 0.5 : 1, paddingHorizontal },
      style
    ]}
  >
    {leftIcon}
    {label && (
      <Typography numberOfLines={1} style={{ color: textColor, fontSize, fontWeight, lineHeight: fontSize }}>
        {label}
      </Typography>
    )}
    {rightIcon}
  </Pressable>
)
