import { ProfileOptions } from '@utils'

export const VALUABLES: ProfileOptions = [
  { id: 0, label: 'Бодипозитив', slug: 'bodyPositivity' },
  { id: 1, label: 'Вечеринки, тусовки, развлечения', slug: 'partiesEntertainment' },
  { id: 2, label: 'Гедонизм', slug: 'hedonism' },
  { id: 3, label: 'Друзья, общение', slug: 'friendsSocializing' },
  { id: 4, label: 'Духовные ценности', slug: 'spiritualValues' },
  { id: 5, label: 'Здоровье и красота', slug: 'healthBeauty' },
  { id: 6, label: 'Карьера, бизнес и социальный статус', slug: 'careerBusinessSocialStatus' },
  { id: 7, label: 'Материальное благополучие', slug: 'materialWellBeing' },
  { id: 8, label: 'Патриотизм', slug: 'patriotism' },
  { id: 9, label: 'Помощь другим', slug: 'helpingOthers' },
  { id: 10, label: 'Права ЛГБТ', slug: 'lgbtRights' },
  { id: 11, label: 'Самообразование', slug: 'selfEducation' },
  { id: 12, label: 'Свобода суждений и поступков', slug: 'freedomOfJudgmentAndActions' },
  { id: 13, label: 'Семья, дом, дети', slug: 'familyHomeChildren' },
  { id: 14, label: 'Спорт', slug: 'sports' },
  { id: 15, label: 'Творческая самореализация', slug: 'creativeSelfRealization' },
  { id: 16, label: 'Феминизм', slug: 'feminism' },
  { id: 17, label: 'Чайлдфри', slug: 'childfree' }
]
