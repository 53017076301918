import { useSelector } from 'react-redux'
import moment from 'moment'
import { createSelector } from 'reselect'

import { DefaultImage, UserDeleted } from '@assets/images'
import { formatUserName } from '@components/utils'
import { RootState } from '@redux/reducer'
import { SERVER_URL } from '@services/apiService'
import { ChatTypes } from '@types'

const getAllDeletedUsers = (state: RootState) => state.allDeletedUsers
const getChatsData = (state: RootState) => state.chats
export const getUserChatId = (state: RootState) => state.currentUser?.userChatId

const getFormattedChats = createSelector(
  [getChatsData, getUserChatId, getAllDeletedUsers],
  (chatsData, userId, deletedUsers) => {
    const deletedUserChatIds: number[] = deletedUsers.map(u => u.userChatId)
    return (chatsData || [])
      .filter(chat => chat.chatType !== ChatTypes.CHANNEL)
      .map(chat => {
        const { id, creator, participants, chatName, chatType, lastMessage, unreadMessages, createdBy } = chat

        const isCreator = creator.user_id === userId
        const participantIds = participants.map(p => p.user_id)
        const isUserDeleted = deletedUserChatIds.some(_id => participantIds.includes(_id) || _id === createdBy)

        let formattedChatName = formatUserName(chatName)
        let photoSource: any = DefaultImage

        if (isUserDeleted) {
          formattedChatName = 'Пользователь удалён'
          photoSource = UserDeleted
        } else if (chatType === ChatTypes.PRIVATE) {
          formattedChatName = formatUserName(isCreator ? participants[0].name : creator.name)
          photoSource = {
            uri: `${SERVER_URL}/${isCreator ? participants[0].profile_photo[0] : creator.profile_photo[0]}`
          }
        } else if (chatType === ChatTypes.GROUP && !!chat.image) {
          photoSource = {
            uri: `${SERVER_URL}/${chat.image}`
          }
        }

        let formattedLastMessage = lastMessage?.content || 'Пока нет сообщений'
        let time = ''

        if (lastMessage?.user_id === userId) {
          formattedLastMessage = `Вы: ${lastMessage.content}`
        }

        if (lastMessage?.message_type === 'attachment') {
          formattedLastMessage = 'Вложение'
        }

        if (lastMessage) {
          time = `${lastMessage.timestamp}`
        }

        return {
          chat,
          id,
          message: formattedLastMessage,
          name: formattedChatName,
          participants,
          photo: photoSource,
          time,
          unreadMessages
        }
      })
      .sort((a, b) => moment(b.time).valueOf() - moment(a.time).valueOf())
  }
)

export const useChatList = () => {
  const chats = useSelector(getFormattedChats)
  return { chats }
}
