import { Image, ScrollView, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { BackSvg } from '@assets/icons'
import { Chip, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useCurrentUserAge } from '@hooks'
import { useNavigator } from '@navigation'
import { selectCurrentUserData } from '@redux/selectors'
import { MenuItemWithPreview } from '@screens'
import { SERVER_URL } from '@services/apiService'
import { DictionaryEnum as Dicts, GOALS, useLabels } from '@utils'

const styles = StyleSheet.create({
  mainWrapper: {
    paddingBottom: 40,
    paddingHorizontal: 26,
    paddingTop: 15
  },
  profileContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 60,
    paddingHorizontal: 16,
    paddingVertical: 8
  },
  topWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    paddingHorizontal: 30,
    paddingTop: isIOS ? 50 : 15
  }
})

export const InformationYourself = () => {
  const { navigation } = useNavigator<'MainProfile'>()
  const currentUser = useSelector(selectCurrentUserData)
  const { isAdult } = useCurrentUserAge()

  const goToMainProfile = () => {
    navigation.navigate('MainProfile')
  }

  const l = useLabels()

  const lArr = (arr: string[] | number[], DICT: Dicts) => {
    return arr.map(el => l(el, DICT))
  }

  const getGoals = (arr: string[] | number[]): string[] => {
    return arr
      .map(el => {
        if (el === 4) {
          return GOALS[2].children?.[0]?.label
        }
        if (el === 5) {
          return GOALS[2].children?.[1]?.label
        }
        return l(el, Dicts.goal)
      })
      .filter(Boolean) as string[]
  }

  if (!currentUser) {
    return null
  }

  const { profile, profileImage } = currentUser

  return (
    <>
      <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />

      <View style={styles.topWrapper}>
        <TouchableOpacity onPress={goToMainProfile}>
          <BackSvg />
        </TouchableOpacity>

        <Typography f17 semibold>
          Данные о себе
        </Typography>
        <View style={{ width: 40 }} />
      </View>
      <ScrollView style={styles.mainWrapper} showsVerticalScrollIndicator={false}>
        <View style={styles.profileContainer}>
          <MenuItemWithPreview title='Фото' page='PersonalPhoto'>
            {profileImage.map(image => (
              <Image
                key={image.name}
                source={{ uri: `${SERVER_URL}/${image.name}` }}
                style={{ borderRadius: 12, height: 92, marginRight: 10, width: 92 }}
              />
            ))}
          </MenuItemWithPreview>

          <MenuItemWithPreview title='Цели' page='Goals'>
            {getGoals(profile.goal).map(el => (
              <Chip label={el} key={el} />
            ))}
          </MenuItemWithPreview>

          <MenuItemWithPreview title='Местоположение' page='Location'>
            <Chip label={profile.userGeo.name} />
          </MenuItemWithPreview>
          {isAdult && (
            <>
              <MenuItemWithPreview title='Сексуальная ориентация' page='SexualOrientation'>
                {profile.sexuality !== -1 && <Chip label={l(profile.sexuality, Dicts.orientation)} />}
              </MenuItemWithPreview>

              <MenuItemWithPreview title='Отношение к ЛГБТ' page='SexualOrientation'>
                {profile.lgbtTolerant !== -1 && <Chip label={l(profile.lgbtTolerant, Dicts.lgbtTolerant)} />}
              </MenuItemWithPreview>
            </>
          )}

          <MenuItemWithPreview title='Языки' page='Languages'>
            {lArr(profile.langs, Dicts.language).map(el => (
              <Chip label={el} key={el} />
            ))}
          </MenuItemWithPreview>

          {isAdult && (
            <>
              <MenuItemWithPreview title='Религия' page='ReligionChildren'>
                {profile.religion !== -1 && <Chip label={l(profile.religion, Dicts.religion)} />}
              </MenuItemWithPreview>

              <MenuItemWithPreview title='Семейное положение' page='ReligionChildren'>
                {profile.relationshipStatus !== -1 && (
                  <Chip label={l(profile.relationshipStatus, Dicts.relationship)} />
                )}
              </MenuItemWithPreview>

              <MenuItemWithPreview title='Дети' page='ReligionChildren'>
                {profile.hasChildren !== -1 && <Chip label={l(profile.hasChildren, Dicts.children)} />}
              </MenuItemWithPreview>

              <MenuItemWithPreview title='Работа' page='Works'>
                {profile.workPosition && <Chip label={`Work ${profile.workPosition} in ${profile.workName || '-'}`} />}
              </MenuItemWithPreview>

              <MenuItemWithPreview title='Финансовое положение' page='Financial'>
                {profile.financial !== -1 && <Chip label={l(profile.financial, Dicts.financial)} />}
              </MenuItemWithPreview>

              <MenuItemWithPreview title='Степень образования' page='Education'>
                {profile.educationGrade !== -1 && <Chip label={l(profile.educationGrade, Dicts.education)} />}
              </MenuItemWithPreview>
            </>
          )}

          <MenuItemWithPreview title='Увлечения' page='Hobbies'>
            {lArr(profile.hobbies, Dicts.hobby).map(el => (
              <Chip label={el} key={el} />
            ))}
          </MenuItemWithPreview>

          <MenuItemWithPreview title='Посещаемые места' page='VisitedPlaces'>
            {profile.places.map(el => (
              <Chip label={el.name} key={el.name} />
            ))}
          </MenuItemWithPreview>

          <MenuItemWithPreview title='Предпочтения: Фильмы' page='Preferences'>
            {lArr(profile.movieGenre, Dicts.cinema).map(el => (
              <Chip label={el} key={el} />
            ))}
          </MenuItemWithPreview>

          <MenuItemWithPreview title='Предпочтения: Книги' page='Preferences'>
            {lArr(profile.bookGenre, Dicts.literature).map(el => (
              <Chip label={el} key={el} />
            ))}
          </MenuItemWithPreview>

          <MenuItemWithPreview title='Предпочтения: Музыка' page='Preferences'>
            {lArr(profile.musicGenre, Dicts.music).map(el => (
              <Chip label={el} key={el} />
            ))}
          </MenuItemWithPreview>

          {isAdult && (
            <MenuItemWithPreview title='Ценности' page='Quality'>
              {lArr(profile.valuables, Dicts.valuable).map(el => (
                <Chip label={el} key={el} />
              ))}
            </MenuItemWithPreview>
          )}
          <MenuItemWithPreview title='О Себе' page='ChangeTellYourself' />
        </View>
      </ScrollView>
    </>
  )
}
