import {
  SEND_READ_MESSAGE_REQUEST,
  SEND_READ_MESSAGES_REQUEST,
  SendReadMessage,
  SendReadMessageRequestAction,
  SendReadMessages,
  SendReadMessagesRequestAction
} from '@redux/types'

export const sendReadMessageRequest = (messageId: SendReadMessage): SendReadMessageRequestAction => ({
  payload: messageId,
  type: SEND_READ_MESSAGE_REQUEST
})

export const sendReadMessagesRequest = (payload: SendReadMessages): SendReadMessagesRequestAction => ({
  payload,
  type: SEND_READ_MESSAGES_REQUEST
})
