import { AUTH_SUCCESS, AuthSuccessAction } from '@redux/types'
import { FacebookAuth, GoogleAuth, InstagramAuth } from '@types'

interface IAuthSuccessAction {
  instagramAuth?: InstagramAuth
  facebookAuth?: FacebookAuth
  googleAuth?: GoogleAuth
  phone?: string
  status: string
  deleted: boolean
}

export const authSuccessAction = ({
  instagramAuth,
  facebookAuth,
  googleAuth,
  phone,
  status,
  deleted
}: IAuthSuccessAction): AuthSuccessAction => ({
  payload: {
    deleted,
    facebookAuth,
    googleAuth,
    instagramAuth,
    phone,
    status
  },
  type: AUTH_SUCCESS
})
