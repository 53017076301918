import { manipulateAsync, SaveFormat } from 'expo-image-manipulator'

import { isWeb } from '@components/utils'
import { useDataLayer } from '@redux/dataLayer'

const dataURItoBlob = (dataURI: string) => {
  const [type, data] = dataURI.split(',')
  // convert base64/URLEncoded data component to raw binary data held in a string
  const byteString = type.includes('base64') ? atob(data) : unescape(data)

  // separate out the mime component
  const mimeString = type.split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  const res = new Blob([ia], { type: mimeString })
  return res
}

export const useUploadImage = () => {
  const { uploadImage } = useDataLayer()

  const uploadPhoto = async (newUri: string) => {
    const formData = new FormData()
    const localUri = (
      await manipulateAsync(newUri, [{ resize: { height: 1920, width: 1080 } }], { format: SaveFormat.PNG })
    ).uri

    const filename = localUri.split('/').pop()

    formData.append(
      'image',
      isWeb ? dataURItoBlob(localUri) : ({ name: filename, type: 'image/png', uri: localUri } as unknown as Blob)
    )

    uploadImage(formData)
  }

  return { uploadPhoto }
}
