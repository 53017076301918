export const LANG_HOBBIES = [
    {
        "id": "0",
        "slug": "handmade",
        "label": {
            "ru": "Handmade",
            "uk": "Ручна робота",
            "en": "Handmade"
        },
        "children": [
            {
                "id": "1_0",
                "slug": "handmade",
                "label": {
                    "ru": "Handmade",
                    "uk": "Ручна робота",
                    "en": "Handmade"
                }
            },
            {
                "id": "1_1",
                "slug": "diamondMosaic",
                "label": {
                    "ru": "Алмазная мозайка",
                    "uk": "Алмазна мозаїка",
                    "en": "Diamond Mosaic"
                }
            },
            {
                "id": "1_2",
                "slug": "batik",
                "label": {
                    "ru": "Батик",
                    "uk": "Батик",
                    "en": "Batik"
                }
            },
            {
                "id": "1_3",
                "slug": "beadCraft",
                "label": {
                    "ru": "Бисероплетение",
                    "uk": "Бісероплетіння",
                    "en": "Bead Craft"
                }
            },
            {
                "id": "1_4",
                "slug": "brooches",
                "label": {
                    "ru": "Броши",
                    "uk": "Брошки",
                    "en": "Brooches"
                }
            },
            {
                "id": "1_5",
                "slug": "pyrography",
                "label": {
                    "ru": "Выжигание",
                    "uk": "Випалювання",
                    "en": "Pyrography"
                }
            },
            {
                "id": "1_6",
                "slug": "crystalGrowing",
                "label": {
                    "ru": "Выращивание кристаллов",
                    "uk": "Вирощування кристалів",
                    "en": "Crystal Growing"
                }
            },
            {
                "id": "1_7",
                "slug": "knitting",
                "label": {
                    "ru": "Вязание",
                    "uk": "В'язання",
                    "en": "Knitting"
                }
            },
            {
                "id": "1_8",
                "slug": "pottery",
                "label": {
                    "ru": "Гончарное дело",
                    "uk": "Гончарство",
                    "en": "Pottery"
                }
            },
            {
                "id": "1_9",
                "slug": "decorativeCanning",
                "label": {
                    "ru": "Декоративное консервирование",
                    "uk": "Декоративне консервування",
                    "en": "Decorative Canning"
                }
            },
            {
                "id": "1_10",
                "slug": "decoupage",
                "label": {
                    "ru": "Декупаж",
                    "uk": "Декупаж",
                    "en": "Decoupage"
                }
            },
            {
                "id": "1_11",
                "slug": "candleMaking",
                "label": {
                    "ru": "Изготовление свечей",
                    "uk": "Виготовлення свічок",
                    "en": "Candle Making"
                }
            },
            {
                "id": "1_12",
                "slug": "jewelryMaking",
                "label": {
                    "ru": "Изготовление украшений",
                    "uk": "Виготовлення прикрас",
                    "en": "Jewelry Making"
                }
            },
            {
                "id": "1_13",
                "slug": "isography",
                "label": {
                    "ru": "Изографика",
                    "uk": "Ізографіка",
                    "en": "Isography"
                }
            },
            {
                "id": "1_14",
                "slug": "isonit",
                "label": {
                    "ru": "Изонить",
                    "uk": "Ізоніт",
                    "en": "Isonit"
                }
            },
            {
                "id": "1_15",
                "slug": "ikebana",
                "label": {
                    "ru": "Икебана",
                    "uk": "Ікебана",
                    "en": "Ikebana"
                }
            },
            {
                "id": "1_16",
                "slug": "kanzashi",
                "label": {
                    "ru": "Канзаши",
                    "uk": "Канзаші",
                    "en": "Kanzashi"
                }
            },
            {
                "id": "1_17",
                "slug": "carving",
                "label": {
                    "ru": "Карвинг - резьба",
                    "uk": "Карвінг - різьба",
                    "en": "Carving"
                }
            },
            {
                "id": "1_18",
                "slug": "customizing",
                "label": {
                    "ru": "Кастомайзинг",
                    "uk": "Кастомайзинг",
                    "en": "Customizing"
                }
            },
            {
                "id": "1_19",
                "slug": "quilling",
                "label": {
                    "ru": "Квиллинг",
                    "uk": "Квілінг",
                    "en": "Quilling"
                }
            },
            {
                "id": "1_20",
                "slug": "rugWeaving",
                "label": {
                    "ru": "Ковроплетение",
                    "uk": "Килимоплетіння",
                    "en": "Rug Weaving"
                }
            },
            {
                "id": "1_21",
                "slug": "lace",
                "label": {
                    "ru": "Кружево",
                    "uk": "Кружево",
                    "en": "Lace"
                }
            },
            {
                "id": "1_22",
                "slug": "blacksmithing",
                "label": {
                    "ru": "Кузнечное дело",
                    "uk": "Кузнечне діло",
                    "en": "Blacksmithing"
                }
            },
            {
                "id": "1_23",
                "slug": "dolls",
                "label": {
                    "ru": "Куклы",
                    "uk": "Ляльки",
                    "en": "Dolls"
                }
            },
            {
                "id": "1_24",
                "slug": "wickerwork",
                "label": {
                    "ru": "Лозоплетение",
                    "uk": "Плетіння з лози",
                    "en": "Wickerwork"
                }
            },
            {
                "id": "1_25",
                "slug": "macrame",
                "label": {
                    "ru": "Макраме",
                    "uk": "Макраме",
                    "en": "Macrame"
                }
            },
            {
                "id": "1_26",
                "slug": "soapMaking",
                "label": {
                    "ru": "Мыловарение",
                    "uk": "Миловаріння",
                    "en": "Soap Making"
                }
            },
            {
                "id": "1_27",
                "slug": "origami",
                "label": {
                    "ru": "Оригами",
                    "uk": "Оригамі",
                    "en": "Origami"
                }
            },
            {
                "id": "1_28",
                "slug": "patchwork",
                "label": {
                    "ru": "Пэчворк",
                    "uk": "Печворк",
                    "en": "Patchwork"
                }
            },
            {
                "id": "1_29",
                "slug": "gingerbreadPainting",
                "label": {
                    "ru": "Роспись пряников",
                    "uk": "Розпис пряників",
                    "en": "Gingerbread Painting"
                }
            },
            {
                "id": "1_30",
                "slug": "sweetDesign",
                "label": {
                    "ru": "Свит-дизайн",
                    "uk": "Солодкий дизайн",
                    "en": "Sweet Design"
                }
            },
            {
                "id": "1_31",
                "slug": "scrapbooking",
                "label": {
                    "ru": "Скрапбукинг",
                    "uk": "Скрапбукінг",
                    "en": "Scrapbooking"
                }
            },
            {
                "id": "1_32",
                "slug": "buildingConstructionSets",
                "label": {
                    "ru": "Собирать конструктор",
                    "uk": "Збирати конструктор",
                    "en": "Building Construction Sets"
                }
            },
            {
                "id": "1_33",
                "slug": "creatingMetalProducts",
                "label": {
                    "ru": "Создание изделий из металла",
                    "uk": "Створення виробів з металу",
                    "en": "Creating Metal Products"
                }
            },
            {
                "id": "1_34",
                "slug": "creatingMiniatureCarModels",
                "label": {
                    "ru": "Создание мини-копий машин",
                    "uk": "Створення міні-копій автомобілів",
                    "en": "Creating Miniature Car Models"
                }
            },
            {
                "id": "1_35",
                "slug": "stringArt",
                "label": {
                    "ru": "Стринг Арт",
                    "uk": "Стрінг Арт",
                    "en": "String Art"
                }
            },
            {
                "id": "1_36",
                "slug": "taxidermy",
                "label": {
                    "ru": "Таксидермия",
                    "uk": "Таксидермія",
                    "en": "Taxidermy"
                }
            },
            {
                "id": "1_37",
                "slug": "twisting",
                "label": {
                    "ru": "Твистинг",
                    "uk": "Твістинг",
                    "en": "Twisting"
                }
            },
            {
                "id": "1_38",
                "slug": "topiary",
                "label": {
                    "ru": "Топиарии",
                    "uk": "Топіарії",
                    "en": "Topiary"
                }
            },
            {
                "id": "1_39",
                "slug": "felting",
                "label": {
                    "ru": "Фелтинг",
                    "uk": "Фелтинг",
                    "en": "Felting"
                }
            },
            {
                "id": "1_40",
                "slug": "sewing",
                "label": {
                    "ru": "Шитье",
                    "uk": "Шиття",
                    "en": "Sewing"
                }
            }
        ]
    },
    {
        "id": "1",
        "slug": "painting",
        "label": {
            "ru": "Рисование",
            "uk": "Малювання",
            "en": "Drawing"
        },
        "children": [
            {
                "id": "2_0",
                "slug": "painting",
                "label": {
                    "ru": "Рисование",
                    "uk": "Малювання",
                    "en": "Drawing"
                }
            },
            {
                "id": "2_1",
                "slug": "bodyArt",
                "label": {
                    "ru": "Бодиарт",
                    "uk": "Бодіарт",
                    "en": "Body Art"
                }
            },
            {
                "id": "2_2",
                "slug": "bodyPainting",
                "label": {
                    "ru": "Бодипейнтинг",
                    "uk": "Бодіпейнтинг",
                    "en": "Body Painting"
                }
            },
            {
                "id": "2_3",
                "slug": "stainedGlassPainting",
                "label": {
                    "ru": "Витражная живопись",
                    "uk": "Вітражний живопис",
                    "en": "Stained Glass Painting"
                }
            },
            {
                "id": "2_4",
                "slug": "graffiti",
                "label": {
                    "ru": "Графитти",
                    "uk": "Графіті",
                    "en": "Graffiti"
                }
            },
            {
                "id": "2_5",
                "slug": "doodlingAndZentangle",
                "label": {
                    "ru": "Дудлинг и зентангл",
                    "uk": "Дудлінг і зентангл",
                    "en": "Doodling and Zentangle"
                }
            },
            {
                "id": "2_6",
                "slug": "painting",
                "label": {
                    "ru": "Живопись",
                    "uk": "Живопис",
                    "en": "Painting"
                }
            },
            {
                "id": "2_7",
                "slug": "iconography",
                "label": {
                    "ru": "Иконография",
                    "uk": "Іконографія",
                    "en": "Iconography"
                }
            },
            {
                "id": "2_8",
                "slug": "iconPainting",
                "label": {
                    "ru": "Иконопись",
                    "uk": "Іконопис",
                    "en": "Icon Painting"
                }
            },
            {
                "id": "2_9",
                "slug": "calligraphy",
                "label": {
                    "ru": "Каллиграфия",
                    "uk": "Каліграфія",
                    "en": "Calligraphy"
                }
            },
            {
                "id": "2_10",
                "slug": "blotPainting",
                "label": {
                    "ru": "Кляксография",
                    "uk": "Кляксографія",
                    "en": "Blot Painting"
                }
            },
            {
                "id": "2_11",
                "slug": "marbling",
                "label": {
                    "ru": "Марблинг",
                    "uk": "Мармурування",
                    "en": "Marbling"
                }
            },
            {
                "id": "2_12",
                "slug": "mehndi",
                "label": {
                    "ru": "Мехенди",
                    "uk": "Мехенді",
                    "en": "Mehndi"
                }
            },
            {
                "id": "2_13",
                "slug": "mosaic",
                "label": {
                    "ru": "Мозаика",
                    "uk": "Мозаїка",
                    "en": "Mosaic"
                }
            },
            {
                "id": "2_14",
                "slug": "monotype",
                "label": {
                    "ru": "Монотипия",
                    "uk": "Монотипія",
                    "en": "Monotype"
                }
            },
            {
                "id": "2_15",
                "slug": "threadGraphics",
                "label": {
                    "ru": "Ниткография",
                    "uk": "Ниткографія",
                    "en": "Thread Graphics"
                }
            },
            {
                "id": "2_16",
                "slug": "rightHemisphereDrawing",
                "label": {
                    "ru": "Правополушарное рисование",
                    "uk": "Правопівкульне малювання",
                    "en": "Right Hemisphere Drawing"
                }
            },
            {
                "id": "2_17",
                "slug": "sandDrawing",
                "label": {
                    "ru": "Рисование песком",
                    "uk": "Малювання піском",
                    "en": "Sand Drawing"
                }
            },
            {
                "id": "2_18",
                "slug": "glassPainting",
                "label": {
                    "ru": "Роспись стекла",
                    "uk": "Розпис скла",
                    "en": "Glass Painting"
                }
            },
            {
                "id": "2_19",
                "slug": "sketching",
                "label": {
                    "ru": "Скетчинг",
                    "uk": "Ескізування",
                    "en": "Sketching"
                }
            },
            {
                "id": "2_20",
                "slug": "temariArt",
                "label": {
                    "ru": "Темари арт",
                    "uk": "Темарі арт",
                    "en": "Temari Art"
                }
            },
            {
                "id": "2_21",
                "slug": "facePainting",
                "label": {
                    "ru": "Фейспеинтинг",
                    "uk": "Фейсарт",
                    "en": "Face Painting"
                }
            },
            {
                "id": "2_22",
                "slug": "freezlight",
                "label": {
                    "ru": "Фризлайт",
                    "uk": "Фризлайт",
                    "en": "Freezlight"
                }
            },
            {
                "id": "2_23",
                "slug": "ebru",
                "label": {
                    "ru": "Эбру",
                    "uk": "Ебру",
                    "en": "Ebru"
                }
            },
            {
                "id": "2_24",
                "slug": "encaustic",
                "label": {
                    "ru": "Энкаустика",
                    "uk": "Енкаустика",
                    "en": "Encaustic"
                }
            }
        ]
    },
    {
        "id": "2",
        "slug": "poemsAndHandWritting",
        "label": {
            "ru": "Поэзия и писательство",
            "uk": "Поезія та письменство",
            "en": "Poetry and Writing"
        },
        "children": [
            {
                "id": "2_0",
                "slug": "poemsAndHandWritting",
                "label": {
                    "ru": "Поэзия и писательство",
                    "uk": "Поезія та письменство",
                    "en": "Poetry and Writing"
                }
            }
        ]
    },
    {
        "id": "3",
        "slug": "postScrolling",
        "label": {
            "ru": "Посткроссинг",
            "uk": "Посткросинг",
            "en": "Postcrossing"
        },
        "children": [
            {
                "id": "3",
                "slug": "postScrolling",
                "label": {
                    "ru": "Посткроссинг",
                    "uk": "Посткросинг",
                    "en": "Postcrossing"
                }
            }
        ]
    },
    {
        "id": "4",
        "slug": "artsAndCulture",
        "label": {
            "ru": "Искусство и Культура",
            "uk": "Мистецтво і Культура",
            "en": "Arts and Culture"
        },
        "children": [
            {
                "id": "4_0",
                "slug": "artsAndCulture",
                "label": {
                    "ru": "Искусство и Культура",
                    "uk": "Мистецтво і Культура",
                    "en": "Arts and Culture"
                }
            },
            {
                "id": "4_1",
                "slug": "ballet",
                "label": {
                    "ru": "Балет",
                    "uk": "Балет",
                    "en": "Ballet"
                }
            },
            {
                "id": "4_2",
                "slug": "exhibitions",
                "label": {
                    "ru": "Выставки",
                    "uk": "Виставки",
                    "en": "Exhibitions"
                }
            },
            {
                "id": "4_3",
                "slug": "cinema",
                "label": {
                    "ru": "Кино",
                    "uk": "Кіно",
                    "en": "Cinema"
                }
            },
            {
                "id": "4_4",
                "slug": "concertsShows",
                "label": {
                    "ru": "Концерт, шоу",
                    "uk": "Концерт, шоу",
                    "en": "Concerts, Shows"
                }
            },
            {
                "id": "4_5",
                "slug": "musical",
                "label": {
                    "ru": "Мюзикл",
                    "uk": "Мюзикл",
                    "en": "Musical"
                }
            },
            {
                "id": "4_6",
                "slug": "writing",
                "label": {
                    "ru": "Писательство",
                    "uk": "Письменство",
                    "en": "Writing"
                }
            },
            {
                "id": "4_7",
                "slug": "artWorks",
                "label": {
                    "ru": "Произведения искусства",
                    "uk": "Твори мистецтва",
                    "en": "Art Works"
                }
            },
            {
                "id": "4_8",
                "slug": "sculptures",
                "label": {
                    "ru": "Скульптуры",
                    "uk": "Скульптури",
                    "en": "Sculptures"
                }
            },
            {
                "id": "4_9",
                "slug": "theatre",
                "label": {
                    "ru": "Театр",
                    "uk": "Театр",
                    "en": "Theatre"
                }
            },
            {
                "id": "4_10",
                "slug": "festivals",
                "label": {
                    "ru": "Фестивали",
                    "uk": "Фестивалі",
                    "en": "Festivals"
                }
            },
            {
                "id": "4_11",
                "slug": "circus",
                "label": {
                    "ru": "Цирк",
                    "uk": "Цирк",
                    "en": "Circus"
                }
            },
            {
                "id": "4_12",
                "slug": "tours",
                "label": {
                    "ru": "Экскурссии",
                    "uk": "Екскурсії",
                    "en": "Tours"
                }
            }
        ]
    },
    {
        "id": "5",
        "slug": "tvShows",
        "label": {
            "ru": "Телешоу",
            "uk": "Телешоу",
            "en": "TV Shows"
        },
        "children": [
            {
                "id": "5_0",
                "slug": "tvShows",
                "label": {
                    "ru": "Телешоу",
                    "uk": "Телешоу",
                    "en": "TV Shows"
                }
            },
            {
                "id": "5_1",
                "slug": "hellKitchen",
                "label": {
                    "ru": "Адская Кухня",
                    "uk": "Пекельна Кухня",
                    "en": "Hell's Kitchen"
                }
            },
            {
                "id": "5_2",
                "slug": "pregnantAt16",
                "label": {
                    "ru": "Беременна в 16",
                    "uk": "Вагітна в 16",
                    "en": "Pregnant at 16"
                }
            },
            {
                "id": "5_3",
                "slug": "battleOfTheClairvoyants",
                "label": {
                    "ru": "Битва экстрасенсов",
                    "uk": "Битва екстрасенсів",
                    "en": "Battle of the Clairvoyants"
                }
            },
            {
                "id": "5_4",
                "slug": "returnToMeBeauty",
                "label": {
                    "ru": "Верните мне красоту",
                    "uk": "Поверніть мені красу",
                    "en": "Return My Beauty"
                }
            },
            {
                "id": "5_5",
                "slug": "theVoice",
                "label": {
                    "ru": "Голос",
                    "uk": "Голос",
                    "en": "The Voice"
                }
            },
            {
                "id": "5_6",
                "slug": "whoWantsToBeAMillionaire",
                "label": {
                    "ru": "Кто хочет стать миллионером",
                    "uk": "Хто хоче стати мільйонером",
                    "en": "Who Wants to Be a Millionaire"
                }
            },
            {
                "id": "5_7",
                "slug": "betterThanEveryone",
                "label": {
                    "ru": "Лучше всех",
                    "uk": "Краще за всіх",
                    "en": "Better Than Everyone"
                }
            },
            {
                "id": "5_8",
                "slug": "masterChef",
                "label": {
                    "ru": "Мастер Шеф",
                    "uk": "Майстер Шеф",
                    "en": "MasterChef"
                }
            },
            {
                "id": "5_9",
                "slug": "eagleAndTails",
                "label": {
                    "ru": "Орел и решка",
                    "uk": "Орёл і решка",
                    "en": "Heads and Tails"
                }
            },
            {
                "id": "5_10",
                "slug": "dances",
                "label": {
                    "ru": "Танцы",
                    "uk": "Танці",
                    "en": "Dances"
                }
            },
            {
                "id": "5_11",
                "slug": "whatWhereWhen",
                "label": {
                    "ru": "Что, где, когда",
                    "uk": "Що, де, коли",
                    "en": "What, Where, When"
                }
            },
            {
                "id": "5_12",
                "slug": "talentShows",
                "label": {
                    "ru": "Шоу талантов",
                    "uk": "Шоу талантів",
                    "en": "Talent Shows"
                }
            },
            {
                "id": "5_13",
                "slug": "bodyShaming",
                "label": {
                    "ru": "Я стесняюсь своего тела",
                    "uk": "Я соромлюсь свого тіла",
                    "en": "I Am Ashamed of My Body"
                }
            }
        ]
    },
    {
        "id": "6",
        "slug": "humor",
        "label": {
            "ru": "Юмор",
            "uk": "Гумор",
            "en": "Humor"
        },
        "children": [
            {
                "id": "6_0",
                "slug": "humor",
                "label": {
                    "ru": "Юмор",
                    "uk": "Гумор",
                    "en": "Humor"
                }
            },
            {
                "id": "6_1",
                "slug": "irony",
                "label": {
                    "ru": "Ирония",
                    "uk": "Іронія",
                    "en": "Irony"
                }
            },
            {
                "id": "6_2",
                "slug": "parody",
                "label": {
                    "ru": "Пародия",
                    "uk": "Пародія",
                    "en": "Parody"
                }
            },
            {
                "id": "6_3",
                "slug": "sarcasm",
                "label": {
                    "ru": "Сарказм",
                    "uk": "Сарказм",
                    "en": "Sarcasm"
                }
            },
            {
                "id": "6_4",
                "slug": "standUpComedy",
                "label": {
                    "ru": "Стендап",
                    "uk": "Стендап",
                    "en": "Stand-up"
                }
            },
            {
                "id": "6_5",
                "slug": "darkHumor",
                "label": {
                    "ru": "Черный юмор",
                    "uk": "Чорний гумор",
                    "en": "Dark humor"
                }
            }
        ]
    },
    {
        "id": "7",
        "slug": "music",
        "label": {
            "ru": "Музыка",
            "uk": "Музика",
            "en": "Music"
        },
        "children": [
            {
                "id": "7_0",
                "slug": "music",
                "label": {
                    "ru": "Музыка",
                    "uk": "Музика",
                    "en": "Music"
                }
            },
            {
                "id": "7_1",
                "slug": "sampling",
                "label": {
                    "ru": "Cэмплирование",
                    "uk": "Семплювання",
                    "en": "Sampling"
                }
            },
            {
                "id": "7_2",
                "slug": "originalSongs",
                "label": {
                    "ru": "Авторские песни",
                    "uk": "Авторські пісні",
                    "en": "Original Songs"
                }
            },
            {
                "id": "7_3",
                "slug": "virtualDJing",
                "label": {
                    "ru": "Виртуальный диджеинг",
                    "uk": "Віртуальний діджеїнг",
                    "en": "Virtual DJing"
                }
            },
            {
                "id": "7_4",
                "slug": "soundRecording",
                "label": {
                    "ru": "Звукозапись",
                    "uk": "Звукозапис",
                    "en": "Sound Recording"
                }
            },
            {
                "id": "7_5",
                "slug": "folkSinging",
                "label": {
                    "ru": "Народное пение",
                    "uk": "Народне співання",
                    "en": "Folk Singing"
                }
            },
            {
                "id": "7_6",
                "slug": "singing",
                "label": {
                    "ru": "Пение",
                    "uk": "Спів",
                    "en": "Singing"
                }
            },
            {
                "id": "7_7",
                "slug": "musicAndRhythmCreation",
                "label": {
                    "ru": "Создание музыки и ритмов",
                    "uk": "Створення музики та ритмів",
                    "en": "Music and Rhythm Creation"
                }
            },
            {
                "id": "7_8",
                "slug": "songLyricsAndPoetry",
                "label": {
                    "ru": "Тексты и стихи для песен",
                    "uk": "Тексти та поезія для пісень",
                    "en": "Song Lyrics and Poetry"
                }
            }
        ]
    },
    {
        "id": "8",
        "slug": "musicalInstruments",
        "label": {
            "ru": "Игра на музыкальных инструментах",
            "uk": "Гра на музичних інструментах",
            "en": "Playing Musical Instruments"
        },
        "children": [
            {
                "id": "8_0",
                "slug": "musicalInstruments",
                "label": {
                    "ru": "Игра на музыкальных инструментах",
                    "uk": "Гра на музичних інструментах",
                    "en": "Playing Musical Instruments"
                }
            },
            {
                "id": "8_1",
                "slug": "windInstruments",
                "label": {
                    "ru": "Духовые",
                    "uk": "Духові",
                    "en": "Wind Instruments"
                }
            },
            {
                "id": "8_2",
                "slug": "bassClarinet",
                "label": {
                    "ru": "Бас-кларнет",
                    "uk": "Бас-кларнет",
                    "en": "Bass Clarinet"
                }
            },
            {
                "id": "8_3",
                "slug": "recorder",
                "label": {
                    "ru": "Блокфлейта",
                    "uk": "Блокфлейта",
                    "en": "Recorder"
                }
            },
            {
                "id": "8_4",
                "slug": "frenchHorn",
                "label": {
                    "ru": "Валторна",
                    "uk": "Валторна",
                    "en": "French Horn"
                }
            },
            {
                "id": "8_5",
                "slug": "whistle",
                "label": {
                    "ru": "Вистл",
                    "uk": "Свисток",
                    "en": "Whistle"
                }
            },
            {
                "id": "8_6",
                "slug": "helicon",
                "label": {
                    "ru": "Геликон",
                    "uk": "Гелікон",
                    "en": "Helicon"
                }
            },
            {
                "id": "8_7",
                "slug": "oboe",
                "label": {
                    "ru": "Гобой",
                    "uk": "Гобой",
                    "en": "Oboe"
                }
            },
            {
                "id": "8_8",
                "slug": "whistle",
                "label": {
                    "ru": "Дудка",
                    "uk": "Дудка",
                    "en": "Pipe"
                }
            },
            {
                "id": "8_9",
                "slug": "duduk",
                "label": {
                    "ru": "Дудука",
                    "uk": "Дудук",
                    "en": "Duduk"
                }
            },
            {
                "id": "8_10",
                "slug": "zurna",
                "label": {
                    "ru": "Зурна",
                    "uk": "Зурна",
                    "en": "Zurna"
                }
            },
            {
                "id": "8_11",
                "slug": "clarinet",
                "label": {
                    "ru": "Кларнет",
                    "uk": "Кларнет",
                    "en": "Clarinet"
                }
            },
            {
                "id": "8_12",
                "slug": "clarinetPiccolo",
                "label": {
                    "ru": "Кларнет-пикколо",
                    "uk": "Кларнет-пікколо",
                    "en": "Piccolo Clarinet"
                }
            },
            {
                "id": "8_13",
                "slug": "cornet",
                "label": {
                    "ru": "Корнет",
                    "uk": "Корнет",
                    "en": "Cornet"
                }
            },
            {
                "id": "8_14",
                "slug": "recorder",
                "label": {
                    "ru": "Рекордер",
                    "uk": "Рекордер",
                    "en": "Recorder"
                }
            },
            {
                "id": "8_15",
                "slug": "saxophone",
                "label": {
                    "ru": "Саксофон",
                    "uk": "Саксофон",
                    "en": "Saxophone"
                }
            },
            {
                "id": "8_16",
                "slug": "sousaphone",
                "label": {
                    "ru": "Сузафон",
                    "uk": "Сузафон",
                    "en": "Sousaphone"
                }
            },
            {
                "id": "8_17",
                "slug": "trombone",
                "label": {
                    "ru": "Тромбон",
                    "uk": "Тромбон",
                    "en": "Trombone"
                }
            },
            {
                "id": "8_18",
                "slug": "trumpet",
                "label": {
                    "ru": "Труба",
                    "uk": "Труба",
                    "en": "Trumpet"
                }
            },
            {
                "id": "8_19",
                "slug": "tuba",
                "label": {
                    "ru": "Туба",
                    "uk": "Туба",
                    "en": "Tuba"
                }
            },
            {
                "id": "8_20",
                "slug": "bassoon",
                "label": {
                    "ru": "Фагот",
                    "uk": "Фагот",
                    "en": "Bassoon"
                }
            },
            {
                "id": "8_21",
                "slug": "flute",
                "label": {
                    "ru": "Флейта",
                    "uk": "Флейта",
                    "en": "Flute"
                }
            },
            {
                "id": "8_22",
                "slug": "piccoloFlute",
                "label": {
                    "ru": "Флейта-пикколо",
                    "uk": "Флейта-пікколо",
                    "en": "Piccolo Flute"
                }
            },
            {
                "id": "8_23",
                "slug": "flugelhorn",
                "label": {
                    "ru": "Флюгельгорн",
                    "uk": "Флюгельгорн",
                    "en": "Flugelhorn"
                }
            },
            {
                "id": "8_24",
                "slug": "shawm",
                "label": {
                    "ru": "Шалмей",
                    "uk": "Шалмей",
                    "en": "Shawm"
                }
            },
            {
                "id": "8_25",
                "slug": "keyboardInstruments",
                "label": {
                    "ru": "Клавишные",
                    "uk": "Клавішні",
                    "en": "Keyboard Instruments"
                }
            },
            {
                "id": "8_26",
                "slug": "midiKeyboard",
                "label": {
                    "ru": "Midi-клавиатура",
                    "uk": "Midi-клавіатура",
                    "en": "Midi Keyboard"
                }
            },
            {
                "id": "8_27",
                "slug": "accordion",
                "label": {
                    "ru": "Аккордеон",
                    "uk": "Акордеон",
                    "en": "Accordion"
                }
            },
            {
                "id": "8_28",
                "slug": "uprightPiano",
                "label": {
                    "ru": "Акустическое пианино",
                    "uk": "Акустичне піаніно",
                    "en": "Upright Piano"
                }
            },
            {
                "id": "8_29",
                "slug": "bayan",
                "label": {
                    "ru": "Баян",
                    "uk": "Баян",
                    "en": "Bayan"
                }
            },
            {
                "id": "8_30",
                "slug": "vocoder",
                "label": {
                    "ru": "Вокодер",
                    "uk": "Вокодер",
                    "en": "Vocoder"
                }
            },
            {
                "id": "8_31",
                "slug": "harmonica",
                "label": {
                    "ru": "Гармонь",
                    "uk": "Гармонь",
                    "en": "Harmonica"
                }
            },
            {
                "id": "8_32",
                "slug": "harpsichord",
                "label": {
                    "ru": "Клавесин",
                    "uk": "Клавесин",
                    "en": "Harpsichord"
                }
            },
            {
                "id": "8_33",
                "slug": "clavichord",
                "label": {
                    "ru": "Клавикорд",
                    "uk": "Клавікорд",
                    "en": "Clavichord"
                }
            },
            {
                "id": "8_34",
                "slug": "clavinet",
                "label": {
                    "ru": "Клавинет",
                    "uk": "Клавінет",
                    "en": "Clavinet"
                }
            },
            {
                "id": "8_35",
                "slug": "melodica",
                "label": {
                    "ru": "Мелодика",
                    "uk": "Мелодика",
                    "en": "Melodica"
                }
            },
            {
                "id": "8_36",
                "slug": "organ",
                "label": {
                    "ru": "Орган",
                    "uk": "Орган",
                    "en": "Organ"
                }
            },
            {
                "id": "8_37",
                "slug": "piano",
                "label": {
                    "ru": "Пианино",
                    "uk": "Піаніно",
                    "en": "Piano"
                }
            },
            {
                "id": "8_38",
                "slug": "grandPiano",
                "label": {
                    "ru": "Рояль",
                    "uk": "Рояль",
                    "en": "Grand Piano"
                }
            },
            {
                "id": "8_39",
                "slug": "synthesizer",
                "label": {
                    "ru": "Синтезатор",
                    "uk": "Синтезатор",
                    "en": "Synthesizer"
                }
            },
            {
                "id": "8_40",
                "slug": "pianoFort",
                "label": {
                    "ru": "Фортепьяно",
                    "uk": "Фортепіано",
                    "en": "Fortepiano"
                }
            },
            {
                "id": "8_41",
                "slug": "digitalPiano",
                "label": {
                    "ru": "Цифровое пианино",
                    "uk": "Цифрове піаніно",
                    "en": "Digital Piano"
                }
            },
            {
                "id": "8_42",
                "slug": "celesta",
                "label": {
                    "ru": "Челеста",
                    "uk": "Челеста",
                    "en": "Celesta"
                }
            },
            {
                "id": "8_43",
                "slug": "electricPiano",
                "label": {
                    "ru": "Электропиано",
                    "uk": "Електропіано",
                    "en": "Electric Piano"
                }
            },
            {
                "id": "8_44",
                "slug": "mechanicalInstruments",
                "label": {
                    "ru": "Механические",
                    "uk": "Механічні",
                    "en": "Mechanical Instruments"
                }
            },
            {
                "id": "8_45",
                "slug": "veltMinion",
                "label": {
                    "ru": "Вельте-миньон",
                    "uk": "Вельте-мійон",
                    "en": "Velt Minion"
                }
            },
            {
                "id": "8_46",
                "slug": "orchestrion",
                "label": {
                    "ru": "Оркестрион",
                    "uk": "Оркестріон",
                    "en": "Orchestrion"
                }
            },
            {
                "id": "8_47",
                "slug": "pianola",
                "label": {
                    "ru": "Пианола",
                    "uk": "Піанола",
                    "en": "Pianola"
                }
            },
            {
                "id": "8_48",
                "slug": "phonola",
                "label": {
                    "ru": "Фонола",
                    "uk": "Фонола",
                    "en": "Phonola"
                }
            },
            {
                "id": "8_49",
                "slug": "barrelOrgan",
                "label": {
                    "ru": "Шарманка",
                    "uk": "Шарманка",
                    "en": "Barrel Organ"
                }
            },
            {
                "id": "8_50",
                "slug": "stringInstruments",
                "label": {
                    "ru": "Струнные",
                    "uk": "Струнні",
                    "en": "String Instruments"
                }
            },
            {
                "id": "8_51",
                "slug": "viola",
                "label": {
                    "ru": "Альт",
                    "uk": "Альт",
                    "en": "Viola"
                }
            },
            {
                "id": "8_52",
                "slug": "harp",
                "label": {
                    "ru": "Арфа",
                    "uk": "Арфа",
                    "en": "Harp"
                }
            },
            {
                "id": "8_53",
                "slug": "balalaika",
                "label": {
                    "ru": "Балалайка",
                    "uk": "Балалайка",
                    "en": "Balalaika"
                }
            },
            {
                "id": "8_54",
                "slug": "bandura",
                "label": {
                    "ru": "Бандура",
                    "uk": "Бандура",
                    "en": "Bandura"
                }
            },
            {
                "id": "8_55",
                "slug": "banjo",
                "label": {
                    "ru": "Банжо",
                    "uk": "Банжо",
                    "en": "Banjo"
                }
            },
            {
                "id": "8_56",
                "slug": "cello",
                "label": {
                    "ru": "Виолончель",
                    "uk": "Віолончель",
                    "en": "Cello"
                }
            },
            {
                "id": "8_57",
                "slug": "gadulka",
                "label": {
                    "ru": "Гадулука",
                    "uk": "Гадулка",
                    "en": "Gadulka"
                }
            },
            {
                "id": "8_58",
                "slug": "guitar",
                "label": {
                    "ru": "Гитара",
                    "uk": "Гітара",
                    "en": "Guitar"
                }
            },
            {
                "id": "8_59",
                "slug": "woodenFlute",
                "label": {
                    "ru": "Гудок",
                    "uk": "Гудок",
                    "en": "Wooden Flute"
                }
            },
            {
                "id": "8_60",
                "slug": "gusle",
                "label": {
                    "ru": "Гусле",
                    "uk": "Гуслі",
                    "en": "Gusle"
                }
            },
            {
                "id": "8_61",
                "slug": "gusli",
                "label": {
                    "ru": "Гусли",
                    "uk": "Гуслі",
                    "en": "Gusli"
                }
            },
            {
                "id": "8_62",
                "slug": "dombra",
                "label": {
                    "ru": "Домбра",
                    "uk": "Домбра",
                    "en": "Dombra"
                }
            },
            {
                "id": "8_63",
                "slug": "domra",
                "label": {
                    "ru": "Домра",
                    "uk": "Домра",
                    "en": "Domra"
                }
            },
            {
                "id": "8_64",
                "slug": "kemanche",
                "label": {
                    "ru": "Кеманча",
                    "uk": "Кеманча",
                    "en": "Kemancha"
                }
            },
            {
                "id": "8_65",
                "slug": "kobza",
                "label": {
                    "ru": "Кобза",
                    "uk": "Кобза",
                    "en": "Kobza"
                }
            },
            {
                "id": "8_66",
                "slug": "kobyz",
                "label": {
                    "ru": "Кобыз",
                    "uk": "Кобиз",
                    "en": "Kobyz"
                }
            },
            {
                "id": "8_67",
                "slug": "komuz",
                "label": {
                    "ru": "Комуз",
                    "uk": "Комуз",
                    "en": "Komuz"
                }
            },
            {
                "id": "8_68",
                "slug": "doubleBass",
                "label": {
                    "ru": "Контрабас",
                    "uk": "Контрабас",
                    "en": "Double Bass"
                }
            },
            {
                "id": "8_69",
                "slug": "koto",
                "label": {
                    "ru": "Кото",
                    "uk": "Кото",
                    "en": "Koto"
                }
            },
            {
                "id": "8_70",
                "slug": "kylak",
                "label": {
                    "ru": "Кыяк",
                    "uk": "Киїк",
                    "en": "Kylyak"
                }
            },
            {
                "id": "8_71",
                "slug": "lute",
                "label": {
                    "ru": "Лютня",
                    "uk": "Лютня",
                    "en": "Lute"
                }
            },
            {
                "id": "8_72",
                "slug": "morinKhuur",
                "label": {
                    "ru": "Морин хуур",
                    "uk": "Морин хуур",
                    "en": "Morin Khuur"
                }
            },
            {
                "id": "8_73",
                "slug": "nickelHarp",
                "label": {
                    "ru": "Никельхарпа",
                    "uk": "Нікельхарпа",
                    "en": "Nickel Harp"
                }
            },
            {
                "id": "8_74",
                "slug": "rebec",
                "label": {
                    "ru": "Ребаб",
                    "uk": "Ребаб",
                    "en": "Rebec"
                }
            },
            {
                "id": "8_75",
                "slug": "saz",
                "label": {
                    "ru": "Саз",
                    "uk": "Саз",
                    "en": "Saz"
                }
            },
            {
                "id": "8_76",
                "slug": "sarangi",
                "label": {
                    "ru": "Саранджи",
                    "uk": "Саранджі",
                    "en": "Sarangi"
                }
            },
            {
                "id": "8_77",
                "slug": "violin",
                "label": {
                    "ru": "Скрипка",
                    "uk": "Скрипка",
                    "en": "Violin"
                }
            },
            {
                "id": "8_78",
                "slug": "tar",
                "label": {
                    "ru": "Тар",
                    "uk": "Тар",
                    "en": "Tar"
                }
            },
            {
                "id": "8_79",
                "slug": "fiddle",
                "label": {
                    "ru": "Фидель",
                    "uk": "Фідель",
                    "en": "Fiddle"
                }
            },
            {
                "id": "8_80",
                "slug": "hardingfele",
                "label": {
                    "ru": "Хардингфеле",
                    "uk": "Хардингфеле",
                    "en": "Hardingfele"
                }
            },
            {
                "id": "8_81",
                "slug": "khomus",
                "label": {
                    "ru": "Хомус",
                    "uk": "Хомус",
                    "en": "Khomus"
                }
            },
            {
                "id": "8_82",
                "slug": "hucin",
                "label": {
                    "ru": "Хуцинь",
                    "uk": "Хуцинь",
                    "en": "Hucin"
                }
            },
            {
                "id": "8_83",
                "slug": "zither",
                "label": {
                    "ru": "Цитра",
                    "uk": "Цитра",
                    "en": "Zither"
                }
            },
            {
                "id": "8_84",
                "slug": "charanga",
                "label": {
                    "ru": "Чаранга",
                    "uk": "Чаранга",
                    "en": "Charanga"
                }
            },
            {
                "id": "8_85",
                "slug": "chatkhan",
                "label": {
                    "ru": "Чатхан",
                    "uk": "Чатхан",
                    "en": "Chatkhan"
                }
            },
            {
                "id": "8_86",
                "slug": "percussionInstruments",
                "label": {
                    "ru": "Ударные",
                    "uk": "Ударні",
                    "en": "Percussion Instruments"
                }
            },
            {
                "id": "8_87",
                "slug": "agogo",
                "label": {
                    "ru": "Агого",
                    "uk": "Агого",
                    "en": "Agogo"
                }
            },
            {
                "id": "8_88",
                "slug": "angklung",
                "label": {
                    "ru": "Ангклунг",
                    "uk": "Ангклунг",
                    "en": "Angklung"
                }
            },
            {
                "id": "8_89",
                "slug": "asalato",
                "label": {
                    "ru": "Асатаяк",
                    "uk": "Асатаяк",
                    "en": "Asalato"
                }
            },
            {
                "id": "8_90",
                "slug": "ashiko",
                "label": {
                    "ru": "Ахоко",
                    "uk": "Ахоко",
                    "en": "Ashiko"
                }
            },
            {
                "id": "8_91",
                "slug": "balafon",
                "label": {
                    "ru": "Балафон",
                    "uk": "Балафон",
                    "en": "Balafon"
                }
            },
            {
                "id": "8_92",
                "slug": "bang",
                "label": {
                    "ru": "Баньгу",
                    "uk": "Баньгу",
                    "en": "Bang"
                }
            },
            {
                "id": "8_93",
                "slug": "barChimes",
                "label": {
                    "ru": "Бар чаймс",
                    "uk": "Бар чаймс",
                    "en": "Bar Chimes"
                }
            },
            {
                "id": "8_94",
                "slug": "drums",
                "label": {
                    "ru": "Барабаны",
                    "uk": "Барабани",
                    "en": "Drums"
                }
            },
            {
                "id": "8_95",
                "slug": "bata",
                "label": {
                    "ru": "Бата",
                    "uk": "Бата",
                    "en": "Bata"
                }
            },
            {
                "id": "8_96",
                "slug": "bomboLeguero",
                "label": {
                    "ru": "Бомбо легуэро",
                    "uk": "Бомбо легуеро",
                    "en": "Bombo Leguero"
                }
            },
            {
                "id": "8_97",
                "slug": "bonang",
                "label": {
                    "ru": "Бонанг",
                    "uk": "Бонанг",
                    "en": "Bonang"
                }
            },
            {
                "id": "8_98",
                "slug": "bongo",
                "label": {
                    "ru": "Бонго",
                    "uk": "Бонго",
                    "en": "Bongo"
                }
            },
            {
                "id": "8_99",
                "slug": "bouran",
                "label": {
                    "ru": "Боуран",
                    "uk": "Боуран",
                    "en": "Bouran"
                }
            },
            {
                "id": "8_100",
                "slug": "buben",
                "label": {
                    "ru": "Бубен",
                    "uk": "Бубен",
                    "en": "Tambourine"
                }
            },
            {
                "id": "8_101",
                "slug": "gambang",
                "label": {
                    "ru": "Гамбанг",
                    "uk": "Гамбанг",
                    "en": "Gambang"
                }
            },
            {
                "id": "8_102",
                "slug": "glukofon",
                "label": {
                    "ru": "Глюкофон",
                    "uk": "Глюкофон",
                    "en": "Hang Drum"
                }
            },
            {
                "id": "8_103",
                "slug": "guiro",
                "label": {
                    "ru": "Гуиро",
                    "uk": "Гуїро",
                    "en": "Guiro"
                }
            },
            {
                "id": "8_104",
                "slug": "dabyl",
                "label": {
                    "ru": "Дабыл",
                    "uk": "Дабил",
                    "en": "Dabyl"
                }
            },
            {
                "id": "8_105",
                "slug": "dangyra",
                "label": {
                    "ru": "Дангыра",
                    "uk": "Дангыра",
                    "en": "Dangyra"
                }
            },
            {
                "id": "8_106",
                "slug": "darbuka",
                "label": {
                    "ru": "Дарбука",
                    "uk": "Дарбука",
                    "en": "Darbuka"
                }
            },
            {
                "id": "8_107",
                "slug": "jaltarang",
                "label": {
                    "ru": "Джалтаранг",
                    "uk": "Джалтаранг",
                    "en": "Jaltarang"
                }
            },
            {
                "id": "8_108",
                "slug": "djembe",
                "label": {
                    "ru": "Джембе",
                    "uk": "Джембе",
                    "en": "Djembe"
                }
            },
            {
                "id": "8_109",
                "slug": "doyra",
                "label": {
                    "ru": "Дойра",
                    "uk": "Дойра",
                    "en": "Doyra"
                }
            },
            {
                "id": "8_110",
                "slug": "dotaku",
                "label": {
                    "ru": "Дотаку",
                    "uk": "Дотаку",
                    "en": "Dotaku"
                }
            },
            {
                "id": "8_111",
                "slug": "dudyga",
                "label": {
                    "ru": "Дудыга",
                    "uk": "Дудига",
                    "en": "Dudyga"
                }
            },
            {
                "id": "8_112",
                "slug": "dholak",
                "label": {
                    "ru": "Дхолак",
                    "uk": "Дхолак",
                    "en": "Dholak"
                }
            },
            {
                "id": "8_113",
                "slug": "cabasa",
                "label": {
                    "ru": "Кабаса",
                    "uk": "Кабаса",
                    "en": "Cabasa"
                }
            },
            {
                "id": "8_114",
                "slug": "kanjira",
                "label": {
                    "ru": "Канджира",
                    "uk": "Канджира",
                    "en": "Kanjira"
                }
            },
            {
                "id": "8_115",
                "slug": "carillons",
                "label": {
                    "ru": "Карильоны",
                    "uk": "Карильони",
                    "en": "Carillons"
                }
            },
            {
                "id": "8_116",
                "slug": "castanets",
                "label": {
                    "ru": "Кастаньеты",
                    "uk": "Кастаньєти",
                    "en": "Castanets"
                }
            },
            {
                "id": "8_117",
                "slug": "cajon",
                "label": {
                    "ru": "Кахон",
                    "uk": "Кахон",
                    "en": "Cajon"
                }
            },
            {
                "id": "8_118",
                "slug": "kashishi",
                "label": {
                    "ru": "Кашиши",
                    "uk": "Кашиши",
                    "en": "Kashishi"
                }
            },
            {
                "id": "8_119",
                "slug": "clave",
                "label": {
                    "ru": "Клаве",
                    "uk": "Клаве",
                    "en": "Clave"
                }
            },
            {
                "id": "8_120",
                "slug": "cowbell",
                "label": {
                    "ru": "Ковбелл",
                    "uk": "Ковбелл",
                    "en": "Cowbell"
                }
            },
            {
                "id": "8_121",
                "slug": "bells",
                "label": {
                    "ru": "Колокола",
                    "uk": "Дзвони",
                    "en": "Bells"
                }
            },
            {
                "id": "8_122",
                "slug": "conga",
                "label": {
                    "ru": "Конга",
                    "uk": "Конга",
                    "en": "Conga"
                }
            },
            {
                "id": "8_123",
                "slug": "kpanlogo",
                "label": {
                    "ru": "Кпанлого",
                    "uk": "Кпанлого",
                    "en": "Kpanlogo"
                }
            },
            {
                "id": "8_124",
                "slug": "xylophone",
                "label": {
                    "ru": "Ксилофон",
                    "uk": "Ксилофон",
                    "en": "Xylophone"
                }
            },
            {
                "id": "8_125",
                "slug": "laghuti",
                "label": {
                    "ru": "Лаггути",
                    "uk": "Лаггути",
                    "en": "Laghuti"
                }
            },
            {
                "id": "8_126",
                "slug": "litavry",
                "label": {
                    "ru": "Литавры",
                    "uk": "Литаври",
                    "en": "Tympani"
                }
            },
            {
                "id": "8_127",
                "slug": "maracas",
                "label": {
                    "ru": "Маракас",
                    "uk": "Маракас",
                    "en": "Maracas"
                }
            },
            {
                "id": "8_128",
                "slug": "marimba",
                "label": {
                    "ru": "Маримба",
                    "uk": "Маримба",
                    "en": "Marimba"
                }
            },
            {
                "id": "8_129",
                "slug": "mridangam",
                "label": {
                    "ru": "Мриданга",
                    "uk": "Мриданга",
                    "en": "Mridangam"
                }
            },
            {
                "id": "8_130",
                "slug": "pandeiro",
                "label": {
                    "ru": "Пандейру",
                    "uk": "Пандейру",
                    "en": "Pandeiro"
                }
            },
            {
                "id": "8_131",
                "slug": "rainstick",
                "label": {
                    "ru": "Рейнстик",
                    "uk": "Рейнстик",
                    "en": "Rainstick"
                }
            },
            {
                "id": "8_132",
                "slug": "rubel",
                "label": {
                    "ru": "Рубель",
                    "uk": "Рубель",
                    "en": "Rubel"
                }
            },
            {
                "id": "8_133",
                "slug": "sagats",
                "label": {
                    "ru": "Сагаты",
                    "uk": "Сагати",
                    "en": "Sagats"
                }
            },
            {
                "id": "8_134",
                "slug": "scraper",
                "label": {
                    "ru": "Скрабалай",
                    "uk": "Скрабалай",
                    "en": "Scraper"
                }
            },
            {
                "id": "8_135",
                "slug": "splash",
                "label": {
                    "ru": "Сплэш",
                    "uk": "Сплеш",
                    "en": "Splash"
                }
            },
            {
                "id": "8_136",
                "slug": "tabla",
                "label": {
                    "ru": "Табла",
                    "uk": "Табла",
                    "en": "Tabla"
                }
            },
            {
                "id": "8_137",
                "slug": "tavil",
                "label": {
                    "ru": "Тавил",
                    "uk": "Тавил",
                    "en": "Tavil"
                }
            },
            {
                "id": "8_138",
                "slug": "taiko",
                "label": {
                    "ru": "Тайко",
                    "uk": "Тайко",
                    "en": "Taiko"
                }
            },
            {
                "id": "8_139",
                "slug": "tambourine",
                "label": {
                    "ru": "Тамбурин",
                    "uk": "Тамбурин",
                    "en": "Tambourine"
                }
            },
            {
                "id": "8_140",
                "slug": "timbale",
                "label": {
                    "ru": "Тимбал",
                    "uk": "Тимбал",
                    "en": "Timbale"
                }
            },
            {
                "id": "8_141",
                "slug": "triangle",
                "label": {
                    "ru": "Треугольник",
                    "uk": "Трикутник",
                    "en": "Triangle"
                }
            },
            {
                "id": "8_142",
                "slug": "ratchet",
                "label": {
                    "ru": "Трещотка",
                    "uk": "Трещотка",
                    "en": "Ratchet"
                }
            },
            {
                "id": "8_143",
                "slug": "tulumbas",
                "label": {
                    "ru": "Тулумбас",
                    "uk": "Тулумбас",
                    "en": "Tulumbas"
                }
            },
            {
                "id": "8_144",
                "slug": "udu",
                "label": {
                    "ru": "Уду",
                    "uk": "Уду",
                    "en": "Udu"
                }
            },
            {
                "id": "8_145",
                "slug": "tsudzumi",
                "label": {
                    "ru": "Цудзуми",
                    "uk": "Цудзуми",
                    "en": "Tsudzumi"
                }
            },
            {
                "id": "8_146",
                "slug": "chinaCymbal",
                "label": {
                    "ru": "Чайна",
                    "uk": "Чайна",
                    "en": "China Cymbal"
                }
            },
            {
                "id": "8_147",
                "slug": "celesta",
                "label": {
                    "ru": "Челеста",
                    "uk": "Челеста",
                    "en": "Celesta"
                }
            },
            {
                "id": "8_148",
                "slug": "shaker",
                "label": {
                    "ru": "Шейкер",
                    "uk": "Шейкер",
                    "en": "Shaker"
                }
            },
            {
                "id": "8_149",
                "slug": "electronicInstruments",
                "label": {
                    "ru": "Электромузыкальные",
                    "uk": "Електромузичні",
                    "en": "Electronic Instruments"
                }
            },
            {
                "id": "8_150",
                "slug": "blasterBeam",
                "label": {
                    "ru": "Blaster Beam",
                    "uk": "Blaster Beam",
                    "en": "Blaster Beam"
                }
            },
            {
                "id": "8_151",
                "slug": "midiKeyboard",
                "label": {
                    "ru": "MIDI-клавиатура",
                    "uk": "MIDI-клавіатура",
                    "en": "MIDI Keyboard"
                }
            },
            {
                "id": "8_152",
                "slug": "gittlerGuitar",
                "label": {
                    "ru": "Гитара Гиттлера",
                    "uk": "Гітара Гіттлера",
                    "en": "Gittler Guitar"
                }
            },
            {
                "id": "8_153",
                "slug": "groovebox",
                "label": {
                    "ru": "Грувбокс",
                    "uk": "Грувбокс",
                    "en": "Groovebox"
                }
            },
            {
                "id": "8_154",
                "slug": "domavindger",
                "label": {
                    "ru": "Домавинджер",
                    "uk": "Домавинджер",
                    "en": "Domavindger"
                }
            },
            {
                "id": "8_155",
                "slug": "drumMachine",
                "label": {
                    "ru": "Драм-машина",
                    "uk": "Драм-машина",
                    "en": "Drum Machine"
                }
            },
            {
                "id": "8_156",
                "slug": "continuum",
                "label": {
                    "ru": "Континуум",
                    "uk": "Континуум",
                    "en": "Continuum"
                }
            },
            {
                "id": "8_157",
                "slug": "laserHarp",
                "label": {
                    "ru": "Лазерная арфа",
                    "uk": "Лазерна арфа",
                    "en": "Laser Harp"
                }
            },
            {
                "id": "8_158",
                "slug": "mellotron",
                "label": {
                    "ru": "Меллотрон",
                    "uk": "Мелотрон",
                    "en": "Mellotron"
                }
            },
            {
                "id": "8_159",
                "slug": "mudswinger",
                "label": {
                    "ru": "Мудсвинджер",
                    "uk": "Мудсвинджер",
                    "en": "Mudswinger"
                }
            },
            {
                "id": "8_160",
                "slug": "reactable",
                "label": {
                    "ru": "Реактейбл",
                    "uk": "Реактейбл",
                    "en": "Reactable"
                }
            },
            {
                "id": "8_161",
                "slug": "sampler",
                "label": {
                    "ru": "Семплер",
                    "uk": "Семплер",
                    "en": "Sampler"
                }
            },
            {
                "id": "8_162",
                "slug": "synthesizer",
                "label": {
                    "ru": "Синтезатор",
                    "uk": "Синтезатор",
                    "en": "Synthesizer"
                }
            },
            {
                "id": "8_163",
                "slug": "theremin",
                "label": {
                    "ru": "Терменвокс",
                    "uk": "Терменвокс",
                    "en": "Theremin"
                }
            },
            {
                "id": "8_164",
                "slug": "trautonium",
                "label": {
                    "ru": "Траутониум",
                    "uk": "Траутониум",
                    "en": "Trautonium"
                }
            },
            {
                "id": "8_165",
                "slug": "tenoriOn",
                "label": {
                    "ru": "Тэнори-он",
                    "uk": "Тенорі-он",
                    "en": "Tenori On"
                }
            },
            {
                "id": "8_166",
                "slug": "electricGuitar",
                "label": {
                    "ru": "Электрогитара",
                    "uk": "Електрогітара",
                    "en": "Electric Guitar"
                }
            },
            {
                "id": "8_167",
                "slug": "electricOrgan",
                "label": {
                    "ru": "Электроорган",
                    "uk": "Електроорган",
                    "en": "Electric Organ"
                }
            },
            {
                "id": "8_168",
                "slug": "emiritone",
                "label": {
                    "ru": "Эмиритон",
                    "uk": "Емірітон",
                    "en": "Emiritone"
                }
            }
        ]
    },
    {
        "id": "9",
        "slug": "dance",
        "label": {
            "ru": "Танцы",
            "uk": "Танці",
            "en": "Dances"
        },
        "children": [
            {
                "id": "9_0",
                "slug": "dance",
                "label": {
                    "ru": "Танцы",
                    "uk": "Танці",
                    "en": "Dances"
                }
            },
            {
                "id": "9_1",
                "slug": "acrobaticDance",
                "label": {
                    "ru": "Акробатические танцы",
                    "uk": "Акробатичні танці",
                    "en": "Acrobatic Dances"
                }
            },
            {
                "id": "9_2",
                "slug": "acrobaticRockNRoll",
                "label": {
                    "ru": "Акробатический рок-н-рол",
                    "uk": "Акробатичний рок-н-рол",
                    "en": "Acrobatic Rock and Roll"
                }
            },
            {
                "id": "9_3",
                "slug": "boogieWoogie",
                "label": {
                    "ru": "Буги-вуги",
                    "uk": "Буги-вугі",
                    "en": "Boogie Woogie"
                }
            },
            {
                "id": "9_4",
                "slug": "aerialEquilibrium",
                "label": {
                    "ru": "Воздушный эквилибр",
                    "uk": "Повітряний еквілібр",
                    "en": "Aerial Equilibrium"
                }
            },
            {
                "id": "9_5",
                "slug": "capoeiraDance",
                "label": {
                    "ru": "Капоэйра",
                    "uk": "Капоейра",
                    "en": "Capoeira"
                }
            },
            {
                "id": "9_6",
                "slug": "poleDance",
                "label": {
                    "ru": "Танец на пилоне",
                    "uk": "Танець на пилоні",
                    "en": "Pole Dance"
                }
            },
            {
                "id": "9_7",
                "slug": "cheerleading",
                "label": {
                    "ru": "Черлидинг",
                    "uk": "Чирлідинг",
                    "en": "Cheerleading"
                }
            },
            {
                "id": "9_8",
                "slug": "ballroomDance",
                "label": {
                    "ru": "Бальные танцы",
                    "uk": "Бальні танці",
                    "en": "Ballroom Dances"
                }
            },
            {
                "id": "9_9",
                "slug": "waltz",
                "label": {
                    "ru": "Вальс",
                    "uk": "Вальс",
                    "en": "Waltz"
                }
            },
            {
                "id": "9_10",
                "slug": "westCoastSwing",
                "label": {
                    "ru": "Вест Коуст",
                    "uk": "Вест Кост",
                    "en": "West Coast"
                }
            },
            {
                "id": "9_11",
                "slug": "jive",
                "label": {
                    "ru": "Джайв",
                    "uk": "Джайв",
                    "en": "Jive"
                }
            },
            {
                "id": "9_12",
                "slug": "pasodoble",
                "label": {
                    "ru": "Пасодобль",
                    "uk": "Пасодобль",
                    "en": "Pasodoble"
                }
            },
            {
                "id": "9_13",
                "slug": "foxtrot",
                "label": {
                    "ru": "Фокстрот",
                    "uk": "Фокстрот",
                    "en": "Foxtrot"
                }
            },
            {
                "id": "9_14",
                "slug": "ballet",
                "label": {
                    "ru": "Балет",
                    "uk": "Балет",
                    "en": "Ballet"
                }
            },
            {
                "id": "9_15",
                "slug": "easternDanceNew",
                "label": {
                    "ru": "Восточные танцы New",
                    "uk": "Східні танці New",
                    "en": "Eastern Dances New"
                }
            },
            {
                "id": "9_16",
                "slug": "alexandriaDance",
                "label": {
                    "ru": "Александрия",
                    "uk": "Олександрія",
                    "en": "Alexandria"
                }
            },
            {
                "id": "9_17",
                "slug": "balediDance",
                "label": {
                    "ru": "Баледи",
                    "uk": "Баледі",
                    "en": "Baledi"
                }
            },
            {
                "id": "9_18",
                "slug": "bandariDance",
                "label": {
                    "ru": "Бандари",
                    "uk": "Бандарі",
                    "en": "Bandari"
                }
            },
            {
                "id": "9_19",
                "slug": "iraqiDance",
                "label": {
                    "ru": "Ираки",
                    "uk": "Іракці",
                    "en": "Iraqi"
                }
            },
            {
                "id": "9_20",
                "slug": "nubianDance",
                "label": {
                    "ru": "Нубия",
                    "uk": "Нубія",
                    "en": "Nubia"
                }
            },
            {
                "id": "9_21",
                "slug": "saidiDance",
                "label": {
                    "ru": "Саиди",
                    "uk": "Саїді",
                    "en": "Saidi"
                }
            },
            {
                "id": "9_22",
                "slug": "bellyDance",
                "label": {
                    "ru": "Танец Живота",
                    "uk": "Танець живота",
                    "en": "Belly Dance"
                }
            },
            {
                "id": "9_23",
                "slug": "tribalDance",
                "label": {
                    "ru": "Трибал",
                    "uk": "Трибал",
                    "en": "Tribal"
                }
            },
            {
                "id": "9_24",
                "slug": "falaahiDance",
                "label": {
                    "ru": "Фалляхи",
                    "uk": "Фаллахі",
                    "en": "Falaahi"
                }
            },
            {
                "id": "9_25",
                "slug": "khaleejiDance",
                "label": {
                    "ru": "Халиджи",
                    "uk": "Халіджі",
                    "en": "Khaleeji"
                }
            },
            {
                "id": "9_26",
                "slug": "shaabiDance",
                "label": {
                    "ru": "Шааби",
                    "uk": "Шаабі",
                    "en": "Shaabi"
                }
            },
            {
                "id": "9_27",
                "slug": "folkEthnicDances",
                "label": {
                    "ru": "Народные(этнические) танцы",
                    "uk": "Народні (етнічні) танці",
                    "en": "Folk (Ethnic) Dances"
                }
            },
            {
                "id": "9_28",
                "slug": "armenianDances",
                "label": {
                    "ru": "Армянские",
                    "uk": "Вірменські",
                    "en": "Armenian Dances"
                }
            },
            {
                "id": "9_29",
                "slug": "georgianDances",
                "label": {
                    "ru": "Грузинские",
                    "uk": "Грузинські",
                    "en": "Georgian Dances"
                }
            },
            {
                "id": "9_30",
                "slug": "jewishDances",
                "label": {
                    "ru": "Еврейские",
                    "uk": "Єврейські",
                    "en": "Jewish Dances"
                }
            },
            {
                "id": "9_31",
                "slug": "irishDances",
                "label": {
                    "ru": "Ирландские",
                    "uk": "Ірландські",
                    "en": "Irish Dances"
                }
            },
            {
                "id": "9_32",
                "slug": "caucasianDances",
                "label": {
                    "ru": "Кавказкие",
                    "uk": "Кавказькі",
                    "en": "Caucasian Dances"
                }
            },
            {
                "id": "9_33",
                "slug": "kazakhDances",
                "label": {
                    "ru": "Казахские",
                    "uk": "Казахські",
                    "en": "Kazakh Dances"
                }
            },
            {
                "id": "9_34",
                "slug": "chineseDances",
                "label": {
                    "ru": "Китайские",
                    "uk": "Китайські",
                    "en": "Chinese Dances"
                }
            },
            {
                "id": "9_35",
                "slug": "cubanDances",
                "label": {
                    "ru": "Кубинские",
                    "uk": "Кубинські",
                    "en": "Cuban Dances"
                }
            },
            {
                "id": "9_36",
                "slug": "mexicanDances",
                "label": {
                    "ru": "Мексиканские",
                    "uk": "Мексиканські",
                    "en": "Mexican Dances"
                }
            },
            {
                "id": "9_37",
                "slug": "polishDances",
                "label": {
                    "ru": "Польские",
                    "uk": "Польські",
                    "en": "Polish Dances"
                }
            },
            {
                "id": "9_38",
                "slug": "russianDances",
                "label": {
                    "ru": "Русские",
                    "uk": "Російські",
                    "en": "Russian Dances"
                }
            },
            {
                "id": "9_39",
                "slug": "turkishDances",
                "label": {
                    "ru": "Турецкие",
                    "uk": "Турецькі",
                    "en": "Turkish Dances"
                }
            },
            {
                "id": "9_40",
                "slug": "ukrainianDances",
                "label": {
                    "ru": "Украинские",
                    "uk": "Українські",
                    "en": "Ukrainian Dances"
                }
            },
            {
                "id": "9_41",
                "slug": "japaneseDances",
                "label": {
                    "ru": "Японские",
                    "uk": "Японські",
                    "en": "Japanese Dances"
                }
            },
            {
                "id": "9_42",
                "slug": "modernDances",
                "label": {
                    "ru": "Современнные танцы",
                    "uk": "Сучасні танці",
                    "en": "Modern Dances"
                }
            },
            {
                "id": "9_43",
                "slug": "buto",
                "label": {
                    "ru": "Буто",
                    "uk": "Буто",
                    "en": "Butoh"
                }
            },
            {
                "id": "9_44",
                "slug": "vogue",
                "label": {
                    "ru": "Вог",
                    "uk": "Вог",
                    "en": "Vogue"
                }
            },
            {
                "id": "9_45",
                "slug": "jazzModern",
                "label": {
                    "ru": "Джаз Модерн",
                    "uk": "Джаз Модерн",
                    "en": "Jazz Modern"
                }
            },
            {
                "id": "9_46",
                "slug": "jazzFunk",
                "label": {
                    "ru": "Джаз Фанк",
                    "uk": "Джаз Фанк",
                    "en": "Jazz Funk"
                }
            },
            {
                "id": "9_47",
                "slug": "dancehall",
                "label": {
                    "ru": "Дэнсхолл",
                    "uk": "Денсхолл",
                    "en": "Dancehall"
                }
            },
            {
                "id": "9_48",
                "slug": "contemporaryDance",
                "label": {
                    "ru": "Контемп",
                    "uk": "Контемп",
                    "en": "Contemporary Dance"
                }
            },
            {
                "id": "9_49",
                "slug": "modernDance",
                "label": {
                    "ru": "Модерн",
                    "uk": "Модерн",
                    "en": "Modern"
                }
            },
            {
                "id": "9_50",
                "slug": "reggaeton",
                "label": {
                    "ru": "Реггетон",
                    "uk": "Реггетон",
                    "en": "Reggaeton"
                }
            },
            {
                "id": "9_51",
                "slug": "stripPlastic",
                "label": {
                    "ru": "Стрип-пластика",
                    "uk": "Стрип-пластика",
                    "en": "Strip Plastic"
                }
            },
            {
                "id": "9_52",
                "slug": "striptease",
                "label": {
                    "ru": "Стриптиз",
                    "uk": "Стриптиз",
                    "en": "Striptease"
                }
            },
            {
                "id": "9_53",
                "slug": "highHeels",
                "label": {
                    "ru": "Хай Хилс",
                    "uk": "Хай Хілс",
                    "en": "High Heels"
                }
            },
            {
                "id": "9_54",
                "slug": "poleDancing",
                "label": {
                    "ru": "Pole Dance",
                    "uk": "Pole Dance",
                    "en": "Pole Dancing"
                }
            },
            {
                "id": "9_55",
                "slug": "latinDancesNew",
                "label": {
                    "ru": "Латинские танцы New",
                    "uk": "Латинські танці New",
                    "en": "Latin Dances New"
                }
            },
            {
                "id": "9_56",
                "slug": "bachata",
                "label": {
                    "ru": "Бачата",
                    "uk": "Бачата",
                    "en": "Bachata"
                }
            },
            {
                "id": "9_57",
                "slug": "lambada",
                "label": {
                    "ru": "Ламбада",
                    "uk": "Ламбада",
                    "en": "Lambada"
                }
            },
            {
                "id": "9_58",
                "slug": "mambo",
                "label": {
                    "ru": "Мамбо",
                    "uk": "Мамбо",
                    "en": "Mambo"
                }
            },
            {
                "id": "9_59",
                "slug": "rumba",
                "label": {
                    "ru": "Румба",
                    "uk": "Румба",
                    "en": "Rumba"
                }
            },
            {
                "id": "9_60",
                "slug": "salsa",
                "label": {
                    "ru": "Сальса",
                    "uk": "Сальса",
                    "en": "Salsa"
                }
            },
            {
                "id": "9_61",
                "slug": "samba",
                "label": {
                    "ru": "Самба",
                    "uk": "Самба",
                    "en": "Samba"
                }
            },
            {
                "id": "9_62",
                "slug": "tango",
                "label": {
                    "ru": "Танго",
                    "uk": "Танго",
                    "en": "Tango"
                }
            },
            {
                "id": "9_63",
                "slug": "chaChaCha",
                "label": {
                    "ru": "Ча-ча-ча",
                    "uk": "Ча-ча-ча",
                    "en": "Cha-Cha-Cha"
                }
            },
            {
                "id": "9_64",
                "slug": "streetDances",
                "label": {
                    "ru": "Уличные танцы",
                    "uk": "Вуличні танці",
                    "en": "Street Dances"
                }
            },
            {
                "id": "9_65",
                "slug": "krump",
                "label": {
                    "ru": "Krump",
                    "uk": "Krump",
                    "en": "Krump"
                }
            },
            {
                "id": "9_66",
                "slug": "breakdance",
                "label": {
                    "ru": "Брейк-данс",
                    "uk": "Брейк-данс",
                    "en": "Breakdance"
                }
            },
            {
                "id": "9_67",
                "slug": "locking",
                "label": {
                    "ru": "Локинг",
                    "uk": "Локінг",
                    "en": "Locking"
                }
            },
            {
                "id": "9_68",
                "slug": "popping",
                "label": {
                    "ru": "Поппинг",
                    "uk": "Поппінг",
                    "en": "Popping"
                }
            },
            {
                "id": "9_69",
                "slug": "tectonic",
                "label": {
                    "ru": "Тектоник",
                    "uk": "Тектонік",
                    "en": "Tectonic"
                }
            },
            {
                "id": "9_70",
                "slug": "freestyle",
                "label": {
                    "ru": "Фристайл",
                    "uk": "Фристайл",
                    "en": "Freestyle"
                }
            },
            {
                "id": "9_71",
                "slug": "houseDance",
                "label": {
                    "ru": "Хаус",
                    "uk": "Хаус",
                    "en": "House"
                }
            },
            {
                "id": "9_72",
                "slug": "hipHop",
                "label": {
                    "ru": "Хип-хоп",
                    "uk": "Хіп-хоп",
                    "en": "Hip-Hop"
                }
            },
            {
                "id": "9_73",
                "slug": "rnb",
                "label": {
                    "ru": "R&B",
                    "uk": "R&B",
                    "en": "R&B"
                }
            },
            {
                "id": "9_74",
                "slug": "twerk",
                "label": {
                    "ru": "Тверк",
                    "uk": "Тверк",
                    "en": "Twerk"
                }
            },
            {
                "id": "9_75",
                "slug": "gogo",
                "label": {
                    "ru": "Go-go",
                    "uk": "Go-go",
                    "en": "Go-go"
                }
            }
        ]
    },
    {
        "id": "10",
        "slug": "fashion",
        "label": {
            "ru": "Мода",
            "uk": "Мода",
            "en": "Fashion"
        },
        "children": [
            {
                "id": "10_0",
                "slug": "fashion",
                "label": {
                    "ru": "Мода",
                    "uk": "Мода",
                    "en": "Fashion"
                }
            },
            {
                "id": "10_1",
                "slug": "fashionHistory",
                "label": {
                    "ru": "История моды",
                    "uk": "Історія моди",
                    "en": "History of Fashion"
                }
            },
            {
                "id": "10_2",
                "slug": "brandCollections",
                "label": {
                    "ru": "Коллекции брендов",
                    "uk": "Колекції брендів",
                    "en": "Brand Collections"
                }
            },
            {
                "id": "10_3",
                "slug": "fashionShows",
                "label": {
                    "ru": "Модные показы",
                    "uk": "Модні покази",
                    "en": "Fashion Shows"
                }
            },
            {
                "id": "10_4",
                "slug": "styleAndImage",
                "label": {
                    "ru": "Стиль и имидж",
                    "uk": "Стиль і імідж",
                    "en": "Style and Image"
                }
            },
            {
                "id": "10_5",
                "slug": "modelingSchools",
                "label": {
                    "ru": "Школы моделей",
                    "uk": "Школи моделей",
                    "en": "Modeling Schools"
                }
            },
            {
                "id": "10_6",
                "slug": "shoppingAndTrends",
                "label": {
                    "ru": "Шоппинг и тренды",
                    "uk": "Шопінг і тренди",
                    "en": "Shopping and Trends"
                }
            },
            {
                "id": "10_7",
                "slug": "ecoFashion",
                "label": {
                    "ru": "Экомода",
                    "uk": "Екомода",
                    "en": "Eco Fashion"
                }
            }
        ]
    },
    {
        "id": "11",
        "slug": "design",
        "label": {
            "ru": "Дизайн",
            "uk": "Дизайн",
            "en": "Design"
        },
        "children": [
            {
                "id": "11_0",
                "slug": "design",
                "label": {
                    "ru": "Дизайн",
                    "uk": "Дизайн",
                    "en": "Design"
                }
            },
            {
                "id": "11_1",
                "slug": "webDesign",
                "label": {
                    "ru": "Web-дизайн",
                    "uk": "Веб-дизайн",
                    "en": "Web Design"
                }
            },
            {
                "id": "11_2",
                "slug": "animationDesign",
                "label": {
                    "ru": "Анимационный дизайн",
                    "uk": "Анімаційний дизайн",
                    "en": "Animation Design"
                }
            },
            {
                "id": "11_3",
                "slug": "architecturalDesign",
                "label": {
                    "ru": "Архитектурный дизайн",
                    "uk": "Архітектурний дизайн",
                    "en": "Architectural Design"
                }
            },
            {
                "id": "11_4",
                "slug": "aeroDesign",
                "label": {
                    "ru": "Аэродизайн",
                    "uk": "Аеродизайн",
                    "en": "Aero Design"
                }
            },
            {
                "id": "11_5",
                "slug": "graphicDesign",
                "label": {
                    "ru": "Графический дизайн",
                    "uk": "Графічний дизайн",
                    "en": "Graphic Design"
                }
            },
            {
                "id": "11_6",
                "slug": "interiorDesign",
                "label": {
                    "ru": "Дизайн интерьера",
                    "uk": "Дизайн інтер'єру",
                    "en": "Interior Design"
                }
            },
            {
                "id": "11_7",
                "slug": "eventDesign",
                "label": {
                    "ru": "Дизайн мероприятий",
                    "uk": "Дизайн заходів",
                    "en": "Event Design"
                }
            },
            {
                "id": "11_8",
                "slug": "clothingDesign",
                "label": {
                    "ru": "Дизайн одежды",
                    "uk": "Дизайн одягу",
                    "en": "Clothing Design"
                }
            },
            {
                "id": "11_9",
                "slug": "customization",
                "label": {
                    "ru": "Кастомайзинг",
                    "uk": "Кастомізація",
                    "en": "Customization"
                }
            },
            {
                "id": "11_10",
                "slug": "landscapeDesign",
                "label": {
                    "ru": "Ландшафтный дизайн",
                    "uk": "Ландшафтний дизайн",
                    "en": "Landscape Design"
                }
            },
            {
                "id": "11_11",
                "slug": "landArt",
                "label": {
                    "ru": "Ленд-арт",
                    "uk": "Ленд-арт",
                    "en": "Land Art"
                }
            },
            {
                "id": "11_12",
                "slug": "modding",
                "label": {
                    "ru": "Моддинг",
                    "uk": "Моддинг",
                    "en": "Modding"
                }
            },
            {
                "id": "11_13",
                "slug": "lightingDesign",
                "label": {
                    "ru": "Световой дизайн",
                    "uk": "Світловий дизайн",
                    "en": "Lighting Design"
                }
            },
            {
                "id": "11_14",
                "slug": "suitDesign",
                "label": {
                    "ru": "Свит-дизайн",
                    "uk": "Сукня-дизайн",
                    "en": "Suit Design"
                }
            },
            {
                "id": "11_15",
                "slug": "floristics",
                "label": {
                    "ru": "Флористика",
                    "uk": "Флористика",
                    "en": "Floristics"
                }
            },
            {
                "id": "11_16",
                "slug": "futureDesign",
                "label": {
                    "ru": "Футуродизайн",
                    "uk": "Футуродизайн",
                    "en": "Future Design"
                }
            },
            {
                "id": "11_17",
                "slug": "ecoDesign",
                "label": {
                    "ru": "Экодизайн",
                    "uk": "Екодизайн",
                    "en": "Eco Design"
                }
            }
        ]
    },
    {
        "id": "12",
        "slug": "beautyAndHealth",
        "label": {
            "ru": "Красота и здоровье",
            "uk": "Краса і здоров'я",
            "en": "Beauty and Health"
        },
        "children": [
            {
                "id": "12_0",
                "slug": "beautyAndHealth",
                "label": {
                    "ru": "Красота и здоровье",
                    "uk": "Краса і здоров'я",
                    "en": "Beauty and Health"
                }
            },
            {
                "id": "12_1",
                "slug": "flylady",
                "label": {
                    "ru": "Flylady",
                    "uk": "Flylady",
                    "en": "Flylady"
                }
            },
            {
                "id": "12_2",
                "slug": "aromatherapy",
                "label": {
                    "ru": "Ароматерапия",
                    "uk": "Ароматерапія",
                    "en": "Aromatherapy"
                }
            },
            {
                "id": "12_3",
                "slug": "aerobics",
                "label": {
                    "ru": "Аэробика",
                    "uk": "Аеробіка",
                    "en": "Aerobics"
                }
            },
            {
                "id": "12_4",
                "slug": "bathsAndSaunas",
                "label": {
                    "ru": "Бани и сауны",
                    "uk": "Бані та сауни",
                    "en": "Baths and Saunas"
                }
            },
            {
                "id": "12_5",
                "slug": "bodyflex",
                "label": {
                    "ru": "Бодифлекс",
                    "uk": "Бодіфлекс",
                    "en": "Bodyflex"
                }
            },
            {
                "id": "12_6",
                "slug": "makeup",
                "label": {
                    "ru": "Визаж",
                    "uk": "Візаж",
                    "en": "Makeup"
                }
            },
            {
                "id": "12_7",
                "slug": "yoga",
                "label": {
                    "ru": "Йога",
                    "uk": "Йога",
                    "en": "Yoga"
                }
            },
            {
                "id": "12_8",
                "slug": "nailArt",
                "label": {
                    "ru": "Нейл-арт",
                    "uk": "Нейл-арт",
                    "en": "Nail Art"
                }
            },
            {
                "id": "12_9",
                "slug": "hairdressingArt",
                "label": {
                    "ru": "Парикмахерское искусство",
                    "uk": "Перукарське мистецтво",
                    "en": "Hairdressing Art"
                }
            },
            {
                "id": "12_10",
                "slug": "dietAndWeightLoss",
                "label": {
                    "ru": "ПП и похудение",
                    "uk": "ПП та схуднення",
                    "en": "Diet and Weight Loss"
                }
            },
            {
                "id": "12_11",
                "slug": "nordicWalking",
                "label": {
                    "ru": "Скандинавская ходьба",
                    "uk": "Скандинавська ходьба",
                    "en": "Nordic Walking"
                }
            },
            {
                "id": "12_12",
                "slug": "creatingCosmetics",
                "label": {
                    "ru": "Создание уходовой косметики",
                    "uk": "Створення доглядової косметики",
                    "en": "Creating Cosmetics"
                }
            },
            {
                "id": "12_13",
                "slug": "nailStanding",
                "label": {
                    "ru": "Стояние на гвоздях",
                    "uk": "Стояння на цвяхах",
                    "en": "Nail Standing"
                }
            },
            {
                "id": "12_14",
                "slug": "massage",
                "label": {
                    "ru": "Массаж",
                    "uk": "Масаж",
                    "en": "Massage"
                }
            },
            {
                "id": "12_15",
                "slug": "faceFitness",
                "label": {
                    "ru": "Фейсфитнес",
                    "uk": "Фейсфітнес",
                    "en": "Face Fitness"
                }
            },
            {
                "id": "12_16",
                "slug": "faceBuilding",
                "label": {
                    "ru": "Фэйсбилдинг",
                    "uk": "Фейсбілдинг",
                    "en": "Face Building"
                }
            },
            {
                "id": "12_17",
                "slug": "qigong",
                "label": {
                    "ru": "Цигун",
                    "uk": "Цигун",
                    "en": "Qigong"
                }
            }
        ]
    },
    {
        "id": "13",
        "slug": "olympicGames",
        "label": {
            "ru": "Олимпийские игры",
            "uk": "Олімпійські ігри",
            "en": "Olympic Games"
        },
        "children": [
            {
                "id": "13_0",
                "slug": "olympicGames",
                "label": {
                    "ru": "Олимпийские игры",
                    "uk": "Олімпійські ігри",
                    "en": "Olympic Games"
                }
            },
            {
                "id": "13_1",
                "slug": "paralympicGames",
                "label": {
                    "ru": "Паролимпийские игры",
                    "uk": "Паралімпійські ігри",
                    "en": "Paralympic Games"
                }
            }
        ]
    },
    {
        "id": "14",
        "slug": "sports",
        "label": {
            "ru": "Спорт",
            "uk": "Спорт",
            "en": "Sport"
        },
        "children": [
            {
                "id": "13_0",
                "slug": "sports",
                "label": {
                    "ru": "Спорт",
                    "uk": "Спорт",
                    "en": "Sport"
                }
            },
            {
                "id": "13_1",
                "slug": "acrobatics",
                "label": {
                    "ru": "Акробатика",
                    "uk": "Акробатика",
                    "en": "Acrobatics"
                }
            },
            {
                "id": "13_2",
                "slug": "armwrestling",
                "label": {
                    "ru": "Армреслинг",
                    "uk": "Армреслінг",
                    "en": "Arm wrestling"
                }
            },
            {
                "id": "13_3",
                "slug": "running",
                "label": {
                    "ru": "Бег",
                    "uk": "Біг",
                    "en": "Running"
                }
            },
            {
                "id": "13_4",
                "slug": "biathlon",
                "label": {
                    "ru": "Биатлон",
                    "uk": "Біатлон",
                    "en": "Biathlon"
                }
            },
            {
                "id": "13_5",
                "slug": "bodybuilding",
                "label": {
                    "ru": "Бодибилдинг",
                    "uk": "Бодібілдинг",
                    "en": "Bodybuilding"
                }
            },
            {
                "id": "13_6",
                "slug": "cycling",
                "label": {
                    "ru": "Велоспорт",
                    "uk": "Велоспорт",
                    "en": "Cycling"
                }
            },
            {
                "id": "13_7",
                "slug": "geocaching",
                "label": {
                    "ru": "Геокэшинг",
                    "uk": "Геокешинг",
                    "en": "Geocaching"
                }
            },
            {
                "id": "13_8",
                "slug": "sportsGymnastics",
                "label": {
                    "ru": "Гимнастика спортивная",
                    "uk": "Спортивна гімнастика",
                    "en": "Sports gymnastics"
                }
            },
            {
                "id": "13_9",
                "slug": "artisticGymnastics",
                "label": {
                    "ru": "Гимнастика художественная",
                    "uk": "Художня гімнастика",
                    "en": "Artistic gymnastics"
                }
            },
            {
                "id": "13_10",
                "slug": "kettlebellSport",
                "label": {
                    "ru": "Гиревой спорт",
                    "uk": "Гирьовий спорт",
                    "en": "Kettlebell sport"
                }
            },
            {
                "id": "13_11",
                "slug": "rowing",
                "label": {
                    "ru": "Гребля",
                    "uk": "Гребля",
                    "en": "Rowing"
                }
            },
            {
                "id": "13_12",
                "slug": "zumba",
                "label": {
                    "ru": "Зумба",
                    "uk": "Зумба",
                    "en": "Zumba"
                }
            },
            {
                "id": "13_13",
                "slug": "kabaddi",
                "label": {
                    "ru": "Кабадди",
                    "uk": "Кабадді",
                    "en": "Kabaddi"
                }
            },
            {
                "id": "13_14",
                "slug": "equestrianSport",
                "label": {
                    "ru": "Конный спорт",
                    "uk": "Конний спорт",
                    "en": "Equestrian sport"
                }
            },
            {
                "id": "13_15",
                "slug": "skating",
                "label": {
                    "ru": "Коньки",
                    "uk": "Ковзани",
                    "en": "Skating"
                }
            },
            {
                "id": "13_16",
                "slug": "crossfit",
                "label": {
                    "ru": "Кроссфит",
                    "uk": "Кросфіт",
                    "en": "Crossfit"
                }
            },
            {
                "id": "13_17",
                "slug": "athletics",
                "label": {
                    "ru": "Легкая атлетика",
                    "uk": "Легка атлетика",
                    "en": "Athletics"
                }
            },
            {
                "id": "13_18",
                "slug": "skiing",
                "label": {
                    "ru": "Лыжи",
                    "uk": "Лижі",
                    "en": "Skiing"
                }
            },
            {
                "id": "13_19",
                "slug": "tableTennis",
                "label": {
                    "ru": "Настольный теннис",
                    "uk": "Настільний теніс",
                    "en": "Table tennis"
                }
            },
            {
                "id": "13_20",
                "slug": "sailing",
                "label": {
                    "ru": "Парусный спорт",
                    "uk": "Парусний спорт",
                    "en": "Sailing"
                }
            },
            {
                "id": "13_21",
                "slug": "powerlifting",
                "label": {
                    "ru": "Пауэрлифтинг",
                    "uk": "Пауерліфтинг",
                    "en": "Powerlifting"
                }
            },
            {
                "id": "13_22",
                "slug": "pilates",
                "label": {
                    "ru": "Пилатес",
                    "uk": "Пілатес",
                    "en": "Pilates"
                }
            },
            {
                "id": "13_23",
                "slug": "swimming",
                "label": {
                    "ru": "Плавание",
                    "uk": "Плавання",
                    "en": "Swimming"
                }
            },
            {
                "id": "13_24",
                "slug": "trampolineJumping",
                "label": {
                    "ru": "Прыжки на батуте",
                    "uk": "Стрибки на батуті",
                    "en": "Trampoline jumping"
                }
            },
            {
                "id": "13_25",
                "slug": "rogaining",
                "label": {
                    "ru": "Рогейн",
                    "uk": "Рогейн",
                    "en": "Rogaining"
                }
            },
            {
                "id": "13_26",
                "slug": "rollerSports",
                "label": {
                    "ru": "Роликобежный спорт",
                    "uk": "Роликовий спорт",
                    "en": "Roller sports"
                }
            },
            {
                "id": "13_27",
                "slug": "scooterSport",
                "label": {
                    "ru": "Самокатный спорт",
                    "uk": "Спорт на самокатах",
                    "en": "Scooter sport"
                }
            },
            {
                "id": "13_28",
                "slug": "sportsShooting",
                "label": {
                    "ru": "Спортивная стрельба",
                    "uk": "Спортивна стрільба",
                    "en": "Sports shooting"
                }
            },
            {
                "id": "13_29",
                "slug": "archery",
                "label": {
                    "ru": "Стрельба из лука",
                    "uk": "Стрільба з лука",
                    "en": "Archery"
                }
            },
            {
                "id": "13_30",
                "slug": "shooting",
                "label": {
                    "ru": "Стрельба пулевая",
                    "uk": "Стрільба з пуль",
                    "en": "Shooting"
                }
            },
            {
                "id": "13_31",
                "slug": "clayShooting",
                "label": {
                    "ru": "Стрельба стендовая",
                    "uk": "Стрільба по тарілках",
                    "en": "Clay shooting"
                }
            },
            {
                "id": "13_32",
                "slug": "sportsDance",
                "label": {
                    "ru": "Танцы спортивные",
                    "uk": "Спортивні танці",
                    "en": "Sports dance"
                }
            },
            {
                "id": "13_33",
                "slug": "gym",
                "label": {
                    "ru": "Тренажорный зал New",
                    "uk": "Тренажерний зал New",
                    "en": "Gym New"
                }
            },
            {
                "id": "13_34",
                "slug": "triathlon",
                "label": {
                    "ru": "Триатлон",
                    "uk": "Триатлон",
                    "en": "Triathlon"
                }
            },
            {
                "id": "13_35",
                "slug": "weightlifting",
                "label": {
                    "ru": "Тяжелая атлетика",
                    "uk": "Важка атлетика",
                    "en": "Weightlifting"
                }
            },
            {
                "id": "13_36",
                "slug": "fencing",
                "label": {
                    "ru": "Фехтование",
                    "uk": "Фехтування",
                    "en": "Fencing"
                }
            },
            {
                "id": "13_37",
                "slug": "figureSkating",
                "label": {
                    "ru": "Фигурное катание",
                    "uk": "Фігурне катання",
                    "en": "Figure skating"
                }
            },
            {
                "id": "13_38",
                "slug": "fitnessNew",
                "label": {
                    "ru": "Фитнес New",
                    "uk": "Фітнес New",
                    "en": "Fitness New"
                }
            },
            {
                "id": "13_39",
                "slug": "shortTrack",
                "label": {
                    "ru": "Шорт-трек",
                    "uk": "Шорт-трек",
                    "en": "Short track"
                }
            },
            {
                "id": "13_40",
                "slug": "yachting",
                "label": {
                    "ru": "Яхтинг",
                    "uk": "Яхтинг",
                    "en": "Yachting"
                }
            }
        ]
    },
    {
        "id": "15",
        "slug": "teamSports",
        "label": {
            "ru": "Командные Игры",
            "uk": "Командні Ігри",
            "en": "Team Sports"
        },
        "children": [
            {
                "id": "15_0",
                "slug": "teamGames",
                "label": {
                    "ru": "Командные Игры",
                    "uk": "Командні Ігри",
                    "en": "Team Games"
                }
            },
            {
                "id": "15_1",
                "slug": "americanFootball",
                "label": {
                    "ru": "Американский футбол",
                    "uk": "Американський футбол",
                    "en": "American Football"
                }
            },
            {
                "id": "15_2",
                "slug": "badminton",
                "label": {
                    "ru": "Бадминтон",
                    "uk": "Бадмінтон",
                    "en": "Badminton"
                }
            },
            {
                "id": "15_3",
                "slug": "basketball",
                "label": {
                    "ru": "Баскетбол",
                    "uk": "Баскетбол",
                    "en": "Basketball"
                }
            },
            {
                "id": "15_4",
                "slug": "baseball",
                "label": {
                    "ru": "Бейсбол",
                    "uk": "Бейсбол",
                    "en": "Baseball"
                }
            },
            {
                "id": "15_5",
                "slug": "tennis",
                "label": {
                    "ru": "Большой теннис",
                    "uk": "Великий теніс",
                    "en": "Tennis"
                }
            },
            {
                "id": "15_6",
                "slug": "bridge",
                "label": {
                    "ru": "Бридж",
                    "uk": "Брідж",
                    "en": "Bridge"
                }
            },
            {
                "id": "15_7",
                "slug": "waterPolo",
                "label": {
                    "ru": "Водное поло",
                    "uk": "Водне поло",
                    "en": "Water Polo"
                }
            },
            {
                "id": "15_8",
                "slug": "volleyball",
                "label": {
                    "ru": "Волейбол",
                    "uk": "Волейбол",
                    "en": "Volleyball"
                }
            },
            {
                "id": "15_9",
                "slug": "beachVolleyball",
                "label": {
                    "ru": "Волейбол пляжный",
                    "uk": "Пляжний волейбол",
                    "en": "Beach Volleyball"
                }
            },
            {
                "id": "15_10",
                "slug": "handball",
                "label": {
                    "ru": "Гандбол",
                    "uk": "Гандбол",
                    "en": "Handball"
                }
            },
            {
                "id": "15_11",
                "slug": "darts",
                "label": {
                    "ru": "Дартс",
                    "uk": "Дартс",
                    "en": "Darts"
                }
            },
            {
                "id": "15_12",
                "slug": "curling",
                "label": {
                    "ru": "Керлинг",
                    "uk": "Керлінг",
                    "en": "Curling"
                }
            },
            {
                "id": "15_13",
                "slug": "kinball",
                "label": {
                    "ru": "Кинбол",
                    "uk": "Кінбол",
                    "en": "Kin-Ball"
                }
            },
            {
                "id": "15_14",
                "slug": "cricket",
                "label": {
                    "ru": "Крикет",
                    "uk": "Крикет",
                    "en": "Cricket"
                }
            },
            {
                "id": "15_15",
                "slug": "lapta",
                "label": {
                    "ru": "Лапта",
                    "uk": "Лапта",
                    "en": "Lapta"
                }
            },
            {
                "id": "15_16",
                "slug": "lacrosse",
                "label": {
                    "ru": "Лякросс",
                    "uk": "Лакросс",
                    "en": "Lacrosse"
                }
            },
            {
                "id": "15_17",
                "slug": "motoball",
                "label": {
                    "ru": "Мотобол",
                    "uk": "Мотобол",
                    "en": "Motoball"
                }
            },
            {
                "id": "15_18",
                "slug": "paintball",
                "label": {
                    "ru": "Пейнтбол",
                    "uk": "Пейнтбол",
                    "en": "Paintball"
                }
            },
            {
                "id": "15_19",
                "slug": "tugOfWar",
                "label": {
                    "ru": "Перетягивание каната",
                    "uk": "Перетягування каната",
                    "en": "Tug of War"
                }
            },
            {
                "id": "15_20",
                "slug": "polo",
                "label": {
                    "ru": "Поло",
                    "uk": "Поло",
                    "en": "Polo"
                }
            },
            {
                "id": "15_21",
                "slug": "rugby",
                "label": {
                    "ru": "Регби",
                    "uk": "Регбі",
                    "en": "Rugby"
                }
            },
            {
                "id": "15_22",
                "slug": "sledgeHockey",
                "label": {
                    "ru": "Следж-хоккей",
                    "uk": "Следж-хокей",
                    "en": "Sledge Hockey"
                }
            },
            {
                "id": "15_23",
                "slug": "slamball",
                "label": {
                    "ru": "Слэмбол",
                    "uk": "Слэмбол",
                    "en": "Slamball"
                }
            },
            {
                "id": "15_24",
                "slug": "softball",
                "label": {
                    "ru": "Софтбол",
                    "uk": "Софтбол",
                    "en": "Softball"
                }
            },
            {
                "id": "15_25",
                "slug": "sportingCompetitions",
                "label": {
                    "ru": "Спортивные состязания",
                    "uk": "Спортивні змагання",
                    "en": "Sporting Competitions"
                }
            },
            {
                "id": "15_26",
                "slug": "strikeball",
                "label": {
                    "ru": "Страйкбол",
                    "uk": "Страйкбол",
                    "en": "Strikeball"
                }
            },
            {
                "id": "15_27",
                "slug": "tableTennis",
                "label": {
                    "ru": "Теннис настольный",
                    "uk": "Настільний теніс",
                    "en": "Table Tennis"
                }
            },
            {
                "id": "15_28",
                "slug": "trackball",
                "label": {
                    "ru": "Трекбол",
                    "uk": "Трекбол",
                    "en": "Trackball"
                }
            },
            {
                "id": "15_29",
                "slug": "football",
                "label": {
                    "ru": "Футбол",
                    "uk": "Футбол",
                    "en": "Football"
                }
            },
            {
                "id": "15_30",
                "slug": "footbagNetGame",
                "label": {
                    "ru": "Футбэг нет-гейм",
                    "uk": "Футбег нет-гейм",
                    "en": "Footbag Net Game"
                }
            },
            {
                "id": "15_31",
                "slug": "fieldHockey",
                "label": {
                    "ru": "Хокей на траве",
                    "uk": "Хокей на траві",
                    "en": "Field Hockey"
                }
            },
            {
                "id": "15_32",
                "slug": "hockey",
                "label": {
                    "ru": "Хоккей",
                    "uk": "Хокей",
                    "en": "Hockey"
                }
            },
            {
                "id": "15_33",
                "slug": "ballHockey",
                "label": {
                    "ru": "Хоккей с мячом",
                    "uk": "Хокей з м'ячем",
                    "en": "Ball Hockey"
                }
            },
            {
                "id": "15_34",
                "slug": "encounter",
                "label": {
                    "ru": "Энкаунтер",
                    "uk": "Енкаунтер",
                    "en": "Encounter"
                }
            }
        ]
    },
    {
        "id": "16",
        "slug": "extremeHobbies",
        "label": {
            "ru": "Экстремальные увлечения",
            "uk": "Екстремальні захоплення",
            "en": "Extreme Hobbies"
        },
        "children": [
            {
                "id": "16_0",
                "slug": "extremeHobbies",
                "label": {
                    "ru": "Экстремальные увлечения",
                    "uk": "Екстремальні захоплення",
                    "en": "Extreme Hobbies"
                }
            },
            {
                "id": "16_1",
                "slug": "mountaineering",
                "label": {
                    "ru": "Альпинизм",
                    "uk": "Альпінізм",
                    "en": "Mountaineering"
                }
            },
            {
                "id": "16_2",
                "slug": "bungeeJumping",
                "label": {
                    "ru": "Банджи-джампинг",
                    "uk": "Банджі-джампінг",
                    "en": "Bungee Jumping"
                }
            },
            {
                "id": "16_3",
                "slug": "baseJumping",
                "label": {
                    "ru": "Бейсджампинг",
                    "uk": "Бейсджампінг",
                    "en": "Base Jumping"
                }
            },
            {
                "id": "16_4",
                "slug": "baseClimbers",
                "label": {
                    "ru": "Бейскламберы",
                    "uk": "Бейсламбери",
                    "en": "Base Climbers"
                }
            },
            {
                "id": "16_5",
                "slug": "bouldering",
                "label": {
                    "ru": "Билдеринг",
                    "uk": "Болдеринг",
                    "en": "Bouldering"
                }
            },
            {
                "id": "16_6",
                "slug": "wakeboarding",
                "label": {
                    "ru": "Вейкбординг",
                    "uk": "Вейкбординг",
                    "en": "Wakeboarding"
                }
            },
            {
                "id": "16_7",
                "slug": "windsurfing",
                "label": {
                    "ru": "Виндсерфинг",
                    "uk": "Вітрильний серфінг",
                    "en": "Windsurfing"
                }
            },
            {
                "id": "16_8",
                "slug": "skiing",
                "label": {
                    "ru": "Горные лыжи",
                    "uk": "Гірські лижі",
                    "en": "Skiing"
                }
            },
            {
                "id": "16_9",
                "slug": "groupAcrobatics",
                "label": {
                    "ru": "Групповая акробатика",
                    "uk": "Групова акробатика",
                    "en": "Group Acrobatics"
                }
            },
            {
                "id": "16_10",
                "slug": "diving",
                "label": {
                    "ru": "Дайвинг",
                    "uk": "Дайвінг",
                    "en": "Diving"
                }
            },
            {
                "id": "16_11",
                "slug": "diggering",
                "label": {
                    "ru": "Диггерство",
                    "uk": "Діггерство",
                    "en": "Diggering"
                }
            },
            {
                "id": "16_12",
                "slug": "zorbing",
                "label": {
                    "ru": "Зорбинг",
                    "uk": "Зорбінг",
                    "en": "Zorbing"
                }
            },
            {
                "id": "16_13",
                "slug": "kitesurfing",
                "label": {
                    "ru": "Кайтсёрфинг",
                    "uk": "Кайтсерфінг",
                    "en": "Kitesurfing"
                }
            },
            {
                "id": "16_14",
                "slug": "dogSledding",
                "label": {
                    "ru": "Катание на собачьих упряжках",
                    "uk": "Катання на собачих упряжках",
                    "en": "Dog Sledding"
                }
            },
            {
                "id": "16_15",
                "slug": "kayaking",
                "label": {
                    "ru": "Каякинг",
                    "uk": "Каякінг",
                    "en": "Kayaking"
                }
            },
            {
                "id": "16_16",
                "slug": "kickScootering",
                "label": {
                    "ru": "Кикскутеринг",
                    "uk": "Кікскутеринг",
                    "en": "Kick Scootering"
                }
            },
            {
                "id": "16_17",
                "slug": "cliffDiving",
                "label": {
                    "ru": "Клиффдайвинг",
                    "uk": "Кліфдайвінг",
                    "en": "Cliff Diving"
                }
            },
            {
                "id": "16_18",
                "slug": "bullfighting",
                "label": {
                    "ru": "Коррида",
                    "uk": "Корида",
                    "en": "Bullfighting"
                }
            },
            {
                "id": "16_19",
                "slug": "domedAcrobatics",
                "label": {
                    "ru": "Купольная акробатика",
                    "uk": "Купольна акробатика",
                    "en": "Domed Acrobatics"
                }
            },
            {
                "id": "16_20",
                "slug": "iceClimbing",
                "label": {
                    "ru": "Ледолазание",
                    "uk": "Льодолазання",
                    "en": "Ice Climbing"
                }
            },
            {
                "id": "16_21",
                "slug": "mountainBiking",
                "label": {
                    "ru": "Маунтин-байкинг",
                    "uk": "Маунтінбайкінг",
                    "en": "Mountain Biking"
                }
            },
            {
                "id": "16_22",
                "slug": "parkour",
                "label": {
                    "ru": "Паркур",
                    "uk": "Паркур",
                    "en": "Parkour"
                }
            },
            {
                "id": "16_23",
                "slug": "swimmingWithSharks",
                "label": {
                    "ru": "Плавание с акулами",
                    "uk": "Плавання з акулами",
                    "en": "Swimming with Sharks"
                }
            },
            {
                "id": "16_24",
                "slug": "hotAirBalloonFlights",
                "label": {
                    "ru": "Полёты на воздушных шарах",
                    "uk": "Польоти на повітряних кулях",
                    "en": "Hot Air Balloon Flights"
                }
            },
            {
                "id": "16_25",
                "slug": "paragliding",
                "label": {
                    "ru": "Полеты на параплане",
                    "uk": "Польоти на параплані",
                    "en": "Paragliding"
                }
            },
            {
                "id": "16_26",
                "slug": "questPassing",
                "label": {
                    "ru": "Прохождение квестов",
                    "uk": "Проходження квестів",
                    "en": "Quest Passing"
                }
            },
            {
                "id": "16_27",
                "slug": "parachuting",
                "label": {
                    "ru": "Прыжки с парашютом",
                    "uk": "Парашутний спорт",
                    "en": "Parachuting"
                }
            },
            {
                "id": "16_28",
                "slug": "rafting",
                "label": {
                    "ru": "Рафтинг",
                    "uk": "Рафтинг",
                    "en": "Rafting"
                }
            },
            {
                "id": "16_29",
                "slug": "rollerblading",
                "label": {
                    "ru": "Ролики",
                    "uk": "Роликові ковзани",
                    "en": "Rollerblading"
                }
            },
            {
                "id": "16_30",
                "slug": "roofering",
                "label": {
                    "ru": "Руфинг",
                    "uk": "Руфінг",
                    "en": "Roofering"
                }
            },
            {
                "id": "16_31",
                "slug": "mushroomPicking",
                "label": {
                    "ru": "Сбор грибов",
                    "uk": "Збір грибів",
                    "en": "Mushroom Picking"
                }
            },
            {
                "id": "16_32",
                "slug": "surfing",
                "label": {
                    "ru": "Серфинг",
                    "uk": "Серфінг",
                    "en": "Surfing"
                }
            },
            {
                "id": "16_33",
                "slug": "skydiving",
                "label": {
                    "ru": "Скайдайвинг",
                    "uk": "Скайдайвінг",
                    "en": "Skydiving"
                }
            },
            {
                "id": "16_34",
                "slug": "climbingWall",
                "label": {
                    "ru": "Скалодром",
                    "uk": "Скалодром",
                    "en": "Climbing Wall"
                }
            },
            {
                "id": "16_35",
                "slug": "races",
                "label": {
                    "ru": "Скачки",
                    "uk": "Перегони",
                    "en": "Races"
                }
            },
            {
                "id": "16_36",
                "slug": "skateboarding",
                "label": {
                    "ru": "Скейтборд",
                    "uk": "Скейтборд",
                    "en": "Skateboarding"
                }
            },
            {
                "id": "16_37",
                "slug": "snowboarding",
                "label": {
                    "ru": "Сноуборд",
                    "uk": "Сноуборд",
                    "en": "Snowboarding"
                }
            },
            {
                "id": "16_38",
                "slug": "caving",
                "label": {
                    "ru": "Спелеотуризм",
                    "uk": "Спелеотуризм",
                    "en": "Caving"
                }
            },
            {
                "id": "16_39",
                "slug": "sportsBetting",
                "label": {
                    "ru": "Ставки на спорт",
                    "uk": "Ставки на спорт",
                    "en": "Sports Betting"
                }
            },
            {
                "id": "16_40",
                "slug": "transurfing",
                "label": {
                    "ru": "Трейнсерфинг",
                    "uk": "Трейнсерфінг",
                    "en": "Transurfing"
                }
            },
            {
                "id": "16_41",
                "slug": "heliboarding",
                "label": {
                    "ru": "Хелибординг",
                    "uk": "Хелібординг",
                    "en": "Heli Boarding"
                }
            },
            {
                "id": "16_42",
                "slug": "walkingOnTheRope",
                "label": {
                    "ru": "Хождение по канату",
                    "uk": "Ходіння по канату",
                    "en": "Walking on the Rope"
                }
            },
            {
                "id": "16_43",
                "slug": "blackDiggers",
                "label": {
                    "ru": "Черные копатели",
                    "uk": "Чорні копачі",
                    "en": "Black Diggers"
                }
            },
            {
                "id": "16_44",
                "slug": "encierro",
                "label": {
                    "ru": "Энсьерро",
                    "uk": "Енсьєрро",
                    "en": "Encierro"
                }
            }
        ]
    },
    {
        "id": "17",
        "slug": "martialArts",
        "label": {
            "ru": "Боевые исскуства",
            "uk": "Бойові мистецтва",
            "en": "Martial Arts"
        },
        "children": [
            {
                "id": "17_0",
                "slug": "martialArts",
                "label": {
                    "ru": "Боевые исскуства",
                    "uk": "Бойові мистецтва",
                    "en": "Martial Arts"
                }
            },
            {
                "id": "17_1",
                "slug": "aikido",
                "label": {
                    "ru": "Айкидо",
                    "uk": "Айкідо",
                    "en": "Aikido"
                }
            },
            {
                "id": "17_2",
                "slug": "arnis",
                "label": {
                    "ru": "Арнис",
                    "uk": "Арніс",
                    "en": "Arnis"
                }
            },
            {
                "id": "17_3",
                "slug": "bartitsu",
                "label": {
                    "ru": "Бартицу",
                    "uk": "Бартіцу",
                    "en": "Bartitsu"
                }
            },
            {
                "id": "17_4",
                "slug": "mixedMartialArts",
                "label": {
                    "ru": "Бои без правил",
                    "uk": "Бої без правил",
                    "en": "Mixed Martial Arts"
                }
            },
            {
                "id": "17_5",
                "slug": "boxing",
                "label": {
                    "ru": "Бокс",
                    "uk": "Бокс",
                    "en": "Boxing"
                }
            },
            {
                "id": "17_6",
                "slug": "wingChun",
                "label": {
                    "ru": "Винь-чуй",
                    "uk": "Вінь-Чунь",
                    "en": "Wing Chun"
                }
            },
            {
                "id": "17_7",
                "slug": "freestyleWrestling",
                "label": {
                    "ru": "Вольная борьба",
                    "uk": "Вільна боротьба",
                    "en": "Freestyle Wrestling"
                }
            },
            {
                "id": "17_8",
                "slug": "grecoRomanWrestling",
                "label": {
                    "ru": "Греко-римская борьба",
                    "uk": "Греко-римська боротьба",
                    "en": "Greco-Roman Wrestling"
                }
            },
            {
                "id": "17_9",
                "slug": "gulesh",
                "label": {
                    "ru": "Гюлеш",
                    "uk": "Гюлеш",
                    "en": "Gulesh"
                }
            },
            {
                "id": "17_10",
                "slug": "jiuJitsu",
                "label": {
                    "ru": "Джиу-джитсу",
                    "uk": "Джиу-джитсу",
                    "en": "Jiu-Jitsu"
                }
            },
            {
                "id": "17_11",
                "slug": "judo",
                "label": {
                    "ru": "Дзюдо",
                    "uk": "Дзюдо",
                    "en": "Judo"
                }
            },
            {
                "id": "17_12",
                "slug": "capoeira",
                "label": {
                    "ru": "Капоэйра",
                    "uk": "Капоейра",
                    "en": "Capoeira"
                }
            },
            {
                "id": "17_13",
                "slug": "karate",
                "label": {
                    "ru": "Каратэ",
                    "uk": "Карате",
                    "en": "Karate"
                }
            },
            {
                "id": "17_14",
                "slug": "kyokushin",
                "label": {
                    "ru": "Киокусинкай",
                    "uk": "Кіокушинкай",
                    "en": "Kyokushin"
                }
            },
            {
                "id": "17_15",
                "slug": "kyokushinkai",
                "label": {
                    "ru": "Киокушин",
                    "uk": "Кіокушин",
                    "en": "Kyokushin"
                }
            },
            {
                "id": "17_16",
                "slug": "kuresh",
                "label": {
                    "ru": "Куреш",
                    "uk": "Куреш",
                    "en": "Kuresh"
                }
            },
            {
                "id": "17_17",
                "slug": "kendo",
                "label": {
                    "ru": "Кэндо",
                    "uk": "Кендо",
                    "en": "Kendo"
                }
            },
            {
                "id": "17_18",
                "slug": "letWei",
                "label": {
                    "ru": "Летхвей",
                    "uk": "Летвей",
                    "en": "Lethwei"
                }
            },
            {
                "id": "17_19",
                "slug": "mma",
                "label": {
                    "ru": "ММА",
                    "uk": "ММА",
                    "en": "MMA"
                }
            },
            {
                "id": "17_20",
                "slug": "muayThai",
                "label": {
                    "ru": "Муай тай",
                    "uk": "Муай тай",
                    "en": "Muay Thai"
                }
            },
            {
                "id": "17_21",
                "slug": "handToHandCombat",
                "label": {
                    "ru": "Рукопашный бой",
                    "uk": "Рукопашний бій",
                    "en": "Hand-to-Hand Combat"
                }
            },
            {
                "id": "17_22",
                "slug": "savate",
                "label": {
                    "ru": "Сават",
                    "uk": "Сават",
                    "en": "Savate"
                }
            },
            {
                "id": "17_23",
                "slug": "sambo",
                "label": {
                    "ru": "Самбо",
                    "uk": "Самбо",
                    "en": "Sambo"
                }
            },
            {
                "id": "17_24",
                "slug": "sumo",
                "label": {
                    "ru": "Сумо",
                    "uk": "Сумо",
                    "en": "Sumo"
                }
            },
            {
                "id": "17_25",
                "slug": "muayBoran",
                "label": {
                    "ru": "Тайский бокс",
                    "uk": "Тайський бокс",
                    "en": "Muay Boran"
                }
            },
            {
                "id": "17_26",
                "slug": "taekwondo",
                "label": {
                    "ru": "Тхэквандо",
                    "uk": "Тхеквондо",
                    "en": "Taekwondo"
                }
            },
            {
                "id": "17_27",
                "slug": "wushu",
                "label": {
                    "ru": "Ушу",
                    "uk": "Ушу",
                    "en": "Wushu"
                }
            },
            {
                "id": "17_28",
                "slug": "fencing",
                "label": {
                    "ru": "Фехтование",
                    "uk": "Фехтування",
                    "en": "Fencing"
                }
            },
            {
                "id": "17_29",
                "slug": "hapkido",
                "label": {
                    "ru": "Хапкидо",
                    "uk": "Хапкідо",
                    "en": "Hapkido"
                }
            },
            {
                "id": "17_30",
                "slug": "shotokan",
                "label": {
                    "ru": "Шотокан",
                    "uk": "Шотокан",
                    "en": "Shotokan"
                }
            }
        ]
    },
    {
        "id": "18",
        "slug": "cookingAndDrinks",
        "label": {
            "ru": "Кулинария и напитки",
            "uk": "Кулінарія та напої",
            "en": "Cooking and Drinks"
        },
        "children": [
            {
                "id": "18_0",
                "slug": "cookingAndDrinks",
                "label": {
                    "ru": "Кулинария и напитки",
                    "uk": "Кулінарія та напої",
                    "en": "Cooking and Drinks"
                }
            },
            {
                "id": "18_1",
                "slug": "asianCuisine",
                "label": {
                    "ru": "Азиатская кухня",
                    "uk": "Азійська кухня",
                    "en": "Asian Cuisine"
                }
            },
            {
                "id": "18_2",
                "slug": "winemaking",
                "label": {
                    "ru": "Виноделие",
                    "uk": "Виноробство",
                    "en": "Winemaking"
                }
            },
            {
                "id": "18_3",
                "slug": "bakingCakesAndDesserts",
                "label": {
                    "ru": "Выпечка тортов и десертов",
                    "uk": "Випічка тортів та десертів",
                    "en": "Baking Cakes and Desserts"
                }
            },
            {
                "id": "18_4",
                "slug": "foodDecoration",
                "label": {
                    "ru": "Декорирование блюд",
                    "uk": "Декорування страв",
                    "en": "Food Decoration"
                }
            },
            {
                "id": "18_5",
                "slug": "homemadeCheesemaking",
                "label": {
                    "ru": "Домашнее сыроделие",
                    "uk": "Домашнє сироробство",
                    "en": "Homemade Cheesemaking"
                }
            },
            {
                "id": "18_6",
                "slug": "spanishCuisine",
                "label": {
                    "ru": "Испанская кухня",
                    "uk": "Іспанська кухня",
                    "en": "Spanish Cuisine"
                }
            },
            {
                "id": "18_7",
                "slug": "italianCuisine",
                "label": {
                    "ru": "Итальянская кухня",
                    "uk": "Італійська кухня",
                    "en": "Italian Cuisine"
                }
            },
            {
                "id": "18_8",
                "slug": "caucasianCuisine",
                "label": {
                    "ru": "Кавказская кухня",
                    "uk": "Кавказька кухня",
                    "en": "Caucasian Cuisine"
                }
            },
            {
                "id": "18_9",
                "slug": "carving",
                "label": {
                    "ru": "Карвинг",
                    "uk": "Карвінг",
                    "en": "Carving"
                }
            },
            {
                "id": "18_10",
                "slug": "catering",
                "label": {
                    "ru": "Кейтеринг",
                    "uk": "Кейтеринг",
                    "en": "Catering"
                }
            },
            {
                "id": "18_11",
                "slug": "canning",
                "label": {
                    "ru": "Консервирование",
                    "uk": "Консервування",
                    "en": "Canning"
                }
            },
            {
                "id": "18_12",
                "slug": "latteArt",
                "label": {
                    "ru": "Латте-арт",
                    "uk": "Латте-арт",
                    "en": "Latte Art"
                }
            },
            {
                "id": "18_13",
                "slug": "mexicanCuisine",
                "label": {
                    "ru": "Мексиканская кухня",
                    "uk": "Мексиканська кухня",
                    "en": "Mexican Cuisine"
                }
            },
            {
                "id": "18_14",
                "slug": "mixology",
                "label": {
                    "ru": "Миксология",
                    "uk": "Міксологія",
                    "en": "Mixology"
                }
            },
            {
                "id": "18_15",
                "slug": "panAsianCuisine",
                "label": {
                    "ru": "Паназиатская кухня",
                    "uk": "Паназійська кухня",
                    "en": "Pan-Asian Cuisine"
                }
            },
            {
                "id": "18_16",
                "slug": "brewing",
                "label": {
                    "ru": "Пивоварение",
                    "uk": "Пивоваріння",
                    "en": "Brewing"
                }
            },
            {
                "id": "18_17",
                "slug": "drinkMaking",
                "label": {
                    "ru": "Приготовление напитков",
                    "uk": "Приготування напоїв",
                    "en": "Drink Making"
                }
            },
            {
                "id": "18_18",
                "slug": "russianCuisine",
                "label": {
                    "ru": "Русская кухня",
                    "uk": "Російська кухня",
                    "en": "Russian Cuisine"
                }
            },
            {
                "id": "18_19",
                "slug": "moonshineMaking",
                "label": {
                    "ru": "Самогоноварение",
                    "uk": "Самогоноваріння",
                    "en": "Moonshine Making"
                }
            },
            {
                "id": "18_20",
                "slug": "servingAndEtiquette",
                "label": {
                    "ru": "Сервировка и этикет",
                    "uk": "Сервіровка та етикет",
                    "en": "Serving and Etiquette"
                }
            },
            {
                "id": "18_21",
                "slug": "handmadeSweets",
                "label": {
                    "ru": "Сладости ручной работы",
                    "uk": "Солодощі ручної роботи",
                    "en": "Handmade Sweets"
                }
            },
            {
                "id": "18_22",
                "slug": "turkishCuisine",
                "label": {
                    "ru": "Турецкая кухня",
                    "uk": "Турецька кухня",
                    "en": "Turkish Cuisine"
                }
            },
            {
                "id": "18_23",
                "slug": "frenchCuisine",
                "label": {
                    "ru": "Французская кухня",
                    "uk": "Французька кухня",
                    "en": "French Cuisine"
                }
            },
            {
                "id": "18_24",
                "slug": "coldDishes",
                "label": {
                    "ru": "Холодные блюда",
                    "uk": "Холодні страви",
                    "en": "Cold Dishes"
                }
            }
        ]
    },
    {
        "id": "19",
        "slug": "nightEntertainment",
        "label": {
            "ru": "Ночные развлечения",
            "uk": "Нічні розваги",
            "en": "Night Entertainment"
        },
        "children": [
            {
                "id": "19_0",
                "slug": "nightEntertainment",
                "label": {
                    "ru": "Ночные развлечения",
                    "uk": "Нічні розваги",
                    "en": "Night Entertainment"
                }
            },
            {
                "id": "19_1",
                "slug": "nightClubs",
                "label": {
                    "ru": "Ночные клубы",
                    "uk": "Нічні клуби",
                    "en": "Night Clubs"
                }
            },
            {
                "id": "19_2",
                "slug": "hookahSmoking",
                "label": {
                    "ru": "Курение кальяна",
                    "uk": "Куріння кальяну",
                    "en": "Hookah Smoking"
                }
            },
            {
                "id": "19_3",
                "slug": "nightFitnessClub",
                "label": {
                    "ru": "Ночной фитнес клуб",
                    "uk": "Нічний фітнес клуб",
                    "en": "Night Fitness Club"
                }
            },
            {
                "id": "19_4",
                "slug": "karaokeBars",
                "label": {
                    "ru": "Караоке-бары",
                    "uk": "Караоке-бари",
                    "en": "Karaoke Bars"
                }
            },
            {
                "id": "19_5",
                "slug": "casinos",
                "label": {
                    "ru": "Казино",
                    "uk": "Казино",
                    "en": "Casinos"
                }
            },
            {
                "id": "19_6",
                "slug": "billiards",
                "label": {
                    "ru": "Бильярд",
                    "uk": "Більярд",
                    "en": "Billiards"
                }
            },
            {
                "id": "19_7",
                "slug": "bowling",
                "label": {
                    "ru": "Боулинг",
                    "uk": "Боулинг",
                    "en": "Bowling"
                }
            },
            {
                "id": "19_8",
                "slug": "stargazing",
                "label": {
                    "ru": "Наблюдение за звездами",
                    "uk": "Спостереження за зірками",
                    "en": "Stargazing"
                }
            }
        ]
    },
    {
        "id": "20",
        "slug": "photo",
        "label": {
            "ru": "Фото",
            "uk": "Фото",
            "en": "Photo"
        },
        "children": [
            {
                "id": "20_0",
                "slug": "photo",
                "label": {
                    "ru": "Фото",
                    "uk": "Фото",
                    "en": "Photo"
                }
            },
            {
                "id": "20_1",
                "slug": "photoRestoration",
                "label": {
                    "ru": "Реставрация фото",
                    "uk": "Реставрація фото",
                    "en": "Photo restoration"
                }
            },
            {
                "id": "20_2",
                "slug": "quadcopterPhotography",
                "label": {
                    "ru": "Съемка на квадрокоптер",
                    "uk": "Зйомка на квадрокоптер",
                    "en": "Quadcopter photography"
                }
            },
            {
                "id": "20_3",
                "slug": "photoMontage",
                "label": {
                    "ru": "Фотомонтаж",
                    "uk": "Фотомонтаж",
                    "en": "Photo montage"
                }
            },
            {
                "id": "20_4",
                "slug": "photoShooting",
                "label": {
                    "ru": "Фотосьемка",
                    "uk": "Фотозйомка",
                    "en": "Photo shooting"
                }
            }
        ]
    },
    {
        "id": "21",
        "slug": "video",
        "label": {
            "ru": "Видео",
            "uk": "Відео",
            "en": "Video"
        },
        "children": [
            {
                "id": "21_0",
                "slug": "video",
                "label": {
                    "ru": "Видео",
                    "uk": "Відео",
                    "en": "Video"
                }
            },
            {
                "id": "21_1",
                "slug": "videoArt",
                "label": {
                    "ru": "Видео Арт",
                    "uk": "Відео Арт",
                    "en": "Video Art"
                }
            },
            {
                "id": "21_2",
                "slug": "videoEditing",
                "label": {
                    "ru": "Видеомонтаж",
                    "uk": "Відеомонтаж",
                    "en": "Video Editing"
                }
            },
            {
                "id": "21_3",
                "slug": "videoShooting",
                "label": {
                    "ru": "Видеосъемка",
                    "uk": "Відеозйомка",
                    "en": "Video Shooting"
                }
            },
            {
                "id": "21_4",
                "slug": "viralVideos",
                "label": {
                    "ru": "Вирусные ролики",
                    "uk": "Вірусні ролики",
                    "en": "Viral Videos"
                }
            },
            {
                "id": "21_5",
                "slug": "quadcopterShooting",
                "label": {
                    "ru": "Съемка на квадрокоптер",
                    "uk": "Зйомка на квадрокоптер",
                    "en": "Quadcopter Shooting"
                }
            }
        ]
    },
    {
        "id": "22",
        "slug": "virtualWorld",
        "label": {
            "ru": "Виртуальный мир",
            "uk": "Віртуальний світ",
            "en": "Virtual World"
        },
        "children": [
            {
                "id": "22_0",
                "slug": "virtualWorld",
                "label": {
                    "ru": "Виртуальный мир",
                    "uk": "Віртуальний світ",
                    "en": "Virtual World"
                }
            },
            {
                "id": "22_1",
                "slug": "mmoMmorpgGames",
                "label": {
                    "ru": "ММО и MMORPG игры",
                    "uk": "ММО та MMORPG ігри",
                    "en": "MMO and MMORPG Games"
                }
            },
            {
                "id": "22_2",
                "slug": "flightSimulators",
                "label": {
                    "ru": "Авиасимуляторы",
                    "uk": "Авіасимулятори",
                    "en": "Flight Simulators"
                }
            },
            {
                "id": "22_3",
                "slug": "gameConsoles",
                "label": {
                    "ru": "Игровые приставки",
                    "uk": "Ігрові приставки",
                    "en": "Game Consoles"
                }
            },
            {
                "id": "22_4",
                "slug": "quests",
                "label": {
                    "ru": "Квесты",
                    "uk": "Квести",
                    "en": "Quests"
                }
            },
            {
                "id": "22_5",
                "slug": "esports",
                "label": {
                    "ru": "Киберспорт",
                    "uk": "Кіберспорт",
                    "en": "Esports"
                }
            },
            {
                "id": "22_6",
                "slug": "computerGames",
                "label": {
                    "ru": "Компьютерные игры",
                    "uk": "Комп'ютерні ігри",
                    "en": "Computer Games"
                }
            },
            {
                "id": "22_7",
                "slug": "simulators",
                "label": {
                    "ru": "Симуляторы",
                    "uk": "Симулятори",
                    "en": "Simulators"
                }
            },
            {
                "id": "22_8",
                "slug": "animationCreation",
                "label": {
                    "ru": "Создание анимации",
                    "uk": "Створення анімації",
                    "en": "Animation Creation"
                }
            },
            {
                "id": "22_9",
                "slug": "cyberpunk",
                "label": {
                    "ru": "Киберпанк",
                    "uk": "Кіберпанк",
                    "en": "Cyberpunk"
                }
            }
        ]
    },
    {
        "id": "23",
        "slug": "it",
        "label": {
            "ru": "IT",
            "uk": "ІТ",
            "en": "IT"
        },
        "children": [
            {
                "id": "23_0",
                "slug": "it",
                "label": {
                    "ru": "IT",
                    "uk": "ІТ",
                    "en": "IT"
                }
            },
            {
                "id": "23_1",
                "slug": "3dModeling",
                "label": {
                    "ru": "3D моделирование",
                    "uk": "3D моделювання",
                    "en": "3D modeling"
                }
            },
            {
                "id": "23_2",
                "slug": "4and5dModeling",
                "label": {
                    "ru": "4 и 5D моделирование",
                    "uk": "4 і 5D моделювання",
                    "en": "4 and 5D modeling"
                }
            },
            {
                "id": "23_3",
                "slug": "arVrTechnologies",
                "label": {
                    "ru": "AR/VR технологии",
                    "uk": "AR/VR технології",
                    "en": "AR/VR technologies"
                }
            },
            {
                "id": "23_4",
                "slug": "itTechnologiesAndInnovations",
                "label": {
                    "ru": "IT-технологии и инновации",
                    "uk": "ІТ-технології та інновації",
                    "en": "IT technologies and innovations"
                }
            },
            {
                "id": "23_5",
                "slug": "webProgramming",
                "label": {
                    "ru": "Web-программирование",
                    "uk": "Web-програмування",
                    "en": "Web programming"
                }
            },
            {
                "id": "23_6",
                "slug": "gameDesign",
                "label": {
                    "ru": "Геймдизайн",
                    "uk": "Геймдизайн",
                    "en": "Game design"
                }
            },
            {
                "id": "23_7",
                "slug": "programming",
                "label": {
                    "ru": "Программирование",
                    "uk": "Програмування",
                    "en": "Programming"
                }
            },
            {
                "id": "23_8",
                "slug": "gameProgramming",
                "label": {
                    "ru": "Программирование игр",
                    "uk": "Програмування ігор",
                    "en": "Game programming"
                }
            },
            {
                "id": "23_9",
                "slug": "appDevelopment",
                "label": {
                    "ru": "Программирование приложений",
                    "uk": "Програмування додатків",
                    "en": "App development"
                }
            },
            {
                "id": "23_10",
                "slug": "development",
                "label": {
                    "ru": "Разработка",
                    "uk": "Розробка",
                    "en": "Development"
                }
            }
        ]
    },
    {
        "id": "24",
        "slug": "blog",
        "label": {
            "ru": "Блогинг",
            "uk": "Блогінг",
            "en": "Blogging"
        },
        "children": [
            {
                "id": "24_0",
                "slug": "blog",
                "label": {
                    "ru": "Блогинг",
                    "uk": "Блогінг",
                    "en": "Blogging"
                }
            }
        ]
    },
    {
        "id": "25",
        "slug": "gambling",
        "label": {
            "ru": "Азартные игры",
            "uk": "Азартні ігри",
            "en": "Gambling"
        },
        "children": [
            {
                "id": "24_0",
                "slug": "gambling",
                "label": {
                    "ru": "Азартные игры",
                    "uk": "Азартні ігри",
                    "en": "Gambling"
                }
            },
            {
                "id": "24_1",
                "slug": "poker",
                "label": {
                    "ru": "Покер",
                    "uk": "Покер",
                    "en": "Poker"
                }
            },
            {
                "id": "24_2",
                "slug": "roulette",
                "label": {
                    "ru": "Рулетка",
                    "uk": "Рулетка",
                    "en": "Roulette"
                }
            },
            {
                "id": "24_3",
                "slug": "bets",
                "label": {
                    "ru": "Ставки",
                    "uk": "Ставки",
                    "en": "Bets"
                }
            },
            {
                "id": "24_4",
                "slug": "blackjack",
                "label": {
                    "ru": "Блэкджек",
                    "uk": "Блекджек",
                    "en": "Blackjack"
                }
            },
            {
                "id": "24_5",
                "slug": "baccarat",
                "label": {
                    "ru": "Баккара",
                    "uk": "Баккара",
                    "en": "Baccarat"
                }
            },
            {
                "id": "24_6",
                "slug": "craps",
                "label": {
                    "ru": "Крэпс (кости)",
                    "uk": "Крепс (кістки)",
                    "en": "Craps"
                }
            },
            {
                "id": "24_7",
                "slug": "lottery",
                "label": {
                    "ru": "Лотерея",
                    "uk": "Лотерея",
                    "en": "Lottery"
                }
            }
        ]
    },
    {
        "id": "26",
        "slug": "boardGames",
        "label": {
            "ru": "Настольные игры",
            "uk": "Настільні ігри",
            "en": "Board Games"
        },
        "children": [
            {
                "id": "26_0",
                "slug": "boardGames",
                "label": {
                    "ru": "Настольные игры",
                    "uk": "Настільні ігри",
                    "en": "Board Games"
                }
            },
            {
                "id": "26_1",
                "slug": "bankerAndBroker",
                "label": {
                    "ru": "Банкир и брокер",
                    "uk": "Банкір і брокер",
                    "en": "Banker and Broker"
                }
            },
            {
                "id": "26_2",
                "slug": "bluff",
                "label": {
                    "ru": "Блеф",
                    "uk": "Блеф",
                    "en": "Bluff"
                }
            },
            {
                "id": "26_3",
                "slug": "blackjack",
                "label": {
                    "ru": "Блэк-джек",
                    "uk": "Блекджек",
                    "en": "Blackjack"
                }
            },
            {
                "id": "26_4",
                "slug": "ten",
                "label": {
                    "ru": "Десятка",
                    "uk": "Десятка",
                    "en": "Ten"
                }
            },
            {
                "id": "26_5",
                "slug": "jenga",
                "label": {
                    "ru": "Дженга",
                    "uk": "Дженга",
                    "en": "Jenga"
                }
            },
            {
                "id": "26_6",
                "slug": "diskit",
                "label": {
                    "ru": "Дискит",
                    "uk": "Дискит",
                    "en": "Diskit"
                }
            },
            {
                "id": "26_7",
                "slug": "domino",
                "label": {
                    "ru": "Домино",
                    "uk": "Доміно",
                    "en": "Domino"
                }
            },
            {
                "id": "26_8",
                "slug": "diceGames",
                "label": {
                    "ru": "Игральные кости",
                    "uk": "Гральні кості",
                    "en": "Dice Games"
                }
            },
            {
                "id": "26_9",
                "slug": "miniatureGames",
                "label": {
                    "ru": "Игры миниатюр",
                    "uk": "Ігри мініатюр",
                    "en": "Miniature Games"
                }
            },
            {
                "id": "26_10",
                "slug": "pencilAndPaperGames",
                "label": {
                    "ru": "Игры с карандашом и бумагой",
                    "uk": "Ігри з олівцем і папером",
                    "en": "Pencil and Paper Games"
                }
            },
            {
                "id": "26_11",
                "slug": "imaginarium",
                "label": {
                    "ru": "Имаджинариум",
                    "uk": "Імаджинаріум",
                    "en": "Imaginarium"
                }
            },
            {
                "id": "26_12",
                "slug": "playingCards",
                "label": {
                    "ru": "Карты игральные",
                    "uk": "Гральні карти",
                    "en": "Playing Cards"
                }
            },
            {
                "id": "26_13",
                "slug": "ticTacToe",
                "label": {
                    "ru": "Крестики-нолики",
                    "uk": "Хрестики-нулики",
                    "en": "Tic Tac Toe"
                }
            },
            {
                "id": "26_14",
                "slug": "crocodile",
                "label": {
                    "ru": "Крокодил",
                    "uk": "Крокодил",
                    "en": "Crocodile"
                }
            },
            {
                "id": "26_15",
                "slug": "mahjong",
                "label": {
                    "ru": "Маджонг",
                    "uk": "Маджонг",
                    "en": "Mahjong"
                }
            },
            {
                "id": "26_16",
                "slug": "munchkin",
                "label": {
                    "ru": "Манчкин",
                    "uk": "Манчкін",
                    "en": "Munchkin"
                }
            },
            {
                "id": "26_17",
                "slug": "mafia",
                "label": {
                    "ru": "Мафия",
                    "uk": "Мафія",
                    "en": "Mafia"
                }
            },
            {
                "id": "26_18",
                "slug": "mill",
                "label": {
                    "ru": "Мельница",
                    "uk": "Млин",
                    "en": "Mill"
                }
            },
            {
                "id": "26_19",
                "slug": "monopoly",
                "label": {
                    "ru": "Монополия",
                    "uk": "Монополія",
                    "en": "Monopoly"
                }
            },
            {
                "id": "26_20",
                "slug": "battleship",
                "label": {
                    "ru": "Морской бой",
                    "uk": "Морський бій",
                    "en": "Battleship"
                }
            },
            {
                "id": "26_21",
                "slug": "thimbles",
                "label": {
                    "ru": "Наперстки",
                    "uk": "Наперстки",
                    "en": "Thimbles"
                }
            },
            {
                "id": "26_22",
                "slug": "backgammon",
                "label": {
                    "ru": "Нарды",
                    "uk": "Нарди",
                    "en": "Backgammon"
                }
            },
            {
                "id": "26_23",
                "slug": "answerIn5Seconds",
                "label": {
                    "ru": "Ответь за 5 секунд",
                    "uk": "Відповідай за 5 секунд",
                    "en": "Answer in 5 Seconds"
                }
            },
            {
                "id": "26_24",
                "slug": "poker",
                "label": {
                    "ru": "Покер",
                    "uk": "Покер",
                    "en": "Poker"
                }
            },
            {
                "id": "26_25",
                "slug": "preferans",
                "label": {
                    "ru": "Преферанс",
                    "uk": "Преферанс",
                    "en": "Preferans"
                }
            },
            {
                "id": "26_26",
                "slug": "russianRoulette",
                "label": {
                    "ru": "Русская рулетка",
                    "uk": "Російська рулетка",
                    "en": "Russian Roulette"
                }
            },
            {
                "id": "26_27",
                "slug": "uno",
                "label": {
                    "ru": "Уно",
                    "uk": "Уно",
                    "en": "Uno"
                }
            },
            {
                "id": "26_28",
                "slug": "hearthstone",
                "label": {
                    "ru": "Хартстоун",
                    "uk": "Хартстоун",
                    "en": "Hearthstone"
                }
            },
            {
                "id": "26_29",
                "slug": "chess",
                "label": {
                    "ru": "Шахматы",
                    "uk": "Шахи",
                    "en": "Chess"
                }
            },
            {
                "id": "26_30",
                "slug": "checkers",
                "label": {
                    "ru": "Шашки",
                    "uk": "Шашки",
                    "en": "Checkers"
                }
            }
        ]
    },
    {
        "id": "27",
        "slug": "autoAndMoto",
        "label": {
            "ru": "Авто и мото",
            "uk": "Авто та мото",
            "en": "Auto and Moto"
        },
        "children": [
            {
                "id": "27_0",
                "slug": "autoAndMoto",
                "label": {
                    "ru": "Авто и мото",
                    "uk": "Авто та мото",
                    "en": "Auto and Moto"
                }
            },
            {
                "id": "27_1",
                "slug": "demolitionDerby",
                "label": {
                    "ru": "Demolition Derby",
                    "uk": "Демоліційний дербі",
                    "en": "Demolition Derby"
                }
            },
            {
                "id": "27_2",
                "slug": "figure8Racing",
                "label": {
                    "ru": "Figure 8 Racing",
                    "uk": "Гонки у формі вісімки",
                    "en": "Figure 8 Racing"
                }
            },
            {
                "id": "27_3",
                "slug": "autoAndMotoRacingOnIce",
                "label": {
                    "ru": "Авто и мотогонки на льду",
                    "uk": "Авто та мотогонки на льоду",
                    "en": "Auto and Moto Racing on Ice"
                }
            },
            {
                "id": "27_4",
                "slug": "autoracing",
                "label": {
                    "ru": "Автогонки",
                    "uk": "Автогонки",
                    "en": "Auto Racing"
                }
            },
            {
                "id": "27_5",
                "slug": "autosound",
                "label": {
                    "ru": "Автозвук",
                    "uk": "Автозвук",
                    "en": "Auto Sound"
                }
            },
            {
                "id": "27_6",
                "slug": "autocross",
                "label": {
                    "ru": "Автокросс",
                    "uk": "Автокрос",
                    "en": "Autocross"
                }
            },
            {
                "id": "27_7",
                "slug": "bikeShow",
                "label": {
                    "ru": "Байк-шоу",
                    "uk": "Байк-шоу",
                    "en": "Bike Show"
                }
            },
            {
                "id": "27_8",
                "slug": "offRoadMotorcycles",
                "label": {
                    "ru": "Внедорожные мотоциклы",
                    "uk": "Внедорожні мотоцикли",
                    "en": "Off-Road Motorcycles"
                }
            },
            {
                "id": "27_9",
                "slug": "snowmobileRacingOnWater",
                "label": {
                    "ru": "Гонки на снегоходе по воде",
                    "uk": "Гонки на снігоходах по воді",
                    "en": "Snowmobile Racing on Water"
                }
            },
            {
                "id": "27_10",
                "slug": "detailing",
                "label": {
                    "ru": "Детейлинг",
                    "uk": "Детейлінг",
                    "en": "Detailing"
                }
            },
            {
                "id": "27_11",
                "slug": "drift",
                "label": {
                    "ru": "Дрифт",
                    "uk": "Дрифт",
                    "en": "Drift"
                }
            },
            {
                "id": "27_12",
                "slug": "dragRacing",
                "label": {
                    "ru": "Дрэг-рейсинг",
                    "uk": "Драг-рейсинг",
                    "en": "Drag Racing"
                }
            },
            {
                "id": "27_13",
                "slug": "carposting",
                "label": {
                    "ru": "Карпостинг",
                    "uk": "Карпостинг",
                    "en": "Carposting"
                }
            },
            {
                "id": "27_14",
                "slug": "karting",
                "label": {
                    "ru": "Картинг",
                    "uk": "Картинг",
                    "en": "Karting"
                }
            },
            {
                "id": "27_15",
                "slug": "quads",
                "label": {
                    "ru": "Квадроциклы",
                    "uk": "Квадроцикли",
                    "en": "Quads"
                }
            },
            {
                "id": "27_16",
                "slug": "motocross",
                "label": {
                    "ru": "Мотокросс",
                    "uk": "Мотокрос",
                    "en": "Motocross"
                }
            },
            {
                "id": "27_17",
                "slug": "motorsport",
                "label": {
                    "ru": "Мотоспорт",
                    "uk": "Мотоспорт",
                    "en": "Motorsport"
                }
            },
            {
                "id": "27_18",
                "slug": "rally",
                "label": {
                    "ru": "Ралли",
                    "uk": "Ралі",
                    "en": "Rally"
                }
            },
            {
                "id": "27_19",
                "slug": "retroAutomoto",
                "label": {
                    "ru": "Ретроавтомото",
                    "uk": "Ретроавтомото",
                    "en": "Retro Automoto"
                }
            },
            {
                "id": "27_20",
                "slug": "styling",
                "label": {
                    "ru": "Стайлинг",
                    "uk": "Стайлінг",
                    "en": "Styling"
                }
            },
            {
                "id": "27_21",
                "slug": "trials",
                "label": {
                    "ru": "Триалы",
                    "uk": "Тріали",
                    "en": "Trials"
                }
            },
            {
                "id": "27_22",
                "slug": "trophyTrucks",
                "label": {
                    "ru": "Трофи траки",
                    "uk": "Трофі-траки",
                    "en": "Trophy Trucks"
                }
            },
            {
                "id": "27_23",
                "slug": "tuning",
                "label": {
                    "ru": "Тюнинг",
                    "uk": "Тюнінг",
                    "en": "Tuning"
                }
            },
            {
                "id": "27_24",
                "slug": "streetRacing",
                "label": {
                    "ru": "Уличные гонки",
                    "uk": "Вуличні гонки",
                    "en": "Street Racing"
                }
            },
            {
                "id": "27_25",
                "slug": "freestyleMotocross",
                "label": {
                    "ru": "Фристайл мотокросс",
                    "uk": "Фристайл мотокрос",
                    "en": "Freestyle Motocross"
                }
            }
        ]
    },
    {
        "id": "28",
        "slug": "languageLearning",
        "label": {
            "ru": "Изучение языков",
            "uk": "Вивчення мов",
            "en": "Language Learning"
        },
        "children": [
            {
                "id": "28_0",
                "slug": "languageLearning",
                "label": {
                    "ru": "Изучение языков",
                    "uk": "Вивчення мов",
                    "en": "Language Learning"
                }
            },
            {
                "id": "28_1",
                "slug": "azerbaijani",
                "label": {
                    "ru": "Азербайджанский",
                    "uk": "Азербайджанська",
                    "en": "Azerbaijani"
                }
            },
            {
                "id": "28_2",
                "slug": "albanian",
                "label": {
                    "ru": "Албанский",
                    "uk": "Албанська",
                    "en": "Albanian"
                }
            },
            {
                "id": "28_3",
                "slug": "english",
                "label": {
                    "ru": "Английский",
                    "uk": "Англійська",
                    "en": "English"
                }
            },
            {
                "id": "28_4",
                "slug": "arabic",
                "label": {
                    "ru": "Арабский",
                    "uk": "Арабська",
                    "en": "Arabic"
                }
            },
            {
                "id": "28_5",
                "slug": "armenian",
                "label": {
                    "ru": "Армянский",
                    "uk": "Вірменська",
                    "en": "Armenian"
                }
            },
            {
                "id": "28_6",
                "slug": "belarusian",
                "label": {
                    "ru": "Белорусский",
                    "uk": "Білоруська",
                    "en": "Belarusian"
                }
            },
            {
                "id": "28_7",
                "slug": "bulgarian",
                "label": {
                    "ru": "Болгарский",
                    "uk": "Болгарська",
                    "en": "Bulgarian"
                }
            },
            {
                "id": "28_8",
                "slug": "hungarian",
                "label": {
                    "ru": "Венгерский",
                    "uk": "Угорська",
                    "en": "Hungarian"
                }
            },
            {
                "id": "28_9",
                "slug": "vietnamese",
                "label": {
                    "ru": "Вьетнамский",
                    "uk": "В'єтнамська",
                    "en": "Vietnamese"
                }
            },
            {
                "id": "28_10",
                "slug": "dutch",
                "label": {
                    "ru": "Голландский",
                    "uk": "Голландська",
                    "en": "Dutch"
                }
            },
            {
                "id": "28_11",
                "slug": "greek",
                "label": {
                    "ru": "Греческий",
                    "uk": "Грецька",
                    "en": "Greek"
                }
            },
            {
                "id": "28_12",
                "slug": "georgian",
                "label": {
                    "ru": "Грузинский",
                    "uk": "Грузинська",
                    "en": "Georgian"
                }
            },
            {
                "id": "28_13",
                "slug": "dari",
                "label": {
                    "ru": "Дари",
                    "uk": "Дари",
                    "en": "Dari"
                }
            },
            {
                "id": "28_14",
                "slug": "danish",
                "label": {
                    "ru": "Датский",
                    "uk": "Данська",
                    "en": "Danish"
                }
            },
            {
                "id": "28_15",
                "slug": "ancientGreek",
                "label": {
                    "ru": "Древнегреческий",
                    "uk": "Давньогрецька",
                    "en": "Ancient Greek"
                }
            },
            {
                "id": "28_16",
                "slug": "ancientLanguages",
                "label": {
                    "ru": "Древние языки",
                    "uk": "Стародавні мови",
                    "en": "Ancient Languages"
                }
            },
            {
                "id": "28_17",
                "slug": "hebrew",
                "label": {
                    "ru": "Иврит",
                    "uk": "Іврит",
                    "en": "Hebrew"
                }
            },
            {
                "id": "28_18",
                "slug": "spanish",
                "label": {
                    "ru": "Испанский",
                    "uk": "Іспанська",
                    "en": "Spanish"
                }
            },
            {
                "id": "28_19",
                "slug": "italian",
                "label": {
                    "ru": "Итальянский",
                    "uk": "Італійська",
                    "en": "Italian"
                }
            },
            {
                "id": "28_20",
                "slug": "kazakh",
                "label": {
                    "ru": "Казахский",
                    "uk": "Казахська",
                    "en": "Kazakh"
                }
            },
            {
                "id": "28_21",
                "slug": "chinese",
                "label": {
                    "ru": "Китайский",
                    "uk": "Китайська",
                    "en": "Chinese"
                }
            },
            {
                "id": "28_22",
                "slug": "korean",
                "label": {
                    "ru": "Корейский",
                    "uk": "Корейська",
                    "en": "Korean"
                }
            },
            {
                "id": "28_23",
                "slug": "kyrgyz",
                "label": {
                    "ru": "Кыргызский",
                    "uk": "Киргизька",
                    "en": "Kyrgyz"
                }
            },
            {
                "id": "28_24",
                "slug": "latin",
                "label": {
                    "ru": "Латинский",
                    "uk": "Латинська",
                    "en": "Latin"
                }
            },
            {
                "id": "28_25",
                "slug": "latvian",
                "label": {
                    "ru": "Латышский",
                    "uk": "Латвійська",
                    "en": "Latvian"
                }
            },
            {
                "id": "28_26",
                "slug": "lithuanian",
                "label": {
                    "ru": "Литовский",
                    "uk": "Литовська",
                    "en": "Lithuanian"
                }
            },
            {
                "id": "28_27",
                "slug": "malay",
                "label": {
                    "ru": "Малазийский",
                    "uk": "Малайська",
                    "en": "Malay"
                }
            },
            {
                "id": "28_28",
                "slug": "moldovan",
                "label": {
                    "ru": "Молдавский",
                    "uk": "Молдавська",
                    "en": "Moldovan"
                }
            },
            {
                "id": "28_29",
                "slug": "mongolian",
                "label": {
                    "ru": "Монгольский",
                    "uk": "Монгольська",
                    "en": "Mongolian"
                }
            },
            {
                "id": "28_30",
                "slug": "german",
                "label": {
                    "ru": "Немецкий",
                    "uk": "Німецька",
                    "en": "German"
                }
            },
            {
                "id": "28_31",
                "slug": "nepali",
                "label": {
                    "ru": "Непальский",
                    "uk": "Непальська",
                    "en": "Nepali"
                }
            },
            {
                "id": "28_32",
                "slug": "dutch",
                "label": {
                    "ru": "Нидерландский",
                    "uk": "Нідерландська",
                    "en": "Dutch"
                }
            },
            {
                "id": "28_33",
                "slug": "norwegian",
                "label": {
                    "ru": "Норвежский",
                    "uk": "Норвезька",
                    "en": "Norwegian"
                }
            },
            {
                "id": "28_34",
                "slug": "persian",
                "label": {
                    "ru": "Персидский",
                    "uk": "Перська",
                    "en": "Persian"
                }
            },
            {
                "id": "28_35",
                "slug": "polish",
                "label": {
                    "ru": "Польский",
                    "uk": "Польська",
                    "en": "Polish"
                }
            },
            {
                "id": "28_36",
                "slug": "portuguese",
                "label": {
                    "ru": "Португальский",
                    "uk": "Португальська",
                    "en": "Portuguese"
                }
            },
            {
                "id": "28_37",
                "slug": "romanian",
                "label": {
                    "ru": "Румынский",
                    "uk": "Румунська",
                    "en": "Romanian"
                }
            },
            {
                "id": "28_38",
                "slug": "russian",
                "label": {
                    "ru": "Русский",
                    "uk": "Російська",
                    "en": "Russian"
                }
            },
            {
                "id": "28_39",
                "slug": "serbian",
                "label": {
                    "ru": "Сербский",
                    "uk": "Сербська",
                    "en": "Serbian"
                }
            },
            {
                "id": "28_40",
                "slug": "slovak",
                "label": {
                    "ru": "Словацкий",
                    "uk": "Словацька",
                    "en": "Slovak"
                }
            },
            {
                "id": "28_41",
                "slug": "slovenian",
                "label": {
                    "ru": "Словенский",
                    "uk": "Словенська",
                    "en": "Slovenian"
                }
            },
            {
                "id": "28_42",
                "slug": "tajik",
                "label": {
                    "ru": "Таджикский",
                    "uk": "Таджикська",
                    "en": "Tajik"
                }
            },
            {
                "id": "28_43",
                "slug": "thai",
                "label": {
                    "ru": "Тайский",
                    "uk": "Тайська",
                    "en": "Thai"
                }
            },
            {
                "id": "28_44",
                "slug": "tatar",
                "label": {
                    "ru": "Татарский",
                    "uk": "Татарська",
                    "en": "Tatar"
                }
            },
            {
                "id": "28_45",
                "slug": "turkish",
                "label": {
                    "ru": "Турецкий",
                    "uk": "Турецька",
                    "en": "Turkish"
                }
            },
            {
                "id": "28_46",
                "slug": "turkmen",
                "label": {
                    "ru": "Туркменский",
                    "uk": "Туркменська",
                    "en": "Turkmen"
                }
            },
            {
                "id": "28_47",
                "slug": "uzbek",
                "label": {
                    "ru": "Узбекский",
                    "uk": "Узбекська",
                    "en": "Uzbek"
                }
            },
            {
                "id": "28_48",
                "slug": "ukrainian",
                "label": {
                    "ru": "Украинский",
                    "uk": "Українська",
                    "en": "Ukrainian"
                }
            },
            {
                "id": "28_49",
                "slug": "farsi",
                "label": {
                    "ru": "Фарси",
                    "uk": "Фарсі",
                    "en": "Farsi"
                }
            },
            {
                "id": "28_50",
                "slug": "finnish",
                "label": {
                    "ru": "Финский",
                    "uk": "Фінська",
                    "en": "Finnish"
                }
            },
            {
                "id": "28_51",
                "slug": "flemish",
                "label": {
                    "ru": "Фламандский",
                    "uk": "Фламандська",
                    "en": "Flemish"
                }
            },
            {
                "id": "28_52",
                "slug": "french",
                "label": {
                    "ru": "Французский",
                    "uk": "Французька",
                    "en": "French"
                }
            },
            {
                "id": "28_53",
                "slug": "hindi",
                "label": {
                    "ru": "Хинди",
                    "uk": "Хінді",
                    "en": "Hindi"
                }
            },
            {
                "id": "28_54",
                "slug": "khorvat",
                "label": {
                    "ru": "Хороватский",
                    "uk": "Хорватська",
                    "en": "Khorvat"
                }
            },
            {
                "id": "28_55",
                "slug": "czech",
                "label": {
                    "ru": "Чешский",
                    "uk": "Чеська",
                    "en": "Czech"
                }
            },
            {
                "id": "28_56",
                "slug": "swedish",
                "label": {
                    "ru": "Шведский",
                    "uk": "Шведська",
                    "en": "Swedish"
                }
            },
            {
                "id": "28_57",
                "slug": "estonian",
                "label": {
                    "ru": "Эстонский",
                    "uk": "Естонська",
                    "en": "Estonian"
                }
            },
            {
                "id": "28_58",
                "slug": "japanese",
                "label": {
                    "ru": "Японский",
                    "uk": "Японська",
                    "en": "Japanese"
                }
            }
        ]
    },
    {
        "id": "29",
        "slug": "scienceLearning",
        "label": {
            "ru": "Изучение наук",
            "uk": "Вивчення наук",
            "en": "Science Learning"
        },
        "children": [
            {
                "id": "29_0",
                "slug": "scienceLearning",
                "label": {
                    "ru": "Изучение наук",
                    "uk": "Вивчення наук",
                    "en": "Science Learning"
                }
            },
            {
                "id": "29_1",
                "slug": "agronomy",
                "label": {
                    "ru": "Агрономия",
                    "uk": "Агрономія",
                    "en": "Agronomy"
                }
            },
            {
                "id": "29_2",
                "slug": "archaeology",
                "label": {
                    "ru": "Археология",
                    "uk": "Археологія",
                    "en": "Archaeology"
                }
            },
            {
                "id": "29_3",
                "slug": "architecture",
                "label": {
                    "ru": "Архитектура",
                    "uk": "Архітектура",
                    "en": "Architecture"
                }
            },
            {
                "id": "29_4",
                "slug": "astrology",
                "label": {
                    "ru": "Астрология",
                    "uk": "Астрологія",
                    "en": "Astrology"
                }
            },
            {
                "id": "29_5",
                "slug": "astronomy",
                "label": {
                    "ru": "Астрономия",
                    "uk": "Астрономія",
                    "en": "Astronomy"
                }
            },
            {
                "id": "29_6",
                "slug": "astrophysics",
                "label": {
                    "ru": "Астрофизика",
                    "uk": "Астрофізика",
                    "en": "Astrophysics"
                }
            },
            {
                "id": "29_7",
                "slug": "aeronautics",
                "label": {
                    "ru": "Аэронавтика",
                    "uk": "Аеронавтика",
                    "en": "Aeronautics"
                }
            },
            {
                "id": "29_8",
                "slug": "ballistics",
                "label": {
                    "ru": "Баллистика",
                    "uk": "Балістика",
                    "en": "Ballistics"
                }
            },
            {
                "id": "29_9",
                "slug": "biology",
                "label": {
                    "ru": "Биология",
                    "uk": "Біологія",
                    "en": "Biology"
                }
            },
            {
                "id": "29_10",
                "slug": "botany",
                "label": {
                    "ru": "Ботаника",
                    "uk": "Ботаніка",
                    "en": "Botany"
                }
            },
            {
                "id": "29_11",
                "slug": "geography",
                "label": {
                    "ru": "География",
                    "uk": "Географія",
                    "en": "Geography"
                }
            },
            {
                "id": "29_12",
                "slug": "geology",
                "label": {
                    "ru": "Геология",
                    "uk": "Геологія",
                    "en": "Geology"
                }
            },
            {
                "id": "29_13",
                "slug": "geophysics",
                "label": {
                    "ru": "Геофизика",
                    "uk": "Геофізика",
                    "en": "Geophysics"
                }
            },
            {
                "id": "29_14",
                "slug": "hydrology",
                "label": {
                    "ru": "Гидрология",
                    "uk": "Гідрологія",
                    "en": "Hydrology"
                }
            },
            {
                "id": "29_15",
                "slug": "zoology",
                "label": {
                    "ru": "Зоология",
                    "uk": "Зоологія",
                    "en": "Zoology"
                }
            },
            {
                "id": "29_16",
                "slug": "informatics",
                "label": {
                    "ru": "Информатика",
                    "uk": "Інформатика",
                    "en": "Informatics"
                }
            },
            {
                "id": "29_17",
                "slug": "artStudies",
                "label": {
                    "ru": "Исскуствоведение",
                    "uk": "Мистецтвознавство",
                    "en": "Art Studies"
                }
            },
            {
                "id": "29_18",
                "slug": "history",
                "label": {
                    "ru": "История",
                    "uk": "Історія",
                    "en": "History"
                }
            },
            {
                "id": "29_19",
                "slug": "bibliology",
                "label": {
                    "ru": "Книговедение",
                    "uk": "Книгознавство",
                    "en": "Bibliology"
                }
            },
            {
                "id": "29_20",
                "slug": "localHistory",
                "label": {
                    "ru": "Краеведение",
                    "uk": "Краєзнавство",
                    "en": "Local History"
                }
            },
            {
                "id": "29_21",
                "slug": "cryptography",
                "label": {
                    "ru": "Криптография",
                    "uk": "Криптографія",
                    "en": "Cryptography"
                }
            },
            {
                "id": "29_22",
                "slug": "culturalAnthropology",
                "label": {
                    "ru": "Культурная антропология",
                    "uk": "Культурна антропологія",
                    "en": "Cultural Anthropology"
                }
            },
            {
                "id": "29_23",
                "slug": "culturalStudies",
                "label": {
                    "ru": "Культурология",
                    "uk": "Культурологія",
                    "en": "Cultural Studies"
                }
            },
            {
                "id": "29_24",
                "slug": "linguistics",
                "label": {
                    "ru": "Лингвистика",
                    "uk": "Лінгвістика",
                    "en": "Linguistics"
                }
            },
            {
                "id": "29_25",
                "slug": "literaryStudies",
                "label": {
                    "ru": "Литературоведение",
                    "uk": "Літературознавство",
                    "en": "Literary Studies"
                }
            },
            {
                "id": "29_26",
                "slug": "mathematics",
                "label": {
                    "ru": "Математика",
                    "uk": "Математика",
                    "en": "Mathematics"
                }
            },
            {
                "id": "29_27",
                "slug": "medicine",
                "label": {
                    "ru": "Медицина",
                    "uk": "Медицина",
                    "en": "Medicine"
                }
            },
            {
                "id": "29_28",
                "slug": "paleontology",
                "label": {
                    "ru": "Палонтология",
                    "uk": "Палеонтологія",
                    "en": "Paleontology"
                }
            },
            {
                "id": "29_29",
                "slug": "pedagogy",
                "label": {
                    "ru": "Педагогика",
                    "uk": "Педагогіка",
                    "en": "Pedagogy"
                }
            },
            {
                "id": "29_30",
                "slug": "soilScience",
                "label": {
                    "ru": "Почвоведение",
                    "uk": "Ґрунтознавство",
                    "en": "Soil Science"
                }
            },
            {
                "id": "29_31",
                "slug": "psychology",
                "label": {
                    "ru": "Психология",
                    "uk": "Психологія",
                    "en": "Psychology"
                }
            },
            {
                "id": "29_32",
                "slug": "religiousStudies",
                "label": {
                    "ru": "Религиоведение",
                    "uk": "Релігієзнавство",
                    "en": "Religious Studies"
                }
            },
            {
                "id": "29_33",
                "slug": "robotics",
                "label": {
                    "ru": "Робототехника",
                    "uk": "Робототехніка",
                    "en": "Robotics"
                }
            },
            {
                "id": "29_34",
                "slug": "sociology",
                "label": {
                    "ru": "Социология",
                    "uk": "Соціологія",
                    "en": "Sociology"
                }
            },
            {
                "id": "29_35",
                "slug": "physiognomy",
                "label": {
                    "ru": "Физиагномика",
                    "uk": "Фізіогноміка",
                    "en": "Physiognomy"
                }
            },
            {
                "id": "29_36",
                "slug": "philosophy",
                "label": {
                    "ru": "Философия",
                    "uk": "Філософія",
                    "en": "Philosophy"
                }
            },
            {
                "id": "29_37",
                "slug": "chemistry",
                "label": {
                    "ru": "Химия",
                    "uk": "Хімія",
                    "en": "Chemistry"
                }
            }
        ]
    },
    {
        "id": "30",
        "slug": "esoterics",
        "label": {
            "ru": "Эзотерика",
            "uk": "Езотерика",
            "en": "Esoterics"
        },
        "children": [
            {
                "id": "30_0",
                "slug": "esoterics",
                "label": {
                    "ru": "Эзотерика",
                    "uk": "Езотерика",
                    "en": "Esoterics"
                }
            },
            {
                "id": "30_1",
                "slug": "alchemy",
                "label": {
                    "ru": "Алхимия",
                    "uk": "Алхімія",
                    "en": "Alchemy"
                }
            },
            {
                "id": "30_2",
                "slug": "astrology",
                "label": {
                    "ru": "Астрология",
                    "uk": "Астрологія",
                    "en": "Astrology"
                }
            },
            {
                "id": "30_3",
                "slug": "bioenergy",
                "label": {
                    "ru": "Биоэнергетика",
                    "uk": "Біоенергетика",
                    "en": "Bioenergy"
                }
            },
            {
                "id": "30_4",
                "slug": "buddhism",
                "label": {
                    "ru": "Буддизм",
                    "uk": "Буддизм",
                    "en": "Buddhism"
                }
            },
            {
                "id": "30_5",
                "slug": "divination",
                "label": {
                    "ru": "Гадание",
                    "uk": "Гадання",
                    "en": "Divination"
                }
            },
            {
                "id": "30_6",
                "slug": "gnosticism",
                "label": {
                    "ru": "Гностицизм",
                    "uk": "Гностицизм",
                    "en": "Gnosticism"
                }
            },
            {
                "id": "30_7",
                "slug": "taoism",
                "label": {
                    "ru": "Даосизм",
                    "uk": "Даосизм",
                    "en": "Taoism"
                }
            },
            {
                "id": "30_8",
                "slug": "humanDesign",
                "label": {
                    "ru": "Дизайн человека",
                    "uk": "Дизайн людини",
                    "en": "Human Design"
                }
            },
            {
                "id": "30_9",
                "slug": "glukofonGame",
                "label": {
                    "ru": "Игра на глюкофоне",
                    "uk": "Гра на глюкофоні",
                    "en": "Glukophone Game"
                }
            },
            {
                "id": "30_10",
                "slug": "kabbalah",
                "label": {
                    "ru": "Каббала",
                    "uk": "Кабала",
                    "en": "Kabbalah"
                }
            },
            {
                "id": "30_11",
                "slug": "cosmoenergy",
                "label": {
                    "ru": "Космоэнергетика",
                    "uk": "Космоенергетика",
                    "en": "Cosmoenergy"
                }
            },
            {
                "id": "30_12",
                "slug": "magicAndRituals",
                "label": {
                    "ru": "Магия и ритуалы",
                    "uk": "Магія та ритуали",
                    "en": "Magic and Rituals"
                }
            },
            {
                "id": "30_13",
                "slug": "masonry",
                "label": {
                    "ru": "Масонство",
                    "uk": "Масонство",
                    "en": "Masonry"
                }
            },
            {
                "id": "30_14",
                "slug": "matrixOfDestiny",
                "label": {
                    "ru": "Матрица Судьбы",
                    "uk": "Матриця Долі",
                    "en": "Matrix of Destiny"
                }
            },
            {
                "id": "30_15",
                "slug": "meditations",
                "label": {
                    "ru": "Медитации",
                    "uk": "Медитації",
                    "en": "Meditations"
                }
            },
            {
                "id": "30_16",
                "slug": "numerology",
                "label": {
                    "ru": "Нумерология",
                    "uk": "Нумерологія",
                    "en": "Numerology"
                }
            },
            {
                "id": "30_17",
                "slug": "occultism",
                "label": {
                    "ru": "Оккультизм",
                    "uk": "Окультизм",
                    "en": "Occultism"
                }
            },
            {
                "id": "30_18",
                "slug": "consciousDreaming",
                "label": {
                    "ru": "Осознанные сновидения",
                    "uk": "Усвідомлені сновидіння",
                    "en": "Conscious Dreaming"
                }
            },
            {
                "id": "30_19",
                "slug": "parapsychology",
                "label": {
                    "ru": "Парапсихология",
                    "uk": "Парапсихологія",
                    "en": "Parapsychology"
                }
            },
            {
                "id": "30_20",
                "slug": "singingBowls",
                "label": {
                    "ru": "Поющие чаши",
                    "uk": "Співучі чаші",
                    "en": "Singing Bowls"
                }
            },
            {
                "id": "30_21",
                "slug": "psychosomatics",
                "label": {
                    "ru": "Психосоматика",
                    "uk": "Психосоматика",
                    "en": "Psychosomatics"
                }
            },
            {
                "id": "30_22",
                "slug": "regression",
                "label": {
                    "ru": "Регресс",
                    "uk": "Регрес",
                    "en": "Regression"
                }
            },
            {
                "id": "30_23",
                "slug": "sacredInstruments",
                "label": {
                    "ru": "Сакральные инструменты",
                    "uk": "Сакральні інструменти",
                    "en": "Sacred Instruments"
                }
            },
            {
                "id": "30_24",
                "slug": "spiritism",
                "label": {
                    "ru": "Спиритизм",
                    "uk": "Спіритизм",
                    "en": "Spiritism"
                }
            },
            {
                "id": "30_25",
                "slug": "tarot",
                "label": {
                    "ru": "Таро",
                    "uk": "Таро",
                    "en": "Tarot"
                }
            },
            {
                "id": "30_26",
                "slug": "thetaHealing",
                "label": {
                    "ru": "Тетахилинг",
                    "uk": "Тетахілінг",
                    "en": "Theta Healing"
                }
            },
            {
                "id": "30_27",
                "slug": "transurfing",
                "label": {
                    "ru": "Трансерфинг",
                    "uk": "Трансерфінг",
                    "en": "Transurfing"
                }
            },
            {
                "id": "30_28",
                "slug": "transformationalGames",
                "label": {
                    "ru": "Трансформационные игры",
                    "uk": "Трансформаційні ігри",
                    "en": "Transformational Games"
                }
            },
            {
                "id": "30_29",
                "slug": "fengShui",
                "label": {
                    "ru": "Фэн-шуй",
                    "uk": "Фен-шуй",
                    "en": "Feng Shui"
                }
            },
            {
                "id": "30_30",
                "slug": "palmistry",
                "label": {
                    "ru": "Хиромантия",
                    "uk": "Хіромантія",
                    "en": "Palmistry"
                }
            },
            {
                "id": "30_31",
                "slug": "healing",
                "label": {
                    "ru": "Целительство",
                    "uk": "Цілительство",
                    "en": "Healing"
                }
            },
            {
                "id": "30_32",
                "slug": "channeling",
                "label": {
                    "ru": "Ченелинг",
                    "uk": "Ченелінг",
                    "en": "Channeling"
                }
            },
            {
                "id": "30_33",
                "slug": "energyTherapy",
                "label": {
                    "ru": "Энерготерапия",
                    "uk": "Енерготерапія",
                    "en": "Energy Therapy"
                }
            },
            {
                "id": "30_34",
                "slug": "clairvoyance",
                "label": {
                    "ru": "Ясновидение",
                    "uk": "Ясновидіння",
                    "en": "Clairvoyance"
                }
            }
        ]
    },
    {
        "id": "31",
        "slug": "travelAndTourism",
        "label": {
            "ru": "Путешествия и туризм",
            "uk": "Подорожі та туризм",
            "en": "Travel and Tourism"
        },
        "children": [
            {
                "id": "31_0",
                "slug": "travelAndTourism",
                "label": {
                    "ru": "Путешествия и туризм",
                    "uk": "Подорожі та туризм",
                    "en": "Travel and Tourism"
                }
            },
            {
                "id": "31_1",
                "slug": "backpackerTourism",
                "label": {
                    "ru": "Бэкпэкер-туризм",
                    "uk": "Бекпекерський туризм",
                    "en": "Backpacker Tourism"
                }
            },
            {
                "id": "31_2",
                "slug": "bicycleTours",
                "label": {
                    "ru": "Велосипедные туры",
                    "uk": "Велосипедні тури",
                    "en": "Bicycle Tours"
                }
            },
            {
                "id": "31_3",
                "slug": "climbing",
                "label": {
                    "ru": "Восхождения",
                    "uk": "Сходження",
                    "en": "Climbing"
                }
            },
            {
                "id": "31_4",
                "slug": "gastronomicTourism",
                "label": {
                    "ru": "Гастрономический туризм",
                    "uk": "Гастрономічний туризм",
                    "en": "Gastronomic Tourism"
                }
            },
            {
                "id": "31_5",
                "slug": "glamping",
                "label": {
                    "ru": "Глемпинг",
                    "uk": "Глемпінг",
                    "en": "Glamping"
                }
            },
            {
                "id": "31_6",
                "slug": "skiingDownhill",
                "label": {
                    "ru": "Горнолыжные спуски",
                    "uk": "Гірськолижні спуски",
                    "en": "Downhill Skiing"
                }
            },
            {
                "id": "31_7",
                "slug": "businessTourism",
                "label": {
                    "ru": "Деловой туризм",
                    "uk": "Діловий туризм",
                    "en": "Business Tourism"
                }
            },
            {
                "id": "31_8",
                "slug": "spiritualTourism",
                "label": {
                    "ru": "Духовный туризм",
                    "uk": "Духовний туризм",
                    "en": "Spiritual Tourism"
                }
            },
            {
                "id": "31_9",
                "slug": "winterTourism",
                "label": {
                    "ru": "Зимние виды туризма",
                    "uk": "Зимові види туризму",
                    "en": "Winter Tourism"
                }
            },
            {
                "id": "31_10",
                "slug": "industrialTourism",
                "label": {
                    "ru": "Индустриальный туризм",
                    "uk": "Індустріальний туризм",
                    "en": "Industrial Tourism"
                }
            },
            {
                "id": "31_11",
                "slug": "caravanning",
                "label": {
                    "ru": "Караванинг",
                    "uk": "Караванінг",
                    "en": "Caravanning"
                }
            },
            {
                "id": "31_12",
                "slug": "couchsurfing",
                "label": {
                    "ru": "Каучсерфинг",
                    "uk": "Коучсерфінг",
                    "en": "Couchsurfing"
                }
            },
            {
                "id": "31_13",
                "slug": "camping",
                "label": {
                    "ru": "Кемпинг",
                    "uk": "Кемпінг",
                    "en": "Camping"
                }
            },
            {
                "id": "31_14",
                "slug": "filmTourism",
                "label": {
                    "ru": "Кинотуризм",
                    "uk": "Кінотуризм",
                    "en": "Film Tourism"
                }
            },
            {
                "id": "31_15",
                "slug": "horseAndHiking",
                "label": {
                    "ru": "Конные и пешие прогулки",
                    "uk": "Конні та піші прогулянки",
                    "en": "Horse and Hiking"
                }
            },
            {
                "id": "31_16",
                "slug": "seaCruises",
                "label": {
                    "ru": "Морские круизы",
                    "uk": "Морські круїзи",
                    "en": "Sea Cruises"
                }
            },
            {
                "id": "31_17",
                "slug": "seaTourism",
                "label": {
                    "ru": "Морской туризм",
                    "uk": "Морський туризм",
                    "en": "Sea Tourism"
                }
            },
            {
                "id": "31_18",
                "slug": "healthTourism",
                "label": {
                    "ru": "Оздоровительный туризм",
                    "uk": "Оздоровчий туризм",
                    "en": "Health Tourism"
                }
            },
            {
                "id": "31_19",
                "slug": "pilgrimageTourism",
                "label": {
                    "ru": "Паломнический туризм",
                    "uk": "Паломницький туризм",
                    "en": "Pilgrimage Tourism"
                }
            },
            {
                "id": "31_20",
                "slug": "educationalTourism",
                "label": {
                    "ru": "Познавательный туризм",
                    "uk": "Пізнавальний туризм",
                    "en": "Educational Tourism"
                }
            },
            {
                "id": "31_21",
                "slug": "mountainHiking",
                "label": {
                    "ru": "Походы в горы",
                    "uk": "Походи в гори",
                    "en": "Mountain Hiking"
                }
            },
            {
                "id": "31_22",
                "slug": "adventureTourism",
                "label": {
                    "ru": "Приключенческий туризм",
                    "uk": "Пригодницький туризм",
                    "en": "Adventure Tourism"
                }
            },
            {
                "id": "31_23",
                "slug": "hitchhiking",
                "label": {
                    "ru": "Путешествие автостопом",
                    "uk": "Подорож автостопом",
                    "en": "Hitchhiking"
                }
            },
            {
                "id": "31_24",
                "slug": "recreationalTourism",
                "label": {
                    "ru": "Рекреационный туризм",
                    "uk": "Рекреаційний туризм",
                    "en": "Recreational Tourism"
                }
            },
            {
                "id": "31_25",
                "slug": "eventTourism",
                "label": {
                    "ru": "Событийный туризм",
                    "uk": "Подієвий туризм",
                    "en": "Event Tourism"
                }
            },
            {
                "id": "31_26",
                "slug": "rafting",
                "label": {
                    "ru": "Сплавы",
                    "uk": "Сплави",
                    "en": "Rafting"
                }
            },
            {
                "id": "31_27",
                "slug": "sportsTourism",
                "label": {
                    "ru": "Спортивный туризм",
                    "uk": "Спортивний туризм",
                    "en": "Sports Tourism"
                }
            },
            {
                "id": "31_28",
                "slug": "festivalTourism",
                "label": {
                    "ru": "Фестивальный туризм",
                    "uk": "Фестивальний туризм",
                    "en": "Festival Tourism"
                }
            },
            {
                "id": "31_29",
                "slug": "photoTourism",
                "label": {
                    "ru": "Фототуризм",
                    "uk": "Фототуризм",
                    "en": "Photo Tourism"
                }
            },
            {
                "id": "31_30",
                "slug": "shoppingTourism",
                "label": {
                    "ru": "Шопинг-туризм",
                    "uk": "Шопінг-туризм",
                    "en": "Shopping Tourism"
                }
            },
            {
                "id": "31_31",
                "slug": "exoticTourism",
                "label": {
                    "ru": "Экзотический туризм",
                    "uk": "Екзотичний туризм",
                    "en": "Exotic Tourism"
                }
            },
            {
                "id": "31_32",
                "slug": "ecoTourism",
                "label": {
                    "ru": "Эко-туризм",
                    "uk": "Еко-туризм",
                    "en": "Eco Tourism"
                }
            },
            {
                "id": "31_33",
                "slug": "extremeTourism",
                "label": {
                    "ru": "Экстремальный туризм",
                    "uk": "Екстремальний туризм",
                    "en": "Extreme Tourism"
                }
            },
            {
                "id": "31_34",
                "slug": "ethnicTourism",
                "label": {
                    "ru": "Этнический туризм",
                    "uk": "Етнічний туризм",
                    "en": "Ethnic Tourism"
                }
            }
        ]
    },
    {
        "id": "32",
        "slug": "animalWorld",
        "label": {
            "ru": "Животный мир",
            "uk": "Тваринний світ",
            "en": "Animal World"
        },
        "children": [
            {
                "id": "32_0",
                "slug": "animalWorld",
                "label": {
                    "ru": "Животный мир",
                    "uk": "Тваринний світ",
                    "en": "Animal World"
                }
            },
            {
                "id": "32_1",
                "slug": "aquariumKeeping",
                "label": {
                    "ru": "Аквариумистика",
                    "uk": "Акваріумістика",
                    "en": "Aquarium Keeping"
                }
            },
            {
                "id": "32_2",
                "slug": "butterflyWatching",
                "label": {
                    "ru": "Бабочкарий",
                    "uk": "Спостереження за метеликами",
                    "en": "Butterfly Watching"
                }
            },
            {
                "id": "32_3",
                "slug": "birdwatching",
                "label": {
                    "ru": "Бердвочинг",
                    "uk": "Спостереження за птахами",
                    "en": "Birdwatching"
                }
            },
            {
                "id": "32_4",
                "slug": "volunteering",
                "label": {
                    "ru": "Волонтерство",
                    "uk": "Волонтерство",
                    "en": "Volunteering"
                }
            },
            {
                "id": "32_5",
                "slug": "animalExhibition",
                "label": {
                    "ru": "Выставка животных",
                    "uk": "Виставка тварин",
                    "en": "Animal Exhibition"
                }
            },
            {
                "id": "32_6",
                "slug": "grooming",
                "label": {
                    "ru": "Грумминг",
                    "uk": "Грумінг",
                    "en": "Grooming"
                }
            },
            {
                "id": "32_7",
                "slug": "animalTraining",
                "label": {
                    "ru": "Дрессировка животных",
                    "uk": "Дресура тварин",
                    "en": "Animal Training"
                }
            },
            {
                "id": "32_8",
                "slug": "animalProtection",
                "label": {
                    "ru": "Защита животных",
                    "uk": "Захист тварин",
                    "en": "Animal Protection"
                }
            },
            {
                "id": "32_9",
                "slug": "zoology",
                "label": {
                    "ru": "Зоология",
                    "uk": "Зоологія",
                    "en": "Zoology"
                }
            },
            {
                "id": "32_10",
                "slug": "zoopsychology",
                "label": {
                    "ru": "Зоопсихология",
                    "uk": "Зоопсихологія",
                    "en": "Zoopsychology"
                }
            },
            {
                "id": "32_11",
                "slug": "zooShow",
                "label": {
                    "ru": "Зоошоу",
                    "uk": "Зоошоу",
                    "en": "Zoo Show"
                }
            },
            {
                "id": "32_12",
                "slug": "canicross",
                "label": {
                    "ru": "Каникросс",
                    "uk": "Канікрос",
                    "en": "Canicross"
                }
            },
            {
                "id": "32_13",
                "slug": "canineTraining",
                "label": {
                    "ru": "Кинология",
                    "uk": "Кінологія",
                    "en": "Canine Training"
                }
            },
            {
                "id": "32_14",
                "slug": "horseBreeding",
                "label": {
                    "ru": "Коневодство",
                    "uk": "Конярство",
                    "en": "Horse Breeding"
                }
            },
            {
                "id": "32_15",
                "slug": "equestrianSport",
                "label": {
                    "ru": "Конный спорт",
                    "uk": "Конний спорт",
                    "en": "Equestrian Sport"
                }
            },
            {
                "id": "32_16",
                "slug": "contactZoo",
                "label": {
                    "ru": "Контактный зоопарк",
                    "uk": "Контактний зоопарк",
                    "en": "Contact Zoo"
                }
            },
            {
                "id": "32_17",
                "slug": "rabbitBreeding",
                "label": {
                    "ru": "Кролиководство",
                    "uk": "Кролиководство",
                    "en": "Rabbit Breeding"
                }
            },
            {
                "id": "32_18",
                "slug": "antFarms",
                "label": {
                    "ru": "Муравьиные фермы",
                    "uk": "Мурав'їні ферми",
                    "en": "Ant Farms"
                }
            },
            {
                "id": "32_19",
                "slug": "petClothing",
                "label": {
                    "ru": "Одежда для питомцев",
                    "uk": "Одяг для домашніх тварин",
                    "en": "Pet Clothing"
                }
            },
            {
                "id": "32_20",
                "slug": "cockfighting",
                "label": {
                    "ru": "Петушиные бои",
                    "uk": "Петушині бої",
                    "en": "Cockfighting"
                }
            },
            {
                "id": "32_21",
                "slug": "feedProduction",
                "label": {
                    "ru": "Производство кормов",
                    "uk": "Виробництво кормів",
                    "en": "Feed Production"
                }
            },
            {
                "id": "32_22",
                "slug": "beekeeping",
                "label": {
                    "ru": "Пчеловодство",
                    "uk": "Бджільництво",
                    "en": "Beekeeping"
                }
            },
            {
                "id": "32_23",
                "slug": "rodentBreeding",
                "label": {
                    "ru": "Разведение грызунов",
                    "uk": "Розведення гризунів",
                    "en": "Rodent Breeding"
                }
            },
            {
                "id": "32_24",
                "slug": "domesticAnimalBreeding",
                "label": {
                    "ru": "Разведение домашних животных",
                    "uk": "Розведення домашніх тварин",
                    "en": "Domestic Animal Breeding"
                }
            },
            {
                "id": "32_25",
                "slug": "wildAnimalBreeding",
                "label": {
                    "ru": "Разведение зверей",
                    "uk": "Розведення диких тварин",
                    "en": "Wild Animal Breeding"
                }
            },
            {
                "id": "32_26",
                "slug": "marineAnimalBreeding",
                "label": {
                    "ru": "Разведение морских животных",
                    "uk": "Розведення морських тварин",
                    "en": "Marine Animal Breeding"
                }
            },
            {
                "id": "32_27",
                "slug": "birdBreeding",
                "label": {
                    "ru": "Разведение птиц",
                    "uk": "Розведення птахів",
                    "en": "Bird Breeding"
                }
            },
            {
                "id": "32_28",
                "slug": "exoticAnimalBreeding",
                "label": {
                    "ru": "Разведение экзотических животных",
                    "uk": "Розведення екзотичних тварин",
                    "en": "Exotic Animal Breeding"
                }
            },
            {
                "id": "32_29",
                "slug": "dogBreeding",
                "label": {
                    "ru": "Собаководство",
                    "uk": "Собаководство",
                    "en": "Dog Breeding"
                }
            },
            {
                "id": "32_30",
                "slug": "roachRacing",
                "label": {
                    "ru": "Тараканьи бега",
                    "uk": "Біги тарганів",
                    "en": "Roach Racing"
                }
            },
            {
                "id": "32_31",
                "slug": "terrariumKeeping",
                "label": {
                    "ru": "Террариумистика",
                    "uk": "Тераріумістика",
                    "en": "Terrarium Keeping"
                }
            }
        ]
    },
    {
        "id": "33",
        "slug": "dogLovers",
        "label": {
            "ru": "Собачники",
            "uk": "Собачники",
            "en": "Dog Lovers"
        },
        "children": [
            {
                "id": "33_0",
                "slug": "dogLovers",
                "label": {
                    "ru": "Собачники",
                    "uk": "Собачники",
                    "en": "Dog Lovers"
                }
            },
            {
                "id": "33_1",
                "slug": "australianShepherd",
                "label": {
                    "ru": "Австралийская овчарка (Аусси)",
                    "uk": "Австралійська вівчарка (Ауссі)",
                    "en": "Australian Shepherd"
                }
            },
            {
                "id": "33_2",
                "slug": "australianCattleDog",
                "label": {
                    "ru": "Австралийский келли",
                    "uk": "Австралійський келлі",
                    "en": "Australian Cattle Dog"
                }
            },
            {
                "id": "33_3",
                "slug": "australianSilkyTerrier",
                "label": {
                    "ru": "Австралийский шелковистый терьер",
                    "uk": "Австралійський шовковистий тер'єр",
                    "en": "Australian Silky Terrier"
                }
            },
            {
                "id": "33_4",
                "slug": "alaskanKleeKai",
                "label": {
                    "ru": "Аляскинский кликай",
                    "uk": "Аляскінський клікай",
                    "en": "Alaskan Klee Kai"
                }
            },
            {
                "id": "33_5",
                "slug": "alaskanMalamute",
                "label": {
                    "ru": "Аляскинский маламут",
                    "uk": "Аляскінський маламут",
                    "en": "Alaskan Malamute"
                }
            },
            {
                "id": "33_6",
                "slug": "americanAkitaInu",
                "label": {
                    "ru": "Американская акита-ину",
                    "uk": "Американська акіта-іну",
                    "en": "American Akita Inu"
                }
            },
            {
                "id": "33_7",
                "slug": "americanBulldog",
                "label": {
                    "ru": "Американский бульдог",
                    "uk": "Американський бульдог",
                    "en": "American Bulldog"
                }
            },
            {
                "id": "33_8",
                "slug": "americanHairlessTerrier",
                "label": {
                    "ru": "Американский голый терьер",
                    "uk": "Американський голий тер'єр",
                    "en": "American Hairless Terrier"
                }
            },
            {
                "id": "33_9",
                "slug": "americanCockerSpaniel",
                "label": {
                    "ru": "Американский кокер-спаниель",
                    "uk": "Американський кокер-спаніель",
                    "en": "American Cocker Spaniel"
                }
            },
            {
                "id": "33_10",
                "slug": "americanPitBullTerrier",
                "label": {
                    "ru": "Американский питбультерьер",
                    "uk": "Американський пітбультер'єр",
                    "en": "American Pit Bull Terrier"
                }
            },
            {
                "id": "33_11",
                "slug": "americanStaffordshireTerrier",
                "label": {
                    "ru": "Американский стаффордширский терьер",
                    "uk": "Американський стаффордширський тер'єр",
                    "en": "American Staffordshire Terrier"
                }
            },
            {
                "id": "33_12",
                "slug": "americanEskimoDog",
                "label": {
                    "ru": "Американский эскимосский шпиц",
                    "uk": "Американський ескімоський шпіц",
                    "en": "American Eskimo Dog"
                }
            },
            {
                "id": "33_13",
                "slug": "englishBulldog",
                "label": {
                    "ru": "Английский бульдог",
                    "uk": "Англійський бульдог",
                    "en": "English Bulldog"
                }
            },
            {
                "id": "33_14",
                "slug": "englishCockerSpaniel",
                "label": {
                    "ru": "Английский кокер-спаниель",
                    "uk": "Англійський кокер-спаніель",
                    "en": "English Cocker Spaniel"
                }
            },
            {
                "id": "33_15",
                "slug": "englishMastiff",
                "label": {
                    "ru": "Английский мастиф",
                    "uk": "Англійський мастиф",
                    "en": "English Mastiff"
                }
            },
            {
                "id": "33_16",
                "slug": "englishSetter",
                "label": {
                    "ru": "Английский сеттер",
                    "uk": "Англійський сеттер",
                    "en": "English Setter"
                }
            },
            {
                "id": "33_17",
                "slug": "englishSpringerSpaniel",
                "label": {
                    "ru": "Английский спрингер-спаниель",
                    "uk": "Англійський спрингер-спаніель",
                    "en": "English Springer Spaniel"
                }
            },
            {
                "id": "33_18",
                "slug": "argentineDogo",
                "label": {
                    "ru": "Аргентинский дог",
                    "uk": "Аргентинський дог",
                    "en": "Argentine Dogo"
                }
            },
            {
                "id": "33_19",
                "slug": "afghanHound",
                "label": {
                    "ru": "Афганская борзая",
                    "uk": "Афганська борза",
                    "en": "Afghan Hound"
                }
            },
            {
                "id": "33_20",
                "slug": "affenpinscher",
                "label": {
                    "ru": "Аффепинчер",
                    "uk": "Аффенпінчер",
                    "en": "Affenpinscher"
                }
            },
            {
                "id": "33_21",
                "slug": "basenji",
                "label": {
                    "ru": "Басенджи",
                    "uk": "Басенджі",
                    "en": "Basenji"
                }
            },
            {
                "id": "33_22",
                "slug": "bassetHound",
                "label": {
                    "ru": "Бассет-хаунд",
                    "uk": "Басет-хаунд",
                    "en": "Basset Hound"
                }
            },
            {
                "id": "33_23",
                "slug": "bedlingtonTerrier",
                "label": {
                    "ru": "Бедлингтон-терьер",
                    "uk": "Бедлінгтон-тер'єр",
                    "en": "Bedlington Terrier"
                }
            },
            {
                "id": "33_24",
                "slug": "swissWhiteShepherd",
                "label": {
                    "ru": "Белая швейцарская овчарка",
                    "uk": "Біла швейцарська вівчарка",
                    "en": "Swiss White Shepherd"
                }
            },
            {
                "id": "33_25",
                "slug": "belgianShepherd",
                "label": {
                    "ru": "Бельгийская овчарка",
                    "uk": "Бельгійська вівчарка",
                    "en": "Belgian Shepherd"
                }
            },
            {
                "id": "33_26",
                "slug": "beaverYork",
                "label": {
                    "ru": "Бивер-йорк",
                    "uk": "Бівер-йорк",
                    "en": "Biewer York"
                }
            },
            {
                "id": "33_27",
                "slug": "beagle",
                "label": {
                    "ru": "Бигль",
                    "uk": "Бігль",
                    "en": "Beagle"
                }
            },
            {
                "id": "33_28",
                "slug": "bichonFrise",
                "label": {
                    "ru": "Бишон фризе",
                    "uk": "Бішон фризе",
                    "en": "Bichon Frise"
                }
            },
            {
                "id": "33_29",
                "slug": "bobtail",
                "label": {
                    "ru": "Бобтейл",
                    "uk": "Бобтейл",
                    "en": "Bobtail"
                }
            },
            {
                "id": "33_30",
                "slug": "boxer",
                "label": {
                    "ru": "Боксер",
                    "uk": "Боксер",
                    "en": "Boxer"
                }
            },
            {
                "id": "33_31",
                "slug": "borderCollie",
                "label": {
                    "ru": "Бордер-колли",
                    "uk": "Бордер-коллі",
                    "en": "Border Collie"
                }
            },
            {
                "id": "33_32",
                "slug": "borderTerrier",
                "label": {
                    "ru": "Бордер-терьер",
                    "uk": "Бордер-тер'єр",
                    "en": "Border Terrier"
                }
            },
            {
                "id": "33_33",
                "slug": "bordeauxDog",
                "label": {
                    "ru": "Бордоский дог",
                    "uk": "Бордо́нський дог",
                    "en": "Bordeaux Dog"
                }
            },
            {
                "id": "33_34",
                "slug": "beardedCollie",
                "label": {
                    "ru": "Бородатый колли",
                    "uk": "Бородатий коллі",
                    "en": "Bearded Collie"
                }
            },
            {
                "id": "33_35",
                "slug": "bouvierDesFlandres",
                "label": {
                    "ru": "Босерон",
                    "uk": "Був'єр де Фландр",
                    "en": "Bouvier des Flandres"
                }
            },
            {
                "id": "33_36",
                "slug": "bostonTerrier",
                "label": {
                    "ru": "Бостон-терьер",
                    "uk": "Бостон-тер'єр",
                    "en": "Boston Terrier"
                }
            },
            {
                "id": "33_37",
                "slug": "brusselsGriffon",
                "label": {
                    "ru": "Брюссельский гриффон",
                    "uk": "Брюссельський гриффон",
                    "en": "Brussels Griffon"
                }
            },
            {
                "id": "33_38",
                "slug": "bullmastiff",
                "label": {
                    "ru": "Бульмастиф",
                    "uk": "Бульмастиф",
                    "en": "Bullmastiff"
                }
            },
            {
                "id": "33_39",
                "slug": "bullTerrier",
                "label": {
                    "ru": "Бультерьер",
                    "uk": "Бультер'єр",
                    "en": "Bull Terrier"
                }
            },
            {
                "id": "33_40",
                "slug": "weimaraner",
                "label": {
                    "ru": "Веймаранер",
                    "uk": "Веймаранер",
                    "en": "Weimaraner"
                }
            },
            {
                "id": "33_41",
                "slug": "welshCorgi",
                "label": {
                    "ru": "Вельш-корги",
                    "uk": "Вельш-коргі",
                    "en": "Welsh Corgi"
                }
            },
            {
                "id": "33_42",
                "slug": "welshTerrier",
                "label": {
                    "ru": "Вельштерьер",
                    "uk": "Вельштер'єр",
                    "en": "Welsh Terrier"
                }
            },
            {
                "id": "33_43",
                "slug": "hungarianVizsla",
                "label": {
                    "ru": "Венгерская выжла (легавая)",
                    "uk": "Угорська візла (легава)",
                    "en": "Hungarian Vizsla"
                }
            },
            {
                "id": "33_44",
                "slug": "hungarianKuvasz",
                "label": {
                    "ru": "Венгерский кувас",
                    "uk": "Угорський кувас",
                    "en": "Hungarian Kuvasz"
                }
            },
            {
                "id": "33_45",
                "slug": "westHighlandWhiteTerrier",
                "label": {
                    "ru": "Вест-хайленд-уайт-терьер",
                    "uk": "Вест-хайленд-уайт-тер'єр",
                    "en": "West Highland White Terrier"
                }
            },
            {
                "id": "33_46",
                "slug": "eastSiberianLaika",
                "label": {
                    "ru": "Восточно-сибирская лайка",
                    "uk": "Східно-сибірська лайка",
                    "en": "East Siberian Laika"
                }
            },
            {
                "id": "33_47",
                "slug": "eastEuropeanShepherd",
                "label": {
                    "ru": "Восточноевропейская овчарка",
                    "uk": "Східноєвропейська вівчарка",
                    "en": "East European Shepherd"
                }
            },
            {
                "id": "33_48",
                "slug": "pyreneanMountainDog",
                "label": {
                    "ru": "Горная пиренейская собака",
                    "uk": "Гірська піренейська собака",
                    "en": "Pyrenean Mountain Dog"
                }
            },
            {
                "id": "33_49",
                "slug": "greyhound",
                "label": {
                    "ru": "Грейхаунд",
                    "uk": "Грейхаунд",
                    "en": "Greyhound"
                }
            },
            {
                "id": "33_50",
                "slug": "dalmatian",
                "label": {
                    "ru": "Далматинец",
                    "uk": "Далматинець",
                    "en": "Dalmatian"
                }
            },
            {
                "id": "33_51",
                "slug": "jackRussellTerrier",
                "label": {
                    "ru": "Джек рассел терьер",
                    "uk": "Джек Рассел тер'єр",
                    "en": "Jack Russell Terrier"
                }
            },
            {
                "id": "33_52",
                "slug": "doberman",
                "label": {
                    "ru": "Доберман",
                    "uk": "Доберман",
                    "en": "Doberman"
                }
            },
            {
                "id": "33_53",
                "slug": "drahthaar",
                "label": {
                    "ru": "Дратхаар или немецкая жесткошерстная легавая",
                    "uk": "Дратхаар або німецька жорсткошерста легава",
                    "en": "Drahthaar"
                }
            },
            {
                "id": "33_54",
                "slug": "eurasier",
                "label": {
                    "ru": "Евразиер",
                    "uk": "Євразієр",
                    "en": "Eurasier"
                }
            },
            {
                "id": "33_55",
                "slug": "westSiberianLaika",
                "label": {
                    "ru": "Западно-сибирская лайка",
                    "uk": "Західно-сибірська лайка",
                    "en": "West Siberian Laika"
                }
            },
            {
                "id": "33_56",
                "slug": "goldenRetriever",
                "label": {
                    "ru": "Золотистый ретривер",
                    "uk": "Золотистий ретривер",
                    "en": "Golden Retriever"
                }
            },
            {
                "id": "33_57",
                "slug": "irishWolfhound",
                "label": {
                    "ru": "Ирландский волкодав",
                    "uk": "Ірландський вовкодав",
                    "en": "Irish Wolfhound"
                }
            },
            {
                "id": "33_58",
                "slug": "irishSoftCoatedWheatenTerrier",
                "label": {
                    "ru": "Ирландский мягкошерстный пшеничный терьер",
                    "uk": "Ірландський м'якошорстний пшеничний тер'єр",
                    "en": "Irish Soft Coated Wheaten Terrier"
                }
            },
            {
                "id": "33_59",
                "slug": "irishSetter",
                "label": {
                    "ru": "Ирландский сеттер",
                    "uk": "Ірландський сеттер",
                    "en": "Irish Setter"
                }
            },
            {
                "id": "33_60",
                "slug": "irishTerrier",
                "label": {
                    "ru": "Ирландский терьер",
                    "uk": "Ірландський тер'єр",
                    "en": "Irish Terrier"
                }
            },
            {
                "id": "33_61",
                "slug": "italianBolognese",
                "label": {
                    "ru": "Итальянская болонка (болоньез, болонский бишон)",
                    "uk": "Італійська болонка (болоньєз, болонський бишон)",
                    "en": "Italian Bolognese"
                }
            },
            {
                "id": "33_62",
                "slug": "italianGreyhound",
                "label": {
                    "ru": "Итальянская лавретка (итальянская борзая)",
                    "uk": "Італійська лавретка (італійська борза)",
                    "en": "Italian Greyhound"
                }
            },
            {
                "id": "33_63",
                "slug": "yorkshireTerrier",
                "label": {
                    "ru": "Йокширский терьер",
                    "uk": "Йоркширський тер'єр",
                    "en": "Yorkshire Terrier"
                }
            },
            {
                "id": "33_64",
                "slug": "cadeBou",
                "label": {
                    "ru": "Каде Бо (майорский мастиф)",
                    "uk": "Каде Бо (майорський мастиф)",
                    "en": "Cade Bou"
                }
            },
            {
                "id": "33_65",
                "slug": "cavalierKingCharlesSpaniel",
                "label": {
                    "ru": "Кавалер-кинг-чарльз-спаниель",
                    "uk": "Кавалер-кінг-чарльз-спаніель",
                    "en": "Cavalier King Charles Spaniel"
                }
            },
            {
                "id": "33_66",
                "slug": "cavapoo",
                "label": {
                    "ru": "Кавапу дизайнерская порода",
                    "uk": "Кавапу дизайнерська порода",
                    "en": "Cavapoo"
                }
            },
            {
                "id": "33_67",
                "slug": "caucasianShepherdDog",
                "label": {
                    "ru": "Кавказская овчарка",
                    "uk": "Кавказька вівчарка",
                    "en": "Caucasian Shepherd Dog"
                }
            },
            {
                "id": "33_68",
                "slug": "caneCorso",
                "label": {
                    "ru": "Кане-корсо",
                    "uk": "Кане-корсо",
                    "en": "Cane Corso"
                }
            },
            {
                "id": "33_69",
                "slug": "karelianBearDog",
                "label": {
                    "ru": "Карело-финская лайка",
                    "uk": "Карело-фінська лайка",
                    "en": "Karelian Bear Dog"
                }
            },
            {
                "id": "33_70",
                "slug": "karelianBearDog",
                "label": {
                    "ru": "Карельская медвежья",
                    "uk": "Карельська ведмежа",
                    "en": "Karelian Bear Dog"
                }
            },
            {
                "id": "33_71",
                "slug": "keeshond",
                "label": {
                    "ru": "Кеесхонд (вольфшпиц)",
                    "uk": "Кеесхонд (вольфшпіц)",
                    "en": "Keeshond"
                }
            },
            {
                "id": "33_72",
                "slug": "kerryBlueTerrier",
                "label": {
                    "ru": "Керн-терьер",
                    "uk": "Керн-тер'єр",
                    "en": "Kerry Blue Terrier"
                }
            },
            {
                "id": "33_73",
                "slug": "kerryBlueTerrier",
                "label": {
                    "ru": "Керри-блю-терьер",
                    "uk": "Керрі-блю-тер'єр",
                    "en": "Kerry Blue Terrier"
                }
            },
            {
                "id": "33_74",
                "slug": "kishu",
                "label": {
                    "ru": "Кисю",
                    "uk": "Кісью",
                    "en": "Kishu"
                }
            },
            {
                "id": "33_75",
                "slug": "chineseCrested",
                "label": {
                    "ru": "Китайская хохлатая",
                    "uk": "Китайська хохлата",
                    "en": "Chinese Crested"
                }
            },
            {
                "id": "33_76",
                "slug": "collieLonghaired",
                "label": {
                    "ru": "Колли длинношерстный",
                    "uk": "Коллі довгошерстний",
                    "en": "Longhaired Collie"
                }
            },
            {
                "id": "33_77",
                "slug": "collieShorthaired",
                "label": {
                    "ru": "Колли короткошерстный",
                    "uk": "Коллі короткошерстний",
                    "en": "Shorthaired Collie"
                }
            },
            {
                "id": "33_78",
                "slug": "komondor",
                "label": {
                    "ru": "Комондор (венгерская овчарка)",
                    "uk": "Комондор (угорська вівчарка)",
                    "en": "Komondor"
                }
            },
            {
                "id": "33_79",
                "slug": "continentalToySpaniel",
                "label": {
                    "ru": "Континентальный той-спаниель (паийон и фален)",
                    "uk": "Континентальний той-спаніель (паийон і фален)",
                    "en": "Continental Toy Spaniel"
                }
            },
            {
                "id": "33_80",
                "slug": "germanShorthairedPointer",
                "label": {
                    "ru": "Курцхаар",
                    "uk": "Курцхаар",
                    "en": "German Shorthaired Pointer"
                }
            },
            {
                "id": "33_81",
                "slug": "labradorRetriever",
                "label": {
                    "ru": "Лабрадор ретривер",
                    "uk": "Лабрадор ретривер",
                    "en": "Labrador Retriever"
                }
            },
            {
                "id": "33_82",
                "slug": "leonberger",
                "label": {
                    "ru": "Леонбергер",
                    "uk": "Леонбергер",
                    "en": "Leonberger"
                }
            },
            {
                "id": "33_83",
                "slug": "maltese",
                "label": {
                    "ru": "Мальтийская болонка",
                    "uk": "Мальтійська болонка",
                    "en": "Maltese"
                }
            },
            {
                "id": "33_84",
                "slug": "neapolitanMastiff",
                "label": {
                    "ru": "Мастино наполетано",
                    "uk": "Мастіно наполетано",
                    "en": "Neapolitan Mastiff"
                }
            },
            {
                "id": "33_85",
                "slug": "mexicanHairlessDog",
                "label": {
                    "ru": "Мексиканская голая собака (ксоло)",
                    "uk": "Мексиканська гола собака (ксоло)",
                    "en": "Mexican Hairless Dog"
                }
            },
            {
                "id": "33_86",
                "slug": "miniatureSchnauzer",
                "label": {
                    "ru": "Миттельшнауцер",
                    "uk": "Міттельшнауцер",
                    "en": "Miniature Schnauzer"
                }
            },
            {
                "id": "33_87",
                "slug": "pug",
                "label": {
                    "ru": "Мопс",
                    "uk": "Мопс",
                    "en": "Pug"
                }
            },
            {
                "id": "33_88",
                "slug": "moscowWatchdog",
                "label": {
                    "ru": "Московская сторожева",
                    "uk": "Московська сторожова",
                    "en": "Moscow Watchdog"
                }
            },
            {
                "id": "33_89",
                "slug": "germanShepherd",
                "label": {
                    "ru": "Немецкая овчарка",
                    "uk": "Німецька вівчарка",
                    "en": "German Shepherd"
                }
            },
            {
                "id": "33_90",
                "slug": "germanMastiff",
                "label": {
                    "ru": "Немецкий дог",
                    "uk": "Німецький дог",
                    "en": "German Mastiff"
                }
            },
            {
                "id": "33_91",
                "slug": "germanHuntingTerrier",
                "label": {
                    "ru": "Немецкий ягдтерьер",
                    "uk": "Німецький ягдтер'єр",
                    "en": "German Hunting Terrier"
                }
            },
            {
                "id": "33_92",
                "slug": "novaScotiaDuckTollingRetriever",
                "label": {
                    "ru": "Новошотландский ретривер (толлер)",
                    "uk": "Новошотландський ретривер (толлер)",
                    "en": "Nova Scotia Duck Tolling Retriever"
                }
            },
            {
                "id": "33_93",
                "slug": "norwichTerrier",
                "label": {
                    "ru": "Норвич-терьер",
                    "uk": "Норвіч-тер'єр",
                    "en": "Norwich Terrier"
                }
            },
            {
                "id": "33_94",
                "slug": "newfoundland",
                "label": {
                    "ru": "Ньюфаундленд",
                    "uk": "Ньюфаундленд",
                    "en": "Newfoundland"
                }
            },
            {
                "id": "33_95",
                "slug": "pekingese",
                "label": {
                    "ru": "Пекинес",
                    "uk": "Пекінес",
                    "en": "Pekingese"
                }
            },
            {
                "id": "33_96",
                "slug": "pointer",
                "label": {
                    "ru": "Пойнтер",
                    "uk": "Пойнтер",
                    "en": "Pointer"
                }
            },
            {
                "id": "33_97",
                "slug": "polishLowlandSheepdog",
                "label": {
                    "ru": "Польская подгалянская овчарка",
                    "uk": "Польська подгальська вівчарка",
                    "en": "Polish Lowland Sheepdog"
                }
            },
            {
                "id": "33_98",
                "slug": "pomeranian",
                "label": {
                    "ru": "Померанский шпиц",
                    "uk": "Померанський шпіц",
                    "en": "Pomeranian"
                }
            },
            {
                "id": "33_99",
                "slug": "portugueseWaterDog",
                "label": {
                    "ru": "Португальская водяная собака",
                    "uk": "Португальська водяна собака",
                    "en": "Portuguese Water Dog"
                }
            },
            {
                "id": "33_100",
                "slug": "brabant",
                "label": {
                    "ru": "Пти брабансон",
                    "uk": "Пти брабансон",
                    "en": "Brabant"
                }
            },
            {
                "id": "33_101",
                "slug": "poodle",
                "label": {
                    "ru": "Пудель",
                    "uk": "Пудель",
                    "en": "Poodle"
                }
            },
            {
                "id": "33_102",
                "slug": "pumi",
                "label": {
                    "ru": "Пуми",
                    "uk": "Пумі",
                    "en": "Pumi"
                }
            },
            {
                "id": "33_103",
                "slug": "giantSchnauzer",
                "label": {
                    "ru": "Ризеншнауцер",
                    "uk": "Різеншнауцер",
                    "en": "Giant Schnauzer"
                }
            },
            {
                "id": "33_104",
                "slug": "rhodesianRidgeback",
                "label": {
                    "ru": "Родезийский риджбек",
                    "uk": "Родезійський риджбек",
                    "en": "Rhodesian Ridgeback"
                }
            },
            {
                "id": "33_105",
                "slug": "rottweiler",
                "label": {
                    "ru": "Ротвейлер",
                    "uk": "Ротвейлер",
                    "en": "Rottweiler"
                }
            },
            {
                "id": "33_106",
                "slug": "russianHound",
                "label": {
                    "ru": "Русская пегая гончая",
                    "uk": "Російська пегая гонча",
                    "en": "Russian Hound"
                }
            },
            {
                "id": "33_107",
                "slug": "russianBorzoi",
                "label": {
                    "ru": "Русская псовая борзая",
                    "uk": "Російська псова борза",
                    "en": "Russian Borzoi"
                }
            },
            {
                "id": "33_108",
                "slug": "russianToyTerrier",
                "label": {
                    "ru": "Русская салонная собака (русалка)",
                    "uk": "Російська салонна собака (русалка)",
                    "en": "Russian Toy Terrier"
                }
            },
            {
                "id": "33_109",
                "slug": "russianSpaniel",
                "label": {
                    "ru": "Русский охотничий спаниель",
                    "uk": "Російський мисливський спаніель",
                    "en": "Russian Spaniel"
                }
            },
            {
                "id": "33_110",
                "slug": "russianToyTerrier",
                "label": {
                    "ru": "Русский той-терьер",
                    "uk": "Російський той-тер'єр",
                    "en": "Russian Toy Terrier"
                }
            },
            {
                "id": "33_111",
                "slug": "blackRussianTerrier",
                "label": {
                    "ru": "Русский черный терьер",
                    "uk": "Російський чорний тер'єр",
                    "en": "Black Russian Terrier"
                }
            },
            {
                "id": "33_112",
                "slug": "samoyed",
                "label": {
                    "ru": "Самоедская лайка",
                    "uk": "Самоєдська лайка",
                    "en": "Samoyed"
                }
            },
            {
                "id": "33_113",
                "slug": "berneseMountainDog",
                "label": {
                    "ru": "Сенбернар",
                    "uk": "Сенбернар",
                    "en": "Bernese Mountain Dog"
                }
            },
            {
                "id": "33_114",
                "slug": "shibaInu",
                "label": {
                    "ru": "Сиба-ину",
                    "uk": "Сіба-іну",
                    "en": "Shiba Inu"
                }
            },
            {
                "id": "33_115",
                "slug": "silkyTerrier",
                "label": {
                    "ru": "Силихем-терьер",
                    "uk": "Сіліхем-тер'єр",
                    "en": "Silky Terrier"
                }
            },
            {
                "id": "33_116",
                "slug": "skyTerrier",
                "label": {
                    "ru": "Скай-терьер",
                    "uk": "Скай-тер'єр",
                    "en": "Sky Terrier"
                }
            },
            {
                "id": "33_117",
                "slug": "scottishTerrier",
                "label": {
                    "ru": "Скотч-терьер",
                    "uk": "Скотч-тер'єр",
                    "en": "Scottish Terrier"
                }
            },
            {
                "id": "33_118",
                "slug": "centralAsianShepherdDog",
                "label": {
                    "ru": "Среднеазиатская овчарка (алабай)",
                    "uk": "Середньоазіатська вівчарка (алабай)",
                    "en": "Central Asian Shepherd Dog"
                }
            },
            {
                "id": "33_119",
                "slug": "staffordshireBullTerrier",
                "label": {
                    "ru": "Стаффордширский бультерьер",
                    "uk": "Стаффордширський бультер'єр",
                    "en": "Staffordshire Bull Terrier"
                }
            },
            {
                "id": "33_120",
                "slug": "thaiRidgeback",
                "label": {
                    "ru": "Тайский риджбек",
                    "uk": "Тайський риджбек",
                    "en": "Thai Ridgeback"
                }
            },
            {
                "id": "33_121",
                "slug": "dachshund",
                "label": {
                    "ru": "Такса",
                    "uk": "Такса",
                    "en": "Dachshund"
                }
            },
            {
                "id": "33_122",
                "slug": "tibetanMastiff",
                "label": {
                    "ru": "Тибетский мастиф",
                    "uk": "Тибетський мастиф",
                    "en": "Tibetan Mastiff"
                }
            },
            {
                "id": "33_123",
                "slug": "tosaInu",
                "label": {
                    "ru": "Тоса-ину",
                    "uk": "Тоса-іну",
                    "en": "Tosa Inu"
                }
            },
            {
                "id": "33_124",
                "slug": "kangal",
                "label": {
                    "ru": "Турецкий кангал",
                    "uk": "Турецький кангал",
                    "en": "Kangal"
                }
            },
            {
                "id": "33_125",
                "slug": "whippet",
                "label": {
                    "ru": "Уиппет",
                    "uk": "Уіппет",
                    "en": "Whippet"
                }
            },
            {
                "id": "33_126",
                "slug": "pharaohHound",
                "label": {
                    "ru": "Фараоновая собака",
                    "uk": "Фараонова собака",
                    "en": "Pharaoh Hound"
                }
            },
            {
                "id": "33_127",
                "slug": "foxTerrier",
                "label": {
                    "ru": "Фокстерьер",
                    "uk": "Фокстер'єр",
                    "en": "Fox Terrier"
                }
            },
            {
                "id": "33_128",
                "slug": "frenchBulldog",
                "label": {
                    "ru": "Французский бульдог",
                    "uk": "Французький бульдог",
                    "en": "French Bulldog"
                }
            },
            {
                "id": "33_129",
                "slug": "husky",
                "label": {
                    "ru": "Хаски",
                    "uk": "Хаскі",
                    "en": "Husky"
                }
            },
            {
                "id": "33_130",
                "slug": "hovawart",
                "label": {
                    "ru": "Ховаварт",
                    "uk": "Ховаварт",
                    "en": "Hovawart"
                }
            },
            {
                "id": "33_131",
                "slug": "hokkaido",
                "label": {
                    "ru": "Хоккайдо",
                    "uk": "Хоккайдо",
                    "en": "Hokkaido"
                }
            },
            {
                "id": "33_132",
                "slug": "miniaturePinscher",
                "label": {
                    "ru": "Цвергпинчер",
                    "uk": "Цвергпінчер",
                    "en": "Miniature Pinscher"
                }
            },
            {
                "id": "33_133",
                "slug": "miniatureSchnauzer",
                "label": {
                    "ru": "Цвергшнауцер",
                    "uk": "Цвергшнауцер",
                    "en": "Miniature Schnauzer"
                }
            },
            {
                "id": "33_134",
                "slug": "chowChow",
                "label": {
                    "ru": "Чау-чау",
                    "uk": "Чау-чау",
                    "en": "Chow Chow"
                }
            },
            {
                "id": "33_135",
                "slug": "chesapeakeBayRetriever",
                "label": {
                    "ru": "Чесапик-бей-ретривер",
                    "uk": "Чесапік-бей-ретривер",
                    "en": "Chesapeake Bay Retriever"
                }
            },
            {
                "id": "33_136",
                "slug": "czechoslovakianWolfhound",
                "label": {
                    "ru": "Чехословацкая волчья",
                    "uk": "Чехословацька волчка",
                    "en": "Czechoslovakian Wolfhound"
                }
            },
            {
                "id": "33_137",
                "slug": "chihuahua",
                "label": {
                    "ru": "Чихуахуа",
                    "uk": "Чіхуахуа",
                    "en": "Chihuahua"
                }
            },
            {
                "id": "33_138",
                "slug": "sharPei",
                "label": {
                    "ru": "Шарпей",
                    "uk": "Шарпей",
                    "en": "Shar Pei"
                }
            },
            {
                "id": "33_139",
                "slug": "swedishVallhund",
                "label": {
                    "ru": "Шведский вальхунд",
                    "uk": "Шведський вальхунд",
                    "en": "Swedish Vallhund"
                }
            },
            {
                "id": "33_140",
                "slug": "swedishLapphund",
                "label": {
                    "ru": "шведский лаппхунд",
                    "uk": "Шведський лаппхунд",
                    "en": "Swedish Lapphund"
                }
            },
            {
                "id": "33_141",
                "slug": "swissMountainDog",
                "label": {
                    "ru": "Швейцарский зенненхунд",
                    "uk": "Швейцарський зенненхунд",
                    "en": "Swiss Mountain Dog"
                }
            },
            {
                "id": "33_142",
                "slug": "sheltie",
                "label": {
                    "ru": "Шелти",
                    "uk": "Шелті",
                    "en": "Sheltie"
                }
            },
            {
                "id": "33_143",
                "slug": "shihtzu",
                "label": {
                    "ru": "Ши-тцу",
                    "uk": "Ши-тцу",
                    "en": "Shih Tzu"
                }
            },
            {
                "id": "33_144",
                "slug": "schipperke",
                "label": {
                    "ru": "Шипперке",
                    "uk": "Шіпперке",
                    "en": "Schipperke"
                }
            },
            {
                "id": "33_145",
                "slug": "scottishSetter",
                "label": {
                    "ru": "Шотландский сеттер (гордон)",
                    "uk": "Шотландський сеттер (гордон)",
                    "en": "Scottish Setter"
                }
            },
            {
                "id": "33_146",
                "slug": "erdelterrier",
                "label": {
                    "ru": "Эрдельтерьер",
                    "uk": "Ердельтер'єр",
                    "en": "Airedale Terrier"
                }
            },
            {
                "id": "33_147",
                "slug": "estonianHound",
                "label": {
                    "ru": "Эстонская гончая",
                    "uk": "Естонська гонча",
                    "en": "Estonian Hound"
                }
            },
            {
                "id": "33_148",
                "slug": "southAfricanBoerboel",
                "label": {
                    "ru": "Южноафриканский бурбуль",
                    "uk": "Південноафриканський бурбуль",
                    "en": "South African Boerboel"
                }
            },
            {
                "id": "33_149",
                "slug": "southRussianOvcharka",
                "label": {
                    "ru": "Южнорусская овчарка",
                    "uk": "Південноросійська вівчарка",
                    "en": "South Russian Ovcharka"
                }
            },
            {
                "id": "33_150",
                "slug": "akitaInu",
                "label": {
                    "ru": "Японская акита-ину",
                    "uk": "Японська акіта-іну",
                    "en": "Japanese Akita Inu"
                }
            },
            {
                "id": "33_151",
                "slug": "japaneseChin",
                "label": {
                    "ru": "Японский хин",
                    "uk": "Японський хін",
                    "en": "Japanese Chin"
                }
            },
            {
                "id": "33_152",
                "slug": "spitz",
                "label": {
                    "ru": "Шпиц",
                    "uk": "Шпіц",
                    "en": "Spitz"
                }
            }
        ]
    },
    {
        "id": "34",
        "slug": "koshatniki",
        "label": {
            "ru": "Кошатники",
            "uk": "Кошатники",
            "en": "Cat Breeds"
        },
        "children": [
            {
                "id": "34_0",
                "slug": "koshatniki",
                "label": {
                    "ru": "Кошатники",
                    "uk": "Кошатники",
                    "en": "Cat Breeds"
                }
            },
            {
                "id": "34_1",
                "slug": "abyssinian",
                "label": {
                    "ru": "Абиссинская",
                    "uk": "Абіссінська",
                    "en": "Abyssinian"
                }
            },
            {
                "id": "34_2",
                "slug": "australianMist",
                "label": {
                    "ru": "Австралийский мист",
                    "uk": "Австралійський міст",
                    "en": "Australian Mist"
                }
            },
            {
                "id": "34_3",
                "slug": "asianTabby",
                "label": {
                    "ru": "Азиатская (табби)",
                    "uk": "Азіатська (таббі)",
                    "en": "Asian Tabby"
                }
            },
            {
                "id": "34_4",
                "slug": "americanWirehair",
                "label": {
                    "ru": "Американсая жесткошерсная",
                    "uk": "Американська жорсткошерста",
                    "en": "American Wirehair"
                }
            },
            {
                "id": "34_5",
                "slug": "americanShorthair",
                "label": {
                    "ru": "Американская короткошерстная",
                    "uk": "Американська короткошерста",
                    "en": "American Shorthair"
                }
            },
            {
                "id": "34_6",
                "slug": "americanBobtailShorthair",
                "label": {
                    "ru": "Американский бобтей короткошерстный",
                    "uk": "Американський бобтейл короткошерстний",
                    "en": "American Bobtail Shorthair"
                }
            },
            {
                "id": "34_7",
                "slug": "americanBobtailLonghair",
                "label": {
                    "ru": "Американский бобтейл длинношерстный",
                    "uk": "Американський бобтейл довгошерстний",
                    "en": "American Bobtail Longhair"
                }
            },
            {
                "id": "34_8",
                "slug": "americanCurlLonghair",
                "label": {
                    "ru": "Американский кёрл длинношерстный",
                    "uk": "Американський кёрл довгошерстний",
                    "en": "American Curl Longhair"
                }
            },
            {
                "id": "34_9",
                "slug": "americanCurlShorthair",
                "label": {
                    "ru": "Американский кёрл короткошерстный",
                    "uk": "Американський кёрл короткошерстний",
                    "en": "American Curl Shorthair"
                }
            },
            {
                "id": "34_10",
                "slug": "anatolian",
                "label": {
                    "ru": "Анатолийская",
                    "uk": "Анатолійська",
                    "en": "Anatolian"
                }
            },
            {
                "id": "34_11",
                "slug": "arabianMau",
                "label": {
                    "ru": "Аравийский мау",
                    "uk": "Аравійський мау",
                    "en": "Arabian Mau"
                }
            },
            {
                "id": "34_12",
                "slug": "balinese",
                "label": {
                    "ru": "Балинезийская",
                    "uk": "Балінезійська",
                    "en": "Balinese"
                }
            },
            {
                "id": "34_13",
                "slug": "bengal",
                "label": {
                    "ru": "Бенгальская",
                    "uk": "Бенгальська",
                    "en": "Bengal"
                }
            },
            {
                "id": "34_14",
                "slug": "bombay",
                "label": {
                    "ru": "Бомбейная",
                    "uk": "Бомбейська",
                    "en": "Bombay"
                }
            },
            {
                "id": "34_15",
                "slug": "brazilianShorthair",
                "label": {
                    "ru": "Бразильская короткошерстная",
                    "uk": "Бразильська короткошерста",
                    "en": "Brazilian Shorthair"
                }
            },
            {
                "id": "34_16",
                "slug": "britishLonghair",
                "label": {
                    "ru": "Британская длинношерстная",
                    "uk": "Британська довгошерста",
                    "en": "British Longhair"
                }
            },
            {
                "id": "34_17",
                "slug": "britishShorthair",
                "label": {
                    "ru": "Британская короткошерстная",
                    "uk": "Британська короткошерста",
                    "en": "British Shorthair"
                }
            },
            {
                "id": "34_18",
                "slug": "burmese",
                "label": {
                    "ru": "Бурманская",
                    "uk": "Бурманська",
                    "en": "Burmese"
                }
            },
            {
                "id": "34_19",
                "slug": "burmillaLonghair",
                "label": {
                    "ru": "Бурмилла длинношерстный",
                    "uk": "Бурміла довгошерстна",
                    "en": "Burmilla Longhair"
                }
            },
            {
                "id": "34_20",
                "slug": "burmillaShorthair",
                "label": {
                    "ru": "Бурмилла короткошерстный",
                    "uk": "Бурміла короткошерста",
                    "en": "Burmilla Shorthair"
                }
            },
            {
                "id": "34_21",
                "slug": "havanaBrown",
                "label": {
                    "ru": "Гаванна",
                    "uk": "Гавана",
                    "en": "Havana"
                }
            },
            {
                "id": "34_22",
                "slug": "himalayan",
                "label": {
                    "ru": "Гималайская",
                    "uk": "Гімалайська",
                    "en": "Himalayan"
                }
            },
            {
                "id": "34_23",
                "slug": "devonRex",
                "label": {
                    "ru": "Девон рекс",
                    "uk": "Девон рекс",
                    "en": "Devon Rex"
                }
            },
            {
                "id": "34_24",
                "slug": "domestic",
                "label": {
                    "ru": "Домашняя",
                    "uk": "Домашня",
                    "en": "Domestic"
                }
            },
            {
                "id": "34_25",
                "slug": "donSphynx",
                "label": {
                    "ru": "Донской сфинкс",
                    "uk": "Донський сфінкс",
                    "en": "Don Sphynx"
                }
            },
            {
                "id": "34_26",
                "slug": "egyptianMau",
                "label": {
                    "ru": "Египетская мау",
                    "uk": "Єгипетський мау",
                    "en": "Egyptian Mau"
                }
            },
            {
                "id": "34_27",
                "slug": "york",
                "label": {
                    "ru": "Йорк",
                    "uk": "Йорк",
                    "en": "York"
                }
            },
            {
                "id": "34_28",
                "slug": "kaani",
                "label": {
                    "ru": "Каани",
                    "uk": "Каані",
                    "en": "Kaani"
                }
            },
            {
                "id": "34_29",
                "slug": "californiaSpangled",
                "label": {
                    "ru": "Калифорнийская сияющая",
                    "uk": "Каліфорнійська сяюча",
                    "en": "California Spangled"
                }
            },
            {
                "id": "34_30",
                "slug": "karelianBobtailLonghair",
                "label": {
                    "ru": "Карельский бобтейл длинношерстный",
                    "uk": "Карельський бобтейл довгошерстний",
                    "en": "Karelian Bobtail Longhair"
                }
            },
            {
                "id": "34_31",
                "slug": "karelianBobtailShorthair",
                "label": {
                    "ru": "Карельский бобтейл короткошерстный",
                    "uk": "Карельський бобтейл короткошерстний",
                    "en": "Karelian Bobtail Shorthair"
                }
            },
            {
                "id": "34_32",
                "slug": "celtic",
                "label": {
                    "ru": "Кельтская",
                    "uk": "Кельтська",
                    "en": "Celtic"
                }
            },
            {
                "id": "34_33",
                "slug": "kimrik",
                "label": {
                    "ru": "Кимрик",
                    "uk": "Кімрик",
                    "en": "Kymrik"
                }
            },
            {
                "id": "34_34",
                "slug": "kokoke",
                "label": {
                    "ru": "Кококе",
                    "uk": "Кококе",
                    "en": "Kokoke"
                }
            },
            {
                "id": "34_35",
                "slug": "colorpoint",
                "label": {
                    "ru": "Колорпоинт",
                    "uk": "Колорпоінт",
                    "en": "Colorpoint"
                }
            },
            {
                "id": "34_36",
                "slug": "korat",
                "label": {
                    "ru": "Корниш рекс",
                    "uk": "Корніш рекс",
                    "en": "Korat"
                }
            },
            {
                "id": "34_37",
                "slug": "kurilianBobtail",
                "label": {
                    "ru": "Курильский бобтейл",
                    "uk": "Курильський бобтейл",
                    "en": "Kurilian Bobtail"
                }
            },
            {
                "id": "34_38",
                "slug": "kurilianBobtailLonghair",
                "label": {
                    "ru": "Курильский бобтейл длинношерстный",
                    "uk": "Курильський бобтейл довгошерстний",
                    "en": "Kurilian Bobtail Longhair"
                }
            },
            {
                "id": "34_39",
                "slug": "kurilianBobtailShorthair",
                "label": {
                    "ru": "Курильский бобтейл короткошерстный",
                    "uk": "Курильський бобтейл короткошерстний",
                    "en": "Kurilian Bobtail Shorthair"
                }
            },
            {
                "id": "34_40",
                "slug": "lapermLonghair",
                "label": {
                    "ru": "Лаперм длинношерстный",
                    "uk": "Лаперм довгошерстний",
                    "en": "Laperm Longhair"
                }
            },
            {
                "id": "34_41",
                "slug": "lapermShorthair",
                "label": {
                    "ru": "Лаперм короткошерстный",
                    "uk": "Лаперм короткошерстний",
                    "en": "Laperm Shorthair"
                }
            },
            {
                "id": "34_42",
                "slug": "munchkinLonghair",
                "label": {
                    "ru": "Манчкин длинношерстный",
                    "uk": "Манчкін довгошерстний",
                    "en": "Munchkin Longhair"
                }
            },
            {
                "id": "34_43",
                "slug": "munchkinShorthair",
                "label": {
                    "ru": "Манчкин короткошерстная",
                    "uk": "Манчкін короткошерста",
                    "en": "Munchkin Shorthair"
                }
            },
            {
                "id": "34_44",
                "slug": "maineCoon",
                "label": {
                    "ru": "Мейн-кун",
                    "uk": "Мейн-кун",
                    "en": "Maine Coon"
                }
            },
            {
                "id": "34_45",
                "slug": "mekongBobtail",
                "label": {
                    "ru": "Меконгский бобтейл",
                    "uk": "Меконгський бобтейл",
                    "en": "Mekong Bobtail"
                }
            },
            {
                "id": "34_46",
                "slug": "minskin",
                "label": {
                    "ru": "Минскин",
                    "uk": "Мінскін",
                    "en": "Minskin"
                }
            },
            {
                "id": "34_47",
                "slug": "munchkin",
                "label": {
                    "ru": "Мэнкс",
                    "uk": "Менкс",
                    "en": "Manx"
                }
            },
            {
                "id": "34_48",
                "slug": "napoleon",
                "label": {
                    "ru": "Наполеон",
                    "uk": "Наполеон",
                    "en": "Napoleon"
                }
            },
            {
                "id": "34_49",
                "slug": "nevaMasquerade",
                "label": {
                    "ru": "Невская маскарадная",
                    "uk": "Невська маскарадна",
                    "en": "Neva Masquerade"
                }
            },
            {
                "id": "34_50",
                "slug": "germanRex",
                "label": {
                    "ru": "Немецкий рекс",
                    "uk": "Німецький рекс",
                    "en": "German Rex"
                }
            },
            {
                "id": "34_51",
                "slug": "nebelung",
                "label": {
                    "ru": "Нибелунг",
                    "uk": "Нібелунг",
                    "en": "Nebelung"
                }
            },
            {
                "id": "34_52",
                "slug": "norwegianForest",
                "label": {
                    "ru": "Норвежская лесная",
                    "uk": "Норвезька лісова",
                    "en": "Norwegian Forest"
                }
            },
            {
                "id": "34_53",
                "slug": "oregonRex",
                "label": {
                    "ru": "Орегон рекс",
                    "uk": "Орегон рекс",
                    "en": "Oregon Rex"
                }
            },
            {
                "id": "34_54",
                "slug": "orientalLonghair",
                "label": {
                    "ru": "Ориентальная длинношерстная",
                    "uk": "Орієнтальна довгошерста",
                    "en": "Oriental Longhair"
                }
            },
            {
                "id": "34_55",
                "slug": "orientalShorthair",
                "label": {
                    "ru": "Ориентальная короткошерстная",
                    "uk": "Орієнтальна короткошерста",
                    "en": "Oriental Shorthair"
                }
            },
            {
                "id": "34_56",
                "slug": "ochosAzules",
                "label": {
                    "ru": "Охос азулес",
                    "uk": "Охос азулес",
                    "en": "Ojos Azules"
                }
            },
            {
                "id": "34_57",
                "slug": "ochosAzulesLonghair",
                "label": {
                    "ru": "Охос азулес длинношерстный",
                    "uk": "Охос азулес довгошерстний",
                    "en": "Ojos Azules Longhair"
                }
            },
            {
                "id": "34_58",
                "slug": "ocicat",
                "label": {
                    "ru": "Оцикет",
                    "uk": "Оцікет",
                    "en": "Ocicat"
                }
            },
            {
                "id": "34_59",
                "slug": "persian",
                "label": {
                    "ru": "Персидская",
                    "uk": "Персидська",
                    "en": "Persian"
                }
            },
            {
                "id": "34_60",
                "slug": "peterbald",
                "label": {
                    "ru": "Петерболд",
                    "uk": "Петерболд",
                    "en": "Peterbald"
                }
            },
            {
                "id": "34_61",
                "slug": "pixiebobLonghair",
                "label": {
                    "ru": "Пиксибоб длинношерстный",
                    "uk": "Піксібоб довгошерстний",
                    "en": "Pixiebob Longhair"
                }
            },
            {
                "id": "34_62",
                "slug": "pixiebobShorthair",
                "label": {
                    "ru": "Пиксибоб короткошерстный",
                    "uk": "Піксібоб короткошерстний",
                    "en": "Pixiebob Shorthair"
                }
            },
            {
                "id": "34_63",
                "slug": "ragamuffin",
                "label": {
                    "ru": "Рагамаффин",
                    "uk": "Рагамаффін",
                    "en": "Ragamuffin"
                }
            },
            {
                "id": "34_64",
                "slug": "russianBlue",
                "label": {
                    "ru": "Русская голубая",
                    "uk": "Руська блакитна",
                    "en": "Russian Blue"
                }
            },
            {
                "id": "34_65",
                "slug": "ragdoll",
                "label": {
                    "ru": "Рэкдолл",
                    "uk": "Рагдолл",
                    "en": "Ragdoll"
                }
            },
            {
                "id": "34_66",
                "slug": "savannah",
                "label": {
                    "ru": "Саванна",
                    "uk": "Саванна",
                    "en": "Savannah"
                }
            },
            {
                "id": "34_67",
                "slug": "sarekgeti",
                "label": {
                    "ru": "Саренгети",
                    "uk": "Саренгеті",
                    "en": "Savannah"
                }
            },
            {
                "id": "34_68",
                "slug": "sacredBirman",
                "label": {
                    "ru": "Священная бирманская",
                    "uk": "Священна бірманська",
                    "en": "Sacred Birman"
                }
            },
            {
                "id": "34_69",
                "slug": "seychelloisLonghair",
                "label": {
                    "ru": "Сейшельская длинношерстная",
                    "uk": "Сейшельська довгошерстна",
                    "en": "Seychellois Longhair"
                }
            },
            {
                "id": "34_70",
                "slug": "seychelloisShorthair",
                "label": {
                    "ru": "Сейшельская короткошерстная",
                    "uk": "Сейшельська короткошерста",
                    "en": "Seychellois Shorthair"
                }
            },
            {
                "id": "34_71",
                "slug": "selkirkRexLonghair",
                "label": {
                    "ru": "Селкирк рекс длинношерстный",
                    "uk": "Селкірк рекс довгошерстний",
                    "en": "Selkirk Rex Longhair"
                }
            },
            {
                "id": "34_72",
                "slug": "selkirkRexShorthair",
                "label": {
                    "ru": "Селкирк рекс короткошерстный",
                    "uk": "Селкірк рекс короткошерстний",
                    "en": "Selkirk Rex Shorthair"
                }
            },
            {
                "id": "34_73",
                "slug": "siamese",
                "label": {
                    "ru": "Сиамская",
                    "uk": "Сіамська",
                    "en": "Siamese"
                }
            },
            {
                "id": "34_74",
                "slug": "siberian",
                "label": {
                    "ru": "Сибирская",
                    "uk": "Сибірська",
                    "en": "Siberian"
                }
            },
            {
                "id": "34_75",
                "slug": "singaporean",
                "label": {
                    "ru": "Сингапурская",
                    "uk": "Сингапурська",
                    "en": "Singaporean"
                }
            },
            {
                "id": "34_76",
                "slug": "scottishStraight",
                "label": {
                    "ru": "Скоттиш страйт",
                    "uk": "Скотіш стейт",
                    "en": "Scottish Straight"
                }
            },
            {
                "id": "34_77",
                "slug": "scottishFold",
                "label": {
                    "ru": "Скоттиш фолд",
                    "uk": "Скотіш фолд",
                    "en": "Scottish Fold"
                }
            },
            {
                "id": "34_78",
                "slug": "snowshoe",
                "label": {
                    "ru": "Сноу-Шу",
                    "uk": "Сноу-Шу",
                    "en": "Snowshoe"
                }
            },
            {
                "id": "34_79",
                "slug": "somali",
                "label": {
                    "ru": "Сомали",
                    "uk": "Сомалі",
                    "en": "Somali"
                }
            },
            {
                "id": "34_80",
                "slug": "sphinxCanadian",
                "label": {
                    "ru": "Сфинкс (канадский)",
                    "uk": "Сфінкс (канадський)",
                    "en": "Canadian Sphynx"
                }
            },
            {
                "id": "34_81",
                "slug": "thai",
                "label": {
                    "ru": "Тайская",
                    "uk": "Тайська",
                    "en": "Thai"
                }
            },
            {
                "id": "34_82",
                "slug": "toyger",
                "label": {
                    "ru": "Тойгер",
                    "uk": "Тойгер",
                    "en": "Toyger"
                }
            },
            {
                "id": "34_83",
                "slug": "tonkinese",
                "label": {
                    "ru": "Тонкинская",
                    "uk": "Тонкінська",
                    "en": "Tonkinese"
                }
            },
            {
                "id": "34_84",
                "slug": "turkishAngora",
                "label": {
                    "ru": "Турецкая ангора",
                    "uk": "Турецька ангора",
                    "en": "Turkish Angora"
                }
            },
            {
                "id": "34_85",
                "slug": "turkishVan",
                "label": {
                    "ru": "Турецкий ван",
                    "uk": "Турецький ван",
                    "en": "Turkish Van"
                }
            },
            {
                "id": "34_86",
                "slug": "ukrainianLevkoy",
                "label": {
                    "ru": "Украинский левкой",
                    "uk": "Український левкой",
                    "en": "Ukrainian Levkoy"
                }
            },
            {
                "id": "34_87",
                "slug": "uralRexLonghair",
                "label": {
                    "ru": "Уральский рекс длинношерстный",
                    "uk": "Уральський рекс довгошерстний",
                    "en": "Ural Rex Longhair"
                }
            },
            {
                "id": "34_88",
                "slug": "uralRexShorthair",
                "label": {
                    "ru": "Уральский рекс короткошерстный",
                    "uk": "Уральський рекс короткошерстний",
                    "en": "Ural Rex Shorthair"
                }
            },
            {
                "id": "34_89",
                "slug": "forinWhite",
                "label": {
                    "ru": "Форин Вайт",
                    "uk": "Форін Вайт",
                    "en": "Forin White"
                }
            },
            {
                "id": "34_90",
                "slug": "highlandFold",
                "label": {
                    "ru": "Хайленд фолд",
                    "uk": "Хайленд фолд",
                    "en": "Highland Fold"
                }
            },
            {
                "id": "34_91",
                "slug": "chantillyTiffany",
                "label": {
                    "ru": "Шантильи-тиффани",
                    "uk": "Шантильї-тиффані",
                    "en": "Chantilly Tiffany"
                }
            },
            {
                "id": "34_92",
                "slug": "chartreux",
                "label": {
                    "ru": "Шартрез",
                    "uk": "Шартрез",
                    "en": "Chartreux"
                }
            },
            {
                "id": "34_93",
                "slug": "aegean",
                "label": {
                    "ru": "Эгейская",
                    "uk": "Егейська",
                    "en": "Aegean"
                }
            },
            {
                "id": "34_94",
                "slug": "exotic",
                "label": {
                    "ru": "Экзотическая",
                    "uk": "Екзотична",
                    "en": "Exotic"
                }
            },
            {
                "id": "34_95",
                "slug": "javanese",
                "label": {
                    "ru": "Яванез",
                    "uk": "Яванез",
                    "en": "Javanese"
                }
            },
            {
                "id": "34_96",
                "slug": "japaneseBobtailLonghair",
                "label": {
                    "ru": "Японский бобтейл длинношерстный",
                    "uk": "Японський бобтейл довгошерстний",
                    "en": "Japanese Bobtail Longhair"
                }
            },
            {
                "id": "34_97",
                "slug": "japaneseBobtailShorthair",
                "label": {
                    "ru": "Японский бобтейл короткошерстный",
                    "uk": "Японський бобтейл короткошерстний",
                    "en": "Japanese Bobtail Shorthair"
                }
            }
        ]
    },
    {
        "id": "35",
        "slug": "motherhood",
        "label": {
            "ru": "Материнство",
            "uk": "Материнство",
            "en": "Motherhood"
        },
        "children": [
            {
                "id": "35_0",
                "slug": "motherhood",
                "label": {
                    "ru": "Материнство",
                    "uk": "Материнство",
                    "en": "Motherhood"
                }
            },
            {
                "id": "35_1",
                "slug": "childrensClothing",
                "label": {
                    "ru": "Детская одежда",
                    "uk": "Дитячий одяг",
                    "en": "Children's Clothing"
                }
            },
            {
                "id": "35_2",
                "slug": "childPsychology",
                "label": {
                    "ru": "Детская психология",
                    "uk": "Дитяча психологія",
                    "en": "Child Psychology"
                }
            },
            {
                "id": "35_3",
                "slug": "childrensBooks",
                "label": {
                    "ru": "Детские книги",
                    "uk": "Дитячі книги",
                    "en": "Children's Books"
                }
            },
            {
                "id": "35_4",
                "slug": "educationalGamesForChildren",
                "label": {
                    "ru": "Детские развивающие игры",
                    "uk": "Дитячі розвиваючі ігри",
                    "en": "Educational Games for Children"
                }
            },
            {
                "id": "35_5",
                "slug": "childrensLeisure",
                "label": {
                    "ru": "Детский досуг",
                    "uk": "Дитячий відпочинок",
                    "en": "Children's Leisure"
                }
            },
            {
                "id": "35_6",
                "slug": "childrensHealth",
                "label": {
                    "ru": "Детское здоровье",
                    "uk": "Дитяче здоров'я",
                    "en": "Children's Health"
                }
            },
            {
                "id": "35_7",
                "slug": "babyFood",
                "label": {
                    "ru": "Детское питание",
                    "uk": "Дитяче харчування",
                    "en": "Baby Food"
                }
            },
            {
                "id": "35_8",
                "slug": "developmentOfEmotionalIntelligenceInChildren",
                "label": {
                    "ru": "Развитие эмоционального интеллекта у детей",
                    "uk": "Розвиток емоційного інтелекту у дітей",
                    "en": "Development of Emotional Intelligence in Children"
                }
            },
            {
                "id": "35_9",
                "slug": "haircutsHairstylesForChildren",
                "label": {
                    "ru": "Стрижки, прически",
                    "uk": "Стрижки, зачіски",
                    "en": "Haircuts, Hairstyles"
                }
            }
        ]
    },
    {
        "id": "36",
        "slug": "Garder",
        "label": {
            "ru": "Сад и огород",
            "uk": "Сад і город",
            "en": "Garden and vegetable garden"
        },
        "children": [
            {
                "id": "36_0",
                "slug": "Garder",
                "label": {
                    "ru": "Сад и огород",
                    "uk": "Сад і город",
                    "en": "Garden and vegetable garden"
                }
            }
        ]
    },
    {
        "id": "37",
        "slug": "HauntingFishing",
        "label": {
            "ru": "Охота и Рыбалка",
            "uk": "Полювання та Риболовля",
            "en": "Hunting and Fishing"
        },
        "children": [
            {
                "id": "37_0",
                "slug": "HauntingFishing",
                "label": {
                    "ru": "Охота и Рыбалка",
                    "uk": "Полювання та Риболовля",
                    "en": "Hunting and Fishing"
                }
            },
            {
                "id": "37_1",
                "slug": "Haunting",
                "label": {
                    "ru": "Охота",
                    "uk": "Полювання",
                    "en": "Hunting"
                }
            },
            {
                "id": "37_2",
                "slug": "Fishing",
                "label": {
                    "ru": "Рыбалка",
                    "uk": "Риболовля",
                    "en": "Fishing"
                }
            }
        ]
    },
    {
        "id": "38",
        "slug": "businessAndEntrepreneurship",
        "label": {
            "ru": "Бизнес и предпринимательство",
            "uk": "Бізнес та підприємництво",
            "en": "Business and Entrepreneurship"
        },
        "children": [
            {
                "id": "38_0",
                "slug": "businessAndEntrepreneurship",
                "label": {
                    "ru": "Бизнес и предпринимательство",
                    "uk": "Бізнес та підприємництво",
                    "en": "Business and Entrepreneurship"
                }
            },
            {
                "id": "38_1",
                "slug": "handmade",
                "label": {
                    "ru": "Handmade",
                    "uk": "Ручна робота",
                    "en": "Handmade"
                }
            },
            {
                "id": "38_2",
                "slug": "it",
                "label": {
                    "ru": "IT",
                    "uk": "ІТ",
                    "en": "IT"
                }
            },
            {
                "id": "38_3",
                "slug": "seo",
                "label": {
                    "ru": "SEO",
                    "uk": "SEO",
                    "en": "SEO"
                }
            },
            {
                "id": "38_4",
                "slug": "smm",
                "label": {
                    "ru": "SMM",
                    "uk": "SMM",
                    "en": "SMM"
                }
            },
            {
                "id": "38_5",
                "slug": "businessForChildren",
                "label": {
                    "ru": "Бизнес для детей",
                    "uk": "Бізнес для дітей",
                    "en": "Business for Children"
                }
            },
            {
                "id": "38_6",
                "slug": "businessWithChina",
                "label": {
                    "ru": "Бизнес с Китаем",
                    "uk": "Бізнес з Китаєм",
                    "en": "Business with China"
                }
            },
            {
                "id": "38_7",
                "slug": "charitableFoundations",
                "label": {
                    "ru": "Благотворительные фонды",
                    "uk": "Благодійні фонди",
                    "en": "Charitable Foundations"
                }
            },
            {
                "id": "38_8",
                "slug": "beautyIndustry",
                "label": {
                    "ru": "Бьюти - сфера",
                    "uk": "Краса - сфера",
                    "en": "Beauty Industry"
                }
            },
            {
                "id": "38_9",
                "slug": "designAndTailoringOfClothes",
                "label": {
                    "ru": "Дизайн и пошив одежды",
                    "uk": "Дизайн та пошиття одягу",
                    "en": "Design and Tailoring of Clothes"
                }
            },
            {
                "id": "38_10",
                "slug": "interiorDesign",
                "label": {
                    "ru": "Дизайн интерьеров",
                    "uk": "Дизайн інтер'єрів",
                    "en": "Interior Design"
                }
            },
            {
                "id": "38_11",
                "slug": "dropshipping",
                "label": {
                    "ru": "Дропшипинг",
                    "uk": "Дропшипінг",
                    "en": "Dropshipping"
                }
            },
            {
                "id": "38_12",
                "slug": "investments",
                "label": {
                    "ru": "Инвестиции",
                    "uk": "Інвестиції",
                    "en": "Investments"
                }
            },
            {
                "id": "38_13",
                "slug": "artAndCreativity",
                "label": {
                    "ru": "Исскусство и творчество",
                    "uk": "Мистецтво та творчість",
                    "en": "Art and Creativity"
                }
            },
            {
                "id": "38_14",
                "slug": "cleaning",
                "label": {
                    "ru": "Клининг",
                    "uk": "Прибирання",
                    "en": "Cleaning"
                }
            },
            {
                "id": "38_15",
                "slug": "cosmetology",
                "label": {
                    "ru": "Косметология",
                    "uk": "Косметологія",
                    "en": "Cosmetology"
                }
            },
            {
                "id": "38_16",
                "slug": "consultingAccountingLegal",
                "label": {
                    "ru": "Консалтинг, бухгалтерия, юриспруденция",
                    "uk": "Консалтинг, бухгалтерія, юриспруденція",
                    "en": "Consulting, Accounting, Legal"
                }
            },
            {
                "id": "38_17",
                "slug": "cryptocurrency",
                "label": {
                    "ru": "Криптовалюта",
                    "uk": "Криптовалюта",
                    "en": "Cryptocurrency"
                }
            },
            {
                "id": "38_18",
                "slug": "marathons",
                "label": {
                    "ru": "Марафоны",
                    "uk": "Марафони",
                    "en": "Marathons"
                }
            },
            {
                "id": "38_19",
                "slug": "marketplaces",
                "label": {
                    "ru": "Маркетплейсы",
                    "uk": "Маркетплейси",
                    "en": "Marketplaces"
                }
            },
            {
                "id": "38_20",
                "slug": "medicalServices",
                "label": {
                    "ru": "Медицинские услуги",
                    "uk": "Медичні послуги",
                    "en": "Medical Services"
                }
            },
            {
                "id": "38_21",
                "slug": "realEstate",
                "label": {
                    "ru": "Недвижимость",
                    "uk": "Нерухомість",
                    "en": "Real Estate"
                }
            },
            {
                "id": "38_22",
                "slug": "nutrition",
                "label": {
                    "ru": "Нутрициология",
                    "uk": "Нутриціологія",
                    "en": "Nutrition"
                }
            },
            {
                "id": "38_23",
                "slug": "catering",
                "label": {
                    "ru": "Общепит",
                    "uk": "Громадське харчування",
                    "en": "Catering"
                }
            },
            {
                "id": "38_24",
                "slug": "onlineEducation",
                "label": {
                    "ru": "Онлайн - обучения",
                    "uk": "Онлайн - навчання",
                    "en": "Online Education"
                }
            },
            {
                "id": "38_25",
                "slug": "staffRecruitment",
                "label": {
                    "ru": "Подбор персонала",
                    "uk": "Підбір персоналу",
                    "en": "Staff Recruitment"
                }
            },
            {
                "id": "38_26",
                "slug": "production",
                "label": {
                    "ru": "Продюссирование",
                    "uk": "Продюсування",
                    "en": "Production"
                }
            },
            {
                "id": "38_27",
                "slug": "furnitureManufacturing",
                "label": {
                    "ru": "Производство мебели",
                    "uk": "Виробництво меблів",
                    "en": "Furniture Manufacturing"
                }
            },
            {
                "id": "38_28",
                "slug": "psychology",
                "label": {
                    "ru": "Психология",
                    "uk": "Психологія",
                    "en": "Psychology"
                }
            },
            {
                "id": "38_29",
                "slug": "animalBreeding",
                "label": {
                    "ru": "Разведение животных",
                    "uk": "Розведення тварин",
                    "en": "Animal Breeding"
                }
            },
            {
                "id": "38_30",
                "slug": "boardGameDevelopment",
                "label": {
                    "ru": "Разработка настольных игр",
                    "uk": "Розробка настільних ігор",
                    "en": "Board Game Development"
                }
            },
            {
                "id": "38_31",
                "slug": "advertisingPromotion",
                "label": {
                    "ru": "Реклама, продвижение",
                    "uk": "Реклама, просування",
                    "en": "Advertising Promotion"
                }
            },
            {
                "id": "38_32",
                "slug": "repairAndConstruction",
                "label": {
                    "ru": "Ремонт и строительство",
                    "uk": "Ремонт і будівництво",
                    "en": "Repair and Construction"
                }
            },
            {
                "id": "38_33",
                "slug": "selfDevelopment",
                "label": {
                    "ru": "Саморазвитие",
                    "uk": "Саморозвиток",
                    "en": "Self Development"
                }
            },
            {
                "id": "38_34",
                "slug": "startups",
                "label": {
                    "ru": "Стартапы",
                    "uk": "Стартапи",
                    "en": "Startups"
                }
            },
            {
                "id": "38_35",
                "slug": "stylingAndFashion",
                "label": {
                    "ru": "Стилистика и мода",
                    "uk": "Стилістика та мода",
                    "en": "Styling and Fashion"
                }
            },
            {
                "id": "38_36",
                "slug": "transportAndLogistics",
                "label": {
                    "ru": "Транспорт и логистика",
                    "uk": "Транспорт і логістика",
                    "en": "Transport and Logistics"
                }
            },
            {
                "id": "38_37",
                "slug": "tourism",
                "label": {
                    "ru": "Туризм",
                    "uk": "Туризм",
                    "en": "Tourism"
                }
            },
            {
                "id": "38_38",
                "slug": "goodsAndServicesForAnimals",
                "label": {
                    "ru": "Услуги и товары для животных",
                    "uk": "Послуги та товари для тварин",
                    "en": "Goods and Services for Animals"
                }
            },
            {
                "id": "38_39",
                "slug": "farming",
                "label": {
                    "ru": "Фермерство",
                    "uk": "Фермерство",
                    "en": "Farming"
                }
            },
            {
                "id": "38_40",
                "slug": "photoAndVideo",
                "label": {
                    "ru": "Фото и видео",
                    "uk": "Фото та відео",
                    "en": "Photo and Video"
                }
            },
            {
                "id": "38_41",
                "slug": "franchises",
                "label": {
                    "ru": "Франшизы",
                    "uk": "Франшизи",
                    "en": "Franchises"
                }
            },
            {
                "id": "38_42",
                "slug": "esoterics",
                "label": {
                    "ru": "Эзотерика",
                    "uk": "Езотерика",
                    "en": "Esoterics"
                }
            },
            {
                "id": "38_43",
                "slug": "ecoProducts",
                "label": {
                    "ru": "ЭКО-товары",
                    "uk": "ЕКО-товари",
                    "en": "Eco Products"
                }
            },
            {
                "id": "38_44",
                "slug": "languageCourses",
                "label": {
                    "ru": "Языковые курсы",
                    "uk": "Мовні курси",
                    "en": "Language Courses"
                }
            }
        ]
    },
    {
        "id": "39",
        "slug": "volonteer",
        "label": {
            "ru": "Волонтерство",
            "uk": "Волонтерство",
            "en": "Volunteering"
        },
        "children": [
            {
                "id": "39_1",
                "slug": "volonteer",
                "label": {
                    "ru": "Волонтерство",
                    "uk": "Волонтерство",
                    "en": "Volunteering"
                }
            }
        ]
    },
    {
        "id": "40",
        "slug": "footballClubs",
        "label": {
            "ru": "Футбольные клубы",
            "uk": "Футбольні клуби",
            "en": "Football Clubs"
        },
        "children": [
            {
                "id": "40_0",
                "slug": "footballClubs",
                "label": {
                    "ru": "Футбольные клубы",
                    "uk": "Футбольні клуби",
                    "en": "Football Clubs"
                }
            },
            {
                "id": "40_1",
                "slug": "bayern",
                "label": {
                    "ru": "Бавария",
                    "uk": "Баварія",
                    "en": "Bavaria"
                }
            },
            {
                "id": "40_2",
                "slug": "barcelona",
                "label": {
                    "ru": "Барселона",
                    "uk": "Барселона",
                    "en": "Barcelona"
                }
            },
            {
                "id": "40_3",
                "slug": "dynamo",
                "label": {
                    "ru": "Динамо",
                    "uk": "Динамо",
                    "en": "Dynamo"
                }
            },
            {
                "id": "40_4",
                "slug": "zenit",
                "label": {
                    "ru": "Зенит",
                    "uk": "Зеніт",
                    "en": "Zenit"
                }
            },
            {
                "id": "40_5",
                "slug": "kryliaSovetov",
                "label": {
                    "ru": "Крылья Советов",
                    "uk": "Крила Рад",
                    "en": "Wings of the Soviets"
                }
            },
            {
                "id": "40_6",
                "slug": "lokomotiv",
                "label": {
                    "ru": "Локомотив",
                    "uk": "Локомотив",
                    "en": "Lokomotiv"
                }
            },
            {
                "id": "40_7",
                "slug": "manchesterUnited",
                "label": {
                    "ru": "Манчестер Юнайтед",
                    "uk": "Манчестер Юнайтед",
                    "en": "Manchester United"
                }
            },
            {
                "id": "40_8",
                "slug": "marseille",
                "label": {
                    "ru": "Марсель",
                    "uk": "Марсель",
                    "en": "Marseille"
                }
            },
            {
                "id": "40_9",
                "slug": "milan",
                "label": {
                    "ru": "Милан",
                    "uk": "Мілан",
                    "en": "Milan"
                }
            },
            {
                "id": "40_10",
                "slug": "parisSaintGermain",
                "label": {
                    "ru": "Пари Сен-Жермен",
                    "uk": "Пари Сен-Жермен",
                    "en": "Paris Saint-Germain"
                }
            },
            {
                "id": "40_11",
                "slug": "realMadrid",
                "label": {
                    "ru": "Реал Мадрид",
                    "uk": "Реал Мадрид",
                    "en": "Real Madrid"
                }
            },
            {
                "id": "40_12",
                "slug": "rostov",
                "label": {
                    "ru": "Ростов",
                    "uk": "Ростов",
                    "en": "Rostov"
                }
            },
            {
                "id": "40_13",
                "slug": "spartak",
                "label": {
                    "ru": "Спартак",
                    "uk": "Спартак",
                    "en": "Spartak"
                }
            },
            {
                "id": "40_14",
                "slug": "torpedo",
                "label": {
                    "ru": "Торпедо",
                    "uk": "Торпедо",
                    "en": "Torpedo"
                }
            },
            {
                "id": "40_15",
                "slug": "fakel",
                "label": {
                    "ru": "Факел",
                    "uk": "Факел",
                    "en": "Fakel"
                }
            },
            {
                "id": "40_16",
                "slug": "fenerbahce",
                "label": {
                    "ru": "Фенербахче",
                    "uk": "Фенербахче",
                    "en": "Fenerbahçe"
                }
            },
            {
                "id": "40_17",
                "slug": "cska",
                "label": {
                    "ru": "ЦСКА",
                    "uk": "ЦСКА",
                    "en": "CSKA"
                }
            },
            {
                "id": "40_18",
                "slug": "chelsea",
                "label": {
                    "ru": "Челси",
                    "uk": "Челсі",
                    "en": "Chelsea"
                }
            },
            {
                "id": "40_19",
                "slug": "juventus",
                "label": {
                    "ru": "Ювентус",
                    "uk": "Ювентус",
                    "en": "Juventus"
                }
            }
        ]
    },
    {
        "id": "41",
        "slug": "collecting",
        "label": {
            "ru": "Коллекционирование",
            "uk": "Колекціонування",
            "en": "Collecting"
        },
        "children": [
            {
                "id": "41_0",
                "slug": "collecting",
                "label": {
                    "ru": "Коллекционирование",
                    "uk": "Колекціонування",
                    "en": "Collecting"
                }
            },
            {
                "id": "41_1",
                "slug": "barbiphilia",
                "label": {
                    "ru": "Барбифилия",
                    "uk": "Барбіфілія",
                    "en": "Barbiphilia"
                }
            },
            {
                "id": "41_2",
                "slug": "vectorism",
                "label": {
                    "ru": "Вектуризм",
                    "uk": "Векторизм",
                    "en": "Vectorism"
                }
            },
            {
                "id": "41_3",
                "slug": "cactusophilia",
                "label": {
                    "ru": "Кактусофилия",
                    "uk": "Кактусофілія",
                    "en": "Cactusophilia"
                }
            },
            {
                "id": "41_4",
                "slug": "kindophilia",
                "label": {
                    "ru": "Киндерфилия",
                    "uk": "Кіндерфілія",
                    "en": "Kindophilia"
                }
            },
            {
                "id": "41_5",
                "slug": "bookophilia",
                "label": {
                    "ru": "Книгофилия",
                    "uk": "Книгофілія",
                    "en": "Bookophilia"
                }
            },
            {
                "id": "41_6",
                "slug": "artCollecting",
                "label": {
                    "ru": "Коллекционирование предметов исскуства",
                    "uk": "Колекціонування предметів мистецтва",
                    "en": "Art Collecting"
                }
            },
            {
                "id": "41_7",
                "slug": "collectingVariousGroups",
                "label": {
                    "ru": "Коллекционирование разных групп",
                    "uk": "Колекціонування різних груп",
                    "en": "Collecting Various Groups"
                }
            },
            {
                "id": "41_8",
                "slug": "loteristics",
                "label": {
                    "ru": "Лотеристика",
                    "uk": "Лотеристика",
                    "en": "Loteristics"
                }
            },
            {
                "id": "41_9",
                "slug": "memomagnetism",
                "label": {
                    "ru": "Мемомагнетика",
                    "uk": "Мемомагнетика",
                    "en": "Memomagnetism"
                }
            },
            {
                "id": "41_10",
                "slug": "numismatics",
                "label": {
                    "ru": "Нумизматика",
                    "uk": "Нумізматика",
                    "en": "Numismatics"
                }
            },
            {
                "id": "41_11",
                "slug": "saculmistics",
                "label": {
                    "ru": "Сакулумистика",
                    "uk": "Сакулумістика",
                    "en": "Saculmistics"
                }
            },
            {
                "id": "41_12",
                "slug": "scripophilia",
                "label": {
                    "ru": "Скрипофилия",
                    "uk": "Скрипофілія",
                    "en": "Scripophilia"
                }
            },
            {
                "id": "41_13",
                "slug": "stilophilia",
                "label": {
                    "ru": "Стилофилия",
                    "uk": "Стилофілія",
                    "en": "Stilophilia"
                }
            },
            {
                "id": "41_14",
                "slug": "tokenophilia",
                "label": {
                    "ru": "Токенофилия",
                    "uk": "Токенофілія",
                    "en": "Tokenophilia"
                }
            },
            {
                "id": "41_15",
                "slug": "phaleristics",
                "label": {
                    "ru": "Фалеристика",
                    "uk": "Фалеристика",
                    "en": "Phaleristics"
                }
            },
            {
                "id": "41_16",
                "slug": "philately",
                "label": {
                    "ru": "Филателия",
                    "uk": "Філателія",
                    "en": "Philately"
                }
            },
            {
                "id": "41_17",
                "slug": "philobutonistics",
                "label": {
                    "ru": "Филобутонистика",
                    "uk": "Філобутонистика",
                    "en": "Philobutonistics"
                }
            },
            {
                "id": "41_18",
                "slug": "philography",
                "label": {
                    "ru": "Филография",
                    "uk": "Філографія",
                    "en": "Philography"
                }
            },
            {
                "id": "41_19",
                "slug": "philokartia",
                "label": {
                    "ru": "Филокартия",
                    "uk": "Філокартія",
                    "en": "Philokartia"
                }
            },
            {
                "id": "41_20",
                "slug": "exonumia",
                "label": {
                    "ru": "Экзонумия",
                    "uk": "Екзонумія",
                    "en": "Exonumia"
                }
            },
            {
                "id": "41_21",
                "slug": "exlibrism",
                "label": {
                    "ru": "Экслибристика",
                    "uk": "Екслибристика",
                    "en": "Exlibrism"
                }
            },
            {
                "id": "41_22",
                "slug": "errorism",
                "label": {
                    "ru": "Эрроризм",
                    "uk": "Ероризм",
                    "en": "Errorism"
                }
            }
        ]
    }
]