import { useMemo } from 'react'

import { ChatSvg, FeedSvg, HomeSvg, SearchSvg } from '@assets/icons'
import { isIOS } from '@components/utils'
import { useWebStatusBarColor } from '@hooks'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { FeedScreen, MainScreen, MyTelegram, Search } from '@screens'

const inactiveColor = 'rgb(182, 182, 182)'
const activeColor = 'rgb(39, 39, 39)'

const Tab = createBottomTabNavigator()

export const TabNavigator = () => {
  useWebStatusBarColor()

  const tabsList = [
    { Component: MainScreen, Icon: HomeSvg, name: 'Main' },
    { Component: FeedScreen, Icon: FeedSvg, name: 'Feed' },
    { Component: Search, Icon: SearchSvg, name: 'Search' },
    { Component: MyTelegram, Icon: ChatSvg, name: 'Message' }
  ]

  const tabs = useMemo(
    () =>
      tabsList.map(({ name, Component, Icon }) => (
        <Tab.Screen
          key={name}
          name={name}
          component={Component}
          options={{
            tabBarIcon: ({ focused }) => <Icon width={24} height={24} fill={focused ? activeColor : inactiveColor} />
          }}
        />
      )),
    []
  )

  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarShowLabel: false,
        tabBarStyle: {
          backgroundColor: '#fff',
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          height: '100%',
          maxHeight: isIOS ? 90 : 60,
          width: '100%'
        }
      }}
      initialRouteName='Message'
      sceneContainerStyle={{
        backgroundColor: '#f3f2f9'
      }}
    >
      {tabs}
    </Tab.Navigator>
  )
}
