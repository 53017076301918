import { useState } from 'react'
import { StyleSheet, TextStyle } from 'react-native'
import { DefaultTheme, Text, TextInput } from 'react-native-paper'

const styles = StyleSheet.create({
  text: {
    textAlign: 'right'
  },
  textInput: {
    backgroundColor: '#F3F2F9',
    borderRadius: 29,
    height: 211
  }
})

interface TextAreaProps {
  text: string
  setText: (t: string) => void
  placeholder?: string
  style?: TextStyle
}

export const TextArea = ({ text, setText, placeholder, style }: TextAreaProps) => {
  const [isTextAreaFocused, setTextAreaFocused] = useState(false)

  const handleTextChange = (value: string) => {
    setText(value)
  }

  const handleTextAreaFocus = () => {
    setTextAreaFocused(true)
  }

  const handleTextAreaBlur = () => {
    setTextAreaFocused(false)
  }

  const opacityStyle = { opacity: isTextAreaFocused ? 1 : 0.3 }

  const inputTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: '#2F2F2F'
    },
    roundness: 29
  }

  return (
    <>
      <TextInput
        mode='outlined'
        label=''
        multiline
        numberOfLines={4}
        maxLength={250}
        placeholder={placeholder || 'Введите текст'}
        onChangeText={handleTextChange}
        value={text}
        theme={inputTheme}
        onFocus={handleTextAreaFocus}
        onBlur={handleTextAreaBlur}
        style={[styles.textInput, opacityStyle, style]}
      />
      <Text style={[styles.text, opacityStyle]}>{`${text.length}/250`}</Text>
    </>
  )
}
