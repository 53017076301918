import {
  GET_FRIENDS_CHANNELS_MESSAGES_REQUEST,
  GET_FRIENDS_CHANNELS_MESSAGES_SUCCESS,
  GetFriendsChannelsMessagesRequestAction,
  GetFriendsChannelsMessagesSuccessAction
} from '@redux/types'
import { ChannelMessage } from '@types'

export const getFriendsChannelsMessagesRequest = (chatUserId: number): GetFriendsChannelsMessagesRequestAction => ({
  payload: { chatUserId },
  type: GET_FRIENDS_CHANNELS_MESSAGES_REQUEST
})

export const getFriendsChannelsMessagesSuccess = (
  posts: ChannelMessage[]
): GetFriendsChannelsMessagesSuccessAction => ({
  payload: posts,
  type: GET_FRIENDS_CHANNELS_MESSAGES_SUCCESS
})
