import { useEffect, useState } from 'react'
import { KeyboardAvoidingView, StyleSheet, Text, View } from 'react-native'
import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { Button, HeaderInfo, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCodeStatus } from '@redux/selectors'

const styles = StyleSheet.create({
  cell: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    fontSize: 27,
    fontWeight: '400',
    height: 40,
    lineHeight: 38,
    textAlign: 'center',
    width: 48
  },
  codeFieldRoot: {
    marginTop: 40
  },
  container: {
    flex: 1,
    height: '100%',
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: 50
  },
  focusCell: {
    borderBottomColor: '#c2c2c2',
    borderBottomWidth: 2
  }
})

const CELL_COUNT = 6

export const EnterCode = () => {
  const { route, navigation } = useNavigator<'EnterCode'>()
  const phone = route.params.phone
  const change = route.params?.change

  const [redirect, setRedirect] = useState(false)
  const [value, setValue] = useState('')
  const [seconds, setSeconds] = useState(120)

  const { checkCode, registration } = useDataLayer()
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({ setValue, value })
  const checkCodeStatus = useSelector(selectCodeStatus)
  const ref = useBlurOnFulfill({ cellCount: CELL_COUNT, value })

  const goToCreatePassword = () => {
    navigation.navigate('CreatePassword', { change, phone })
  }

  useEffect(() => {
    if (!seconds) {
      return
    }

    const timerId = setTimeout(() => setSeconds(seconds - 1), 1000)
    return () => clearTimeout(timerId)
  }, [seconds])

  const sendRequestAndRestartTimer = () => {
    if (seconds) {
      return
    }

    registration({ phone })
    setSeconds(120)
  }

  useEffect(() => {
    if (redirect && checkCodeStatus && checkCodeStatus === 'OK') {
      goToCreatePassword()
    }
  }, [checkCodeStatus, redirect])

  const renderCell = ({ index, symbol, isFocused }: { index: number; symbol: string; isFocused: boolean }) => (
    <View style={styles.cell} key={`${symbol}-${index}`}>
      <Text style={[styles.cell, isFocused && styles.focusCell]} onLayout={getCellOnLayoutHandler(index)}>
        {symbol || (isFocused ? <Cursor /> : null)}
      </Text>
    </View>
  )

  return (
    <View style={styles.container}>
      <HeaderInfo />
      <KeyboardAvoidingView behavior={isIOS ? 'padding' : undefined} style={{ flex: 1 }}>
        <Typography f27 bold marginBottom={8} marginTop={52}>
          Введите код
        </Typography>
        <Typography f15 normal color='#535354' marginBottom={24}>
          Код отправлен на номер {phone}
        </Typography>
        <CodeField
          ref={ref}
          {...props}
          value={value}
          onChangeText={setValue}
          cellCount={CELL_COUNT}
          rootStyle={styles.codeFieldRoot}
          keyboardType='number-pad'
          textContentType='oneTimeCode'
          renderCell={renderCell}
        />
        <View style={{ flexGrow: 1 }} />

        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Typography
            marginBottom={16}
            color={seconds === 0 ? '2c2c2c' : 'b7b7bb'}
            onPress={seconds === 0 ? sendRequestAndRestartTimer : undefined}
          >
            <Typography f15 normal>
              {moment.utc(seconds * 1000).format('m:ss')}{' '}
            </Typography>
            <Typography f15 normal style={{ textDecorationLine: 'underline' }}>
              Не пришел код?
            </Typography>
          </Typography>
        </View>

        <Button
          text='Продолжить'
          onPress={() => {
            checkCode(phone, value)
            setRedirect(true)
          }}
          disabled={value.length !== 6}
        />
      </KeyboardAvoidingView>
    </View>
  )
}
