import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import uniqBy from 'lodash.uniqby'

import { useChatList } from '@hooks'
import { useDataLayer } from '@redux/dataLayer'
import { selectAllDeletedUsers, selectUserChatId } from '@redux/selectors'

export const useMatchedUsers = () => {
  const { chats } = useChatList()
  const { getAllDeletedUsers } = useDataLayer()
  const userChatId = useSelector(selectUserChatId)
  const deletedUsers = useSelector(selectAllDeletedUsers)

  useEffect(() => {
    getAllDeletedUsers()
  }, [])

  const matchedUsers = useMemo(() => {
    const chatParticipants = chats
      .map(_chat => _chat.participants)
      .flat(1)
      .filter(
        user =>
          user.user_id !== userChatId && !deletedUsers.some(deletedUser => deletedUser.userChatId === user.user_id)
      )

    return uniqBy(chatParticipants, 'user_id')
  }, [chats])

  return { matchedUsers }
}
