export { default as BasketBlockSvg } from './basketBlock.svg'
export { default as BusinessBlockSvg } from './businessBlock.svg'
export { default as DocumentBlockSvg } from './documentBlock.svg'
export { default as ExitBlockSvg } from './exitBlock.svg'
export { default as FriendsBlockSvg } from './friendsBlock.svg'
export { default as LockBlockSvg } from './lockBlock.svg'
export { default as LoveBlockSvg } from './loveBlock.svg'
export { default as SexBlockSvg } from './sexBlock.svg'
export { default as ShareBlockSvg } from './shareBlock.svg'
export { default as TranslateBlockSvg } from './translateBlock.svg'
export { default as WriteBlockSvg } from './writeBlock.svg'
