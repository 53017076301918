import { useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet, ViewStyle } from 'react-native'
import * as Facebook from 'expo-auth-session/providers/facebook'
import * as WebBrowser from 'expo-web-browser'

import { Button } from '@components'
import { AntDesign } from '@expo/vector-icons'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { SocialAuth, SocialProvider } from '@types'

const FACEBOOK_APP_ID = '1155855639086162'

WebBrowser.maybeCompleteAuthSession()

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#fff',
    paddingHorizontal: 0,
    width: 60
  }
})

interface FacebookAuthButtonProps {
  mode?: 'login' | 'connect'
  isDisabled?: boolean
  buttonStyles?: ViewStyle
}

export const FacebookAuthButton = ({ mode = 'login', isDisabled, buttonStyles }: FacebookAuthButtonProps) => {
  const [isAuthInProgress, setIsAuthInProgress] = useState(false)
  const { registration, connectSocial } = useDataLayer()
  const { navigation } = useNavigator()
  const [, response, promptAsync] = Facebook.useAuthRequest({
    clientId: FACEBOOK_APP_ID
  })

  const onPress = () => {
    setIsAuthInProgress(true)
    promptAsync()
  }

  const handleSignIn = () => {
    setIsAuthInProgress(false)

    if (response?.type !== 'success' || !response?.authentication) {
      return
    }

    const params: SocialAuth = { provider: SocialProvider.FACEBOOK, token: response.authentication.accessToken }

    if (mode === 'connect') {
      connectSocial(params)
      return
    }

    registration({ socialAuth: params }, () => navigation.navigate('Tabs'))
  }

  useEffect(() => {
    if (response) {
      handleSignIn()
    }
  }, [response])

  const Icon = <AntDesign name='facebook-square' size={30} color='black' />

  if (isDisabled) {
    return (
      <Button isView style={[styles.button, buttonStyles]}>
        {Icon}
      </Button>
    )
  }

  return (
    <Button onPress={onPress} style={[styles.button, buttonStyles]}>
      {isAuthInProgress ? <ActivityIndicator /> : Icon}
    </Button>
  )
}
