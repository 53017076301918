import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'

import { FeedPosts, FeedPostWithComments, InputMessage, ModalWrapper, SButton, Typography } from '@components'
import { useDataLayer } from '@redux/dataLayer'
import { selectChannel, selectCurrentUserId, selectFeed, selectUserChatId } from '@redux/selectors'
import { ChannelMessage, UserProfile } from '@types'

export const POST_PADDING = 18

const styles = StyleSheet.create({
  input: {
    bottom: 0,
    position: 'absolute'
  },
  option: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
    padding: 18,
    width: '100%'
  }
})
interface FeedProps {
  user?: UserProfile | null
  isFriendsFeed?: boolean
}

export const Feed = ({ user, isFriendsFeed }: FeedProps) => {
  const { getChannelByUserId, getFriendsChannelsMessages, deleteMessages } = useDataLayer()
  const [selectedPost, setSelectedPost] = useState<ChannelMessage | undefined>()
  const [posts, setPosts] = useState<ChannelMessage[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  const channel = useSelector(selectChannel)
  const feed = useSelector(selectFeed)
  const insets = useSafeAreaInsets()
  const currentUserId = useSelector(selectCurrentUserId)
  const currentUserChatId = useSelector(selectUserChatId)

  const showInput = useMemo(() => {
    if (channel) {
      return user?._id === currentUserId
    }

    return !!selectedPost
  }, [channel, selectedPost])

  useLayoutEffect(() => {
    if (user) {
      getChannelByUserId(user.userChatId)
    }

    if (isFriendsFeed) {
      getFriendsChannelsMessages(currentUserChatId)
    }
  }, [])

  useEffect(() => {
    setPosts((isFriendsFeed ? feed : channel?.history) || [])
  }, [isFriendsFeed, feed, channel])

  const onPostPress = useCallback((post?: ChannelMessage) => {
    setSelectedPost(post?.message_id ? post : undefined)
  }, [])

  const onPostLongPress = useCallback((post: ChannelMessage) => {
    setIsModalVisible(true)
    setSelectedPost(post)
  }, [])

  const deletePost = useCallback(() => {
    setSelectedPost(undefined)
    setIsModalVisible(false)

    if (selectedPost && channel) {
      deleteMessages({ chat_id: channel.id, messages: [selectedPost?.message_id] })
    }
  }, [selectedPost, channel])

  const headerInfo = useMemo(() => {
    if (!user) {
      return
    }

    return {
      avatar: user.profileImage?.[0].name,
      name: user.name
    }
  }, [user])

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={showInput && { paddingBottom: insets.bottom + 40 }}
      >
        {selectedPost ? (
          <FeedPostWithComments onPress={onPostPress} postId={selectedPost.message_id} />
        ) : (
          <FeedPosts
            headerInfo={headerInfo}
            posts={posts}
            onPostPress={onPostPress}
            onPostLongPress={onPostLongPress}
          />
        )}
      </ScrollView>
      {showInput && (
        <InputMessage
          style={{ ...styles.input, paddingBottom: showInput && insets.bottom }}
          userChatId={user?.userChatId || currentUserChatId}
          chatId={selectedPost?.chat_id || channel?.id}
          selectedMessageId={selectedPost?.message_id}
          placeholder={selectedPost ? 'Оставить комментарий...' : 'Новый пост...'}
        />
      )}
      <ModalWrapper isVisible={isModalVisible} close={() => setIsModalVisible(false)}>
        <SButton styleBtn={styles.option} onPress={deletePost}>
          <Typography f16 style={{ color: '#F64A3F', fontWeight: '700' }}>
            Удалить
          </Typography>
        </SButton>
      </ModalWrapper>
    </>
  )
}
