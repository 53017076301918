import { ProfileOptions } from '@utils'

export { GENRES, GenreTypes } from './ArtGenres'
export { HOBBIES } from './Hobbies'
export { LANG_HOBBIES } from './LangHobbies'
export { RELIGION } from './Religion'
export * from './Status'
export * from './types'
export { VALUABLES } from './Valuables'

export const LANGUAGES: ProfileOptions = [
  { id: 0, label: 'Английский', slug: 'english' },
  { id: 1, label: 'Русский', slug: 'russian' },
  { id: 2, label: 'Украинский', slug: 'ukrainian' },
  { id: 3, label: 'Испанский', slug: 'spanish' },
  { id: 4, label: 'Китайский', slug: 'chinese' },
  { id: 5, label: 'Арабский', slug: 'arabic' },
  // Убедитесь, что нет дублирования идентификаторов
  { id: 6, label: 'Хинди', slug: 'hindi' },
  { id: 7, label: 'Бенгальский', slug: 'bengali' },
  { id: 8, label: 'Португальский', slug: 'portuguese' },
  { id: 9, label: 'Французский', slug: 'french' },
  { id: 10, label: 'Немецкий', slug: 'german' },
  { id: 11, label: 'Японский', slug: 'japanese' },
  { id: 12, label: 'Корейский', slug: 'korean' },
  { id: 13, label: 'Турецкий', slug: 'turkish' },
  { id: 14, label: 'Итальянский', slug: 'italian' },
  { id: 15, label: 'Польский', slug: 'polish' },
  { id: 16, label: 'Голландский', slug: 'dutch' },
  { id: 17, label: 'Тайский', slug: 'thai' },
  { id: 18, label: 'Вьетнамский', slug: 'vietnamese' },
  { id: 19, label: 'Персидский', slug: 'persian' },
  { id: 20, label: 'Румынский', slug: 'romanian' },
  { id: 21, label: 'Малайский', slug: 'malay' },
  { id: 22, label: 'Индонезийский', slug: 'indonesian' },
  { id: 23, label: 'Чешский', slug: 'czech' },
  { id: 24, label: 'Греческий', slug: 'greek' },
  { id: 25, label: 'Датский', slug: 'danish' },
  { id: 26, label: 'Шведский', slug: 'swedish' },
  { id: 27, label: 'Норвежский', slug: 'norwegian' },
  { id: 28, label: 'Венгерский', slug: 'hungarian' },
  { id: 29, label: 'Финский', slug: 'finnish' },
  { id: 30, label: 'Словацкий', slug: 'slovak' },
  { id: 31, label: 'Иврит', slug: 'hebrew' },
  { id: 32, label: 'Филиппинский', slug: 'filipino' },
  { id: 33, label: 'Сербский', slug: 'serbian' },
  { id: 34, label: 'Хорватский', slug: 'croatian' },
  { id: 35, label: 'Болгарский', slug: 'bulgarian' },
  { id: 36, label: 'Литовский', slug: 'lithuanian' },
  { id: 37, label: 'Словенский', slug: 'slovenian' },
  { id: 38, label: 'Латышский', slug: 'latvian' },
  { id: 39, label: 'Эстонский', slug: 'estonian' },
  { id: 40, label: 'Суахили', slug: 'swahili' },
  { id: 41, label: 'Урду', slug: 'urdu' },
  { id: 42, label: 'Пенджаби', slug: 'punjabi' },
  { id: 43, label: 'Амхарский', slug: 'amharic' },
  { id: 44, label: 'Непальский', slug: 'nepali' },
  { id: 45, label: 'Маратхи', slug: 'marathi' },
  { id: 46, label: 'Телугу', slug: 'telugu' },
  { id: 47, label: 'Тамильский', slug: 'tamil' },
  { id: 48, label: 'Гуджарати', slug: 'gujarati' },
  { id: 49, label: 'Каннада', slug: 'kannada' },
  { id: 50, label: 'Малаялам', slug: 'malayalam' }
]

export const EDUCATION: ProfileOptions = [
  { id: 0, label: 'Докторская', slug: 'doctoral' },
  { id: 1, label: 'Высшее', slug: 'higher' },
  { id: 2, label: 'Не оконченное высшее', slug: 'incompleteHigher' },
  { id: 3, label: 'Среднее', slug: 'secondary' },
  { id: 4, label: 'Без образования', slug: 'noEducation' }
]

export const FINANCIAL: ProfileOptions = [
  { id: 0, label: 'Все не очень', slug: 'bad' },
  { id: 1, label: 'На жизнь хватает', slug: 'enoughForLiving' },
  { id: 2, label: 'Выше среднего', slug: 'moreAV' },
  { id: 3, label: 'Хорошо зарабатываю', slug: 'good' },
  { id: 4, label: 'Богат(а)', slug: 'rich' }
]

export const ORIENTATION: ProfileOptions = [
  { id: 1, label: 'Гетеро', slug: 'hetero' },
  { id: 2, label: 'Би', slug: 'bi' },
  { id: 3, label: 'Гомо', slug: 'homo' },
  { id: 4, label: 'Другое', slug: 'otherOrientation' }
]
export const LGBT_TOLERANT: ProfileOptions = [
  { id: 1, label: 'Поддерживаю', slug: 'iSupportLGBT' },
  { id: 2, label: 'Нейтральное', slug: 'lGBTIsNeutral' },
  { id: 3, label: 'Негативное', slug: 'againstLGBT' }
]

export const GOALS: ProfileOptions = [
  { id: 1, label: 'Дружба, друзья по интересам', slug: 'meetFriends' },
  { id: 2, label: 'Любовь-брак', slug: 'meetLove' },
  {
    children: [
      { id: 4, label: 'Я спонсор', slug: 'beSponsor' },
      { id: 5, label: 'Ищу спонсора', slug: 'findSponsor' }
    ],
    id: 3,
    label: 'Отношения по расчету',
    slug: 'commercial'
  },
  { id: 6, label: 'Бизнес партнерство', slug: 'findBusinessPartners' },
  { id: 7, label: 'Свободные отношения', slug: 'sexyAdventures' }
]

export const RELATIONSHIP: ProfileOptions = [
  { id: 0, label: 'Замужем/Женат', slug: 'married/Married' },
  { id: 1, label: 'В отношениях', slug: 'inARelationship' },
  { id: 2, label: 'Свободен', slug: 'single' }
]

export const CHILDREN: ProfileOptions = [
  { id: 0, label: 'Есть', slug: 'haveChildren' },
  { id: 1, label: 'Нет, но хочу', slug: 'NoChildrenButWant' },
  { id: 2, label: 'Нет и не хочу', slug: 'NoChildrenAndDoNotWantTo' }
]

export const SEX: ProfileOptions = [
  { id: 'male', label: 'Мужчина', slug: 'man' },
  { id: 'female', label: 'Женщина', slug: 'woman' },
  { id: 'other', label: 'Другое', slug: 'otherGender' }
]

export const PETS: ProfileOptions = [
  { id: 0, label: 'Люблю животных, есть', slug: 'iLoveAnimalsIHave' },
  { id: 1, label: 'Люблю, но нету', slug: 'ILoveButIDontHave' },
  { id: 2, label: 'Нейтрально отношусь', slug: 'IAmNeutral' },
  { id: 3, label: 'Не люблю', slug: 'IDontLike' }
]
