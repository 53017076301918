import { Fragment, useCallback, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { FeedPostHeader, FeedPostItem, Typography } from '@components'
import { selectChannel, selectFeed } from '@redux/selectors'

export const POST_PADDING = 18

const styles = StyleSheet.create({
  commentContent: {
    backgroundColor: '#E8E7ED',
    borderRadius: 10,
    padding: 12
  },
  commentsContainer: {
    gap: 12,
    paddingHorizontal: 18
  }
})

interface FeedPostWithCommentsProps {
  postId: number
  onPress?: () => void
}

export const FeedPostWithComments = ({ postId, onPress }: FeedPostWithCommentsProps) => {
  const channel = useSelector(selectChannel)
  const feed = useSelector(selectFeed)

  const post = useMemo(() => {
    if (!channel && !feed) {
      return null
    }

    return (
      channel?.history.find(_post => _post.message_id === postId) || feed?.find(_post => _post.message_id === postId)
    )
  }, [channel, postId])

  const renderComments = useCallback(() => {
    if (!post?.discussionChat?.messages) {
      return null
    }

    return post.discussionChat.messages
      .sort((a, b) => moment(b.createdAt).diff(a.createdAt))
      .map(message => (
        <Fragment key={message.message_id}>
          <FeedPostHeader
            userName={message.User.name}
            imageUri={message.User.profile_photo[0]}
            timestamp={message.createdAt}
          />
          <View style={styles.commentContent}>
            <Typography f15 color='rgba(24, 24, 28, 0.8)'>
              {message.content}
            </Typography>
          </View>
        </Fragment>
      ))
  }, [post])

  if (!post) {
    return null
  }

  return (
    <>
      <FeedPostItem onPress={onPress} post={post} isCommentsCountAsString backgroundColor='transparent' />
      <View style={styles.commentsContainer}>{renderComments()}</View>
    </>
  )
}
