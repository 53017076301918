import { FC } from 'react'
import { ImageBackground, StyleSheet, Text, View } from 'react-native'

import { CompatibilityBackground } from '@assets/images'
import { CircularProgress } from '@components/Model'

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute'
  },
  image: {
    alignItems: 'center',
    height: 60,
    justifyContent: 'center',
    position: 'relative',
    width: 60
  },
  text: {
    color: 'white',
    display: 'flex',
    fontSize: 12,
    fontWeight: '600',
    marginTop: 4,
    textAlign: 'center'
  }
})

interface CompatibilityBarItemProps {
  value: number
  Icon: FC
  iconColor?: string
  textColor?: string
  fontSize?: number
}

export const CompatibilityBarItem = ({
  value,
  Icon,
  textColor = 'white',
  fontSize = 12
}: CompatibilityBarItemProps) => (
  <ImageBackground source={CompatibilityBackground} resizeMode='contain' style={styles.image}>
    <CircularProgress percentage={value} size={60} />
    <View style={styles.content}>
      <Icon />
      <Text style={{ ...styles.text, color: textColor, fontSize }}>{value}</Text>
    </View>
  </ImageBackground>
)
