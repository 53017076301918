import { BellSvg } from '@assets/icons'
import { Button, ModalWrapper, Typography } from '@components'

interface InfoModalProps {
  visible: boolean
  onClose: () => void
}

export const InfoModal = ({ visible, onClose }: InfoModalProps) => (
  <ModalWrapper isVisible={visible} close={onClose} contentStyle={{ paddingTop: 44 }}>
    <BellSvg />
    <Typography f20 semibold marginTop={40} marginBottom={12}>
      Важно
    </Typography>
    <Typography f15 normal textAlign='center' marginBottom={52}>
      Работа алгоритма соцсети зависит от того, насколько подробно вы заполните данные о себе
    </Typography>
    <Button text='Продолжить' onPress={onClose} />
  </ModalWrapper>
)
