import {
  GET_ALL_DELETED_USERS_REQUEST,
  GET_ALL_DELETED_USERS_SUCCESS,
  GetAllDeletedUsersRequestAction,
  GetAllDeletedUsersSuccessAction
} from '@redux/types'
import { UserProfile } from '@types'

export const getAllDeletedUsersRequest = (): GetAllDeletedUsersRequestAction => {
  return {
    type: GET_ALL_DELETED_USERS_REQUEST
  }
}

export const getAllDeletedUsersSuccess = (users: UserProfile[]): GetAllDeletedUsersSuccessAction => {
  return {
    payload: users,
    type: GET_ALL_DELETED_USERS_SUCCESS
  }
}
