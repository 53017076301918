import {
  CHANGE_ROLE_GROUP_CHAT_REQUEST,
  CHANGE_ROLE_GROUP_CHAT_SUCCESS,
  ChangeRoleGroupChatRequestAction,
  ChangeRoleGroupChatSuccessAction,
  CREATE_GROUP_CHAT_REQUEST,
  CREATE_GROUP_CHAT_SUCCESS,
  CreateGroupChatRequestAction,
  CreateGroupChatSuccessAction,
  LEAVE_GROUP_CHAT_REQUEST,
  LEAVE_GROUP_CHAT_SUCCESS,
  LeaveGroupChatRequestAction,
  LeaveGroupChatSuccessAction,
  UPDATE_GROUP_CHAT_REQUEST,
  UPDATE_GROUP_CHAT_SUCCESS,
  UpdateGroupChatRequestAction,
  UpdateGroupChatSuccessAction
} from '@redux/types'
import { ChangeRoleGroupChatAttributes, CreateGroupChatAttributes, UpdateGroupChatAttributes, UserChat } from '@types'

export const createGroupChatRequest = (data: CreateGroupChatAttributes): CreateGroupChatRequestAction => ({
  payload: data,
  type: CREATE_GROUP_CHAT_REQUEST
})

export const createGroupChatSuccess = (data: UserChat): CreateGroupChatSuccessAction => ({
  payload: data,
  type: CREATE_GROUP_CHAT_SUCCESS
})

export const updateGroupChatRequest = (data: UpdateGroupChatAttributes): UpdateGroupChatRequestAction => ({
  payload: data,
  type: UPDATE_GROUP_CHAT_REQUEST
})

export const updateGroupChatSuccess = (data: UserChat): UpdateGroupChatSuccessAction => ({
  payload: data,
  type: UPDATE_GROUP_CHAT_SUCCESS
})

export const leaveGroupChatRequest = (chatUserId: number): LeaveGroupChatRequestAction => ({
  payload: chatUserId,
  type: LEAVE_GROUP_CHAT_REQUEST
})

export const leaveGroupChatSuccess = (data: UserChat): LeaveGroupChatSuccessAction => ({
  payload: data,
  type: LEAVE_GROUP_CHAT_SUCCESS
})

export const changeRoleGroupChatRequest = (data: ChangeRoleGroupChatAttributes): ChangeRoleGroupChatRequestAction => ({
  payload: data,
  type: CHANGE_ROLE_GROUP_CHAT_REQUEST
})

export const changeRoleGroupChatSuccess = (data: UserChat): ChangeRoleGroupChatSuccessAction => ({
  payload: data,
  type: CHANGE_ROLE_GROUP_CHAT_SUCCESS
})
