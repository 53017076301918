import { useMemo, useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { DefaultTheme, TextInput, TextInputProps } from 'react-native-paper'

import { SelectModal } from '@components'
import { ProfileOption, ProfileOptions } from '@utils'

const styles = StyleSheet.create({
  container: {
    width: '100%'
  },
  input: {
    backgroundColor: '#F3F2F9',
    fontSize: 14,
    width: '100%'
  }
})

const pickerTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#2F2F2F'
  },
  roundness: 29
}

interface SelectProps extends Omit<TextInputProps, 'value' | 'onChange'> {
  options: ProfileOptions
  value?: string | number
  onChange: (option: ProfileOption) => void
}

export const Select = ({ options, value, label, onChange, style }: SelectProps) => {
  const [showOptions, setShowOptions] = useState(false)

  const valueLabel = useMemo(() => {
    return options.find(option => option.id === value)?.label || 'Выбрать...'
  }, [value, options])

  const closeModal = () => setShowOptions(false)

  const handleChange = (newValue: ProfileOption) => {
    onChange(newValue)
    closeModal()
  }

  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity onPress={() => setShowOptions(!showOptions)}>
        <TextInput
          label={label}
          value={valueLabel}
          editable={false}
          mode='outlined'
          style={styles.input}
          theme={pickerTheme}
          onTouchEnd={() => setShowOptions(!showOptions)}
        />
      </TouchableOpacity>
      {showOptions && (
        <SelectModal<ProfileOption, ProfileOption['id']>
          selected={value}
          options={options}
          onSelect={handleChange}
          onClose={closeModal}
        />
      )}
    </View>
  )
}
