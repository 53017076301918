import {
  FeedActionTypes,
  FeedState,
  GET_FRIENDS_CHANNELS_MESSAGES_REQUEST,
  GET_FRIENDS_CHANNELS_MESSAGES_SUCCESS
} from '@redux/types'

const initialState: FeedState = null

export const feedReducer = (state = initialState, action: FeedActionTypes): FeedState => {
  switch (action.type) {
    case GET_FRIENDS_CHANNELS_MESSAGES_REQUEST:
      return state
    case GET_FRIENDS_CHANNELS_MESSAGES_SUCCESS:
      return action.payload
    default:
      return state
  }
}
