import { useEffect, useMemo, useRef } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { EnvelopeChatSvg } from '@assets/icons'
import { Message, Typography } from '@components'
import { useDataLayer } from '@redux/dataLayer'
import { selectChatMessages } from '@redux/selectors'
import { ChatTypes, UserChat } from '@types'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  },
  noMessages: {
    alignItems: 'center',
    borderRadius: 10,
    padding: 20
  }
})

interface ChatSpaceProps {
  chat: UserChat
  ownerId: number
}

export const ChatSpace = ({ chat, ownerId }: ChatSpaceProps) => {
  const scrollViewRef = useRef<ScrollView | null>(null)
  const { sendReadMessages, getChatMessages } = useDataLayer()
  const messagesState = useSelector(selectChatMessages)

  useEffect(() => {
    getChatMessages(chat.id)
  }, [messagesState.length])

  useEffect(() => {
    if (messagesState.length === 0) {
      return
    }

    try {
      const unreadMessageIds = messagesState
        .filter(message => {
          if (message.user_id !== ownerId) {
            return !message.read?.some(readMessage => readMessage.user_id === ownerId)
          }
        })
        .map(m => m.message_id)

      sendReadMessages({
        chat_id: chat.id,
        messages: unreadMessageIds,
        user_id: ownerId
      })
    } catch (err) {
      console.log('Cant read messages')
    }
  }, [messagesState])

  const renderMessages = useMemo(
    () =>
      messagesState
        .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
        .map(message => {
          let sender
          if (chat.chatType === ChatTypes.GROUP) {
            sender = chat.participants.find(participant => participant.user_id === message.user_id)
          }

          return <Message key={message.message_id} sender={sender} message={message} ownerId={ownerId} />
        }),
    [messagesState]
  )

  if (renderMessages.length === 0) {
    return (
      <View style={styles.container}>
        <View style={styles.noMessages}>
          <EnvelopeChatSvg />
          <Typography f17 semibold marginTop={8}>
            Это начало вашего чата
          </Typography>
        </View>
      </View>
    )
  }

  return (
    <ScrollView
      ref={scrollViewRef}
      style={{ flex: 1, paddingHorizontal: 8 }}
      contentContainerStyle={{ alignItems: 'flex-start', gap: 10, paddingVertical: 10 }}
      showsVerticalScrollIndicator={false}
      onContentSizeChange={() => scrollViewRef.current?.scrollToEnd({ animated: false })}
    >
      {renderMessages}
    </ScrollView>
  )
}
