/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { authSuccessAction, getCurrentUserSuccess } from '@redux/actions'
import { REGISTRATION_REQUEST, RegistrationRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { UserProfile } from '@types'

type SagaEffects = CallEffect | PutEffect

function* registration(action: RegistrationRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.registration], action.payload.authMethods)
    const { user }: { user: UserProfile } = response.data

    if (user.status === 'creating') {
      yield put(getCurrentUserSuccess(user))
    } else {
      const options: any = {
        deleted: !!user.deleted,
        status: user.status
      }

      if (user.phone) {
        options.phone = user.phone
      }

      if (user.googleAuth) {
        options.googleAuth = user.googleAuth
      }

      if (user.facebookAuth) {
        options.facebookAuth = user.facebookAuth
      }

      if (user.instagramAuth) {
        options.instagramAuth = user.instagramAuth
      }

      yield put(authSuccessAction(options))

      if (user.googleAuth || user.facebookAuth || user.instagramAuth) {
        yield put(getCurrentUserSuccess(user))
      }

      action.payload.onSocialLogin?.()
    }
  } catch (error: any) {
    yield put(authSuccessAction({ deleted: false, status: '' }))
  }
}

export function* registrationSaga() {
  yield takeLatest<RegistrationRequestAction>(REGISTRATION_REQUEST, registration)
}
