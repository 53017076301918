import { PageAttributes } from '@navigation'
import {
  AuthMethods,
  ChangeRoleGroupChatAttributes,
  Channel,
  ChannelMessage,
  Chat,
  ComplaintAttributes,
  CreateGroupChatAttributes,
  DeleteMessagesAttributes,
  Discussion,
  DiscussionMessage,
  DiscussionResponseAttributes,
  FacebookAuth,
  GoogleAuth,
  InstagramAuth,
  Like,
  MessagesAttributes,
  SocialAuth,
  UpdateGroupChatAttributes,
  UserChat,
  UserProfile
} from '@types'

// AUTH

export const AUTH_SUCCESS = 'AUTH_SUCCESS'

export interface AuthSuccessAction {
  type: string
  payload: {
    instagramAuth?: InstagramAuth
    facebookAuth?: FacebookAuth
    googleAuth?: GoogleAuth
    phone?: string
    status: string
    deleted: boolean
  }
}

export type AuthActionTypes = AuthSuccessAction

export type AuthState = {
  instagramAuth?: InstagramAuth | null
  facebookAuth?: FacebookAuth | null
  googleAuth?: GoogleAuth | null
  phone: string
  status: string
  deleted?: boolean
}

// GET USERS

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'

export interface GetUsersRequestAction {
  type: string
  payload?: {
    filter: any
  }
}

export interface GetUsersSuccessAction {
  type: string
  payload: UserProfile[]
}

export interface GetUsersFailureAction {
  type: string
  payload: string
}

export type GetUsersActionTypes = GetUsersRequestAction | GetUsersSuccessAction

export type UsersState = UserProfile[]

// GET ALL USERS

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST'
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const CLEAR_USERS_SUCCESS = 'CLEAR_USERS_SUCCESS'

export interface GetAllUsersRequestAction {
  type: string
  payload: {
    login: string
    filter: any
  }
}

export interface GetAllUsersSuccessAction {
  type: string
  payload: UserProfile[]
}

export interface ClearUsersAction {
  type: string
}

export type GetAllUsersActionTypes = GetAllUsersRequestAction | GetAllUsersSuccessAction | ClearUsersAction

export type AllUsersState = UserProfile[]

// GET ALL DELETED USERS

export const GET_ALL_DELETED_USERS_REQUEST = 'GET_ALL_DELETED_USERS_REQUEST'
export const GET_ALL_DELETED_USERS_SUCCESS = 'GET_ALL_DELETED_USERS_SUCCESS'

export interface GetAllDeletedUsersRequestAction {
  type: string
}

export interface GetAllDeletedUsersSuccessAction {
  type: string
  payload: UserProfile[]
}

export interface ClearAllDeletedUsersAction {
  type: string
}

export type GetAllDeletedUsersActionTypes =
  | GetAllDeletedUsersRequestAction
  | GetAllDeletedUsersSuccessAction
  | ClearAllDeletedUsersAction

export type AllDeletedUsersState = UserProfile[]

// GET CURRENT USER

export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'

export interface GetCurrentUserRequestAction {
  type: string
  payload?: null
}

export interface GetCurrentUserSuccessAction {
  type: string
  payload: UserProfile
}

export interface GetCurrentUserFailureAction {
  type: string
  payload: string
}

export type GetCurrentUserActionTypes =
  | GetCurrentUserRequestAction
  | GetCurrentUserSuccessAction
  | GetCurrentUserFailureAction

export type CurrentUserState = UserProfile | null

// REGISTRATION

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST'

export interface RegistrationRequestAction {
  type: typeof REGISTRATION_REQUEST
  payload: {
    authMethods: AuthMethods
    onSocialLogin?: () => void
  }
}

// CONNECT SOCIAL

export const CONNECT_SOCIAL_REQUEST = 'CONNECT_SOCIAL_REQUEST'

export interface ConnectSocialRequestAction {
  type: typeof CONNECT_SOCIAL_REQUEST
  payload: SocialAuth
}

// CHECK CODE

export const CHECK_CODE_REQUEST = 'CHECK_CODE_REQUEST'
export const CHECK_CODE_SUCCESS = 'CHECK_CODE_SUCCESS'

export interface CheckCodeRequestAction {
  type: typeof CHECK_CODE_REQUEST
  payload: {
    phone: string
    code: string
  }
}

export interface CheckCodeSuccessAction {
  type: typeof CHECK_CODE_SUCCESS
  payload: string
}

export type CheckCodeActionTypes = CheckCodeRequestAction | CheckCodeSuccessAction

// CREATE PASSWORD

export interface CreatePasswordResponse {
  user: UserProfile
  token: string
}

export const CREATE_PASSWORD_REQUEST = 'CREATE_PASSWORD_REQUEST'

export interface CreatePasswordRequestAction {
  type: typeof CREATE_PASSWORD_REQUEST
  payload: {
    phone: string
    password: string
  }
}

// RESTORE

export const RESTORE_REQUEST = 'RESTORE_REQUEST'

export interface RestoreRequestAction {
  type: typeof RESTORE_REQUEST
  payload: {
    phone: string
    next: PageAttributes
  }
}

// GENERAL INFORMATION

export const GENERAL_INFORMATION_REQUEST = 'GENERAL_INFORMATION_REQUEST'

export interface GeneralInformationRequestAction {
  type: typeof GENERAL_INFORMATION_REQUEST
  payload: {
    name: string
    dateOfBirth: string
    sex: string
    log: string
    next: PageAttributes
  }
}

// LOGIN

export const LOGIN_REQUEST = 'LOGIN_REQUEST'

export interface LoginRequestAction {
  type: typeof LOGIN_REQUEST
  payload: AuthMethods
}

// CHANGE PASSWORD

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'

export interface ChangePasswordRequestAction {
  type: typeof CHANGE_PASSWORD_REQUEST
  payload: {
    phone: string
    oldPassword: string
    newPassword: string
    next: PageAttributes
  }
}

// UPLOAD IMAGE

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST'

export interface UploadImageRequestAction {
  type: typeof UPLOAD_IMAGE_REQUEST
  payload: {
    files: FormData
  }
}

// DELETE IMAGE

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST'

export interface DeleteImageRequestAction {
  type: typeof DELETE_IMAGE_REQUEST
  payload: {
    id: string
  }
}

// DELETE USER

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'

export interface UserDeleteRequestAction {
  type: typeof USER_DELETE_REQUEST
  payload: {
    userId: string
  }
}

// UPDATE USER

export const UPDATE_USER_REQUEST = 'UPDATE_STEP_REQUEST'

export interface UpdateUserRequestAction {
  type: typeof UPDATE_USER_REQUEST
  payload: {
    update: any
    next: PageAttributes
  }
}

// GET MY LIKES

export const GET_MY_LIKES_REQUEST = 'GET_MY_LIKES_REQUEST'
export const GET_MY_LIKES_SUCCESS = 'GET_MY_LIKES_SUCCESS'

export interface GetMyLikesRequestAction {
  type: typeof GET_MY_LIKES_REQUEST
}

export interface GetMyLikesSuccessAction {
  type: typeof GET_MY_LIKES_SUCCESS
  payload: {
    likes: Chat[]
  }
}

export type GetMyLikesActionTypes = GetMyLikesRequestAction | GetMyLikesSuccessAction

export type GetMyLikesState = Chat[]

// NOTIFICATION

export interface Notification {
  type: string
  text: string
  image: string
  chatId?: number
}

export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS'

export interface NotificationSuccessAction {
  type: typeof NOTIFICATION_SUCCESS
  payload: Notification | null
}

export type NotificationActionTypes = NotificationSuccessAction

export type NotificationState = Notification | null

// GET MY CHATS

export interface ChatInterlocutorResponse {
  data: ChatInterlocutor[]
  counter: number
}

export interface ChatInterlocutor {
  _id: string
  created: string
  invited: string
  status: string
  interlocutor: UserProfile
  timeAccepted: string
  timeInvited: string
  messages: {
    from: string
    time: string
    text: string
  }
}

export const GET_MY_CHATS_REQUEST = 'GET_MY_CHATS_REQUEST'
export const GET_MY_CHATS_SUCCESS = 'GET_MY_CHATS_SUCCESS'

export interface GetMyChatsRequestAction {
  type: typeof GET_MY_CHATS_REQUEST
}

export interface GetMyChatsSuccessAction {
  type: typeof GET_MY_CHATS_SUCCESS
  payload: { data: ChatInterlocutorResponse }
}

export type GetMyChatsActionTypes = GetMyChatsRequestAction | GetMyChatsSuccessAction

export type GetMyChatsState = ChatInterlocutorResponse

// READ NOTIFICATIONS

export const READ_NOTIFICATIONS_REQUEST = 'READ_NOTIFICATIONS_REQUEST'

export interface ReadNotificationsRequestAction {
  type: typeof READ_NOTIFICATIONS_REQUEST
  payload: string[]
}

// LIKE WEBSOCKET

export const SEND_LIKE_REQUEST = 'LIKE_REQUEST'
export const RECEIVE_LIKE = 'RECEIVE_LIKE'

export interface SendLikeRequestAction {
  type: typeof SEND_LIKE_REQUEST
  payload: Like
}

export interface ReceiveLikeAction {
  type: typeof RECEIVE_LIKE
  payload: any
}

// GET USER'S CHATS

export const GET_USER_CHATS_REQUEST = 'GET_USER_CHATS_REQUEST'
export const GET_USER_CHATS_SUCCESS = 'GET_USER_CHATS_SUCCESS'

export interface GetUserChatsRequestAction {
  type: typeof GET_USER_CHATS_REQUEST
  payload: number
}

export interface GetUserChatsSuccessAction {
  type: typeof GET_USER_CHATS_SUCCESS
  payload: UserChat[]
}

export type UserChatActionTypes = GetUserChatsRequestAction | GetUserChatsSuccessAction

export type UserChatsState = UserChat[]

// PHONE CONNECT

export const PHONE_CONNECT_REQUEST = 'PHONE_CONNECT_REQUEST'

export interface PhoneConnectRequestAction {
  type: typeof PHONE_CONNECT_REQUEST
  payload: {
    id: string
  }
}

// CHAT CONNECT

export const CHAT_CONNECT_REQUEST = 'CHAT_CONNECT_REQUEST'

export interface ChatConnectRequestAction {
  type: typeof CHAT_CONNECT_REQUEST
  payload: {
    id: number
  }
}

export type ChatConnectActionTypes = ChatConnectRequestAction

// USER CHANNEL

export const GET_CHANNEL_BY_USER_ID_REQUEST = 'GET_CHANNEL_BY_USER_ID_REQUEST'
export const GET_CHANNEL_BY_USER_ID_SUCCESS = 'GET_CHANNEL_BY_USER_ID_SUCCESS'

export interface GetChannelByUserIdRequestAction {
  type: typeof GET_CHANNEL_BY_USER_ID_REQUEST
  payload: { chatUserId: number }
}

export interface GetChannelByUserIdSuccessAction {
  type: typeof GET_CHANNEL_BY_USER_ID_SUCCESS
  payload: Channel
}

export type ChannelActionTypes =
  | GetChannelByUserIdRequestAction
  | GetChannelByUserIdSuccessAction
  | ReceiveMessagesForChannelAction
export type ChannelState = Channel | null

// FEED MESSAGES (friends channels messages list)

export const GET_FRIENDS_CHANNELS_MESSAGES_REQUEST = 'GET_FRIENDS_CHANNELS_MESSAGES_REQUEST'
export const GET_FRIENDS_CHANNELS_MESSAGES_SUCCESS = 'GET_FRIENDS_CHANNELS_MESSAGES_SUCCESS'

export interface GetFriendsChannelsMessagesRequestAction {
  type: typeof GET_FRIENDS_CHANNELS_MESSAGES_REQUEST
  payload: { chatUserId: number }
}

export interface GetFriendsChannelsMessagesSuccessAction {
  type: typeof GET_FRIENDS_CHANNELS_MESSAGES_SUCCESS
  payload: ChannelMessage[]
}

export type FeedActionTypes = GetFriendsChannelsMessagesRequestAction | GetFriendsChannelsMessagesSuccessAction

export type FeedState = ChannelMessage[] | null

export const RECEIVE_MESSAGES_FOR_CHANNEL = 'RECEIVE_MESSAGES_FOR_CHANNEL'

export interface ReceiveMessagesForChannelAction {
  type: typeof RECEIVE_MESSAGES_FOR_CHANNEL
  payload: ChannelMessage[]
}

// CHAT MESSAGES

export const GET_MESSAGES_FOR_CHAT_REQUEST = 'GET_MESSAGES_FOR_CHAT_REQUEST'
export const EXPLORE_MESSAGES = 'EXPLORE_MESSAGES'

export interface GetMessagesForChatRequestAction {
  type: typeof GET_MESSAGES_FOR_CHAT_REQUEST
  payload: number
}

export interface ExploreMessagesRequestAction {
  type: typeof EXPLORE_MESSAGES
  payload: MessagesAttributes | ChannelMessage
}

// RECIEVE MESSAGES

export const RECEIVE_MESSAGES_FOR_CHAT = 'RECEIVE_MESSAGES_FOR_CHAT'

export interface ReceiveMessagesForChatAction {
  type: typeof RECEIVE_MESSAGES_FOR_CHAT
  payload: MessagesAttributes[]
}

export type ReceiveMessagesForChatState = {
  messages: MessagesAttributes[]
}

// SEND MESSAGE

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST'

export interface SendMessageRequestAction {
  type: typeof SEND_MESSAGE_REQUEST
  payload: MessagesAttributes
}

// SEND DISCUSSION MESSAGE

export const SEND_DISCUSSION_MESSAGE_REQUEST = 'SEND_DISCUSSION_MESSAGE_REQUEST'

export interface SendDiscussionMessageRequestAction {
  type: typeof SEND_DISCUSSION_MESSAGE_REQUEST
  payload: DiscussionMessage
}

// RECIEVE DISCUSSION

export const RECEIVE_DISCUSSION = 'RECEIVE_DISCUSSION'

export interface ReceiveDiscussionAction {
  type: typeof RECEIVE_DISCUSSION
  payload: DiscussionResponseAttributes
}

export type DiscussionActionTypes = ReceiveDiscussionAction
export type DiscussionState = Discussion | null

// DELETE MESSAGE

export const DELETE_MESSAGES_REQUEST = 'DELETE_MESSAGES_REQUEST'
export const DELETE_MESSAGES_SUCCESS = 'DELETE_MESSAGES_SUCCESS'

export interface DeleteMessagesRequestAction {
  type: typeof DELETE_MESSAGES_REQUEST
  payload: DeleteMessagesAttributes
}

export interface DeleteMessagesSuccessAction {
  type: typeof DELETE_MESSAGES_SUCCESS
  payload: DeleteMessagesAttributes
}

// RECIEVE MESSAGE

export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE'

export interface ReceiveMessageAction {
  type: typeof RECEIVE_MESSAGE
  payload: MessagesAttributes
}

// SEND READ MESSAGES

export const SEND_READ_MESSAGES_REQUEST = 'SEND_READ_MESSAGES_REQUEST'

export interface SendReadMessages {
  messages: number[]
  chat_id: number
  user_id: number
}

export interface SendReadMessagesRequestAction {
  type: typeof SEND_READ_MESSAGES_REQUEST
  payload: SendReadMessages
}

// SEND READ MESSAGE

export const SEND_READ_MESSAGE_REQUEST = 'SEND_READ_MESSAGE_REQUEST'

export interface SendReadMessage {
  message_id: number
  chat_id: number
  user_id: number
}

export interface SendReadMessageRequestAction {
  type: typeof SEND_READ_MESSAGE_REQUEST
  payload: SendReadMessage
}

// RECIEVE READ MESSAGE

export const RECEIVE_READ_MESSAGE = 'RECEIVE_READ_MESSAGE'

// RECIEVE READ MESSAGES

export const RECEIVE_READ_MESSAGES = 'RECEIVE_READ_MESSAGES'

// RESET STORE

export const RESET_STORE = 'RESET_STORE'

// CREATE NEW CHAT USER

export interface CreateChatUserAtributes {
  mongo_id: string
  last_online: string
  name: string
  phone_number: string
  username: string
  profile_photo: string[]
}

export const CREATE_NEW_CHAT_USER_REQUEST = 'CREATE_NEW_CHAT_USER_REQUEST'

export interface CreateNewChatUserRequestAction {
  type: typeof CREATE_NEW_CHAT_USER_REQUEST
  payload: CreateChatUserAtributes
}

// FINISH REGISTRATION

export const FINISH_REGISTRATION = 'FINISH_REGISTRATION'

export interface FinishRegistrationRequestAction {
  type: typeof FINISH_REGISTRATION
  payload: { user: UserProfile; next: PageAttributes }
}

// FULL CHAT USER

export const GET_FULL_CHAT_USER_REQUEST = 'GET_FULL_CHAT_USER_REQUEST'
export const GET_FULL_CHAT_USER_SUCCESS = 'GET_FULL_CHAT_USER_SUCCESS'
export const DELETE_FULL_CHAT_USER = 'DELETE_FULL_CHAT_USER'

export interface GetFullChatUserRequestAction {
  type: typeof GET_FULL_CHAT_USER_REQUEST
  payload: number
}

export interface GetFullChatUserSuccessAction {
  type: typeof GET_FULL_CHAT_USER_SUCCESS
  payload: UserProfile
}

export interface DeleteFullChatUserAction {
  type: typeof DELETE_FULL_CHAT_USER
}

export type GetFullChatUserActionTypes =
  | GetFullChatUserRequestAction
  | GetFullChatUserSuccessAction
  | DeleteFullChatUserAction

export type FullChatUserState = UserProfile | null

// GET DATE COMPATIBILITY

export const GET_DATE_COMPATIBILITY_REQUEST = 'GET_DATE_COMPATIBILITY_REQUEST'

export interface GetDateCompatibilityRequestAction {
  type: string
  payload: {
    date: string
    next: PageAttributes
  }
}

export type GetDateCompatibilityActionTypes = GetDateCompatibilityRequestAction

// COMPLAINTS

export const CREATE_COMPLAINT_REQUEST = 'CREATE_COMPLAINT_REQUEST'

export interface CreateComplaintRequestAction {
  type: string
  payload: { data: ComplaintAttributes }
}

export const BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST'

export interface BlockUserRequestAction {
  type: string
  payload: { userId: string }
}

export const UNBLOCK_USER_REQUEST = 'UNBLOCK_USER_REQUEST'

export interface UnblockUserRequestAction {
  type: string
  payload: { userId: string }
}

export const SEND_ATTACHMENTS_TO_CHAT_REQUEST = 'SEND_ATTACHMENTS_TO_CHAT_REQUEST'

export interface SendAttachmentsToChatRequestAction {
  type: string
  payload: any
}

// GROUP CHATS //

// CREATE GROUP CHAT

export const CREATE_GROUP_CHAT_REQUEST = 'CREATE_GROUP_CHAT_REQUEST'
export const CREATE_GROUP_CHAT_SUCCESS = 'CREATE_GROUP_CHAT_SUCCESS'

export interface CreateGroupChatRequestAction {
  type: string
  payload: CreateGroupChatAttributes
}

export interface CreateGroupChatSuccessAction {
  type: string
  payload: UserChat
}

// UPDATE GROUP CHAT

export const UPDATE_GROUP_CHAT_REQUEST = 'UPDATE_GROUP_CHAT_REQUEST'
export const UPDATE_GROUP_CHAT_SUCCESS = 'UPDATE_GROUP_CHAT_SUCCESS'
export interface UpdateGroupChatRequestAction {
  type: string
  payload: UpdateGroupChatAttributes
}

export interface UpdateGroupChatSuccessAction {
  type: string
  payload: UserChat
}

// LEAVE GROUP CHAT

export const LEAVE_GROUP_CHAT_REQUEST = 'LEAVE_GROUP_CHAT_REQUEST'
export const LEAVE_GROUP_CHAT_SUCCESS = 'LEAVE_GROUP_CHAT_SUCCESS'

export interface LeaveGroupChatRequestAction {
  type: string
  payload: number
}

export interface LeaveGroupChatSuccessAction {
  type: string
  payload: UserChat
}

// CHANGE ROLE GROUP CHAT

export const CHANGE_ROLE_GROUP_CHAT_REQUEST = 'CHANGE_ROLE_GROUP_CHAT_REQUEST'
export const CHANGE_ROLE_GROUP_CHAT_SUCCESS = 'CHANGE_ROLE_GROUP_CHAT_SUCCESS'

export interface ChangeRoleGroupChatRequestAction {
  type: string
  payload: ChangeRoleGroupChatAttributes
}

export interface ChangeRoleGroupChatSuccessAction {
  type: string
  payload: UserChat
}

export type GroupChatSuccessActionTypes =
  | CreateGroupChatSuccessAction
  | UpdateGroupChatSuccessAction
  | LeaveGroupChatSuccessAction
  | ChangeRoleGroupChatSuccessAction
