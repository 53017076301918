/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { navigate } from '@navigation'
import { createGroupChatSuccess, leaveGroupChatSuccess, updateGroupChatSuccess } from '@redux/actions'
import {
  CHANGE_ROLE_GROUP_CHAT_REQUEST,
  ChangeRoleGroupChatRequestAction,
  CREATE_GROUP_CHAT_REQUEST,
  CreateGroupChatRequestAction,
  LEAVE_GROUP_CHAT_REQUEST,
  LeaveGroupChatRequestAction,
  UPDATE_GROUP_CHAT_REQUEST,
  UpdateGroupChatRequestAction
} from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* createGroupChat(action: CreateGroupChatRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.createGroupChat], action.payload)
    yield put(createGroupChatSuccess(response.data))
    navigate({ page: 'Chat', params: { chat: response.data, userId: response.data.creator } })
  } catch (error: any) {
    console.log('createGroupChatSaga', error)
  }
}

export function* createGroupChatSaga() {
  yield takeLatest<CreateGroupChatRequestAction>(CREATE_GROUP_CHAT_REQUEST, createGroupChat)
}

function* updateGroupChat(action: UpdateGroupChatRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.updateGroupChat], action.payload)
    yield put(updateGroupChatSuccess(response.data))
  } catch (error: any) {
    console.log('updateGroupChatSaga', error)
  }
}

export function* updateGroupChatSaga() {
  yield takeLatest<UpdateGroupChatRequestAction>(UPDATE_GROUP_CHAT_REQUEST, updateGroupChat)
}

function* leaveGroupChat(action: LeaveGroupChatRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.leaveGroupChat], action.payload)
    yield put(leaveGroupChatSuccess(response.data))
    navigate({ page: 'Message' })
  } catch (error: any) {
    console.log('leaveGroupChatSaga', error)
  }
}

export function* leaveGroupChatSaga() {
  yield takeLatest<LeaveGroupChatRequestAction>(LEAVE_GROUP_CHAT_REQUEST, leaveGroupChat)
}

function* changeRoleGroupChat(action: ChangeRoleGroupChatRequestAction): Generator<SagaEffects, void, any> {
  try {
    yield call([apiService, apiService.changeRoleGroupChat], action.payload)
  } catch (error: any) {
    console.log('changeRoleGroupChatSaga', error)
  }
}

export function* changeRoleGroupChatSaga() {
  yield takeLatest<ChangeRoleGroupChatRequestAction>(CHANGE_ROLE_GROUP_CHAT_REQUEST, changeRoleGroupChat)
}
