import { ImageBackground, Pressable, StyleSheet, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

import { InfoSvg, LocationSvg } from '@assets/icons'
import { CompatibilityBar, SButton, SFlex, Typography } from '@components'
import { Colors, formatUserName, screenHeight, screenWidth } from '@components/utils'
import { useNavigator } from '@navigation'
import { SERVER_URL } from '@services/apiService'
import { UserProfile } from '@types'
import { calculateAgeByDateOfBirth } from '@utils'

interface ModelCardProps {
  item: UserProfile
  goToModelScreen: () => void
}

const styles = StyleSheet.create({
  bottom: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 20,
    paddingTop: 160
  },
  contentWrapper: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    alignItems: 'flex-end',
    padding: 20,
    paddingBottom: 40,
    zIndex: 1
  },
  wrapper: {
    borderRadius: 24,
    height: screenHeight * 0.7,
    justifyContent: 'space-between',
    overflow: 'hidden',
    width: screenWidth - 40
  }
})

export const ModelCard = ({ item }: ModelCardProps) => {
  const { navigation } = useNavigator()

  const goToModelScreen = () => {
    navigation.navigate('ModelScreen', { user: item })
  }

  const mainPhotoUri = item.profileImage[0]?.name
    ? `${SERVER_URL}/${item.profileImage[0].name}`
    : `${SERVER_URL}/65f7dbe9712f52c1bb4b62db.png`

  return (
    <View style={styles.contentWrapper}>
      <ImageBackground source={{ uri: mainPhotoUri }} resizeMode='cover' style={styles.wrapper}>
        <LinearGradient colors={['rgba(0,0,0,0.55)', 'rgba(0,0,0,0)']} style={styles.header}>
          <CompatibilityBar
            attraction={item.results[item.best].attraction}
            sex={item.results[item.best].sex_points}
            compPoints={item.results[item.best].points}
            type={item.results[item.best].type}
          />
        </LinearGradient>
        <LinearGradient colors={['rgba(0,0,0,0.0)', 'rgba(0,0,0,0.55)']} style={styles.bottom}>
          <Pressable onPress={goToModelScreen}>
            <View
              style={{
                flexDirection: 'row',
                gap: 10,
                marginBottom: 8,
                maxWidth: 200,
                opacity: 0.5
              }}
            >
              <LocationSvg style={{ overflow: 'visible' }} />
              <Typography f14 normal color='#ffffff' numberOfLines={1}>
                {item.profile.userGeo.name}
              </Typography>
            </View>
            <SFlex>
              <Typography f24 bold color='#FFFFFF'>
                {formatUserName(item.name)}
              </Typography>
              <Typography f24 light color='#FFFFFF' marginLeft={10}>
                {calculateAgeByDateOfBirth(item.dof)}
              </Typography>
            </SFlex>
          </Pressable>
          <SButton
            onPress={goToModelScreen}
            baseColor={Colors.WhiteTransparent}
            borderRadius={50}
            styleBtn={{ padding: 15 }}
          >
            <InfoSvg />
          </SButton>
        </LinearGradient>
      </ImageBackground>
    </View>
  )
}
