import {
  adventure,
  angry,
  anticipation,
  attention,
  badMood,
  blanket,
  brokenHeart,
  coffee,
  company,
  dance,
  dream,
  drink,
  exited,
  hand,
  happy,
  house,
  hugs,
  inLove,
  lonely,
  love,
  noPower,
  noStatus,
  party,
  partyToday,
  playMood,
  pleasantLife,
  prey,
  reading,
  readyTalk,
  sad,
  smile,
  sport,
  stressed,
  time,
  tired,
  vacation,
  waiting,
  work
} from '@assets/icons'

export type StatusItem = {
  id: number
  text: { ru: string }
  icon: any
}

export const STATUSES: StatusItem[] = [
  { icon: noStatus, id: 0, text: { ru: 'нет статуса' } },
  { icon: love, id: 1, text: { ru: 'влюблена' } },
  { icon: happy, id: 2, text: { ru: 'счастлива' } },
  { icon: vacation, id: 3, text: { ru: 'нахожусь в отпуске' } },
  { icon: reading, id: 4, text: { ru: 'читаю' } },
  { icon: pleasantLife, id: 5, text: { ru: 'Наслаждаюсь жизнью' } },
  { icon: coffee, id: 6, text: { ru: 'Пригласи меня на кофе' } },
  { icon: work, id: 7, text: { ru: 'Я люблю свою работу, я приду сюда в субботу' } },
  { icon: smile, id: 8, text: { ru: 'Улыбаюсь, улыбнись и ты' } },
  { icon: waiting, id: 9, text: { ru: 'Жду предложений' } },
  { icon: sad, id: 10, text: { ru: 'Хочется грустить' } },
  { icon: badMood, id: 11, text: { ru: 'Вообще не в духе' } },
  { icon: tired, id: 12, text: { ru: 'Очень устала' } },
  { icon: playMood, id: 13, text: { ru: 'Игривое настроение' } },
  { icon: lonely, id: 14, text: { ru: 'Мне одиноко' } },
  { icon: company, id: 15, text: { ru: 'Ищу компанию на вечер' } },
  { icon: party, id: 16, text: { ru: 'Готов к вечеринке' } },
  { icon: angry, id: 17, text: { ru: 'Злюсь' } },
  { icon: hugs, id: 18, text: { ru: 'Хочу обнимашек' } },
  { icon: prey, id: 19, text: { ru: 'Хочу побыть один' } },
  { icon: drink, id: 20, text: { ru: 'Хочется выпить' } },
  { icon: dance, id: 21, text: { ru: 'Хочется потанцевать' } },
  { icon: adventure, id: 22, text: { ru: 'Ищу приключений' } },
  { icon: readyTalk, id: 23, text: { ru: 'Готов пообщаться' } },
  { icon: attention, id: 24, text: { ru: 'Хочется внимания и цветочков' } },
  { icon: sport, id: 25, text: { ru: 'Настроение для тренировки' } },
  { icon: hand, id: 26, text: { ru: 'Я за любой движ, кроме голодовки' } },
  { icon: partyToday, id: 27, text: { ru: 'У меня сегодня праздник' } },
  { icon: stressed, id: 28, text: { ru: 'Волнуюсь, переживаю' } },
  { icon: noPower, id: 29, text: { ru: 'Без сил' } },
  { icon: house, id: 30, text: { ru: 'Я в домике' } },
  { icon: exited, id: 31, text: { ru: 'Я в восторге' } },
  { icon: anticipation, id: 32, text: { ru: 'В предвкушении…' } },
  { icon: time, id: 33, text: { ru: 'Жду' } },
  { icon: inLove, id: 34, text: { ru: 'Влюблен' } },
  { icon: brokenHeart, id: 35, text: { ru: 'Мое сердце разбито' } },
  { icon: blanket, id: 36, text: { ru: 'Лежу под одеялком' } },
  { icon: dream, id: 37, text: { ru: 'Сегодня я ленивец' } }
]

export const getStatusById = (id: number | undefined) =>
  id ? STATUSES.find(item => item.id === id) || STATUSES[0] : STATUSES[0]
