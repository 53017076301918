import { BusinessBlockSvg, FriendsBlockSvg, LoveBlockSvg } from '@assets/icons'
import { ExpandableContentItem, ExpandableInfoBlock, Typography } from '@components'
import { UserProfile } from '@types'

interface PersonDescriptionProps {
  description?: UserProfile['description']
}

export const PersonDescription = ({ description }: PersonDescriptionProps) => {
  const contentArray: ExpandableContentItem[] = [
    {
      content: description?.love || 'Скоро будет...',
      photoBlock: <LoveBlockSvg />,
      title: 'В любви'
    },
    {
      content: description?.friend || 'Скоро будет...',
      photoBlock: <FriendsBlockSvg />,
      title: 'В дружбе'
    },
    {
      content: description?.business || 'Скоро будет...',
      photoBlock: <BusinessBlockSvg />,
      title: 'В бизнесе'
    }
  ]

  return (
    <>
      <Typography f14 semibold marginBottom={12}>
        Описание личности
      </Typography>

      <ExpandableInfoBlock contentArray={contentArray} />
    </>
  )
}
