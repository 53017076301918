import { useCallback, useMemo, useState } from 'react'
import { Image, Pressable, SafeAreaView, ScrollView, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import * as ImagePicker from 'expo-image-picker'

import { BackSvg, SearchSvg, TickSvg } from '@assets/icons'
import { Avatar, Button, TextInput, Typography } from '@components'
import { Colors, isAndroid } from '@components/utils'
import { useMatchedUsers } from '@hooks'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectUserChatId } from '@redux/selectors'
import { CreateGroupChatAttributes, ShortUser } from '@types'

const styles = StyleSheet.create({
  image: {
    height: 80,
    width: 80
  },
  imageWrapper: {
    alignSelf: 'center',
    backgroundColor: Colors.Gray,
    borderRadius: 40,
    height: 80,
    marginBottom: 0,
    overflow: 'hidden',
    width: 80
  },
  info: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'center'
  },
  input: {
    height: 40,
    marginTop: 10
  },
  inputs: {
    flex: 1,
    marginBottom: 10
  },
  listItem: {
    alignItems: 'center',
    borderRadius: 10,
    flexDirection: 'row',
    height: 'auto',
    justifyContent: 'space-between',
    padding: 10
  },
  mainWrapper: {
    flex: 1,
    gap: 20,
    justifyContent: 'flex-start',
    paddingHorizontal: 26
  },
  topWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingHorizontal: 30,
    paddingTop: isAndroid ? 15 : 5
  },
  userInfo: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10
  }
})

export const AddChat = () => {
  const [groupName, setGroupName] = useState('')
  const [groupDescription, setGroupDescription] = useState('')
  const [image, setImage] = useState<ImagePicker.ImagePickerAsset | null>(null)
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const { navigation } = useNavigator<'Chat'>()
  const { createGroupChat } = useDataLayer()
  const { matchedUsers } = useMatchedUsers()
  const userChatId = useSelector(selectUserChatId)
  const insets = useSafeAreaInsets()

  const pickImage = useCallback(async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      base64: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1
    })

    if (!result.canceled) {
      setImage(result.assets[0])
    }
  }, [])

  const toggleId = useCallback(
    (_id: number) => setSelectedIds(prev => (prev.includes(_id) ? prev.filter(id => id !== _id) : [...prev, _id])),
    [selectedIds]
  )

  const renderedUsers = useMemo(
    () =>
      matchedUsers.map((user: ShortUser) => (
        <Pressable key={user.user_id} onPress={() => toggleId(user.user_id)} style={styles.listItem}>
          <View style={styles.userInfo}>
            <Avatar size={60} uri={user.profile_photo[0]} />
            <Typography>{user.name}</Typography>
          </View>
          {selectedIds.includes(user.user_id) && <TickSvg width={20} height={20} />}
        </Pressable>
      )),
    [matchedUsers, selectedIds]
  )

  const createChat = useCallback(() => {
    if (!selectedIds.length) {
      return
    }

    const unionName = matchedUsers
      .filter((user: ShortUser) => selectedIds.includes(user.user_id))
      .map((user: ShortUser) => user.name)
      .join(', ')

    const defaultGroupName = groupName.length ? groupName : unionName

    const groupChatAttributes: CreateGroupChatAttributes = {
      creator: userChatId,
      groupDescription,
      groupName: defaultGroupName,
      participants: selectedIds
    }

    if (image && image.base64 && image.type && image.fileSize) {
      groupChatAttributes.image = {
        base64: image.base64,
        size: image.fileSize,
        type: image.type
      }
    }

    createGroupChat(groupChatAttributes)
  }, [selectedIds, groupName, groupDescription, image])

  return (
    <SafeAreaView style={{ flex: 1, paddingBottom: insets.bottom }}>
      <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />
      <View style={styles.topWrapper}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <BackSvg />
        </TouchableOpacity>
        <Typography f17 semibold>
          Новый чат
        </Typography>
        <View style={{ height: 40, width: 40 }} />
      </View>
      <View style={styles.mainWrapper}>
        <View style={styles.info}>
          <Button onPress={pickImage} style={styles.imageWrapper}>
            {image ? <Image source={{ uri: image?.uri }} style={styles.image} /> : <SearchSvg fill='white' />}
          </Button>
          <View style={styles.inputs}>
            <TextInput style={styles.input} placeholder='Название' onChange={t => setGroupName(t)} />
            <TextInput style={styles.input} placeholder='Описание' onChange={t => setGroupDescription(t)} />
          </View>
        </View>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Typography f17 semibold>
            Выбрано {selectedIds.length}
          </Typography>
          {renderedUsers}
        </ScrollView>
        <Button onPress={createChat} disabled={selectedIds.length < 1} text='Добавить чат' />
      </View>
    </SafeAreaView>
  )
}
