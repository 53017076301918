import { useEffect, useMemo, useState } from 'react'
import { KeyboardAvoidingView, View } from 'react-native'
import { useSelector } from 'react-redux'

import { ChatSpace, HeaderMessage, InputMessage } from '@components'
import { isIOS, isWeb } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import {
  fullChatUserData,
  selectAllDeletedUsers,
  selectCurrentUserBlockedUserIds,
  selectCurrentUserId
} from '@redux/selectors'

export const Chat = () => {
  const { route } = useNavigator<'Chat'>()
  const { userId, chat } = route.params

  const { getAllDeletedUsers } = useDataLayer()
  const allDeletedUsers = useSelector(selectAllDeletedUsers)
  const blockedUserIds = useSelector(selectCurrentUserBlockedUserIds)
  const currentUserId = useSelector(selectCurrentUserId)
  const chatUser = useSelector(fullChatUserData)

  const [webContainerHeight, setWebContainerHeight] = useState(0)

  const isBlocked =
    !!blockedUserIds?.includes(chatUser?._id as string) || !!chatUser?.blockedUserIds.includes(currentUserId)

  const isUserDeleted = useMemo(
    () =>
      allDeletedUsers.some(
        u => chat.participants.map(p => p.user_id).includes(u.userChatId) || u.userChatId === chat.createdBy
      ),
    [allDeletedUsers.length]
  )

  useEffect(() => {
    getAllDeletedUsers()
  }, [])

  useEffect(() => {
    if (!isWeb) {
      return
    }

    const setHeight = () => setWebContainerHeight(window.innerHeight)
    window.addEventListener('resize', setHeight)
    return () => window.removeEventListener('resize', setHeight)
  }, [])

  return (
    <>
      <KeyboardAvoidingView
        behavior={isIOS ? 'padding' : undefined}
        style={[{ flex: 1 }, isWeb && { maxHeight: webContainerHeight || 'auto' }]}
      >
        <HeaderMessage chat={chat} isUserDeleted={isUserDeleted} ownerId={userId} />
        <ChatSpace chat={chat} ownerId={userId} />
        <InputMessage isDeleted={isUserDeleted} isBlocked={isBlocked} chatId={chat.id} userChatId={userId} />
      </KeyboardAvoidingView>
      <View style={{ backgroundColor: '#e8e7ed', height: isIOS ? 20 : 0 }} />
    </>
  )
}
