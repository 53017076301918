import { ImageBackground, StyleSheet, TouchableOpacity, View } from 'react-native'

import { BackSvg, ConnectTextLogoSvg, MessageIconSvg } from '@assets/icons'
import { StartSelectionBackground } from '@assets/images'
import { SocialAuthButtonList, TermsMessage, Typography } from '@components'
import { useNavigator } from '@navigation'

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 55,
    borderWidth: 2,
    flexDirection: 'row',
    height: 60,
    justifyContent: 'center',
    marginBottom: 10
  },
  container: {
    flex: 1,
    paddingHorizontal: 26,
    paddingTop: 60,
    resizeMode: 'cover'
  },
  contentWrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 20
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})

export const StartSelection = () => {
  const { navigation } = useNavigator<'FirstScreen' | 'EnterPhone'>()

  const goToFirstScreen = () => {
    navigation.navigate('FirstScreen')
  }

  const goToEnterPhone = () => {
    navigation.navigate('EnterPhone')
  }

  return (
    <ImageBackground source={StartSelectionBackground} style={styles.container}>
      <View style={styles.headerWrapper}>
        <TouchableOpacity onPress={goToFirstScreen}>
          <BackSvg />
        </TouchableOpacity>
        <ConnectTextLogoSvg />
        <View style={{ height: 40, width: 40 }}></View>
      </View>
      <View style={styles.contentWrapper}>
        <Typography f27 extraBold marginBottom={8}>
          Регистрация
        </Typography>
        <Typography f15 normal color='#535354' marginBottom={24}>
          Найдите друзей с которыми легко, идеального партнера и эффективные деловые связи!
        </Typography>
        <TouchableOpacity style={styles.button} onPress={goToEnterPhone}>
          <MessageIconSvg style={{ height: 24, left: 18, position: 'absolute', width: 24 }} />
          <Typography f16 bold textAlign='center'>
            Продолжить по номеру
          </Typography>
        </TouchableOpacity>
        <SocialAuthButtonList />
        <TermsMessage />
      </View>
    </ImageBackground>
  )
}
