import { AUTH_SUCCESS, AuthState, AuthSuccessAction } from '@redux/types'

const initialState: AuthState = {
  deleted: false,
  facebookAuth: null,
  googleAuth: null,
  instagramAuth: null,
  phone: '',
  status: ''
}

export const authReducer = (state = initialState, action: AuthSuccessAction) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        deleted: action.payload.deleted,
        facebookAuth: action.payload.facebookAuth,
        googleAuth: action.payload.googleAuth,
        instagramAuth: action.payload.instagramAuth,
        phone: action.payload.phone,
        status: action.payload.status
      }
    default:
      return state
  }
}
