import { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { TextArea, Typography } from '@components'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserId } from '@redux/selectors'
import { ComplaintTypes } from '@types'

const styles = StyleSheet.create({
  buttons: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 16,
    width: '100%'
  },
  option: {
    alignItems: 'center',
    borderTopColor: '#fff',
    borderTopWidth: 1,
    flexGrow: 1,
    justifyContent: 'center',
    padding: 18,
    width: '100%'
  },
  textarea: {
    paddingHorizontal: 26,
    width: '100%'
  }
})

interface ModalUserActionsComplaintProps {
  close: () => void
  targetId: string
}

export const ModalUserActionsComplaint = ({ close, targetId }: ModalUserActionsComplaintProps) => {
  const [complaintReason, setComplaintReason] = useState<ComplaintTypes | null>()
  const [complaintMessage, setComplaintMessage] = useState('')
  const [isComplaintDone, setIsComplaintDone] = useState(false)

  const currentUserId = useSelector(selectCurrentUserId)
  const { createComplaint } = useDataLayer()

  const onClose = () => {
    close()
    setComplaintMessage('')
    setComplaintReason(null)
  }

  const complain = () => {
    if (!complaintReason) {
      return
    }

    createComplaint({
      creatorId: currentUserId,
      message: complaintMessage,
      targetId,
      type: complaintReason
    })
    setIsComplaintDone(true)
  }

  if (isComplaintDone) {
    return (
      <>
        <Typography padding={16}>Ваша жалоба на рассмотрении</Typography>
        <TouchableOpacity style={styles.option} onPress={close}>
          <Typography f16>Понятно</Typography>
        </TouchableOpacity>
      </>
    )
  }

  if (complaintReason === ComplaintTypes.OTHER) {
    return (
      <>
        <Typography f11 style={{ opacity: 0.5, padding: 8 }}>
          Опишите причину жалобы:
        </Typography>
        <View style={styles.textarea}>
          <TextArea text={complaintMessage} setText={text => setComplaintMessage(text)} />
        </View>
        <View style={styles.buttons}>
          <TouchableOpacity style={[styles.option, { width: 'auto' }]} onPress={onClose}>
            <Typography f16 style={{ opacity: 0.5 }}>
              Отмена
            </Typography>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.option, { width: 'auto' }]} onPress={complain}>
            <Typography f16 style={{ color: '#F64A3F', fontWeight: '700' }}>
              Отправить
            </Typography>
          </TouchableOpacity>
        </View>
      </>
    )
  }

  return (
    <>
      <Typography f11 style={{ opacity: 0.5, padding: 8 }}>
        Выберите причину жалобы:
      </Typography>
      <TouchableOpacity
        style={styles.option}
        onPress={() => {
          setComplaintReason(ComplaintTypes.SPAM)
          complain()
        }}
      >
        <Typography f16 style={{ fontWeight: '700' }}>
          Спам
        </Typography>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.option}
        onPress={() => {
          setComplaintReason(ComplaintTypes.CONTENT)
          complain()
        }}
      >
        <Typography f16 style={{ fontWeight: '700' }}>
          Неприемлемый контент
        </Typography>
      </TouchableOpacity>
      <TouchableOpacity style={styles.option} onPress={() => setComplaintReason(ComplaintTypes.OTHER)}>
        <Typography f16 style={{ fontWeight: '700' }}>
          Другое
        </Typography>
      </TouchableOpacity>
      <TouchableOpacity style={styles.option} onPress={close}>
        <Typography f16 style={{ opacity: 0.5 }}>
          Отмена
        </Typography>
      </TouchableOpacity>
    </>
  )
}
