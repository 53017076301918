/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { navigate } from '@navigation'
import { getCurrentUserSuccess, notificationAction } from '@redux/actions'
import { CHANGE_PASSWORD_REQUEST, ChangePasswordRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* changePassword(action: ChangePasswordRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call(
      [apiService, apiService.changePassword],
      action.payload.phone,
      action.payload.oldPassword,
      action.payload.newPassword
    )
    const data = response.data
    yield put(getCurrentUserSuccess(data))
    navigate(action.payload.next)
  } catch (error: any) {
    yield put(
      notificationAction({
        image: '',
        text: error.response.data.message,
        type: 'error'
      })
    )
  }
}

export function* changePasswordSaga() {
  yield takeLatest<ChangePasswordRequestAction>(CHANGE_PASSWORD_REQUEST, changePassword)
}
