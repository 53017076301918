import { useEffect, useState } from 'react'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { Provider } from 'react-redux'
import { Asset } from 'expo-asset'
import { useFonts } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'

import { Close, CompatibilityBackground, FirstScreenBackground, Plus, SendIcon } from '@assets/images'
import { Toast, ToastProvider, WebAppContainer } from '@components'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { AppNavigator } from '@navigation'
import { store } from '@redux/configureStore'

import '@services/socketService'
import '@services/chatService'
import { TelegramHobbies } from './screens/start'

const loadAssetsAsync = async () => {
  const imageAssets = [FirstScreenBackground, CompatibilityBackground, SendIcon, Plus, Close]

  await Promise.all(imageAssets.map(image => Asset.fromModule(image).downloadAsync()))
}

SplashScreen.preventAutoHideAsync()

type Lang = "ru" | "en" | "uk"

export const App = () => {
  const [fontsLoaded] = useFonts({
    ManropeLight: require('../assets/fonts/Manrope-Light.ttf'),
    ManropeSemiBold: require('../assets/fonts/Manrope-SemiBold.ttf')
  })

  const [screen, setScreen] = useState('')
  const [telegramId, setTelegramId] = useState('')
  const [lang, setLang] = useState<Lang>('ru')

  useEffect(() => {
    // Parse the query parameters
    const queryParams = new URLSearchParams(window.location.search)
    const screenParam = queryParams.get('screen') || ''
    setScreen(screenParam)
    const idParam = queryParams.get('telegram') || ''
    setTelegramId(idParam)
    const langParam = queryParams.get('lang') || 'ru'
    setLang(langParam as Lang)
  }, [])

  useEffect(() => {
    loadAssetsAsync()
  }, [])

  if (!fontsLoaded && !screen) {
    return null
  }

  if (screen === 'hobbies') {
    return <TelegramHobbies telegramId={telegramId} lang={lang} />
  } else {
    return (
      <Provider store={store}>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <BottomSheetModalProvider>
            <WebAppContainer>
              <ToastProvider config={{ timeout: 2000 }}>
                <AppNavigator />
                <Toast />
              </ToastProvider>
            </WebAppContainer>
          </BottomSheetModalProvider>
        </GestureHandlerRootView>
      </Provider>
    )
  }
}
