export type CompatibilityTypes = 'BusinessPartners' | 'Friendship' | 'LoveMarriage' | 'SexyAdventures' | 'Commercial'
export enum ComplaintTypes {
  SPAM = 'spam',
  CONTENT = 'content',
  OTHER = 'other'
}

export interface CompResult {
  attraction: number
  partner_sign_text: string
  user_sign_text: string
  sex_points?: number
  points: number
  type: CompatibilityTypes
  hobbies: string[]
  languages: number[] | null
  valuables: number[] | null
  movieGenre: number[] | null
  bookGenre: number[] | null
  musicGenre: number[] | null
  workGeo: Geo
  places: Geo[]
  travel: Geo[]
}

type CompResults = {
  [K in CompatibilityTypes]: CompResult
}

export interface UserProfile {
  profile: Profile
  profileVisability: ProfileVisability
  _id: string
  phone?: string
  status: string
  code?: string
  sex: string
  dof: string
  name: string
  login: string
  significator: string
  isVerify: boolean
  userChatId: number
  profileImage: ProfileImage[]
  __v: number
  hide?: boolean
  results: CompResults
  best: CompatibilityTypes
  description: PersonDescription
  planets_friendship: string
  planets_love: string
  moodStatus: number[]
  mood: Mood
  blockedUserIds: string[]
  deleted?: boolean
  googleAuth?: GoogleAuth
  facebookAuth?: FacebookAuth
  instagramAuth?: InstagramAuth
}

export interface SocialAuth {
  provider: SocialProvider
  token?: string
  redirectUrl?: string
}

export type InstagramAuth = SocialAuth
export type FacebookAuth = SocialAuth
export type GoogleAuth = SocialAuth

export interface AuthMethods {
  phone?: string
  password?: string
  socialAuth?: SocialAuth
}

export enum SocialProvider {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram'
}

export interface Mood {
  physical: string
  emotional: string
  average: string
  text: string
}

export interface DateComp {
  person: string
  attraction: number
  sexual: number
  love: number
  friendship: number
  matrix: number
  metadata: {
    partner_sign: string
    partner_text_friendship: string
    partner_text_love: string
    sign_friendship: number
    sign_love: number
    user_sign: string
    user_text_friendship: string
    user_text_love: string
    planets: string
    planets_friendship: string
    planets_love: string
  }
}

interface PersonDescription {
  person: string
  love: string
  friend: string
  business: string
}

interface ProfileImage {
  _id: string
  name: string
}

export interface Geo {
  name: string
  position: {
    lat: number
    lng: number
  }
  placeId: string
}

// type ProfileItemValue = string | number

interface ProfileVisability {
  goal: boolean
  userGeo: boolean
  sexuality: boolean
  lgbtTolerant: boolean
  religion: boolean
  relationshipStatus: boolean
  hasChildren: boolean
  petsTolerant: boolean
  langs: boolean
  readyToUseTranslator: boolean
  educationGrade: boolean
  workName: boolean
  workPosition: boolean
  workGeo: boolean
  hobbies: boolean
  places: boolean
  travel: boolean
  movieGenre: boolean
  bookGenre: boolean
  musicGenre: boolean
  valuables: boolean
  financial: boolean
}

interface Profile {
  goal: number[]
  userGeo: Geo
  workGeo: Geo
  lgbtTolerant: number
  sexuality: number
  relationshipStatus: number
  hasChildren: number
  langs: []
  readyToUseTranslator: boolean
  workName: string
  workPosition: string
  religion: number
  petsTolerant: number
  educationGrade: number
  hobbies: string[]
  places: Geo[]
  travel: Geo[]
  travelFuture: Geo[]
  valuables: number[]
  movieGenre: number[]
  bookGenre: number[]
  musicGenre: number[]
  about: string
  financial: number
  readyWithForeigners: boolean
  showOnlyYourSexuality: boolean
  algorithmReligion: boolean
  algorithmFinancial: boolean
}

export interface UserChatAttributes {
  user_id?: number
  username: string
  name: string
  mongo_id: string
  phone_number: string
  last_online: Date
}

export interface Chat {
  _id: string
  created: string
  invited: string
  status: string
  user: UserProfile
  messages: []
}

export interface ChatAttributes {
  chat_id: number
  chat_type: string
  chat_name: string
  chat_description: string
  createdAt: Date
  createdBy: number
}

export interface AttachmentAttributes {
  attachment_id: number
  message_id: number
  attachment_type: string
  attachment_url: string
  attachment_size: number
}

export interface MessagesAttributes {
  message_id: number
  chat_id: number
  user_id: number
  content?: string
  message_type: string
  timestamp?: string
  createdAt?: string
  attachments?: AttachmentAttributes[]
  read?: ReadMessageAttributes[]
}

export interface ChannelMessage extends MessagesAttributes {
  replyToMessageId: number
  discussionChat: Discussion | null
  repliedMessage: MessagesAttributes
  User: ShortUser & UserProfile
}

export interface DiscussionMessage extends Omit<MessagesAttributes, 'chat_id'> {
  message_id: number
}

export interface DiscussionResponseAttributes {
  chat: Discussion
  message: MessagesAttributes
  user: UserChatAttributes
}

export interface DeleteMessagesAttributes {
  chat_id: number
  messages: number[]
}

export interface Channel {
  id: number
  chatType: ChatTypes
  chatName: string
  chatDescription: string
  createdAt: Date
  updatedAt: Date
  createdBy: number
  discussion_id: number | null
  history: ChannelMessage[]
}

export interface Discussion {
  chatDescription: string
  chatName: string
  chatType: ChatTypes
  createdAt: string
  createdBy: number
  discussion_id: number
  id: number
  messages: ChannelMessage[]
}

export interface ShortUser {
  user_id: number
  username: string
  name: string
  profile_photo: string[]
  role?: 'user' | 'admin' | 'owner'
}

export interface UserChat {
  id: number
  chatType: ChatTypes
  chatName: string
  chatDescription: string
  createdAt: Date
  createdBy: number
  creator: ShortUser
  participants: ShortUser[]
  lastMessage: MessagesAttributes
  unreadMessages: number
  image?: string
}

export interface ChatParticipant {
  participantId: number
  chat_id: number
  user_id: number
  added_at: Date
  added_by: number
  role: string
  participant: ShortUser
}

export interface Like {
  type: string
  sender: string
  liked: string
  message: any | null
}

export interface ReadMessageAttributes {
  read_message_id?: number
  chat_id: number
  message_id: number
  user_id: number
  read_at: Date
  createdAt: Date
  updatedAt: Date
}

export interface ReadMessagesAttributes {
  chat_id: number
  counter: number
  user_id: number
}

export interface ComplaintAttributes {
  creatorId: string
  targetId: string
  type: ComplaintTypes
  message?: string
}

export interface CreateGroupChatAttributes {
  creator: number
  groupName: string
  groupDescription?: string
  participants: number[]
  image?: {
    base64: string
    type: string
    size: number
  }
}

export interface UpdateGroupChatAttributes {
  chatId: number
  groupName?: string
  groupDescription?: string
  participants?: number[]
  image?: {
    base64: string
    type: string
    size: number
  }
}

export interface ChangeRoleGroupChatAttributes {
  chatId: number
  userId: number
  role: 'admin' | 'user'
}

export interface AttachmentMessageData {
  chat_id: number
  user_id: number
  type?: string
  message_type?: string
  content?: string
  reply?: number
}

export interface Base64File {
  width: number
  height: number
  base64: string
  uri?: string
}

export enum ChatTypes {
  PRIVATE = 'private',
  GROUP = 'group',
  CHANNEL = 'channel'
}
