import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as SplashScreen from 'expo-splash-screen'

import { CustomHeader } from '@components'
import { useAppToast } from '@hooks'
import { navigationRef, RootStackParamList, TabNavigator } from '@navigation'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserId, selectCurrentUserStatus, selectUserChatId } from '@redux/selectors'
import {
  AddChat,
  ChangeGeneralInformation,
  ChangePassword,
  ChangeTellYourself,
  Chat,
  ChatSettings,
  Congratulations,
  CountryList,
  CreatePassword,
  DateCompResult,
  Education,
  EndSign,
  EnterCode,
  EnterPhone,
  EnterPhoneLogin,
  Financial,
  FirstScreen,
  GeneralInformation,
  Goals,
  Hobbies,
  InformationYourself,
  Languages,
  Location,
  MainProfile,
  Match,
  ModelScreen,
  Notification,
  NotificationScreen,
  PasswordLogin,
  PersonalInformation,
  PersonalPhoto,
  Preferences,
  Quality,
  ReligionChildren,
  RestoreAccount,
  Settings,
  SexualOrientation,
  StartSelection,
  TellYourself,
  VisitedPlaces,
  Works
} from '@screens'
import { apiService } from '@services/apiService'
import { getFromLS } from '@utils'

const Stack = createStackNavigator<RootStackParamList>()

export const AppNavigator = () => {
  const [startScreen, setStartScreen] = useState<keyof RootStackParamList>()
  const {
    getChannelByUserId,
    getCurrentUser,
    phoneConnect,
    chatConnect,
    getUsers,
    getUserChats,
    getNotificationHistory
  } = useDataLayer()
  const userStatus = useSelector(selectCurrentUserStatus)
  const currentUserId = useSelector(selectCurrentUserId)
  const currentUserChatId = useSelector(selectUserChatId)
  useAppToast()

  const detectUser = async () => {
    const token = await getFromLS('@token')
    if (token) {
      await apiService.setToken(token)
      return getCurrentUser()
    }
    setStartScreen('FirstScreen')
    SplashScreen.hideAsync()
  }

  useEffect(() => {
    if (userStatus === 'done') {
      phoneConnect(currentUserId)
      getChannelByUserId(currentUserChatId)
      chatConnect(currentUserChatId)
      getUsers()
      getUserChats(currentUserChatId)
      getNotificationHistory()
      setStartScreen('Tabs')
      SplashScreen.hideAsync()
    }

    if (userStatus === 'creating') {
      setStartScreen('EndSign')
      SplashScreen.hideAsync()
    }

    if (!userStatus) {
      setStartScreen('FirstScreen')
      SplashScreen.hideAsync()
    }
  }, [userStatus])

  useEffect(() => {
    detectUser()
  }, [])

  if (!startScreen) {
    return
  }

  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator
        initialRouteName={startScreen}
        screenOptions={{
          cardStyle: { backgroundColor: '#f3f2f9' },
          headerShown: false
        }}
      >
        {userStatus === 'done' && (
          <>
            <Stack.Screen
              name='Tabs'
              component={TabNavigator}
              options={() => ({
                header: () => <CustomHeader />,
                headerShown: true
              })}
            />
            <Stack.Screen name='MainProfile' component={MainProfile} />
            <Stack.Screen name='Notification' component={Notification} />
            <Stack.Screen name='Chat' component={Chat} />
            <Stack.Screen name='AddChat' component={AddChat} />
            <Stack.Screen name='ChatSettings' component={ChatSettings} />
            <Stack.Screen name='ModelScreen' component={ModelScreen} />
            <Stack.Screen name='Match' component={Match} />
            <Stack.Screen name='Settings' component={Settings} />
            <Stack.Screen name='ChangePassword' component={ChangePassword} />
            <Stack.Screen name='EnterCode' component={EnterCode} />
            <Stack.Screen name='CreatePassword' component={CreatePassword} />
            <Stack.Screen name='DateCompResult' component={DateCompResult} />
          </>
        )}
        {!userStatus && (
          <>
            <Stack.Screen name='FirstScreen' component={FirstScreen} />
            <Stack.Screen name='StartSelection' component={StartSelection} />
            <Stack.Screen name='EnterPhone' component={EnterPhone} />
            <Stack.Screen name='RestoreAccount' component={RestoreAccount} />
            <Stack.Screen name='EnterPhoneLogin' component={EnterPhoneLogin} />
            <Stack.Screen name='ChoseCountry' component={CountryList} />
            <Stack.Screen name='EnterCode' component={EnterCode} />
            <Stack.Screen name='CreatePassword' component={CreatePassword} />
            <Stack.Screen name='ChangePassword' component={ChangePassword} />
            <Stack.Screen name='PasswordLogin' component={PasswordLogin} />
          </>
        )}
        {userStatus && (
          <>
            <Stack.Screen name='EndSign' component={EndSign} />
            <Stack.Screen name='GeneralInformation' component={GeneralInformation} />
            <Stack.Screen name='Goals' component={Goals} />
            <Stack.Screen name='PersonalPhoto' component={PersonalPhoto} />
            <Stack.Screen name='Location' component={Location} />
            <Stack.Screen name='Languages' component={Languages} />
            <Stack.Screen name='SexualOrientation' component={SexualOrientation} />
            <Stack.Screen name='ReligionChildren' component={ReligionChildren} />
            <Stack.Screen name='Works' component={Works} />
            <Stack.Screen name='Financial' component={Financial} />
            <Stack.Screen name='Education' component={Education} />
            <Stack.Screen name='Hobbies' component={Hobbies} />
            <Stack.Screen name='VisitedPlaces' component={VisitedPlaces} />
            <Stack.Screen name='Preferences' component={Preferences} />
            <Stack.Screen name='Quality' component={Quality} />
            <Stack.Screen name='TellYourself' component={TellYourself} />
            <Stack.Screen name='Congratulations' component={Congratulations} />
            <Stack.Screen name='NotificationScreen' component={NotificationScreen} />
            <Stack.Screen name='PersonalInformation' component={PersonalInformation} />
            <Stack.Screen name='ChangeGeneralInformation' component={ChangeGeneralInformation} />
            <Stack.Screen name='ChangeTellYourself' component={ChangeTellYourself} />
            <Stack.Screen name='InformationYourself' component={InformationYourself} />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  )
}
