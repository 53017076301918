import { useEffect, useState } from 'react'
import { Image, KeyboardAvoidingView, Pressable, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { HideEye, ShowEye } from '@assets/images'
import { Button, HeaderInfo, TextInput, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'

const styles = StyleSheet.create({
  icon: {
    position: 'absolute',
    right: 20,
    top: 23
  },
  mainContainer: {
    flex: 1,
    height: '100%',
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: 50
  }
})

export const CreatePassword = () => {
  const { route, navigation } = useNavigator<'CreatePassword'>()
  const phone = route.params.phone
  const change = route.params?.change

  const { createPassword } = useDataLayer()
  const currentUser = useSelector(selectCurrentUserData)

  const [password, setPassword] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [isHidden, setIsHidden] = useState(true)

  const toggleIsHidden = () => setIsHidden(current => (current ? false : true))
  const toggleIcon = isHidden ? HideEye : ShowEye

  const handleChangePassword = (newPassword: string) => {
    setPassword(newPassword)
  }
  const goToEndSign = () => {
    navigation.navigate('EndSign')
  }

  useEffect(() => {
    if (!redirect || !currentUser) {
      return
    }

    if (currentUser.status !== 'creating' && currentUser.status !== 'done') {
      console.log('SOMETHING WENT WRONG ')
      return
    }

    if (change) {
      navigation.navigate('Tabs')
    } else {
      goToEndSign()
    }
  }, [currentUser, redirect])

  return (
    <View style={styles.mainContainer}>
      <HeaderInfo />
      <KeyboardAvoidingView behavior={isIOS ? 'padding' : undefined} style={{ flex: 1 }}>
        <Typography f27 bold marginBottom={8} marginTop={52}>
          Придумайте пароль
        </Typography>
        <Typography f15 normal color='#535354' marginBottom={24}>
          Пароль к аккаунту +{phone}
        </Typography>
        <View>
          <TextInput
            onChange={handleChangePassword}
            label='Пароль'
            placeholder='Введите пароль'
            isHidden={isHidden}
            rightIcon={() => (
              <Pressable style={styles.icon} onPress={toggleIsHidden}>
                <Image source={toggleIcon} style={{ height: 20, width: 20 }} />
              </Pressable>
            )}
          />
        </View>
        <View style={{ flexGrow: 1 }} />
        <Button
          text='Продолжить'
          disabled={password.length < 8}
          onPress={() => {
            goToEndSign()
            createPassword(phone, password)
            setRedirect(true)
          }}
        />
      </KeyboardAvoidingView>
    </View>
  )
}
