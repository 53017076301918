import { REGISTRATION_REQUEST, RegistrationRequestAction } from '@redux/types'
import { AuthMethods } from '@types'

export const registrationRequest = (
  authMethods: AuthMethods,
  onSocialLogin?: () => void
): RegistrationRequestAction => ({
  payload: { authMethods, onSocialLogin },
  type: REGISTRATION_REQUEST
})
