import { CHECK_CODE_REQUEST, CHECK_CODE_SUCCESS, CheckCodeRequestAction, CheckCodeSuccessAction } from '@redux/types'

export const checkCodeRequest = (phone: string, code: string): CheckCodeRequestAction => ({
  payload: { code, phone },
  type: CHECK_CODE_REQUEST
})

export const checkCodeSuccess = (data: string): CheckCodeSuccessAction => ({
  payload: data,
  type: CHECK_CODE_SUCCESS
})
