import { SafeAreaView, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { ActiveNotificationSvg, LogoSvg, LogoTextSvg, NotificationSvg } from '@assets/icons'
import { Avatar } from '@components'
import { useNavigator } from '@navigation'
import { NavigationState, useNavigationState } from '@react-navigation/native'
import { useDataLayer } from '@redux/dataLayer'
import { selectNotificationHistoryCounter, selectNotificationHistoryData } from '@redux/selectors'

const styles = StyleSheet.create({
  notificationCircle: {
    alignItems: 'center',
    backgroundColor: '#e6e5eb',
    borderRadius: 50,
    height: 40,
    justifyContent: 'center',
    width: 40
  }
})

const getActiveRouteName = (state: NavigationState): string => {
  const route = state.routes[state.index]

  if (route.state) {
    return getActiveRouteName(route.state as NavigationState)
  }

  return route.name
}

export const CustomHeader = () => {
  const { navigation } = useNavigator()
  const { readNotifications } = useDataLayer()
  const notificationCounter = useSelector(selectNotificationHistoryCounter)
  const notificationData = useSelector(selectNotificationHistoryData)
  const currentRouteName = useNavigationState(state => getActiveRouteName(state))
  const isTabs = currentRouteName === 'Tabs'
  const isSearch = currentRouteName === 'Search'
  const isMessage = currentRouteName === 'Message'
  const bgColor = isTabs || isSearch || isMessage ? '#f3f2f9' : '#fff'

  const onNotificationClick = () => {
    readNotifications(notificationData.map(el => el._id))
    navigation.navigate('Notification')
  }

  const NotificationLogo = notificationCounter ? ActiveNotificationSvg : NotificationSvg

  return (
    <SafeAreaView style={{ backgroundColor: bgColor }}>
      <View
        style={{
          alignItems: 'center',
          backgroundColor: bgColor,
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: 26,
          paddingVertical: 8
        }}
      >
        <StatusBar backgroundColor={bgColor} barStyle='dark-content' />
        <Avatar size={40} onPress={() => navigation.navigate('MainProfile')} />
        {isSearch ? <LogoSvg width={40} height={26} /> : <LogoTextSvg />}
        <TouchableOpacity style={styles.notificationCircle} onPress={onNotificationClick}>
          <NotificationLogo width={20} height={20} />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  )
}
