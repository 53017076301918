import { SafeAreaView, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'

import { BackSvg } from '@assets/icons'
import { ContentItem, InfoBlock, Typography } from '@components'
import { isAndroid } from '@components/utils'
import { useNavigator } from '@navigation'

const personalArray: ContentItem[] = [
  {
    nextScreen: 'ChangeGeneralInformation',
    title: 'Общая информация'
  },
  {
    nextScreen: 'ChangeTellYourself',
    title: 'Описание себя'
  }
]

const styles = StyleSheet.create({
  mainWrapper: {
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 15
  },
  topWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    paddingHorizontal: 30,
    paddingTop: isAndroid ? 15 : 5
  }
})

export const PersonalInformation = () => {
  const { navigation } = useNavigator<'MainProfile'>()

  const goToMainProfile = () => {
    navigation.navigate('MainProfile')
  }

  return (
    <SafeAreaView style={{ backgroundColor: '#F3F2F9' }}>
      <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />

      <View style={styles.topWrapper}>
        <TouchableOpacity onPress={goToMainProfile}>
          <BackSvg />
        </TouchableOpacity>

        <Typography f17 semibold>
          Личная информация
        </Typography>
        <View style={{ height: 40, width: 40 }} />
      </View>
      <View style={styles.mainWrapper}>
        <InfoBlock contentArray={personalArray} />
      </View>
    </SafeAreaView>
  )
}
