import { ProfileOptions } from '@utils'

type Genres = Record<string, ProfileOptions>

export enum GenreTypes {
  Cinema = 'Cinema',
  Music = 'Music',
  Literature = 'Literature'
}

export const GENRES: Genres = {
  [GenreTypes.Cinema]: [
    { id: 0, label: 'DC комикс', slug: 'dcComics' },
    { id: 1, label: 'Marvel кино', slug: 'marvelMovies' },
    { id: 2, label: 'Аниме', slug: 'anime' },
    { id: 3, label: 'Артхаус', slug: 'arthouse' },
    { id: 4, label: 'Биографии', slug: 'biographies' },
    { id: 5, label: 'Блокбастеры', slug: 'blockbusters' },
    { id: 6, label: 'Боевик', slug: 'action' },
    { id: 7, label: 'Вестерн', slug: 'western' },
    { id: 8, label: 'Властелин колец New', slug: 'lordOfTheRings' },
    { id: 9, label: 'Военный', slug: 'war' },
    { id: 10, label: 'Детектив', slug: 'detective' },
    { id: 11, label: 'Джеймс Бонд', slug: 'jamesBond' },
    { id: 12, label: 'Гари Поттер New', slug: 'harryPotter' },
    { id: 13, label: 'Документальный', slug: 'documentary' },
    { id: 14, label: 'Драма', slug: 'drama' },
    { id: 15, label: 'Игра престолов', slug: 'gameOfThrones' },
    { id: 16, label: 'Индийское кино', slug: 'indianCinema' },
    { id: 17, label: 'Исторический', slug: 'historical' },
    { id: 18, label: 'Кинокомедия', slug: 'movieComedy' },
    { id: 19, label: 'Кинокомикс', slug: 'comicBookMovies' },
    { id: 20, label: 'Кинороман', slug: 'movieNovel' },
    { id: 21, label: 'Мелодрама', slug: 'melodrama' },
    { id: 22, label: 'Мультфильм', slug: 'cartoon' },
    { id: 23, label: 'Мюзикл', slug: 'musical' },
    { id: 24, label: 'Научный', slug: 'scientific' },
    { id: 25, label: 'Немое кино', slug: 'silentMovies' },
    { id: 26, label: 'Приключения', slug: 'adventure' },
    { id: 27, label: 'Психологические триллеры', slug: 'psychologicalThrillers' },
    { id: 28, label: 'Семейный', slug: 'family' },
    { id: 29, label: 'Зарубежные Сериалы New', slug: 'foreignSeries' },
    { id: 30, label: 'Русские Сериалы New', slug: 'russianSeries' },
    { id: 31, label: 'Трагикомедии', slug: 'tragicomedies' },
    { id: 32, label: 'Триллеры', slug: 'thrillers' },
    { id: 33, label: 'Фантастика', slug: 'fantasy' },
    { id: 34, label: 'Фильмы ужасов', slug: 'horrorMovies' },
    { id: 35, label: 'Французкое кино', slug: 'frenchCinema' },
    { id: 36, label: 'Фэнтези', slug: 'fantasy' },
    { id: 37, label: 'Чёрно-белое кино', slug: 'blackAndWhiteMovies' },
    { id: 38, label: 'Экшены', slug: 'action' },
    { id: 39, label: 'Эротическое кино', slug: 'eroticMovies' },
    { id: 40, label: 'Турецкое кино', slug: 'turkishCinema' },
    { id: 41, label: 'Корейское кино', slug: 'koreanCinema' }
  ],
  [GenreTypes.Music]: [
    { id: 0, label: 'Dance / Electronic / House', slug: 'danceElectronicHouse' },
    { id: 1, label: 'Авторская песня', slug: 'authorSong' },
    { id: 2, label: 'Альтернативный рок', slug: 'alternativeRock' },
    { id: 3, label: 'Блюз', slug: 'blues' },
    { id: 4, label: 'Босса-нова', slug: 'bossaNova' },
    { id: 5, label: 'Готик-метал', slug: 'gothicMetal' },
    { id: 6, label: 'Готик-рок', slug: 'gothicRock' },
    { id: 7, label: 'Джаз', slug: 'jazz' },
    { id: 8, label: 'Джаз-рок', slug: 'jazzRock' },
    { id: 9, label: 'Диско', slug: 'disco' },
    { id: 10, label: 'Драм-н-бейс', slug: 'drumAndBass' },
    { id: 11, label: 'Духовная музыка', slug: 'spiritualMusic' },
    { id: 12, label: 'Инди-рок', slug: 'indieRock' },
    { id: 13, label: 'Инструментальная музыка', slug: 'instrumentalMusic' },
    { id: 14, label: 'Классическая музыка', slug: 'classicalMusic' },
    { id: 15, label: 'Клубная музыка', slug: 'clubMusic' },
    { id: 16, label: 'Латиноамериканская музыка', slug: 'latinMusic' },
    { id: 17, label: 'Лаунж', slug: 'lounge' },
    { id: 18, label: 'Метал', slug: 'metal' },
    { id: 19, label: 'Музыка 80-х', slug: 'musicOfThe80s' },
    { id: 20, label: 'Музыка 90-х', slug: 'musicOfThe90s' },
    { id: 21, label: 'Музыка 00-х', slug: 'musicOfThe2000s' },
    { id: 22, label: 'Опера', slug: 'opera' },
    { id: 23, label: 'Панк-рок', slug: 'punkRock' },
    { id: 24, label: 'Поп музыка', slug: 'popMusic' },
    { id: 25, label: 'Поп-рок', slug: 'popRock' },
    { id: 26, label: 'Реп', slug: 'rap' },
    { id: 27, label: 'Ретро', slug: 'retro' },
    { id: 28, label: 'Рок музыка', slug: 'rockMusic' },
    { id: 29, label: 'Рок-н-ролл', slug: 'rockNRoll' },
    { id: 30, label: 'Романс', slug: 'romance' },
    { id: 31, label: 'Хард\'н\'хэви', slug: 'hardAndHeavy' },
    { id: 32, label: 'Хип-хоп', slug: 'hipHop' },
    { id: 33, label: 'Чилаут', slug: 'chillout' },
    { id: 34, label: 'Шансон', slug: 'chanson' },
    { id: 35, label: 'Электронная музыка', slug: 'electronicMusic' },
    { id: 36, label: 'Этническая музыка', slug: 'ethnicMusic' }
  ],
  [GenreTypes.Literature]: [
    { id: 0, label: 'Античная литература', slug: 'ancientLiterature' },
    { id: 1, label: 'Бизнес-книги', slug: 'businessBooks' },
    { id: 2, label: 'Биографии и мемуары', slug: 'biographiesAndMemoirs' },
    { id: 3, label: 'Боевик, остросюжетные', slug: 'actionThrillers' },
    { id: 4, label: 'Военная литература', slug: 'militaryLiterature' },
    { id: 5, label: 'Детективы', slug: 'detectives' },
    { id: 6, label: 'Документальная литература', slug: 'documentaryLiterature' },
    { id: 7, label: 'Европейская литература', slug: 'europeanLiterature' },
    { id: 8, label: 'Зарубежная литература', slug: 'foreignLiterature' },
    { id: 9, label: 'Историческая проза', slug: 'historicalProse' },
    { id: 10, label: 'Исторический роман', slug: 'historicalNovel' },
    { id: 11, label: 'Киберпанк', slug: 'cyberpunk' },
    { id: 12, label: 'Классическая поэзия', slug: 'classicalPoetry' },
    { id: 13, label: 'Книги по психологии', slug: 'psychologyBooks' },
    { id: 14, label: 'Книги про вампиров', slug: 'vampireBooks' },
    { id: 15, label: 'Комиксы', slug: 'comics' },
    { id: 16, label: 'Красота, здоровье и спорт', slug: 'beautyHealthAndSports' },
    { id: 17, label: 'Любовный роман', slug: 'romanceNovel' },
    { id: 18, label: 'Медицина', slug: 'medicine' },
    { id: 19, label: 'Мистическая проза', slug: 'mysticalProse' },
    { id: 20, label: 'Мифы, эпос', slug: 'mythsAndEpics' },
    { id: 21, label: 'Научная фантастика', slug: 'scienceFiction' },
    { id: 22, label: 'Подкасты', slug: 'podcasts' },
    { id: 23, label: 'Приключения', slug: 'adventures' },
    { id: 24, label: 'Публицистическая литература', slug: 'publicisticLiterature' },
    { id: 25, label: 'Пьесы, драматургия', slug: 'playsDramaturgy' },
    { id: 26, label: 'Русская классическая литература', slug: 'russianClassicLiterature' },
    { id: 27, label: 'Саморазвитие, личностный рост', slug: 'selfDevelopmentPersonalGrowth' },
    { id: 28, label: 'Современная проза', slug: 'modernProse' },
    { id: 29, label: 'Триллер', slug: 'thriller' },
    { id: 30, label: 'Учебная и научная литература', slug: 'educationalAndScientificLiterature' },
    { id: 31, label: 'Фантастика', slug: 'fantasy' },
    { id: 32, label: 'Финансы и инвестиции', slug: 'financeAndInvestments' },
    { id: 33, label: 'Фэнтези', slug: 'fantasy' },
    { id: 34, label: 'Эзотерика', slug: 'esoterics' }
  ]
}
