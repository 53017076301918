/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { navigate } from '@navigation'
import { getCurrentUserSuccess } from '@redux/actions'
import { FINISH_REGISTRATION, FinishRegistrationRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { saveToLS } from '@utils'

type SagaEffects = CallEffect | PutEffect

function* finishRegistration(action: FinishRegistrationRequestAction): Generator<SagaEffects, void, any> {
  try {
    const user = action.payload.user
    yield call([apiService, apiService.createNewChatUser], {
      last_online: new Date().toString(),
      mongo_id: user._id,
      name: user.name,
      phone_number: user.phone || '',
      profile_photo: user.profileImage.map(el => el.name),
      username: user.login
    })
    const res = yield call([apiService, apiService.updateUser], {
      profile: user.profile,
      status: 'done'
    })
    saveToLS('@user', JSON.stringify(res.data))
    navigate(action.payload.next)
    yield put(getCurrentUserSuccess(res.data))
  } catch (error: any) {
    console.log('finishRegistrationSaga', error)
  }
}

export function* finishRegistrationSaga() {
  yield takeLatest<FinishRegistrationRequestAction>(FINISH_REGISTRATION, finishRegistration)
}
