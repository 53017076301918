import {
  CHANGE_ROLE_GROUP_CHAT_SUCCESS,
  CREATE_GROUP_CHAT_SUCCESS,
  GET_USER_CHATS_REQUEST,
  GET_USER_CHATS_SUCCESS,
  GroupChatSuccessActionTypes,
  LEAVE_GROUP_CHAT_SUCCESS,
  UPDATE_GROUP_CHAT_SUCCESS,
  UserChatActionTypes,
  UserChatsState
} from '@redux/types'
import { UserChat } from '@types'

const initialState: UserChatsState = []

export const chatsReducer = (
  state = initialState,
  action: UserChatActionTypes | GroupChatSuccessActionTypes
): UserChatsState => {
  switch (action.type) {
    case GET_USER_CHATS_REQUEST:
      return state
    case GET_USER_CHATS_SUCCESS:
      return action.payload as UserChat[]
    case CREATE_GROUP_CHAT_SUCCESS:
      return state.concat(action.payload)
    case UPDATE_GROUP_CHAT_SUCCESS:
    case CHANGE_ROLE_GROUP_CHAT_SUCCESS:
      return state.filter(chat => chat.id !== action.payload.id).concat(action.payload)
    case LEAVE_GROUP_CHAT_SUCCESS:
      return state.filter(chat => chat.id !== action.payload.id)
    default:
      return state
  }
}
