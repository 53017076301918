import { Pressable, StyleSheet, TouchableOpacityProps, View } from 'react-native'

import { Typography } from '@components'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: '#2f2f2f',
    borderRadius: 55,
    cursor: 'pointer',
    flexDirection: 'row',
    gap: 10,
    height: 60,
    justifyContent: 'center',
    marginBottom: 24,
    opacity: 1,
    paddingHorizontal: 24,
    width: '100%'
  },
  text: {
    color: '#fff',
    fontWeight: '700'
  }
})

interface ButtonProps extends TouchableOpacityProps {
  text?: string | false
  outlined?: boolean
  isView?: boolean
}

export const Button = ({ disabled, onPress, text, style, outlined, isView, children }: ButtonProps) => {
  const ButtonComponent = isView ? View : Pressable

  const outlinedStyles = {
    backgroundColor: 'transparent',
    borderColor: '#2f2f2f',
    borderWidth: 1
  }

  const stylesArray = [styles.container, style, outlined && outlinedStyles, disabled && { backgroundColor: '#e8e7ed' }]

  const customStyles = isView
    ? stylesArray
    : ({ pressed }: { pressed: boolean }) => [...stylesArray, pressed && { opacity: 0.5 }]

  return (
    <ButtonComponent disabled={disabled} onPress={onPress} style={customStyles}>
      {children}
      {text && (
        <Typography
          f16
          style={[styles.text, outlined && { color: '#2f2f2f' }, disabled && { color: '#a9a8ac' }]}
          disabled={disabled}
        >
          {text}
        </Typography>
      )}
    </ButtonComponent>
  )
}
