import { useMemo, useState } from 'react'
import { SafeAreaView, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { BackSvg, BasketBlockSvg, DocumentBlockSvg, ExitBlockSvg, LockBlockSvg, ShareBlockSvg } from '@assets/icons'
import {
  ContentItem,
  FacebookAuthButton,
  GoogleAuthButton,
  InfoBlock,
  InstagramAuthButton,
  ShareModal,
  Typography
} from '@components'
import { isAndroid, isWeb } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { ProfileActionModal } from '@screens'
import { saveToLS } from '@utils'

const generalArray: ContentItem[] = [
  {
    photoBlock: <DocumentBlockSvg />,
    title: 'Privacy policy',
    url: 'https://info.connectapp.me/privacy-policy'
  },
  {
    photoBlock: <DocumentBlockSvg />,
    title: 'Terms of Use',
    url: 'https://info.connectapp.me/terms-of-use'
  }
]

enum ModalTypes {
  DELETE = 'DELETE',
  LOGOUT = 'LOGOUT'
}

const styles = StyleSheet.create({
  mainWrapper: {
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: 15
  },
  socialBlock: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  socialBlocks: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 16,
    justifyContent: 'center',
    marginTop: 10
  },
  topWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    paddingHorizontal: 30,
    paddingTop: isAndroid ? 15 : 5
  }
})

export const Settings = () => {
  const { navigation } = useNavigator<'MainProfile'>()
  const user = useSelector(selectCurrentUserData)
  const { resetStore, userDelete } = useDataLayer()
  const [modalType, setModalType] = useState('')
  const [isShareModalVisible, setIsShareModalVisible] = useState(false)

  const modalData = useMemo(() => {
    const data = { description: '', submitLabel: '', title: '' }

    if (modalType === ModalTypes.LOGOUT) {
      data.title = 'Выйти'
      data.description = 'Вы уверенны, что хотите выйти из профиля?'
      data.submitLabel = 'Выйти'
    }

    if (modalType === ModalTypes.DELETE) {
      data.title = 'Удалить профиль'
      data.description = 'Вы уверенны, что хотите удалить ваш профиль навсегда?'
      data.submitLabel = 'Удалить'
    }

    return data
  }, [modalType])

  const etcArray: ContentItem[] = [
    {
      nextScreen: 'ChangePassword',
      photoBlock: <LockBlockSvg />,
      title: 'Смена пароля'
    },
    {
      nextScreen: 'ProfileActionModal',
      onPress: () => setModalType(ModalTypes.LOGOUT),
      photoBlock: <ExitBlockSvg />,
      title: 'Выйти'
    },
    {
      nextScreen: 'ProfileActionModal',
      onPress: () => setModalType(ModalTypes.DELETE),
      photoBlock: <BasketBlockSvg />,
      title: 'Удалить профиль'
    }
  ]

  const helpArray: ContentItem[] = [
    {
      onPress: () => setIsShareModalVisible(true),
      photoBlock: <ShareBlockSvg />,
      title: 'Поделиться'
    }
  ]

  const onModalSubmit = async () => {
    await saveToLS('@token', '')
    await saveToLS('@user', '')
    resetStore()

    if (modalType === ModalTypes.DELETE && user?._id) {
      userDelete(user._id)
    }
  }

  return (
    <SafeAreaView style={{ backgroundColor: '#F3F2F9' }}>
      <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />
      <View style={styles.topWrapper}>
        <TouchableOpacity onPress={() => navigation.navigate('MainProfile')}>
          <BackSvg />
        </TouchableOpacity>
        <Typography f17 semibold>
          Настройки
        </Typography>
        <View style={{ height: 40, width: 40 }} />
      </View>
      <View style={styles.mainWrapper}>
        <Typography f14 semibold marginBottom={12}>
          Общее
        </Typography>
        <InfoBlock contentArray={generalArray} />
        <Typography f14 semibold marginBottom={12} marginTop={20}>
          Помощь
        </Typography>
        <InfoBlock contentArray={helpArray} />
        {isWeb && (
          <>
            <Typography f14 semibold marginBottom={12} marginTop={20}>
              Подключить аккаунты соцсетей
            </Typography>
            <View style={styles.socialBlocks}>
              <View style={styles.socialBlock}>
                <GoogleAuthButton mode='connect' buttonStyles={{ marginBottom: 0 }} isDisabled={!!user?.googleAuth} />
                <Typography f14 marginTop={8}>
                  {user?.googleAuth?.token ? 'Активен' : 'Не активен'}
                </Typography>
              </View>
              <View style={styles.socialBlock}>
                <FacebookAuthButton
                  mode='connect'
                  buttonStyles={{ marginBottom: 0 }}
                  isDisabled={!!user?.facebookAuth}
                />
                <Typography f14 marginTop={8}>
                  {user?.facebookAuth?.token ? 'Активен' : 'Не активен'}
                </Typography>
              </View>
              <View style={styles.socialBlock}>
                <InstagramAuthButton
                  mode='connect'
                  buttonStyles={{ marginBottom: 0 }}
                  isDisabled={!!user?.instagramAuth}
                />
                <Typography f14 marginTop={8}>
                  {user?.instagramAuth?.token ? 'Активен' : 'Не активен'}
                </Typography>
              </View>
            </View>
          </>
        )}
        <Typography f14 semibold marginBottom={12} marginTop={20}>
          Прочее
        </Typography>
        <InfoBlock contentArray={etcArray} />
      </View>
      <ProfileActionModal
        visible={!!modalType}
        title={modalData.title}
        description={modalData.description}
        submitLabel={modalData.submitLabel}
        onSubmit={onModalSubmit}
        onClose={() => setModalType('')}
      />
      <ShareModal title='Поделиться' isVisible={isShareModalVisible} close={() => setIsShareModalVisible(false)} />
    </SafeAreaView>
  )
}
