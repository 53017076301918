import { useCallback, useMemo, useState } from 'react'
import { Image, Pressable, SafeAreaView, ScrollView, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import * as ImagePicker from 'expo-image-picker'

import { BackSvg, SearchSvg, TickSvg } from '@assets/icons'
import { Plus } from '@assets/images'
import { Avatar, Button, ModalWrapper, TextInput, Typography } from '@components'
import { Colors, isAndroid } from '@components/utils'
import { useMatchedUsers } from '@hooks'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectUserChatId } from '@redux/selectors'
import { SERVER_URL } from '@services/apiService'
import { ShortUser } from '@types'

const styles = StyleSheet.create({
  addParticipants: {
    height: 24,
    width: 24
  },
  badge: {
    backgroundColor: Colors.Gray,
    borderRadius: 5,
    color: Colors.White,
    overflow: 'hidden',
    paddingHorizontal: 10,
    paddingVertical: 5
  },
  image: {
    height: 80,
    width: 80
  },
  imageWrapper: {
    alignSelf: 'center',
    backgroundColor: Colors.Gray,
    borderRadius: 40,
    height: 80,
    marginBottom: 0,
    overflow: 'hidden',
    width: 80
  },
  info: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'center'
  },
  input: {
    height: 40,
    marginTop: 10
  },
  inputs: {
    flex: 1,
    marginBottom: 10
  },
  leave: {
    backgroundColor: Colors.Gray,
    borderRadius: 10,
    height: 50,
    justifyContent: 'center'
  },
  listItem: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'space-between',
    paddingVertical: 10
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingHorizontal: 26
  },
  option: {
    alignItems: 'center',
    borderTopColor: '#fff',
    borderTopWidth: 1,
    flexGrow: 1,
    justifyContent: 'center',
    padding: 18,
    width: '100%'
  },
  participantsTitle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20
  },
  save: {
    marginTop: 10
  },
  saveDescription: {
    alignSelf: 'center',
    color: Colors.Gray,
    maxWidth: 250,
    textAlign: 'center'
  },
  topWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingHorizontal: 30,
    paddingTop: isAndroid ? 15 : 5
  },
  userInfo: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10
  }
})

export const ChatSettings = () => {
  const { route, navigation } = useNavigator<'ChatSettings'>()
  const { chat } = route.params

  const { updateGroupChat, leaveGroupChat, changeRoleGroupChat } = useDataLayer()
  const insets = useSafeAreaInsets()
  const userChatId = useSelector(selectUserChatId)
  const { matchedUsers } = useMatchedUsers()

  const [groupName, setGroupName] = useState(chat.chatName || '')
  const [groupDescription, setGroupDescription] = useState(chat.chatDescription || '')
  const [image, setImage] = useState<any>(chat.image || null)
  const [participants, setParticipants] = useState(chat.participants || [])
  const [selectedUser, setSelectedUser] = useState<ShortUser | null>(null)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false)
  const [isImageSelectModalOpen, setIsImageSelectModalOpen] = useState(false)

  const isOwner = chat.createdBy === userChatId
  const isOwnerOrAdmin = useMemo(() => isOwner || participants.some(user => user.role === 'admin'), [participants])

  const closeModal = useCallback(() => {
    setSelectedUser(null)
    setIsParticipantsModalOpen(false)
    setIsImageSelectModalOpen(false)
  }, [])

  const pickImage = useCallback(async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      base64: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1
    })

    if (!result.canceled) {
      setImage(result.assets[0])
    }

    closeModal()
  }, [])

  const updateChat = useCallback(() => {
    if (!isOwnerOrAdmin) {
      navigation.goBack()
      return
    }

    const groupChatAttributes: any = {
      chatId: chat.id,
      groupDescription,
      groupName,
      participants: participants.map(user => user.user_id)
    }

    if (image && image.base64 && image.type && image.fileSize) {
      groupChatAttributes.image = {
        base64: image.base64,
        size: image.fileSize,
        type: image.type
      }
    }
    updateGroupChat(groupChatAttributes)

    navigation.navigate('Chat', {
      chat: {
        ...chat,
        chatDescription: groupDescription,
        chatName: groupName,
        image: image?.uri,
        participants
      },
      userId: userChatId
    })
  }, [groupName, groupDescription, image, participants])

  const leaveChat = useCallback(() => leaveGroupChat(chat.id), [])

  const removeUserFromChat = useCallback(() => {
    if (!selectedUser) {
      return
    }

    setParticipants(prev => prev.filter(user => user.user_id !== selectedUser.user_id))
    setSelectedUser(null)
  }, [selectedUser])

  const addParticipants = useCallback(() => {
    setParticipants(prev => [...prev, ...matchedUsers.filter(user => selectedIds.includes(user.user_id))])
    setIsParticipantsModalOpen(false)
  }, [selectedIds])

  const toggleId = useCallback(
    (_id: number) => setSelectedIds(prev => (prev.includes(_id) ? prev.filter(id => id !== _id) : [...prev, _id])),
    [selectedIds]
  )

  const switchAdmin = useCallback(() => {
    if (!selectedUser) {
      return
    }

    changeRoleGroupChat({
      chatId: chat.id,
      role: selectedUser?.role === 'admin' ? 'user' : 'admin',
      userId: selectedUser?.user_id
    })

    setParticipants(prev =>
      prev.map(user => {
        if (user.user_id !== selectedUser.user_id) {
          return user
        }

        return {
          ...user,
          role: user.role === 'admin' ? 'user' : 'admin'
        }
      })
    )

    setSelectedUser(null)
  }, [selectedUser])

  const renderedUsers = useMemo(
    () =>
      participants.map((user: ShortUser) => (
        <Pressable key={user.user_id} disabled={!isOwner} onPress={() => setSelectedUser(user)} style={styles.listItem}>
          <View style={styles.userInfo}>
            <Avatar size={60} uri={user.profile_photo[0]} />
            <Typography marginLeft={8}>{user.name}</Typography>
          </View>
          {user.role === 'owner' && (
            <Typography style={[styles.badge, { backgroundColor: Colors.Red }]} f12>
              Владелец
            </Typography>
          )}
          {user.role === 'admin' && (
            <Typography style={[styles.badge, { backgroundColor: Colors.Green }]} f12>
              Администратор
            </Typography>
          )}
        </Pressable>
      )),
    [chat, participants]
  )

  const renderedParticipantsToAdd = useMemo(
    () =>
      matchedUsers
        .filter((user: ShortUser) => !participants.some(participant => participant.user_id === user.user_id))
        .map((user: ShortUser) => (
          <Pressable key={user.user_id} onPress={() => toggleId(user.user_id)} style={styles.listItem}>
            <View style={styles.userInfo}>
              <Avatar size={60} uri={user.profile_photo[0]} />
              <Typography>{user.name}</Typography>
            </View>
            {selectedIds.includes(user.user_id) && <TickSvg width={20} height={20} />}
          </Pressable>
        )),
    [matchedUsers, participants, selectedIds]
  )

  const renderImage = useMemo(() => {
    if (image === 'delete') {
      return <SearchSvg fill='white' />
    }

    if (image) {
      return <Image source={{ uri: image.uri || `${SERVER_URL}/${image}` }} style={styles.image} />
    }

    return <SearchSvg fill='white' />
  }, [image])

  return (
    <>
      <SafeAreaView style={{ flex: 1, paddingBottom: insets.bottom }}>
        <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />
        <View style={styles.topWrapper}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <BackSvg />
          </TouchableOpacity>
          <Typography f17 semibold>
            Настройки чата
          </Typography>
          <View style={{ height: 40, width: 40 }} />
        </View>
        <View style={styles.mainWrapper}>
          <View style={styles.info}>
            <Button onPress={() => setIsImageSelectModalOpen(true)} style={styles.imageWrapper} disabled={!isOwner}>
              {renderImage}
            </Button>
            <View style={styles.inputs}>
              <TextInput
                style={styles.input}
                defaultValue={groupName}
                placeholder='Название'
                onChange={t => setGroupName(t)}
                disabled={!isOwnerOrAdmin}
              />
              <TextInput
                style={styles.input}
                defaultValue={groupDescription}
                placeholder='Описание'
                onChange={t => setGroupDescription(t)}
                disabled={!isOwnerOrAdmin}
              />
            </View>
          </View>
          {!isOwner && <Button onPress={leaveChat} text='Покинуть чат' style={styles.leave} />}
          <View style={styles.participantsTitle}>
            <Typography f17 semibold>
              Участники ({participants.length})
            </Typography>
            {renderedParticipantsToAdd.length > 0 && (
              <Pressable onPress={() => setIsParticipantsModalOpen(true)}>
                <Image source={Plus} style={styles.addParticipants} />
              </Pressable>
            )}
          </View>
          <ScrollView showsVerticalScrollIndicator={false}>{renderedUsers}</ScrollView>
          {isOwnerOrAdmin && (
            <Typography f10 style={styles.saveDescription}>
              Любые изменения в настройках чата будут применены только после сохранения
            </Typography>
          )}
          <Button style={styles.save} onPress={updateChat} text={isOwnerOrAdmin ? 'Сохранить' : 'Назад'} />
        </View>
      </SafeAreaView>

      {/* On user click options modal */}
      <ModalWrapper isVisible={!!selectedUser && selectedUser?.role !== 'owner'} close={closeModal}>
        <Typography>{selectedUser?.name}</Typography>
        <TouchableOpacity style={[styles.option, { marginTop: 20 }]} onPress={switchAdmin}>
          <Typography f16 style={{ fontWeight: '700' }}>
            {selectedUser?.role === 'admin' ? 'Снять роль администратора' : 'Назначить администратором'}
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity style={styles.option} onPress={removeUserFromChat}>
          <Typography f16 style={{ fontWeight: '700' }}>
            Исключить из чата
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity style={styles.option} onPress={closeModal}>
          <Typography f16 style={{ opacity: 0.5 }}>
            Отмена
          </Typography>
        </TouchableOpacity>
      </ModalWrapper>

      {/* Add participants modal */}
      <ModalWrapper isVisible={isParticipantsModalOpen} close={closeModal}>
        <Typography f17 semibold style={{ marginBottom: 20 }}>
          Добавить участников
        </Typography>
        <ScrollView style={{ width: '100%' }} showsVerticalScrollIndicator={false}>
          {renderedParticipantsToAdd}
        </ScrollView>

        <Button style={styles.save} disabled={!selectedIds.length} text='Добавить' onPress={addParticipants} />
      </ModalWrapper>

      {/* Image options modal */}
      <ModalWrapper hideCloseButton isVisible={isImageSelectModalOpen} close={closeModal}>
        <TouchableOpacity style={[styles.option, { borderTopWidth: 0 }]} onPress={pickImage}>
          <Typography f16 style={{ fontWeight: '700' }}>
            Загрузить изображение
          </Typography>
        </TouchableOpacity>
        {image && image !== 'delete' && (
          <TouchableOpacity
            style={styles.option}
            onPress={() => {
              setImage('delete')
              closeModal()
            }}
          >
            <Typography f16 style={{ color: Colors.Red, fontWeight: '700' }}>
              Удалить
            </Typography>
          </TouchableOpacity>
        )}
        <TouchableOpacity style={styles.option} onPress={closeModal}>
          <Typography f16 style={{ opacity: 0.5 }}>
            Отмена
          </Typography>
        </TouchableOpacity>
      </ModalWrapper>
    </>
  )
}
