import { Pressable, StyleSheet, View } from 'react-native'

import { Typography } from '@components'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderColor: '#A2A0AC',
    borderRadius: 40,
    borderWidth: 0.3,
    flexDirection: 'row',
    height: 51,
    justifyContent: 'space-between',
    marginBottom: 16,
    paddingHorizontal: 20,
    width: '100%'
  },
  radioWrapper: {
    alignItems: 'center',
    borderColor: '#a2a0ac',
    borderRadius: 10,
    borderWidth: 1,
    height: 18,
    justifyContent: 'center',
    width: 18
  },
  selectedContainer: {
    borderColor: '#2F2F2F',
    borderWidth: 1
  },
  selectedRadio: {
    color: '#2C2C2C'
  }
})

interface RadioButtonProps {
  textRadio: string
  isSelected: boolean
  onPress: () => void
  showImage?: boolean
}

export const CustomRadioButton = ({ textRadio, isSelected, onPress, showImage = true }: RadioButtonProps) => (
  <Pressable style={[styles.container, isSelected && styles.selectedContainer]} onPress={onPress}>
    <Typography f14 style={isSelected && styles.selectedRadio}>
      {textRadio}
    </Typography>
    {showImage && (
      <View style={styles.radioWrapper}>
        {isSelected && <View style={{ backgroundColor: '#2f2f2f', borderRadius: 10, height: 12, width: 12 }} />}
      </View>
    )}
  </Pressable>
)
