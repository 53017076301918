import { PropsWithChildren } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { ChildrenSvg, CollegeSvg, GenderSvg, HandsSvg, LocationSvg, LoveSvg } from '@assets/icons'
import { Chip, SFlex, Typography } from '@components'
import { Colors, formatUserName } from '@components/utils'
import { CompResult, UserProfile } from '@types'
import { DictionaryEnum, useLabels } from '@utils'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 26,
    paddingTop: 18
  },
  jobInfo: {
    backgroundColor: Colors.GrayBg,
    borderRadius: 40,
    marginTop: 10,
    paddingHorizontal: 26,
    paddingVertical: 24
  },
  joblocation: {
    backgroundColor: Colors.White,
    borderRadius: 14,
    marginBottom: 12
  }
})

interface InfoTabProps {
  user: UserProfile
}

const BlockTitle = ({ children, ...rest }: PropsWithChildren) => (
  <Typography f16 medium color='#7b7b7d' marginBottom={16} {...rest}>
    {children}
  </Typography>
)

export const InfoTab = ({ user }: InfoTabProps) => {
  const insets = useSafeAreaInsets()
  const l = useLabels()

  const createJobLocation = (locationText: string, isCommonTravel?: boolean) => {
    return (
      <View
        key={locationText}
        style={[styles.joblocation, { backgroundColor: isCommonTravel ? Colors.White : '#F3F2F8' }]}
      >
        <SFlex flex={1} gap={10} paddingTop={17} paddingBottom={17} paddingLeft={18} paddingRight={18}>
          <LocationSvg />
          <SFlex flex={1}>
            <Typography f16 normal numberOfLines={1}>
              {locationText}
            </Typography>
          </SFlex>
        </SFlex>
      </View>
    )
  }

  const common: Partial<CompResult> = user.results[user.best] || {
    attraction: 0,
    bookGenre: null,
    hobbies: [],
    languages: null,
    movieGenre: null,
    musicGenre: null,
    places: [],
    travel: [],
    valuables: []
  }

  const filteredMovieGenre = user.profile.movieGenre.filter(
    genre => common.movieGenre && !common.movieGenre.includes(genre)
  )
  const filteredBookGenre = user.profile.bookGenre.filter(
    genre => common.bookGenre && !common.bookGenre.includes(genre)
  )

  const filteredMusicGenre = user.profile.musicGenre.filter(
    genre => common.musicGenre && !common.musicGenre.includes(genre)
  )
  const filteredValuables = user.profile.valuables.filter(valuable => !common.valuables?.includes(valuable))

  const filteredHobbies = user.profile.hobbies.filter(hobby => !common.hobbies?.includes(hobby))

  const filteredLanguages = user.profile.langs.filter(lang => !common.languages?.includes(lang))

  const filteredTravel = user.profile.travel.filter(
    travelProfile => !common.travel?.some(travelCommon => travelCommon.name === travelProfile.name)
  )
  const filteredPlaces = user.profile.places.filter(
    placeProfile => !common.places?.some(placeCommon => placeCommon.name === placeProfile.name)
  )

  const formattedUserName = formatUserName(user.name)

  return (
    <View style={[styles.container, { paddingBottom: insets.bottom + 90 }]}>
      {user.profile.about && (
        <>
          <BlockTitle>Информация о себе</BlockTitle>
          <Typography f15 normal color='#535354' marginBottom={40}>
            {user.profile.about}
          </Typography>
        </>
      )}

      <BlockTitle>Общая информация</BlockTitle>
      <SFlex flexWrap='wrap' gap={12} marginBottom={40}>
        {user.profile.sexuality !== -1 && user.profileVisability.sexuality && (
          <Chip
            leftIcon={<LoveSvg style={{ paddingHorizontal: 10 }} />}
            label={l(user.profile.sexuality, DictionaryEnum.orientation)}
            fontSize={16}
          />
        )}
        {user.profile.lgbtTolerant !== -1 && user.profileVisability.lgbtTolerant && (
          <Chip
            leftIcon={<GenderSvg style={{ paddingHorizontal: 10 }} />}
            label={l(user.profile.lgbtTolerant, DictionaryEnum.lgbtTolerant)}
            fontSize={16}
          />
        )}
        {user.profile.educationGrade !== -1 && (
          <Chip
            leftIcon={<CollegeSvg style={{ paddingHorizontal: 10 }} />}
            label={l(user.profile.educationGrade, DictionaryEnum.education)}
            fontSize={16}
          />
        )}
        {user.profile.religion !== -1 && (
          <Chip
            leftIcon={<HandsSvg style={{ paddingHorizontal: 10 }} />}
            label={l(user.profile.religion, DictionaryEnum.religion)}
            fontSize={16}
          />
        )}
        {user.profile.hasChildren !== -1 && user.profileVisability.hasChildren && (
          <Chip
            leftIcon={<ChildrenSvg style={{ paddingHorizontal: 10 }} />}
            label={l(user.profile.hasChildren, DictionaryEnum.children)}
            fontSize={16}
          />
        )}
      </SFlex>

      {((common.hobbies?.length && common.hobbies?.length > 0) || filteredHobbies.length > 0) && (
        <>
          <BlockTitle>Увлечения</BlockTitle>
          <SFlex flexWrap='wrap' gap={12} marginBottom={40}>
            {[...(common.hobbies || []), ...filteredHobbies].map(el => (
              <Chip
                label={l(el, DictionaryEnum.hobby)}
                bgColor={common.hobbies?.includes(el) ? '#fff' : undefined}
                textColor={common.hobbies?.includes(el) ? '#2F2F2F' : undefined}
                key={el}
                fontSize={16}
              />
            ))}
          </SFlex>
        </>
      )}

      {common.valuables && (
        <>
          <BlockTitle>Ценности</BlockTitle>
          <SFlex flexWrap='wrap' gap={12} marginBottom={40}>
            {[...common.valuables, ...filteredValuables].map(el => (
              <Chip
                key={el}
                label={l(el, DictionaryEnum.valuable)}
                bgColor={common.valuables?.includes(el) ? '#fff' : undefined}
                textColor={common.valuables?.includes(el) ? '#2F2F2F' : undefined}
                fontSize={16}
                paddingHorizontal={18}
              />
            ))}
          </SFlex>
        </>
      )}

      <BlockTitle>Язык</BlockTitle>
      <SFlex flexWrap='wrap' gap={12} marginBottom={20}>
        {[...(common.languages?.length ? common.languages : []), ...filteredLanguages].map(el => (
          <Chip
            key={el}
            label={l(el, DictionaryEnum.language)}
            bgColor={common.languages?.includes(el) ? '#fff' : undefined}
            textColor={common.languages?.includes(el) ? '#2F2F2F' : undefined}
            fontSize={16}
            paddingHorizontal={18}
          />
        ))}
      </SFlex>

      {user.profile.workPosition && (
        <View style={styles.jobInfo}>
          <BlockTitle>{formattedUserName} работает в</BlockTitle>
          <Typography f24 bold marginBottom={28}>
            {user.profile.workName}
          </Typography>

          <BlockTitle>На должности</BlockTitle>
          <Typography f24 bold marginBottom={12}>
            {user.profile.workPosition}
          </Typography>

          <BlockTitle>Местоположение</BlockTitle>
          {createJobLocation(user.profile.workGeo.name)}
        </View>
      )}
      {user.profile.places.length || (common.places && common.places.length) ? (
        <View style={styles.jobInfo}>
          <BlockTitle>
            {formattedUserName} посещал{user.sex === 'male' ? '' : 'а'}
          </BlockTitle>
          {common.places && common.places.map(el => createJobLocation(el.name, true))}
          {filteredPlaces.map(el => createJobLocation(el.name, false))}
        </View>
      ) : null}

      {user.profile.travel.length || (common.travel && common.travel.length) ? (
        <View style={styles.jobInfo}>
          <BlockTitle>
            {formattedUserName} путешествовал{user.sex === 'male' ? '' : 'а'}
          </BlockTitle>
          {common.travel && common.travel.map(el => createJobLocation(el.name, true))}
          {filteredTravel.map(el => createJobLocation(el.name, false))}
        </View>
      ) : null}
      <View style={styles.jobInfo}>
        {user.profile.bookGenre.length > 0 && (
          <>
            <BlockTitle>{formattedUserName} предпочитает книги</BlockTitle>
            <SFlex flexWrap='wrap' gap={12} marginBottom={20}>
              {[...(common.bookGenre?.length ? common.bookGenre : user.profile.bookGenre), ...filteredBookGenre].map(
                el => (
                  <Chip
                    key={el}
                    label={l(el, DictionaryEnum.literature)}
                    bgColor={common.bookGenre?.includes(el) ? '#fff' : '#F3F2F8'}
                    textColor={common.bookGenre?.includes(el) ? '#2F2F2F' : undefined}
                    fontSize={16}
                  />
                )
              )}
            </SFlex>
          </>
        )}

        {user.profile.movieGenre.length > 0 && (
          <>
            <BlockTitle>{formattedUserName} предпочитает фильмы</BlockTitle>
            <SFlex flexWrap='wrap' gap={12} marginBottom={20}>
              {[
                ...(common.movieGenre?.length ? common.movieGenre : user.profile.movieGenre),
                ...filteredMovieGenre
              ].map(el => (
                <Chip
                  key={el}
                  label={l(el, DictionaryEnum.cinema)}
                  bgColor={common.movieGenre?.includes(el) ? '#fff' : '#F3F2F8'}
                  textColor={common.movieGenre?.includes(el) ? '#2F2F2F' : undefined}
                  fontSize={16}
                />
              ))}
            </SFlex>
          </>
        )}

        {user.profile.musicGenre.length > 0 && (
          <>
            <BlockTitle>{formattedUserName} предпочитает музыку</BlockTitle>
            <SFlex flexWrap='wrap' gap={12} marginBottom={20}>
              {[
                ...(common.musicGenre?.length ? common.musicGenre : user.profile.musicGenre),
                ...filteredMusicGenre
              ].map(el => (
                <Chip
                  key={el}
                  label={l(el, DictionaryEnum.music)}
                  bgColor={common.musicGenre?.includes(el) ? '#fff' : '#F3F2F8'}
                  textColor={common.musicGenre?.includes(el) ? '#2F2F2F' : undefined}
                  fontSize={16}
                />
              ))}
            </SFlex>
          </>
        )}
      </View>
    </View>
  )
}
