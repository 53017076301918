import { useDispatch } from 'react-redux'

import { PageAttributes } from '@navigation'
import {
  blockUserRequest,
  changePasswordRequest,
  changeRoleGroupChatRequest,
  chatConnectRequest,
  checkCodeRequest,
  clearAllUsersRequest,
  connectSocialRequest,
  createChatUserRequest,
  createComplaintRequest,
  createGroupChatRequest,
  createPasswordRequest,
  deleteFullChatUserRequest,
  deleteImageRequest,
  deleteMessagesRequest,
  finishRegistrationAction,
  generalInformationRequest,
  getAllDeletedUsersRequest,
  getAllUsersRequest,
  getChannelByUserIdRequest,
  getCurrentUserRequest,
  getDateCompatibilityRequest,
  getFriendsChannelsMessagesRequest,
  getFullChatUserRequest,
  getMessagesForChatRequest,
  getMyChatsRequest,
  getMyLikesRequest,
  getUserChatsRequest,
  getUsersRequest,
  leaveGroupChatRequest,
  loginRequest,
  notificationAction,
  phoneConnectRequest,
  readNotificationsRequest,
  registrationRequest,
  resetStoreAction,
  restoreRequest,
  sendAttachmentsToChatRequest,
  sendDiscussionMessageRequest,
  sendLikeRequest,
  sendMessageRequest,
  sendReadMessageRequest,
  sendReadMessagesRequest,
  unblockUserRequest,
  updateGroupChatRequest,
  updateUserRequest,
  uploadImageRequest,
  userDeleteRequest
} from '@redux/actions'
import { CreateChatUserAtributes, SendReadMessage, SendReadMessages } from '@redux/types'
import {
  AttachmentMessageData,
  AuthMethods,
  Base64File,
  ChangeRoleGroupChatAttributes,
  ComplaintAttributes,
  CreateGroupChatAttributes,
  DeleteMessagesAttributes,
  DiscussionMessage,
  Like,
  MessagesAttributes,
  SocialAuth,
  UpdateGroupChatAttributes,
  UserProfile
} from '@types'

export const useDataLayer = () => {
  const dispatch = useDispatch()

  const getUsers = (filter: any = null) => {
    dispatch(getUsersRequest(filter))
  }

  const getAllUsers = (login: string, filter: any = null) => {
    dispatch(getAllUsersRequest(login, filter))
  }

  const getAllDeletedUsers = () => {
    dispatch(getAllDeletedUsersRequest())
  }

  const clearAllUsers = () => {
    dispatch(clearAllUsersRequest())
  }

  const getCurrentUser = () => {
    dispatch(getCurrentUserRequest())
  }

  const registration = (authMethods: AuthMethods, onSocialLogin?: () => void) => {
    dispatch(registrationRequest(authMethods, onSocialLogin))
  }

  const connectSocial = (params: SocialAuth) => {
    dispatch(connectSocialRequest(params))
  }

  const login = (authMethods: AuthMethods) => {
    dispatch(loginRequest(authMethods))
  }

  const checkCode = (phone: string, code: string) => {
    dispatch(checkCodeRequest(phone, code))
  }

  const createPassword = (phone: string, password: string) => {
    dispatch(createPasswordRequest(phone, password))
  }

  const restore = (phone: string, next: PageAttributes) => {
    dispatch(restoreRequest(phone, next))
  }

  const generalInformation = (name: string, dateOfBirth: string, sex: string, log: string, next: PageAttributes) => {
    dispatch(generalInformationRequest(name, dateOfBirth, sex, log, next))
  }

  const changePassword = (phone: string, oldPassword: string, newPassword: string, next: PageAttributes) => {
    dispatch(changePasswordRequest(phone, oldPassword, newPassword, next))
  }

  const uploadImage = (files: FormData) => {
    dispatch(uploadImageRequest(files))
  }

  const deleteImage = (id: string) => {
    dispatch(deleteImageRequest(id))
  }

  const userDelete = (userId: string) => {
    dispatch(userDeleteRequest(userId))
  }

  const userUpdate = (update: any, next: PageAttributes) => {
    dispatch(updateUserRequest(update, next))
  }

  const getMyLikes = () => {
    dispatch(getMyLikesRequest())
  }

  const getNotificationHistory = () => {
    dispatch(getMyChatsRequest())
  }

  const readNotifications = (likes: string[]) => {
    dispatch(readNotificationsRequest(likes))
  }

  const sendLike = (payload: Like) => {
    dispatch(sendLikeRequest(payload))
  }

  const getUserChats = (id: number) => {
    dispatch(getUserChatsRequest(id))
  }

  const getChannelByUserId = (chatUserId: number) => {
    dispatch(getChannelByUserIdRequest(chatUserId))
  }

  const getFriendsChannelsMessages = (chatUserId: number) => {
    dispatch(getFriendsChannelsMessagesRequest(chatUserId))
  }

  const phoneConnect = (id: string) => {
    dispatch(phoneConnectRequest(id))
  }

  const chatConnect = (id: number) => {
    dispatch(chatConnectRequest(id))
  }

  const getChatMessages = (id: number) => {
    dispatch(getMessagesForChatRequest(id))
  }

  const sendMessage = (message: MessagesAttributes) => {
    dispatch(sendMessageRequest(message))
  }

  const sendDiscussionMessage = (message: DiscussionMessage) => {
    dispatch(sendDiscussionMessageRequest(message))
  }

  const deleteMessages = (data: DeleteMessagesAttributes) => {
    dispatch(deleteMessagesRequest(data))
  }

  const sendReadMessage = (message: SendReadMessage) => {
    dispatch(sendReadMessageRequest(message))
  }

  const sendReadMessages = (data: SendReadMessages) => {
    dispatch(sendReadMessagesRequest(data))
  }

  const sendAttachmentsToChat = (data: AttachmentMessageData, files: Base64File[]) => {
    dispatch(sendAttachmentsToChatRequest(data, files))
  }

  const createNewChatUser = (data: CreateChatUserAtributes) => {
    dispatch(createChatUserRequest(data))
  }

  const clearNotification = () => {
    dispatch(notificationAction(null))
  }

  const resetStore = () => {
    dispatch(resetStoreAction())
  }

  const finishRegistration = (user: UserProfile, next: PageAttributes) => {
    dispatch(finishRegistrationAction(user, next))
  }

  const getFullChatUser = (id: number) => {
    dispatch(getFullChatUserRequest(id))
  }

  const deleteFullChatUser = () => {
    dispatch(deleteFullChatUserRequest())
  }

  const dateCompatibility = (date: string, next: PageAttributes) => {
    dispatch(getDateCompatibilityRequest(date, next))
  }

  const createComplaint = (data: ComplaintAttributes) => {
    dispatch(createComplaintRequest(data))
  }

  const blockUser = (userId: string) => {
    dispatch(blockUserRequest(userId))
  }

  const unblockUser = (userId: string) => {
    dispatch(unblockUserRequest(userId))
  }

  const createGroupChat = (data: CreateGroupChatAttributes) => {
    dispatch(createGroupChatRequest(data))
  }

  const updateGroupChat = (data: UpdateGroupChatAttributes) => {
    dispatch(updateGroupChatRequest(data))
  }

  const leaveGroupChat = (userChatId: number) => {
    dispatch(leaveGroupChatRequest(userChatId))
  }

  const changeRoleGroupChat = (data: ChangeRoleGroupChatAttributes) => {
    dispatch(changeRoleGroupChatRequest(data))
  }

  return {
    blockUser,
    changePassword,
    changeRoleGroupChat,
    chatConnect,
    checkCode,
    clearAllUsers,
    clearNotification,
    connectSocial,
    createComplaint,
    createGroupChat,
    createNewChatUser,
    createPassword,
    dateCompatibility,
    deleteFullChatUser,
    deleteImage,
    deleteMessages,
    finishRegistration,
    generalInformation,
    getAllDeletedUsers,
    getAllUsers,
    getChannelByUserId,
    getChatMessages,
    getCurrentUser,
    getFriendsChannelsMessages,
    getFullChatUser,
    getMyLikes,
    getNotificationHistory,
    getUserChats,
    getUsers,
    leaveGroupChat,
    login,
    phoneConnect,
    readNotifications,
    registration,
    resetStore,
    restore,
    sendAttachmentsToChat,
    sendDiscussionMessage,
    sendLike,
    sendMessage,
    sendReadMessage,
    sendReadMessages,
    unblockUser,
    updateGroupChat,
    uploadImage,
    userDelete,
    userUpdate
  }
}
