import { StyleSheet, View } from 'react-native'
import moment from 'moment'

import { Avatar, Typography } from '@components'

const styles = StyleSheet.create({
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  userInfo: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8
  }
})

interface FeedPostHeaderProps {
  userName?: string
  imageUri?: string
  timestamp?: string
}

export const FeedPostHeader = ({ userName, imageUri, timestamp }: FeedPostHeaderProps) => (
  <View style={styles.header}>
    <View style={styles.userInfo}>
      <Avatar size={20} uri={imageUri} />
      <Typography f12 bold>
        {userName}
      </Typography>
    </View>
    <Typography f12 opacity={0.5}>
      {moment(timestamp).format('DD.MM.YYYY HH:mm')}
    </Typography>
  </View>
)
