import { useEffect, useState } from 'react'
import { Image, KeyboardAvoidingView, Pressable, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { HideEye, ShowEye } from '@assets/images'
import { Button, HeaderInfo, TextInput, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: 50
  },
  icon: {
    position: 'absolute',
    right: 20,
    top: 23
  }
})

export const PasswordLogin = () => {
  const { route, navigation } = useNavigator<'PasswordLogin'>()
  const phone = route.params.phone

  const { login, restore } = useDataLayer()
  const currentUser = useSelector(selectCurrentUserData)

  const [password, setPassword] = useState('')
  const [redirect, setRedirect] = useState('')
  const [isHidden, setIsHidden] = useState(true)

  const toggleIsHidden = () => setIsHidden(current => !current)
  const toggleIcon = isHidden ? HideEye : ShowEye

  const handleChangePassword = (newPassword: string) => {
    setPassword(newPassword)
  }

  const goToEndSign = (status: string) => {
    setRedirect('')
    navigation.navigate(status === 'done' ? 'Tabs' : 'EndSign')
  }

  useEffect(() => {
    if (!currentUser || redirect !== 'login') {
      return
    }

    if (currentUser.status !== 'creating' && currentUser.status !== 'done') {
      console.log('SOMETHING WENT WRONG')
      return
    }

    goToEndSign(currentUser.status)
  }, [currentUser])

  return (
    <View style={styles.container}>
      <HeaderInfo />
      <KeyboardAvoidingView behavior={isIOS ? 'padding' : undefined} style={{ flex: 1 }}>
        <Typography f27 bold marginBottom={8} marginTop={52}>
          Введите пароль
        </Typography>
        <Typography f15 normal color='#535354' marginBottom={24}>
          Пароль к аккаунту {phone}
        </Typography>
        <View>
          <TextInput
            onChange={handleChangePassword}
            label='Пароль'
            placeholder='Введите пароль'
            isHidden={isHidden}
            rightIcon={() => (
              <Pressable style={styles.icon} onPress={toggleIsHidden}>
                <Image source={toggleIcon} style={{ height: 20, width: 20 }} />
              </Pressable>
            )}
          />
        </View>
        <View style={{ flexGrow: 1 }} />
        <TouchableOpacity
          onPress={() => {
            restore(phone, {
              page: 'EnterCode',
              params: { change: true, phone }
            })
            setRedirect('restore')
          }}
        >
          <Typography f15 normal textAlign='center' marginBottom={16} underline>
            Забыли пароль?
          </Typography>
        </TouchableOpacity>
        <Button
          text='Продолжить'
          disabled={!password}
          onPress={() => {
            login({ password, phone })
            setRedirect('login')
          }}
        />
      </KeyboardAvoidingView>
    </View>
  )
}
