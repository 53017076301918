import { PageAttributes } from '@navigation'
import { CHANGE_PASSWORD_REQUEST, ChangePasswordRequestAction } from '@redux/types'

export const changePasswordRequest = (
  phone: string,
  oldPassword: string,
  newPassword: string,
  next: PageAttributes
): ChangePasswordRequestAction => ({
  payload: {
    newPassword,
    next,
    oldPassword,
    phone
  },
  type: CHANGE_PASSWORD_REQUEST
})
