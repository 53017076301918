import { memo, useMemo } from 'react'
import { View } from 'react-native'

import { FeedPostItem, Typography } from '@components'
import { ChannelMessage } from '@types'

interface FeedProps {
  headerInfo?: { name: string; avatar: string }
  posts: ChannelMessage[]
  onPostPress: (post?: ChannelMessage) => void
  onPostLongPress: (post: ChannelMessage) => void
}

const FeedPosts = memo(({ headerInfo, posts, onPostPress, onPostLongPress }: FeedProps) => {
  const FeedPostsComponent = useMemo(
    () =>
      posts.map(post => (
        <FeedPostItem
          key={post.message_id}
          headerInfo={headerInfo}
          post={post}
          onPress={() => onPostPress(post)}
          onLongPress={() => onPostLongPress(post)}
        />
      )),
    [posts]
  )

  if (!posts?.length) {
    return (
      <View style={{ margin: 40, marginTop: 60 }}>
        <Typography f16 textAlign='center' color='#b6b6bb'>
          Пользователь еще не делал записей
        </Typography>
      </View>
    )
  }

  return FeedPostsComponent
})

FeedPosts.displayName = 'FeedPosts'

export { FeedPosts }
