import {
  RECEIVE_DISCUSSION,
  ReceiveDiscussionAction,
  SEND_DISCUSSION_MESSAGE_REQUEST,
  SEND_MESSAGE_REQUEST,
  SendDiscussionMessageRequestAction,
  SendMessageRequestAction
} from '@redux/types'
import { DiscussionMessage, DiscussionResponseAttributes, MessagesAttributes } from '@types'

export const sendMessageRequest = (payload: MessagesAttributes): SendMessageRequestAction => ({
  payload,
  type: SEND_MESSAGE_REQUEST
})

export const sendDiscussionMessageRequest = (payload: DiscussionMessage): SendDiscussionMessageRequestAction => ({
  payload,
  type: SEND_DISCUSSION_MESSAGE_REQUEST
})

export const receiveDiscussion = (payload: DiscussionResponseAttributes): ReceiveDiscussionAction => ({
  payload,
  type: RECEIVE_DISCUSSION
})
