/* eslint-disable func-style */
import { all, fork } from 'redux-saga/effects'

import {
  blockUserSaga,
  changePasswordSaga,
  changeRoleGroupChatSaga,
  chatConnectSaga,
  checkCodeSaga,
  connectSocialSaga,
  createChatUserSaga,
  createComplaintSaga,
  createGroupChatSaga,
  createPasswordSaga,
  deleteImageSaga,
  deleteMessagesSaga,
  exploreMessagesSaga,
  finishRegistrationSaga,
  generalInformationSaga,
  getAllDeletedUsersSaga,
  getAllUsersSaga,
  getChannelByUserIdSaga,
  getCurrentUserSaga,
  getDateCompatibilitySaga,
  getDeletedMessagesSaga,
  getFriendsChannelsMessagesSaga,
  getFullChatUserSaga,
  getMyChatsSaga,
  getMyLikesSaga,
  getUserChatsSaga,
  getUsersSaga,
  handleSocket,
  leaveGroupChatSaga,
  loginSaga,
  phoneConnectSaga,
  readNotificationsSaga,
  registrationSaga,
  restoreSaga,
  sendAttachmentsToChatSaga,
  sendDiscussionMessageSaga,
  sendGetMessagesForChatSaga,
  sendLikeSaga,
  sendMessageSaga,
  sendReadMessageSaga,
  sendReadMessagesSaga,
  unblockUserSaga,
  updateGroupChatSaga,
  updateUserSaga,
  uploadImageSaga,
  userDeleteSaga
} from '@redux/sagas'

export function* rootSaga() {
  yield all([
    fork(blockUserSaga),
    fork(changePasswordSaga),
    fork(changeRoleGroupChatSaga),
    fork(chatConnectSaga),
    fork(checkCodeSaga),
    fork(connectSocialSaga),
    fork(createChatUserSaga),
    fork(createComplaintSaga),
    fork(createGroupChatSaga),
    fork(createPasswordSaga),
    fork(deleteImageSaga),
    fork(deleteMessagesSaga),
    fork(exploreMessagesSaga),
    fork(finishRegistrationSaga),
    fork(getAllDeletedUsersSaga),
    fork(getAllUsersSaga),
    fork(getChannelByUserIdSaga),
    fork(getCurrentUserSaga),
    fork(getDateCompatibilitySaga),
    fork(getDeletedMessagesSaga),
    fork(getFriendsChannelsMessagesSaga),
    fork(getFullChatUserSaga),
    fork(getMyChatsSaga),
    fork(getMyLikesSaga),
    fork(getUserChatsSaga),
    fork(getUsersSaga),
    fork(generalInformationSaga),
    fork(handleSocket),
    fork(leaveGroupChatSaga),
    fork(loginSaga),
    fork(phoneConnectSaga),
    fork(readNotificationsSaga),
    fork(registrationSaga),
    fork(restoreSaga),
    fork(sendAttachmentsToChatSaga),
    fork(sendDiscussionMessageSaga),
    fork(sendGetMessagesForChatSaga),
    fork(sendLikeSaga),
    fork(sendMessageSaga),
    fork(sendReadMessageSaga),
    fork(sendReadMessagesSaga),
    fork(unblockUserSaga),
    fork(updateGroupChatSaga),
    fork(updateUserSaga),
    fork(uploadImageSaga),
    fork(userDeleteSaga)
  ])
}
