import { useCallback, useEffect, useRef, useState } from 'react'
import { Animated, Image, SafeAreaView, StyleSheet, Text, View } from 'react-native'

import { useToast } from '@components'
import { isWeb, screenWidth } from '@components/utils'
import { SERVER_URL } from '@services/apiService'
import { errorDict } from '@utils'

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    elevation: 5,
    position: 'absolute',
    shadowColor: '#000',
    shadowOffset: { height: 2, width: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    width: '92%',
    zIndex: 999
  },
  containerWeb: {
    left: 0,
    marginHorizontal: 'auto',
    maxWidth: screenWidth,
    position: 'fixed',
    right: 0,
    top: 0
  },
  image: {
    borderRadius: 20,
    height: 40,
    marginHorizontal: 12,
    width: 40
  },
  message: {
    color: '#333',
    flex: 1,
    fontSize: 16,
    marginHorizontal: 10
  },
  toast: {
    alignItems: 'center',
    backgroundColor: '#f9f9f9',
    borderColor: '#ddd',
    borderRadius: 12,
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 16
  }
})

const MOVE_DURATION = 8

export const Toast = () => {
  const { toast, hideToast } = useToast()
  const opacity = useRef(new Animated.Value(0)).current
  const translateY = useRef(new Animated.Value(0)).current
  const timer = useRef<NodeJS.Timeout>()
  const [isVisible, setVisible] = useState(false)
  const duration = toast.animationDuration

  const fadeIn = useCallback(() => {
    Animated.timing(opacity, {
      duration,
      toValue: 1,
      useNativeDriver: true
    }).start()

    Animated.timing(translateY, {
      duration,
      toValue: (toast.top ? -1 : 1) * MOVE_DURATION,
      useNativeDriver: true
    }).start()
  }, [opacity])

  const fadeOut = useCallback(() => {
    Animated.timing(opacity, {
      duration,
      toValue: 0,
      useNativeDriver: true
    }).start(() => hideToast())

    Animated.timing(translateY, {
      duration,
      toValue: 0,
      useNativeDriver: true
    }).start()
  }, [opacity, hideToast])

  useEffect(() => {
    if (toast.isVisible && !isVisible) {
      setVisible(true)
      fadeIn()

      const { timeout } = toast
      if (timeout) {
        if (timer.current) {
          clearTimeout(timer.current)
        }

        timer.current = setTimeout(() => {
          hideToast()
        }, timeout)
      }
    }

    if (!toast.isVisible && isVisible) {
      setVisible(false)
      fadeOut()
    }
  }, [toast, isVisible, fadeIn, fadeOut])

  if (!isVisible) {
    return <></>
  }

  const { message, image, type } = toast

  const renderToast = (msg: string, img: string, msgType: string) => {
    if (msgType === 'error') {
      return (
        <View style={[styles.toast, toast.containerStyle, { backgroundColor: '#ff8989' }]}>
          <Text style={[styles.message, toast.textStyle, { color: 'white' }]}>{errorDict[msg]?.ru}</Text>
        </View>
      )
    }

    return (
      <View style={[styles.toast, toast.containerStyle]}>
        <Image source={{ uri: `${SERVER_URL}/${img}` }} style={styles.image} />
        <Text style={[styles.message, toast.textStyle]}>{msg}</Text>
      </View>
    )
  }

  return (
    <Animated.View
      style={[
        styles.container,
        !!toast.top && { top: toast.top },
        !!toast.bottom && { bottom: toast.bottom },
        { opacity, transform: [{ translateY }] },
        isWeb && styles.containerWeb
      ]}
    >
      <SafeAreaView style={{ flex: 0 }}>{renderToast(message, image, type)}</SafeAreaView>
    </Animated.View>
  )
}
