import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { Typography } from '@components'
import { useDataLayer } from '@redux/dataLayer'

const styles = StyleSheet.create({
  buttons: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 16,
    width: '100%'
  },
  option: {
    alignItems: 'center',
    borderTopColor: '#fff',
    borderTopWidth: 1,
    flexGrow: 1,
    justifyContent: 'center',
    padding: 18,
    width: '100%'
  }
})

interface ModalUserActionsBlockProps {
  close: () => void
  targetId: string
  isBlocked: boolean
}

export const ModalUserActionsBlock = ({ close, targetId, isBlocked }: ModalUserActionsBlockProps) => {
  const { blockUser, unblockUser } = useDataLayer()

  const toggleBlockUser = () => {
    const action = isBlocked ? unblockUser : blockUser
    action(targetId)
    close()
  }

  return (
    <>
      <Typography f16 style={{ fontWeight: '700', marginTop: 16 }}>
        {isBlocked ? 'Разблокировать' : 'Заблокировать'}
      </Typography>
      <Typography f13 style={{ marginTop: 8, textAlign: 'center' }}>
        Вы уверенны, что хотите {isBlocked ? 'разблокировать' : 'заблокировать'} этого пользователя?
      </Typography>
      <View style={styles.buttons}>
        <TouchableOpacity style={[styles.option, { width: 'auto' }]} onPress={close}>
          <Typography f16 style={{ opacity: 0.5 }}>
            Отмена
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.option, { width: 'auto' }]} onPress={toggleBlockUser}>
          <Typography f16 style={{ color: '#F64A3F', fontWeight: '700' }}>
            Подтвердить
          </Typography>
        </TouchableOpacity>
      </View>
    </>
  )
}
