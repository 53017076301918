import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { ProfilePhoto } from '@components'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserGallery } from '@redux/selectors'
import { SERVER_URL } from '@services/apiService'

const styles = StyleSheet.create({
  loadPhotosContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    justifyContent: 'space-between',
    width: '100%'
  }
})

export const PhotoGallery = () => {
  const { deleteImage } = useDataLayer()
  const gallery = useSelector(selectCurrentUserGallery)

  const photos = useMemo(
    () =>
      [...Array(9)].map((_, i) => {
        return {
          file: null,
          id: gallery?.[i]?._id || i.toString(),
          link: gallery?.[i] ? `${SERVER_URL}/${gallery[i].name}` : ''
        }
      }),
    [gallery]
  )

  const handleClick = async (id: string) => {
    try {
      if (id) {
        deleteImage(id)
      }
    } catch (err: any) {
      console.log('photoGallery', err)
    }
  }

  const renderPhotos = () =>
    photos.map(photo => <ProfilePhoto key={photo.id} image={photo.link} onClickIcon={() => handleClick(photo.id)} />)

  return <View style={styles.loadPhotosContainer}>{renderPhotos()}</View>
}
