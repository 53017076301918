import { useEffect } from 'react'
import { ImageBackground, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'

import { ConnectTextLogoSvg } from '@assets/icons'
import { FirstScreenBackground } from '@assets/images'
import { Button, TermsMessage, Typography } from '@components'
import { isWeb } from '@components/utils'
import { useNavigator } from '@navigation'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 26,
    paddingTop: 60,
    resizeMode: 'cover'
  },
  contentWrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 20
  },
  entryButton: {
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 55,
    height: 60,
    justifyContent: 'center',
    marginBottom: 16,
    width: '100%'
  },
  headerWrapper: {
    alignItems: 'center'
  }
})

export const FirstScreen = () => {
  const { navigation } = useNavigator<'StartSelection' | 'EnterPhoneLogin'>()

  useEffect(() => {
    if (!isWeb) {
      return
    }

    const urlParams = new URLSearchParams(window.location.search)
    const instagramCode = urlParams.get('code')

    if (!instagramCode) {
      return
    }

    // random route just to trigger redirect to close instagram popup automatically
    navigation.navigate('EnterPhoneLogin')
  }, [])

  return (
    <ImageBackground source={FirstScreenBackground} style={styles.container}>
      <StatusBar backgroundColor='transparent' translucent barStyle='dark-content' />
      <View style={styles.headerWrapper}>
        <ConnectTextLogoSvg />
      </View>
      <View style={styles.contentWrapper}>
        <Typography f27 extraBold marginBottom={8}>
          Коннект будущего!
        </Typography>
        <Typography f15 normal color='#535354' marginBottom={36}>
          Алгоритм поиска совместимости основан на работе искусственного интеллекта, обученного психологии и
          нумерологии.
        </Typography>
        <Button onPress={() => navigation.navigate('StartSelection')} text='Регистрация' />
        <TouchableOpacity style={styles.entryButton} onPress={() => navigation.navigate('EnterPhoneLogin')}>
          <Typography f16 bold justifyContent='center'>
            Вход
          </Typography>
        </TouchableOpacity>
        <TermsMessage />
      </View>
    </ImageBackground>
  )
}
