import {
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GetCurrentUserRequestAction,
  GetCurrentUserSuccessAction
} from '@redux/types'
import { UserProfile } from '@types'

export const getCurrentUserRequest = (): GetCurrentUserRequestAction => ({
  type: GET_CURRENT_USER_REQUEST
})

export const getCurrentUserSuccess = (currentUser: UserProfile): GetCurrentUserSuccessAction => {
  return {
    payload: currentUser,
    type: GET_CURRENT_USER_SUCCESS
  }
}
