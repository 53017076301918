import Constants from 'expo-constants'
import * as Sentry from 'sentry-expo'

import { isWeb } from '@components/utils'

import { App } from './src/App'

if (__DEV__ && !isWeb) {
  require('./ReactotronConfig')
}

Object.entries(Constants.expoConfig?.extra?.env).forEach(([key, value]) => {
  if (!key.startsWith('EXPO_PUBLIC_') || process.env[key]) {
    return
  }

  process.env[key] = `${value}`
})

let integrations: any = []

if (!isWeb) {
  integrations = [new Sentry.Native.ReactNativeTracing({ enableAppStartTracking: false })]
}

Sentry.init({
  debug: true,
  dsn: 'https://92bcf85d3fadbb203110a6de9963f0cf@o4506015163351040.ingest.sentry.io/4506015165513728',
  enableInExpoDevelopment: true,
  integrations,
  tracesSampleRate: 1.0
})

// eslint-disable-next-line import/no-default-export
export default isWeb ? App : Sentry.Native.wrap(App)
