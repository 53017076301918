import { ReactNode } from 'react'
import { StyleSheet, Text } from 'react-native'
import {
  DefaultTheme,
  TextInput as TextInputComponent,
  TextInputProps as TextInputComponentProps
} from 'react-native-paper'

interface TextInputProps extends Omit<TextInputComponentProps, 'onChange'> {
  onChange?: (value: string) => void
  error?: boolean
  errorMessage?: string
  helperText?: string | false
  isHidden?: boolean
  rightIcon?: () => ReactNode
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters'
}

const styles = StyleSheet.create({
  error: {
    color: 'red',
    fontSize: 12,
    marginTop: 5,
    paddingLeft: 25
  },
  helperText: {
    color: '#2c2c2c',
    fontSize: 10,
    fontWeight: '400',
    marginTop: 5,
    opacity: 0.3,
    paddingLeft: 25
  },
  input: {
    backgroundColor: '#F3F2F9',
    fontSize: 14,
    fontWeight: '400',
    width: '100%'
  }
})

export const TextInput = ({
  onChange,
  autoCapitalize,
  error,
  errorMessage,
  helperText,
  isHidden,
  rightIcon,
  ...restProps
}: TextInputProps) => {
  const inputTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: error ? '#F64A3F' : '#2f2f2f'
    },
    roundness: 29
  }

  return (
    <>
      <TextInputComponent
        {...restProps}
        mode='outlined'
        placeholderTextColor='rgba(47, 47, 47, 0.3)'
        style={[styles.input, restProps.style]}
        theme={inputTheme}
        secureTextEntry={isHidden}
        onChangeText={onChange}
        autoCapitalize={autoCapitalize}
      />
      {error && <Text style={styles.error}>{errorMessage}</Text>}
      {!error && helperText && <Text style={styles.helperText}>{helperText}</Text>}
      {rightIcon?.()}
    </>
  )
}
