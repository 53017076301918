import { GET_USERS_REQUEST, GET_USERS_SUCCESS, GetUsersRequestAction, GetUsersSuccessAction } from '@redux/types'
import { UserProfile } from '@types'

export const getUsersRequest = (filter?: any): GetUsersRequestAction => {
  return {
    payload: { filter },
    type: GET_USERS_REQUEST
  }
}

export const getUsersSuccess = (users: UserProfile[]): GetUsersSuccessAction => {
  return {
    payload: users,
    type: GET_USERS_SUCCESS
  }
}
