import { useMemo, useState } from 'react'
import { Image, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { BackSvg, EllipsisSvg } from '@assets/icons'
import { UserDeleted } from '@assets/images'
import { ModalUserActions, Typography } from '@components'
import { formatUserName, isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { fullChatUserData } from '@redux/selectors'
import { SERVER_URL } from '@services/apiService'
import { ChatTypes, UserChat } from '@types'

const styles = StyleSheet.create({
  icon: {
    alignItems: 'center',
    backgroundColor: 'rgba(131, 131, 131, 0.12)',
    borderRadius: 20,
    height: 40,
    justifyContent: 'center',
    width: 40
  },
  image: {
    borderRadius: 25,
    height: 40,
    marginHorizontal: 16,
    width: 40
  },
  mainWrapper: {
    alignItems: 'center',
    backgroundColor: '#E8E7ED',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: isIOS ? 55 : 15
  },
  user: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  }
})

interface HeaderMessageProps {
  chat: UserChat
  ownerId: number
  isUserDeleted: boolean
}

export const HeaderMessage = ({ chat, ownerId, isUserDeleted }: HeaderMessageProps) => {
  const { navigation } = useNavigator<'Message'>()
  const fullUser = useSelector(fullChatUserData)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const isGroupChat = chat.chatType === ChatTypes.GROUP
  const userInfo = chat.creator.user_id === ownerId ? chat.participants[0] : chat.creator
  const isImageLocal = typeof chat.image === 'string' && chat.image?.startsWith('file')

  const headerInfo = useMemo(() => {
    if (isUserDeleted) {
      return {
        image: UserDeleted,
        subtitle: '',
        title: 'Пользователь удалён'
      }
    }

    if (isGroupChat) {
      return {
        image: { uri: isImageLocal ? chat.image : `${SERVER_URL}/${chat.image}` },
        subtitle: chat.chatDescription,
        title: chat.chatName
      }
    }

    return {
      image: { uri: `${SERVER_URL}/${userInfo.profile_photo[0]}` },
      subtitle: userInfo.username,
      title: formatUserName(userInfo.name)
    }
  }, [chat])

  const goToChatList = () => navigation.navigate('Message')
  const goToChatSettings = () => navigation.navigate('ChatSettings', { chat })
  const goToUserProfile = () => {
    if (!fullUser || isUserDeleted) {
      return
    }

    navigation.navigate('ModelScreen', {
      hideButtons: true,
      showModal: false,
      user: fullUser
    })
  }

  const onHeaderPress = () => (isGroupChat ? goToChatSettings() : goToUserProfile())

  return (
    <>
      <StatusBar backgroundColor='#E8E7ED' barStyle='dark-content' />
      <View style={styles.mainWrapper}>
        <TouchableOpacity style={styles.icon} onPress={goToChatList}>
          <BackSvg />
        </TouchableOpacity>
        <TouchableOpacity disabled={isUserDeleted} onPress={onHeaderPress} style={styles.user}>
          <Image source={headerInfo.image} style={styles.image} />
          <View style={{ justifyContent: 'center' }}>
            <Typography f17 semibold>
              {headerInfo.title}
            </Typography>
            {!isUserDeleted && (
              <Typography f11 light>
                {headerInfo.subtitle}
              </Typography>
            )}
          </View>
        </TouchableOpacity>
        {fullUser && !isGroupChat && (
          <TouchableOpacity style={styles.icon} onPress={() => setIsModalVisible(true)}>
            <EllipsisSvg />
          </TouchableOpacity>
        )}
      </View>
      {fullUser && !isGroupChat && (
        <ModalUserActions isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} targetId={fullUser._id} />
      )}
    </>
  )
}
