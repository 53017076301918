import {
  ChatInterlocutorResponse,
  GET_MY_CHATS_REQUEST,
  GET_MY_CHATS_SUCCESS,
  GetMyChatsRequestAction,
  GetMyChatsSuccessAction,
  READ_NOTIFICATIONS_REQUEST,
  ReadNotificationsRequestAction
} from '@redux/types'

export const getMyChatsRequest = (): GetMyChatsRequestAction => ({
  type: GET_MY_CHATS_REQUEST
})

export const readNotificationsRequest = (likes: string[]): ReadNotificationsRequestAction => ({
  payload: likes,
  type: READ_NOTIFICATIONS_REQUEST
})

export const getMyChatsSuccess = (data: ChatInterlocutorResponse): GetMyChatsSuccessAction => ({
  payload: { data },
  type: GET_MY_CHATS_SUCCESS
})
