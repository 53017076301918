import { ProfileOptions } from '@utils'

export const HOBBIES: ProfileOptions = [
  {
    children: [
      { id: '1_0', label: 'Handmade', slug: 'handmade' },
      { id: '1_1', label: 'Алмазная мозайка', slug: 'diamondMosaic' },
      { id: '1_2', label: 'Батик', slug: 'batik' },
      { id: '1_3', label: 'Бисероплетение', slug: 'beadCraft' },
      { id: '1_4', label: 'Броши', slug: 'brooches' },
      { id: '1_5', label: 'Выжигание', slug: 'pyrography' },
      { id: '1_6', label: 'Выращивание кристаллов', slug: 'crystalGrowing' },
      { id: '1_7', label: 'Вязание', slug: 'knitting' },
      { id: '1_8', label: 'Гончарное дело', slug: 'pottery' },
      { id: '1_9', label: 'Декоративное консервирование', slug: 'decorativeCanning' },
      { id: '1_10', label: 'Декупаж', slug: 'decoupage' },
      { id: '1_11', label: 'Изготовление свечей', slug: 'candleMaking' },
      { id: '1_12', label: 'Изготовление украшений', slug: 'jewelryMaking' },
      { id: '1_13', label: 'Изографика', slug: 'isography' },
      { id: '1_14', label: 'Изонить', slug: 'isonit' },
      { id: '1_15', label: 'Икебана', slug: 'ikebana' },
      { id: '1_16', label: 'Канзаши', slug: 'kanzashi' },
      { id: '1_17', label: 'Карвинг - резьба', slug: 'carving' },
      { id: '1_18', label: 'Кастомайзинг', slug: 'customizing' },
      { id: '1_19', label: 'Квиллинг', slug: 'quilling' },
      { id: '1_20', label: 'Ковроплетение', slug: 'rugWeaving' },
      { id: '1_21', label: 'Кружево', slug: 'lace' },
      { id: '1_22', label: 'Кузнечное дело', slug: 'blacksmithing' },
      { id: '1_23', label: 'Куклы', slug: 'dolls' },
      { id: '1_24', label: 'Лозоплетение', slug: 'wickerwork' },
      { id: '1_25', label: 'Макраме', slug: 'macrame' },
      { id: '1_26', label: 'Мыловарение', slug: 'soapMaking' },
      { id: '1_27', label: 'Оригами', slug: 'origami' },
      { id: '1_28', label: 'Пэчворк', slug: 'patchwork' },
      { id: '1_29', label: 'Роспись пряников', slug: 'gingerbreadPainting' },
      { id: '1_30', label: 'Свит-дизайн', slug: 'sweetDesign' },
      { id: '1_31', label: 'Скрапбукинг', slug: 'scrapbooking' },
      { id: '1_32', label: 'Собирать конструктор', slug: 'buildingConstructionSets' },
      { id: '1_33', label: 'Создание изделий из металла', slug: 'creatingMetalProducts' },
      { id: '1_34', label: 'Создание мини-копий машин', slug: 'creatingMiniatureCarModels' },
      { id: '1_35', label: 'Стринг Арт', slug: 'stringArt' },
      { id: '1_36', label: 'Таксидермия', slug: 'taxidermy' },
      { id: '1_37', label: 'Твистинг', slug: 'twisting' },
      { id: '1_38', label: 'Топиарии', slug: 'topiary' },
      { id: '1_39', label: 'Фелтинг', slug: 'felting' },
      { id: '1_40', label: 'Шитье', slug: 'sewing' }
    ],
    id: '0',
    label: 'Handmade',
    slug: 'handmade'
  },
  {
    children: [
      { id: '2_0', label: 'Рисование', slug: 'painting' },
      { id: '2_1', label: 'Бодиарт', slug: 'bodyArt' },
      { id: '2_2', label: 'Бодипейнтинг', slug: 'bodyPainting' },
      { id: '2_3', label: 'Витражная живопись', slug: 'stainedGlassPainting' },
      { id: '2_4', label: 'Графитти', slug: 'graffiti' },
      { id: '2_5', label: 'Дудлинг и зентангл', slug: 'doodlingAndZentangle' },
      { id: '2_6', label: 'Живопись', slug: 'painting' },
      { id: '2_7', label: 'Иконография', slug: 'iconography' },
      { id: '2_8', label: 'Иконопись', slug: 'iconPainting' },
      { id: '2_9', label: 'Каллиграфия', slug: 'calligraphy' },
      { id: '2_10', label: 'Кляксография', slug: 'blotPainting' },
      { id: '2_11', label: 'Марблинг', slug: 'marbling' },
      { id: '2_12', label: 'Мехенди', slug: 'mehndi' },
      { id: '2_13', label: 'Мозаика', slug: 'mosaic' },
      { id: '2_14', label: 'Монотипия', slug: 'monotype' },
      { id: '2_15', label: 'Ниткография', slug: 'threadGraphics' },
      { id: '2_16', label: 'Правополушарное рисование', slug: 'rightHemisphereDrawing' },
      { id: '2_17', label: 'Рисование песком', slug: 'sandDrawing' },
      { id: '2_18', label: 'Роспись стекла', slug: 'glassPainting' },
      { id: '2_19', label: 'Скетчинг', slug: 'sketching' },
      { id: '2_20', label: 'Темари арт', slug: 'temariArt' },
      { id: '2_21', label: 'Фейспеинтинг', slug: 'facePainting' },
      { id: '2_22', label: 'Фризлайт', slug: 'freezlight' },
      { id: '2_23', label: 'Эбру', slug: 'ebru' },
      { id: '2_24', label: 'Энкаустика', slug: 'encaustic' }
    ],
    id: '1',
    label: 'Рисование',
    slug: 'painting'
  },
  {
    children: [{ id: '2_0', label: 'Поэзия и писательство', slug: 'poemsAndHandWritting' }],
    id: '2',
    label: 'Поэзия и писательство',
    slug: 'poemsAndHandWritting'
  },
  {
    children: [{ id: '3', label: 'Посткроссинг', slug: 'postScrolling' }],
    id: '3',
    label: 'Посткроссинг',
    slug: 'postScrolling'
  },
  {
    children: [
      { id: '4_0', label: 'Искусство и Культура', slug: 'artsAndCulture' },
      { id: '4_1', label: 'Балет', slug: 'ballet' },
      { id: '4_2', label: 'Выставки', slug: 'exhibitions' },
      { id: '4_3', label: 'Кино', slug: 'cinema' },
      { id: '4_4', label: 'Концерт, шоу', slug: 'concertsShows' },
      { id: '4_5', label: 'Мюзикл', slug: 'musical' },
      { id: '4_6', label: 'Писательство', slug: 'writing' },
      { id: '4_7', label: 'Произведения искусства', slug: 'artWorks' },
      { id: '4_8', label: 'Скульптуры', slug: 'sculptures' },
      { id: '4_9', label: 'Театр', slug: 'theatre' },
      { id: '4_10', label: 'Фестивали', slug: 'festivals' },
      { id: '4_11', label: 'Цирк', slug: 'circus' },
      { id: '4_12', label: 'Экскурссии', slug: 'tours' }
    ],
    id: '4',
    label: 'Искусство и Культура',
    slug: 'artsAndCulture'
  },
  {
    children: [
      { id: '5_0', label: 'Телешоу', slug: 'tvShows' },
      { id: '5_1', label: 'Адская Кухня', slug: 'hellKitchen' },
      { id: '5_2', label: 'Беременна в 16', slug: 'pregnantAt16' },
      { id: '5_3', label: 'Битва экстрасенсов', slug: 'battleOfTheClairvoyants' },
      { id: '5_4', label: 'Верните мне красоту', slug: 'returnToMeBeauty' },
      { id: '5_5', label: 'Голос', slug: 'theVoice' },
      { id: '5_6', label: 'Кто хочет стать миллионером', slug: 'whoWantsToBeAMillionaire' },
      { id: '5_7', label: 'Лучше всех', slug: 'betterThanEveryone' },
      { id: '5_8', label: 'Мастер Шеф', slug: 'masterChef' },
      { id: '5_9', label: 'Орел и решка', slug: 'eagleAndTails' },
      { id: '5_10', label: 'Танцы', slug: 'dances' },
      { id: '5_11', label: 'Что, где, когда', slug: 'whatWhereWhen' },
      { id: '5_12', label: 'Шоу талантов', slug: 'talentShows' },
      { id: '5_13', label: 'Я стесняюсь своего тела', slug: 'bodyShaming' }
    ],
    id: '5',
    label: 'Телешоу',
    slug: 'tvShows'
  },
  {
    children: [
      { id: '6_0', label: 'Юмор', slug: 'humor' },
      { id: '6_1', label: 'Ирония', slug: 'irony' },
      { id: '6_2', label: 'Пародия', slug: 'parody' },
      { id: '6_3', label: 'Сарказм', slug: 'sarcasm' },
      { id: '6_4', label: 'Стендап', slug: 'standUpComedy' },
      { id: '6_5', label: 'Черный юмор', slug: 'darkHumor' }
    ],
    id: '6',
    label: 'Юмор',
    slug: 'humor'
  },
  {
    children: [
      { id: '7_0', label: 'Музыка', slug: 'music' },
      { id: '7_1', label: 'Cэмплирование', slug: 'sampling' },
      { id: '7_2', label: 'Авторские песни', slug: 'originalSongs' },
      { id: '7_3', label: 'Виртуальный диджеинг', slug: 'virtualDJing' },
      { id: '7_4', label: 'Звукозапись', slug: 'soundRecording' },
      { id: '7_5', label: 'Народное пение', slug: 'folkSinging' },
      { id: '7_6', label: 'Пение', slug: 'singing' },
      { id: '7_7', label: 'Создание музыки и ритмов', slug: 'musicAndRhythmCreation' },
      { id: '7_8', label: 'Тексты и стихи для песен', slug: 'songLyricsAndPoetry' }
    ],
    id: '7',
    label: 'Музыка',
    slug: 'music'
  },
  {
    children: [
      { id: '8_0', label: 'Игра на музыкальных инструментах', slug: 'musicalInstruments' },
      { id: '8_1', label: 'Духовые', slug: 'windInstruments' },
      { id: '8_2', label: 'Бас-кларнет', slug: 'bassClarinet' },
      { id: '8_3', label: 'Блокфлейта', slug: 'recorder' },
      { id: '8_4', label: 'Валторна', slug: 'frenchHorn' },
      { id: '8_5', label: 'Вистл', slug: 'whistle' },
      { id: '8_6', label: 'Геликон', slug: 'helicon' },
      { id: '8_7', label: 'Гобой', slug: 'oboe' },
      { id: '8_8', label: 'Дудка', slug: 'whistle' },
      { id: '8_9', label: 'Дудука', slug: 'duduk' },
      { id: '8_10', label: 'Зурна', slug: 'zurna' },
      { id: '8_11', label: 'Кларнет', slug: 'clarinet' },
      { id: '8_12', label: 'Кларнет-пикколо', slug: 'clarinetPiccolo' },
      { id: '8_13', label: 'Корнет', slug: 'cornet' },
      { id: '8_14', label: 'Рекордер', slug: 'recorder' },
      { id: '8_15', label: 'Саксофон', slug: 'saxophone' },
      { id: '8_16', label: 'Сузафон', slug: 'sousaphone' },
      { id: '8_17', label: 'Тромбон', slug: 'trombone' },
      { id: '8_18', label: 'Труба', slug: 'trumpet' },
      { id: '8_19', label: 'Туба', slug: 'tuba' },
      { id: '8_20', label: 'Фагот', slug: 'bassoon' },
      { id: '8_21', label: 'Флейта', slug: 'flute' },
      { id: '8_22', label: 'Флейта-пикколо', slug: 'piccoloFlute' },
      { id: '8_23', label: 'Флюгельгорн', slug: 'flugelhorn' },
      { id: '8_24', label: 'Шалмей', slug: 'shawm' },
      { id: '8_25', label: 'Клавишные', slug: 'keyboardInstruments' },
      { id: '8_26', label: 'Midi-клавиатура', slug: 'midiKeyboard' },
      { id: '8_27', label: 'Аккордеон', slug: 'accordion' },
      { id: '8_28', label: 'Акустическое пианино', slug: 'uprightPiano' },
      { id: '8_29', label: 'Баян', slug: 'bayan' },
      { id: '8_30', label: 'Вокодер', slug: 'vocoder' },
      { id: '8_31', label: 'Гармонь', slug: 'harmonica' },
      { id: '8_32', label: 'Клавесин', slug: 'harpsichord' },
      { id: '8_33', label: 'Клавикорд', slug: 'clavichord' },
      { id: '8_34', label: 'Клавинет', slug: 'clavinet' },
      { id: '8_35', label: 'Мелодика', slug: 'melodica' },
      { id: '8_36', label: 'Орган', slug: 'organ' },
      { id: '8_37', label: 'Пианино', slug: 'piano' },
      { id: '8_38', label: 'Рояль', slug: 'grandPiano' },
      { id: '8_39', label: 'Синтезатор', slug: 'synthesizer' },
      { id: '8_40', label: 'Фортепьяно', slug: 'pianoFort' },
      { id: '8_41', label: 'Цифровое пианино', slug: 'digitalPiano' },
      { id: '8_42', label: 'Челеста', slug: 'celesta' },
      { id: '8_43', label: 'Электропиано', slug: 'electricPiano' },
      { id: '8_44', label: 'Механические', slug: 'mechanicalInstruments' },
      { id: '8_45', label: 'Вельте-миньон', slug: 'veltMinion' },
      { id: '8_46', label: 'Оркестрион', slug: 'orchestrion' },
      { id: '8_47', label: 'Пианола', slug: 'pianola' },
      { id: '8_48', label: 'Фонола', slug: 'phonola' },
      { id: '8_49', label: 'Шарманка', slug: 'barrelOrgan' },
      { id: '8_50', label: 'Струнные', slug: 'stringInstruments' },
      { id: '8_51', label: 'Альт', slug: 'viola' },
      { id: '8_52', label: 'Арфа', slug: 'harp' },
      { id: '8_53', label: 'Балалайка', slug: 'balalaika' },
      { id: '8_54', label: 'Бандура', slug: 'bandura' },
      { id: '8_55', label: 'Банжо', slug: 'banjo' },
      { id: '8_56', label: 'Виолончель', slug: 'cello' },
      { id: '8_57', label: 'Гадулука', slug: 'gadulka' },
      { id: '8_58', label: 'Гитара', slug: 'guitar' },
      { id: '8_59', label: 'Гудок', slug: 'woodenFlute' },
      { id: '8_60', label: 'Гусле', slug: 'gusle' },
      { id: '8_61', label: 'Гусли', slug: 'gusli' },
      { id: '8_62', label: 'Домбра', slug: 'dombra' },
      { id: '8_63', label: 'Домра', slug: 'domra' },
      { id: '8_64', label: 'Кеманча', slug: 'kemanche' },
      { id: '8_65', label: 'Кобза', slug: 'kobza' },
      { id: '8_66', label: 'Кобыз', slug: 'kobyz' },
      { id: '8_67', label: 'Комуз', slug: 'komuz' },
      { id: '8_68', label: 'Контрабас', slug: 'doubleBass' },
      { id: '8_69', label: 'Кото', slug: 'koto' },
      { id: '8_70', label: 'Кыяк', slug: 'kylak' },
      { id: '8_71', label: 'Лютня', slug: 'lute' },
      { id: '8_72', label: 'Морин хуур', slug: 'morinKhuur' },
      { id: '8_73', label: 'Никельхарпа', slug: 'nickelHarp' },
      { id: '8_74', label: 'Ребаб', slug: 'rebec' },
      { id: '8_75', label: 'Саз', slug: 'saz' },
      { id: '8_76', label: 'Саранджи', slug: 'sarangi' },
      { id: '8_77', label: 'Скрипка', slug: 'violin' },
      { id: '8_78', label: 'Тар', slug: 'tar' },
      { id: '8_79', label: 'Фидель', slug: 'fiddle' },
      { id: '8_80', label: 'Хардингфеле', slug: 'hardingfele' },
      { id: '8_81', label: 'Хомус', slug: 'khomus' },
      { id: '8_82', label: 'Хуцинь', slug: 'hucin' },
      { id: '8_83', label: 'Цитра', slug: 'zither' },
      { id: '8_84', label: 'Чаранга', slug: 'charanga' },
      { id: '8_85', label: 'Чатхан', slug: 'chatkhan' },
      { id: '8_86', label: 'Ударные', slug: 'percussionInstruments' },
      { id: '8_87', label: 'Агого', slug: 'agogo' },
      { id: '8_88', label: 'Ангклунг', slug: 'angklung' },
      { id: '8_89', label: 'Асатаяк', slug: 'asalato' },
      { id: '8_90', label: 'Ахоко', slug: 'ashiko' },
      { id: '8_91', label: 'Балафон', slug: 'balafon' },
      { id: '8_92', label: 'Баньгу', slug: 'bang' },
      { id: '8_93', label: 'Бар чаймс', slug: 'barChimes' },
      { id: '8_94', label: 'Барабаны', slug: 'drums' },
      { id: '8_95', label: 'Бата', slug: 'bata' },
      { id: '8_96', label: 'Бомбо легуэро', slug: 'bomboLeguero' },
      { id: '8_97', label: 'Бонанг', slug: 'bonang' },
      { id: '8_98', label: 'Бонго', slug: 'bongo' },
      { id: '8_99', label: 'Боуран', slug: 'bouran' },
      { id: '8_100', label: 'Бубен', slug: 'buben' },
      { id: '8_101', label: 'Гамбанг', slug: 'gambang' },
      { id: '8_102', label: 'Глюкофон', slug: 'glukofon' },
      { id: '8_103', label: 'Гуиро', slug: 'guiro' },
      { id: '8_104', label: 'Дабыл', slug: 'dabyl' },
      { id: '8_105', label: 'Дангыра', slug: 'dangyra' },
      { id: '8_106', label: 'Дарбука', slug: 'darbuka' },
      { id: '8_107', label: 'Джалтаранг', slug: 'jaltarang' },
      { id: '8_108', label: 'Джембе', slug: 'djembe' },
      { id: '8_109', label: 'Дойра', slug: 'doyra' },
      { id: '8_110', label: 'Дотаку', slug: 'dotaku' },
      { id: '8_111', label: 'Дудыга', slug: 'dudyga' },
      { id: '8_112', label: 'Дхолак', slug: 'dholak' },
      { id: '8_113', label: 'Кабаса', slug: 'cabasa' },
      { id: '8_114', label: 'Канджира', slug: 'kanjira' },
      { id: '8_115', label: 'Карильоны', slug: 'carillons' },
      { id: '8_116', label: 'Кастаньеты', slug: 'castanets' },
      { id: '8_117', label: 'Кахон', slug: 'cajon' },
      { id: '8_118', label: 'Кашиши', slug: 'kashishi' },
      { id: '8_119', label: 'Клаве', slug: 'clave' },
      { id: '8_120', label: 'Ковбелл', slug: 'cowbell' },
      { id: '8_121', label: 'Колокола', slug: 'bells' },
      { id: '8_122', label: 'Конга', slug: 'conga' },
      { id: '8_123', label: 'Кпанлого', slug: 'kpanlogo' },
      { id: '8_124', label: 'Ксилофон', slug: 'xylophone' },
      { id: '8_125', label: 'Лаггути', slug: 'laghuti' },
      { id: '8_126', label: 'Литавры', slug: 'litavry' },
      { id: '8_127', label: 'Маракас', slug: 'maracas' },
      { id: '8_128', label: 'Маримба', slug: 'marimba' },
      { id: '8_129', label: 'Мриданга', slug: 'mridangam' },
      { id: '8_130', label: 'Пандейру', slug: 'pandeiro' },
      { id: '8_131', label: 'Рейнстик', slug: 'rainstick' },
      { id: '8_132', label: 'Рубель', slug: 'rubel' },
      { id: '8_133', label: 'Сагаты', slug: 'sagats' },
      { id: '8_134', label: 'Скрабалай', slug: 'scraper' },
      { id: '8_135', label: 'Сплэш', slug: 'splash' },
      { id: '8_136', label: 'Табла', slug: 'tabla' },
      { id: '8_137', label: 'Тавил', slug: 'tavil' },
      { id: '8_138', label: 'Тайко', slug: 'taiko' },
      { id: '8_139', label: 'Тамбурин', slug: 'tambourine' },
      { id: '8_140', label: 'Тимбал', slug: 'timbale' },
      { id: '8_141', label: 'Треугольник', slug: 'triangle' },
      { id: '8_142', label: 'Трещотка', slug: 'ratchet' },
      { id: '8_143', label: 'Тулумбас', slug: 'tulumbas' },
      { id: '8_144', label: 'Уду', slug: 'udu' },
      { id: '8_145', label: 'Цудзуми', slug: 'tsudzumi' },
      { id: '8_146', label: 'Чайна', slug: 'chinaCymbal' },
      { id: '8_147', label: 'Челеста', slug: 'celesta' },
      { id: '8_148', label: 'Шейкер', slug: 'shaker' },
      { id: '8_149', label: 'Электромузыкальные', slug: 'electronicInstruments' },
      { id: '8_150', label: 'Blaster Beam', slug: 'blasterBeam' },
      { id: '8_151', label: 'MIDI-клавиатура', slug: 'midiKeyboard' },
      { id: '8_152', label: 'Гитара Гиттлера', slug: 'gittlerGuitar' },
      { id: '8_153', label: 'Грувбокс', slug: 'groovebox' },
      { id: '8_154', label: 'Домавинджер', slug: 'domavindger' },
      { id: '8_155', label: 'Драм-машина', slug: 'drumMachine' },
      { id: '8_156', label: 'Континуум', slug: 'continuum' },
      { id: '8_157', label: 'Лазерная арфа', slug: 'laserHarp' },
      { id: '8_158', label: 'Меллотрон', slug: 'mellotron' },
      { id: '8_159', label: 'Мудсвинджер', slug: 'mudswinger' },
      { id: '8_160', label: 'Реактейбл', slug: 'reactable' },
      { id: '8_161', label: 'Семплер', slug: 'sampler' },
      { id: '8_162', label: 'Синтезатор', slug: 'synthesizer' },
      { id: '8_163', label: 'Терменвокс', slug: 'theremin' },
      { id: '8_164', label: 'Траутониум', slug: 'trautonium' },
      { id: '8_165', label: 'Тэнори-он', slug: 'tenoriOn' },
      { id: '8_166', label: 'Электрогитара', slug: 'electricGuitar' },
      { id: '8_167', label: 'Электроорган', slug: 'electricOrgan' },
      { id: '8_168', label: 'Эмиритон', slug: 'emiritone' }
    ],
    id: '8',
    label: 'Игра на музыкальных инструментах',
    slug: 'musicalInstruments'
  },
  {
    children: [
      { id: '9_0', label: 'Танцы', slug: 'dance' },
      { id: '9_1', label: 'Акробатические танцы', slug: 'acrobaticDance' },
      { id: '9_2', label: 'Акробатический рок-н-рол', slug: 'acrobaticRockNRoll' },
      { id: '9_3', label: 'Буги-вуги', slug: 'boogieWoogie' },
      { id: '9_4', label: 'Воздушный эквилибр', slug: 'aerialEquilibrium' },
      { id: '9_5', label: 'Капоэйра', slug: 'capoeiraDance' },
      { id: '9_6', label: 'Танец на пилоне', slug: 'poleDance' },
      { id: '9_7', label: 'Черлидинг', slug: 'cheerleading' },
      { id: '9_8', label: 'Бальные танцы', slug: 'ballroomDance' },
      { id: '9_9', label: 'Вальс', slug: 'waltz' },
      { id: '9_10', label: 'Вест Коуст', slug: 'westCoastSwing' },
      { id: '9_11', label: 'Джайв', slug: 'jive' },
      { id: '9_12', label: 'Пасодобль', slug: 'pasodoble' },
      { id: '9_13', label: 'Фокстрот', slug: 'foxtrot' },
      { id: '9_14', label: 'Балет', slug: 'ballet' },
      { id: '9_15', label: 'Восточные танцы New', slug: 'easternDanceNew' },
      { id: '9_16', label: 'Александрия', slug: 'alexandriaDance' },
      { id: '9_17', label: 'Баледи', slug: 'balediDance' },
      { id: '9_18', label: 'Бандари', slug: 'bandariDance' },
      { id: '9_19', label: 'Ираки', slug: 'iraqiDance' },
      { id: '9_20', label: 'Нубия', slug: 'nubianDance' },
      { id: '9_21', label: 'Саиди', slug: 'saidiDance' },
      { id: '9_22', label: 'Танец Живота', slug: 'bellyDance' },
      { id: '9_23', label: 'Трибал', slug: 'tribalDance' },
      { id: '9_24', label: 'Фалляхи', slug: 'falaahiDance' },
      { id: '9_25', label: 'Халиджи', slug: 'khaleejiDance' },
      { id: '9_26', label: 'Шааби', slug: 'shaabiDance' },
      { id: '9_27', label: 'Народные(этнические) танцы', slug: 'folkEthnicDances' },
      { id: '9_28', label: 'Армянские', slug: 'armenianDances' },
      { id: '9_29', label: 'Грузинские', slug: 'georgianDances' },
      { id: '9_30', label: 'Еврейские', slug: 'jewishDances' },
      { id: '9_31', label: 'Ирландские', slug: 'irishDances' },
      { id: '9_32', label: 'Кавказкие', slug: 'caucasianDances' },
      { id: '9_33', label: 'Казахские', slug: 'kazakhDances' },
      { id: '9_34', label: 'Китайские', slug: 'chineseDances' },
      { id: '9_35', label: 'Кубинские', slug: 'cubanDances' },
      { id: '9_36', label: 'Мексиканские', slug: 'mexicanDances' },
      { id: '9_37', label: 'Польские', slug: 'polishDances' },
      { id: '9_38', label: 'Русские', slug: 'russianDances' },
      { id: '9_39', label: 'Турецкие', slug: 'turkishDances' },
      { id: '9_40', label: 'Украинские', slug: 'ukrainianDances' },
      { id: '9_41', label: 'Японские', slug: 'japaneseDances' },
      { id: '9_42', label: 'Современнные танцы', slug: 'modernDances' },
      { id: '9_43', label: 'Буто', slug: 'buto' },
      { id: '9_44', label: 'Вог', slug: 'vogue' },
      { id: '9_45', label: 'Джаз Модерн', slug: 'jazzModern' },
      { id: '9_46', label: 'Джаз Фанк', slug: 'jazzFunk' },
      { id: '9_47', label: 'Дэнсхолл', slug: 'dancehall' },
      { id: '9_48', label: 'Контемп', slug: 'contemporaryDance' },
      { id: '9_49', label: 'Модерн', slug: 'modernDance' },
      { id: '9_50', label: 'Реггетон', slug: 'reggaeton' },
      { id: '9_51', label: 'Стрип-пластика', slug: 'stripPlastic' },
      { id: '9_52', label: 'Стриптиз', slug: 'striptease' },
      { id: '9_53', label: 'Хай Хилс', slug: 'highHeels' },
      { id: '9_54', label: 'Pole Dance', slug: 'poleDancing' },
      { id: '9_55', label: 'Латинские танцы New', slug: 'latinDancesNew' },
      { id: '9_56', label: 'Бачата', slug: 'bachata' },
      { id: '9_57', label: 'Ламбада', slug: 'lambada' },
      { id: '9_58', label: 'Мамбо', slug: 'mambo' },
      { id: '9_59', label: 'Румба', slug: 'rumba' },
      { id: '9_60', label: 'Сальса', slug: 'salsa' },
      { id: '9_61', label: 'Самба', slug: 'samba' },
      { id: '9_62', label: 'Танго', slug: 'tango' },
      { id: '9_63', label: 'Ча-ча-ча', slug: 'chaChaCha' },
      { id: '9_64', label: 'Уличные танцы', slug: 'streetDances' },
      { id: '9_65', label: 'Krump', slug: 'krump' },
      { id: '9_66', label: 'Брейк-данс', slug: 'breakdance' },
      { id: '9_67', label: 'Локинг', slug: 'locking' },
      { id: '9_68', label: 'Поппинг', slug: 'popping' },
      { id: '9_69', label: 'Тектоник', slug: 'tectonic' },
      { id: '9_70', label: 'Фристайл', slug: 'freestyle' },
      { id: '9_71', label: 'Хаус', slug: 'houseDance' },
      { id: '9_72', label: 'Хип-хоп', slug: 'hipHop' },
      { id: '9_73', label: 'R&B', slug: 'rnb' },
      { id: '9_74', label: 'Тверк', slug: 'twerk' },
      { id: '9_75', label: 'Go-go', slug: 'gogo' }
    ],
    id: '9',
    label: 'Танцы',
    slug: 'dance'
  },
  {
    children: [
      { id: '10_0', label: 'Мода', slug: 'fashion' },
      { id: '10_1', label: 'История моды', slug: 'fashionHistory' },
      { id: '10_2', label: 'Коллекции брендов', slug: 'brandCollections' },
      { id: '10_3', label: 'Модные показы', slug: 'fashionShows' },
      { id: '10_4', label: 'Стиль и имидж', slug: 'styleAndImage' },
      { id: '10_5', label: 'Школы моделей', slug: 'modelingSchools' },
      { id: '10_6', label: 'Шоппинг и тренды', slug: 'shoppingAndTrends' },
      { id: '10_7', label: 'Экомода', slug: 'ecoFashion' }
    ],
    id: '10',
    label: 'Мода',
    slug: 'fashion'
  },
  {
    children: [
      { id: '11_0', label: 'Дизайн', slug: 'design' },
      { id: '11_1', label: 'Web-дизайн', slug: 'webDesign' },
      { id: '11_2', label: 'Анимационный дизайн', slug: 'animationDesign' },
      { id: '11_3', label: 'Архитектурный дизайн', slug: 'architecturalDesign' },
      { id: '11_4', label: 'Аэродизайн', slug: 'aeroDesign' },
      { id: '11_5', label: 'Графический дизайн', slug: 'graphicDesign' },
      { id: '11_6', label: 'Дизайн интерьера', slug: 'interiorDesign' },
      { id: '11_7', label: 'Дизайн мероприятий', slug: 'eventDesign' },
      { id: '11_8', label: 'Дизайн одежды', slug: 'clothingDesign' },
      { id: '11_9', label: 'Кастомайзинг', slug: 'customization' },
      { id: '11_10', label: 'Ландшафтный дизайн', slug: 'landscapeDesign' },
      { id: '11_11', label: 'Ленд-арт', slug: 'landArt' },
      { id: '11_12', label: 'Моддинг', slug: 'modding' },
      { id: '11_13', label: 'Световой дизайн', slug: 'lightingDesign' },
      { id: '11_14', label: 'Свит-дизайн', slug: 'suitDesign' },
      { id: '11_15', label: 'Флористика', slug: 'floristics' },
      { id: '11_16', label: 'Футуродизайн', slug: 'futureDesign' },
      { id: '11_17', label: 'Экодизайн', slug: 'ecoDesign' }
    ],
    id: '11',
    label: 'Дизайн',
    slug: 'design'
  },
  {
    children: [
      { id: '12_0', label: 'Красота и здоровье', slug: 'beautyAndHealth' },
      { id: '12_1', label: 'Flylady', slug: 'flylady' },
      { id: '12_2', label: 'Ароматерапия', slug: 'aromatherapy' },
      { id: '12_3', label: 'Аэробика', slug: 'aerobics' },
      { id: '12_4', label: 'Бани и сауны', slug: 'bathsAndSaunas' },
      { id: '12_5', label: 'Бодифлекс', slug: 'bodyflex' },
      { id: '12_6', label: 'Визаж', slug: 'makeup' },
      { id: '12_7', label: 'Йога', slug: 'yoga' },
      { id: '12_8', label: 'Нейл-арт', slug: 'nailArt' },
      { id: '12_9', label: 'Парикмахерское искусство', slug: 'hairdressingArt' },
      { id: '12_10', label: 'ПП и похудение', slug: 'dietAndWeightLoss' },
      { id: '12_11', label: 'Скандинавская ходьба', slug: 'nordicWalking' },
      { id: '12_12', label: 'Создание уходовой косметики', slug: 'creatingCosmetics' },
      { id: '12_13', label: 'Стояние на гвоздях', slug: 'nailStanding' },
      { id: '12_14', label: 'Массаж', slug: 'massage' },
      { id: '12_15', label: 'Фейсфитнес', slug: 'faceFitness' },
      { id: '12_16', label: 'Фэйсбилдинг', slug: 'faceBuilding' },
      { id: '12_17', label: 'Цигун', slug: 'qigong' }
    ],
    id: '12',
    label: 'Красота и здоровье',
    slug: 'beautyAndHealth'
  },
  {
    children: [
      { id: '13_0', label: 'Олимпийские игры', slug: 'olympicGames' },
      { id: '13_1', label: 'Паролимпийские игры', slug: 'paralympicGames' }
    ],
    id: '13',
    label: 'Олимпийские игры',
    slug: 'olympicGames'
  },
  {
    children: [
      { id: '13_0', label: 'Спорт', slug: 'sports' },
      { id: '13_1', label: 'Акробатика', slug: 'acrobatics' },
      { id: '13_2', label: 'Армреслинг', slug: 'armwrestling' },
      { id: '13_3', label: 'Бег', slug: 'running' },
      { id: '13_4', label: 'Биатлон', slug: 'biathlon' },
      { id: '13_5', label: 'Бодибилдинг', slug: 'bodybuilding' },
      { id: '13_6', label: 'Велоспорт', slug: 'cycling' },
      { id: '13_7', label: 'Геокэшинг', slug: 'geocaching' },
      { id: '13_8', label: 'Гимнастика спортивная', slug: 'sportsGymnastics' },
      { id: '13_9', label: 'Гимнастика художественная', slug: 'artisticGymnastics' },
      { id: '13_10', label: 'Гиревой спорт', slug: 'kettlebellSport' },
      { id: '13_11', label: 'Гребля', slug: 'rowing' },
      { id: '13_12', label: 'Зумба', slug: 'zumba' },
      { id: '13_13', label: 'Кабадди', slug: 'kabaddi' },
      { id: '13_14', label: 'Конный спорт', slug: 'equestrianSport' },
      { id: '13_15', label: 'Коньки', slug: 'skating' },
      { id: '13_16', label: 'Кроссфит', slug: 'crossfit' },
      { id: '13_17', label: 'Легкая атлетика', slug: 'athletics' },
      { id: '13_18', label: 'Лыжи', slug: 'skiing' },
      { id: '13_19', label: 'Настольный теннис', slug: 'tableTennis' },
      { id: '13_20', label: 'Парусный спорт', slug: 'sailing' },
      { id: '13_21', label: 'Пауэрлифтинг', slug: 'powerlifting' },
      { id: '13_22', label: 'Пилатес', slug: 'pilates' },
      { id: '13_23', label: 'Плавание', slug: 'swimming' },
      { id: '13_24', label: 'Прыжки на батуте', slug: 'trampolineJumping' },
      { id: '13_25', label: 'Рогейн', slug: 'rogaining' },
      { id: '13_26', label: 'Роликобежный спорт', slug: 'rollerSports' },
      { id: '13_27', label: 'Самокатный спорт', slug: 'scooterSport' },
      { id: '13_28', label: 'Спортивная стрельба', slug: 'sportsShooting' },
      { id: '13_29', label: 'Стрельба из лука', slug: 'archery' },
      { id: '13_30', label: 'Стрельба пулевая', slug: 'shooting' },
      { id: '13_31', label: 'Стрельба стендовая', slug: 'clayShooting' },
      { id: '13_32', label: 'Танцы спортивные', slug: 'sportsDance' },
      { id: '13_33', label: 'Тренажорный зал New', slug: 'gym' },
      { id: '13_34', label: 'Триатлон', slug: 'triathlon' },
      { id: '13_35', label: 'Тяжелая атлетика', slug: 'weightlifting' },
      { id: '13_36', label: 'Фехтование', slug: 'fencing' },
      { id: '13_37', label: 'Фигурное катание', slug: 'figureSkating' },
      { id: '13_38', label: 'Фитнес New', slug: 'fitnessNew' },
      { id: '13_39', label: 'Шорт-трек', slug: 'shortTrack' },
      { id: '13_40', label: 'Яхтинг', slug: 'yachting' }
    ],
    id: '14',
    label: 'Спорт',
    slug: 'sports'
  },
  {
    children: [
      { id: '15_0', label: 'Командные Игры', slug: 'teamGames' },
      { id: '15_1', label: 'Американский футбол', slug: 'americanFootball' },
      { id: '15_2', label: 'Бадминтон', slug: 'badminton' },
      { id: '15_3', label: 'Баскетбол', slug: 'basketball' },
      { id: '15_4', label: 'Бейсбол', slug: 'baseball' },
      { id: '15_5', label: 'Большой теннис', slug: 'tennis' },
      { id: '15_6', label: 'Бридж', slug: 'bridge' },
      { id: '15_7', label: 'Водное поло', slug: 'waterPolo' },
      { id: '15_8', label: 'Волейбол', slug: 'volleyball' },
      { id: '15_9', label: 'Волейбол пляжный', slug: 'beachVolleyball' },
      { id: '15_10', label: 'Гандбол', slug: 'handball' },
      { id: '15_11', label: 'Дартс', slug: 'darts' },
      { id: '15_12', label: 'Керлинг', slug: 'curling' },
      { id: '15_13', label: 'Кинбол', slug: 'kinball' },
      { id: '15_14', label: 'Крикет', slug: 'cricket' },
      { id: '15_15', label: 'Лапта', slug: 'lapta' },
      { id: '15_16', label: 'Лякросс', slug: 'lacrosse' },
      { id: '15_17', label: 'Мотобол', slug: 'motoball' },
      { id: '15_18', label: 'Пейнтбол', slug: 'paintball' },
      { id: '15_19', label: 'Перетягивание каната', slug: 'tugOfWar' },
      { id: '15_20', label: 'Поло', slug: 'polo' },
      { id: '15_21', label: 'Регби', slug: 'rugby' },
      { id: '15_22', label: 'Следж-хоккей', slug: 'sledgeHockey' },
      { id: '15_23', label: 'Слэмбол', slug: 'slamball' },
      { id: '15_24', label: 'Софтбол', slug: 'softball' },
      { id: '15_25', label: 'Спортивные состязания', slug: 'sportingCompetitions' },
      { id: '15_26', label: 'Страйкбол', slug: 'strikeball' },
      { id: '15_27', label: 'Теннис настольный', slug: 'tableTennis' },
      { id: '15_28', label: 'Трекбол', slug: 'trackball' },
      { id: '15_29', label: 'Футбол', slug: 'football' },
      { id: '15_30', label: 'Футбэг нет-гейм', slug: 'footbagNetGame' },
      { id: '15_31', label: 'Хокей на траве', slug: 'fieldHockey' },
      { id: '15_32', label: 'Хоккей', slug: 'hockey' },
      { id: '15_33', label: 'Хоккей с мячом', slug: 'ballHockey' },
      { id: '15_34', label: 'Энкаунтер', slug: 'encounter' }
    ],
    id: '15',
    label: 'Team Sports',
    slug: 'teamSports'
  },
  {
    children: [
      { id: '16_0', label: 'Экстремальные увлечения', slug: 'extremeHobbies' },
      { id: '16_1', label: 'Альпинизм', slug: 'mountaineering' },
      { id: '16_2', label: 'Банджи-джампинг', slug: 'bungeeJumping' },
      { id: '16_3', label: 'Бейсджампинг', slug: 'baseJumping' },
      { id: '16_4', label: 'Бейскламберы', slug: 'baseClimbers' },
      { id: '16_5', label: 'Билдеринг', slug: 'bouldering' },
      { id: '16_6', label: 'Вейкбординг', slug: 'wakeboarding' },
      { id: '16_7', label: 'Виндсерфинг', slug: 'windsurfing' },
      { id: '16_8', label: 'Горные лыжи', slug: 'skiing' },
      { id: '16_9', label: 'Групповая акробатика', slug: 'groupAcrobatics' },
      { id: '16_10', label: 'Дайвинг', slug: 'diving' },
      { id: '16_11', label: 'Диггерство', slug: 'diggering' },
      { id: '16_12', label: 'Зорбинг', slug: 'zorbing' },
      { id: '16_13', label: 'Кайтсёрфинг', slug: 'kitesurfing' },
      { id: '16_14', label: 'Катание на собачьих упряжках', slug: 'dogSledding' },
      { id: '16_15', label: 'Каякинг', slug: 'kayaking' },
      { id: '16_16', label: 'Кикскутеринг', slug: 'kickScootering' },
      { id: '16_17', label: 'Клиффдайвинг', slug: 'cliffDiving' },
      { id: '16_18', label: 'Коррида', slug: 'bullfighting' },
      { id: '16_19', label: 'Купольная акробатика', slug: 'domedAcrobatics' },
      { id: '16_20', label: 'Ледолазание', slug: 'iceClimbing' },
      { id: '16_21', label: 'Маунтин-байкинг', slug: 'mountainBiking' },
      { id: '16_22', label: 'Паркур', slug: 'parkour' },
      { id: '16_23', label: 'Плавание с акулами', slug: 'swimmingWithSharks' },
      { id: '16_24', label: 'Полёты на воздушных шарах', slug: 'hotAirBalloonFlights' },
      { id: '16_25', label: 'Полеты на параплане', slug: 'paragliding' },
      { id: '16_26', label: 'Прохождение квестов', slug: 'questPassing' },
      { id: '16_27', label: 'Прыжки с парашютом', slug: 'parachuting' },
      { id: '16_28', label: 'Рафтинг', slug: 'rafting' },
      { id: '16_29', label: 'Ролики', slug: 'rollerblading' },
      { id: '16_30', label: 'Руфинг', slug: 'roofering' },
      { id: '16_31', label: 'Сбор грибов', slug: 'mushroomPicking' },
      { id: '16_32', label: 'Серфинг', slug: 'surfing' },
      { id: '16_33', label: 'Скайдайвинг', slug: 'skydiving' },
      { id: '16_34', label: 'Скалодром', slug: 'climbingWall' },
      { id: '16_35', label: 'Скачки', slug: 'races' },
      { id: '16_36', label: 'Скейтборд', slug: 'skateboarding' },
      { id: '16_37', label: 'Сноуборд', slug: 'snowboarding' },
      { id: '16_38', label: 'Спелеотуризм', slug: 'caving' },
      { id: '16_39', label: 'Ставки на спорт', slug: 'sportsBetting' },
      { id: '16_40', label: 'Трейнсерфинг', slug: 'transurfing' },
      { id: '16_41', label: 'Хелибординг', slug: 'heliboarding' },
      { id: '16_42', label: 'Хождение по канату', slug: 'walkingOnTheRope' },
      { id: '16_43', label: 'Черные копатели', slug: 'blackDiggers' },
      { id: '16_44', label: 'Энсьерро', slug: 'encierro' }
    ],
    id: '16',
    label: 'Экстремальные увлечения',
    slug: 'extremeHobbies'
  },
  {
    children: [
      { id: '17_0', label: 'Боевые исскуства', slug: 'martialArts' },
      { id: '17_1', label: 'Айкидо', slug: 'aikido' },
      { id: '17_2', label: 'Арнис', slug: 'arnis' },
      { id: '17_3', label: 'Бартицу', slug: 'bartitsu' },
      { id: '17_4', label: 'Бои без правил', slug: 'mixedMartialArts' },
      { id: '17_5', label: 'Бокс', slug: 'boxing' },
      { id: '17_6', label: 'Винь-чуй', slug: 'wingChun' },
      { id: '17_7', label: 'Вольная борьба', slug: 'freestyleWrestling' },
      { id: '17_8', label: 'Греко-римская борьба', slug: 'grecoRomanWrestling' },
      { id: '17_9', label: 'Гюлеш', slug: 'gulesh' },
      { id: '17_10', label: 'Джиу-джитсу', slug: 'jiuJitsu' },
      { id: '17_11', label: 'Дзюдо', slug: 'judo' },
      { id: '17_12', label: 'Капоэйра', slug: 'capoeira' },
      { id: '17_13', label: 'Каратэ', slug: 'karate' },
      { id: '17_14', label: 'Киокусинкай', slug: 'kyokushin' },
      { id: '17_15', label: 'Киокушин', slug: 'kyokushinkai' },
      { id: '17_16', label: 'Куреш', slug: 'kuresh' },
      { id: '17_17', label: 'Кэндо', slug: 'kendo' },
      { id: '17_18', label: 'Летхвей', slug: 'letWei' },
      { id: '17_19', label: 'ММА', slug: 'mma' },
      { id: '17_20', label: 'Муай тай', slug: 'muayThai' },
      { id: '17_21', label: 'Рукопашный бой', slug: 'handToHandCombat' },
      { id: '17_22', label: 'Сават', slug: 'savate' },
      { id: '17_23', label: 'Самбо', slug: 'sambo' },
      { id: '17_24', label: 'Сумо', slug: 'sumo' },
      { id: '17_25', label: 'Тайский бокс', slug: 'muayBoran' },
      { id: '17_26', label: 'Тхэквандо', slug: 'taekwondo' },
      { id: '17_27', label: 'Ушу', slug: 'wushu' },
      { id: '17_28', label: 'Фехтование', slug: 'fencing' },
      { id: '17_29', label: 'Хапкидо', slug: 'hapkido' },
      { id: '17_30', label: 'Шотокан', slug: 'shotokan' }
    ],
    id: '17',
    label: 'Боевые исскуства',
    slug: 'martialArts'
  },
  {
    children: [
      { id: '18_0', label: 'Кулинария и напитки', slug: 'cookingAndDrinks' },
      { id: '18_1', label: 'Азиатская кухня', slug: 'asianCuisine' },
      { id: '18_2', label: 'Виноделие', slug: 'winemaking' },
      { id: '18_3', label: 'Выпечка тортов и десертов', slug: 'bakingCakesAndDesserts' },
      { id: '18_4', label: 'Декорирование блюд', slug: 'foodDecoration' },
      { id: '18_5', label: 'Домашнее сыроделие', slug: 'homemadeCheesemaking' },
      { id: '18_6', label: 'Испанская кухня', slug: 'spanishCuisine' },
      { id: '18_7', label: 'Итальянская кухня', slug: 'italianCuisine' },
      { id: '18_8', label: 'Кавказская кухня', slug: 'caucasianCuisine' },
      { id: '18_9', label: 'Карвинг', slug: 'carving' },
      { id: '18_10', label: 'Кейтеринг', slug: 'catering' },
      { id: '18_11', label: 'Консервирование', slug: 'canning' },
      { id: '18_12', label: 'Латте-арт', slug: 'latteArt' },
      { id: '18_13', label: 'Мексиканская кухня', slug: 'mexicanCuisine' },
      { id: '18_14', label: 'Миксология', slug: 'mixology' },
      { id: '18_15', label: 'Паназиатская кухня', slug: 'panAsianCuisine' },
      { id: '18_16', label: 'Пивоварение', slug: 'brewing' },
      { id: '18_17', label: 'Приготовление напитков', slug: 'drinkMaking' },
      { id: '18_18', label: 'Русская кухня', slug: 'russianCuisine' },
      { id: '18_19', label: 'Самогоноварение', slug: 'moonshineMaking' },
      { id: '18_20', label: 'Сервировка и этикет', slug: 'servingAndEtiquette' },
      { id: '18_21', label: 'Сладости ручной работы', slug: 'handmadeSweets' },
      { id: '18_22', label: 'Турецкая кухня', slug: 'turkishCuisine' },
      { id: '18_23', label: 'Французская кухня', slug: 'frenchCuisine' },
      { id: '18_24', label: 'Холодные блюда', slug: 'coldDishes' }
    ],
    id: '18',
    label: 'Кулинария и напитки',
    slug: 'cookingAndDrinks'
  },
  {
    children: [
      { id: '19_0', label: 'Ночные развлечения', slug: 'nightEntertainment' },
      { id: '19_1', label: 'Ночные клубы', slug: 'nightClubs' },
      { id: '19_2', label: 'Курение кальяна', slug: 'hookahSmoking' },
      { id: '19_3', label: 'Ночной фитнес клуб', slug: 'nightFitnessClub' },
      { id: '19_4', label: 'Караоке-бары', slug: 'karaokeBars' },
      { id: '19_5', label: 'Казино', slug: 'casinos' },
      { id: '19_6', label: 'Бильярд', slug: 'billiards' },
      { id: '19_7', label: 'Боулинг', slug: 'bowling' },
      { id: '19_8', label: 'Наблюдение за звездами', slug: 'stargazing' }
    ],
    id: '19',
    label: 'Ночные развлечения',
    slug: 'nightEntertainment'
  },
  {
    children: [
      { id: '20_0', label: 'Фото', slug: 'photo' },
      { id: '20_1', label: 'Реставрация фото', slug: 'photoRestoration' },
      { id: '20_2', label: 'Съемка на квадрокоптер', slug: 'quadcopterPhotography' },
      { id: '20_3', label: 'Фотомонтаж', slug: 'photoMontage' },
      { id: '20_4', label: 'Фотосьемка', slug: 'photoShooting' }
    ],
    id: '20',
    label: 'Фото',
    slug: 'photo'
  },
  {
    children: [
      { id: '21_0', label: 'Видео', slug: 'video' },
      { id: '21_1', label: 'Видео Арт', slug: 'videoArt' },
      { id: '21_2', label: 'Видеомонтаж', slug: 'videoEditing' },
      { id: '21_3', label: 'Видеосъемка', slug: 'videoShooting' },
      { id: '21_4', label: 'Вирусные ролики', slug: 'viralVideos' },
      { id: '21_5', label: 'Съемка на квадрокоптер', slug: 'quadcopterShooting' }
    ],
    id: '21',
    label: 'Видео',
    slug: 'video'
  },
  {
    children: [
      { id: '22_0', label: 'Виртуальный мир', slug: 'virtualWorld' },
      { id: '22_1', label: 'ММО и MMORPG игры', slug: 'mmoMmorpgGames' },
      { id: '22_2', label: 'Авиасимуляторы', slug: 'flightSimulators' },
      { id: '22_3', label: 'Игровые приставки', slug: 'gameConsoles' },
      { id: '22_4', label: 'Квесты', slug: 'quests' },
      { id: '22_5', label: 'Киберспорт', slug: 'esports' },
      { id: '22_6', label: 'Компьютерные игры', slug: 'computerGames' },
      { id: '22_7', label: 'Симуляторы', slug: 'simulators' },
      { id: '22_8', label: 'Создание анимации', slug: 'animationCreation' },
      { id: '22_9', label: 'Cyberpunk', slug: 'cyberpunk' }
    ],
    id: '22',
    label: 'Виртуальный мир',
    slug: 'virtualWorld'
  },
  {
    children: [
      { id: '23_0', label: 'IT', slug: 'it' },
      { id: '23_1', label: '3D моделирование', slug: '3dModeling' },
      { id: '23_2', label: '4 и 5D моделирование', slug: '4and5dModeling' },
      { id: '23_3', label: 'AR/VR технологии', slug: 'arVrTechnologies' },
      { id: '23_4', label: 'IT-технологии и инновации', slug: 'itTechnologiesAndInnovations' },
      { id: '23_5', label: 'Web-программирование', slug: 'webProgramming' },
      { id: '23_6', label: 'Геймдизайн', slug: 'gameDesign' },
      { id: '23_7', label: 'Программирование', slug: 'programming' },
      { id: '23_8', label: 'Программирование игр', slug: 'gameProgramming' },
      { id: '23_9', label: 'Программирование приложений', slug: 'appDevelopment' },
      { id: '23_10', label: 'Разработка', slug: 'development' }
    ],
    id: '23',
    label: 'IT',
    slug: 'it'
  },
  { children: [{ id: '24_0', label: 'Блогинг', slug: 'blog' }], id: '24', label: 'Блогинг', slug: 'blog' },
  {
    children: [
      { id: '24_0', label: 'Азартные игры', slug: 'gambling' },
      { id: '24_1', label: 'Покер', slug: 'poker' },
      { id: '24_2', label: 'Рулетка', slug: 'roulette' },
      { id: '24_3', label: 'Ставки', slug: 'bets' },
      { id: '24_4', label: 'Блэкджек', slug: 'blackjack' },
      { id: '24_5', label: 'Баккара', slug: 'baccarat' },
      { id: '24_6', label: 'Крэпс (кости)', slug: 'craps' },
      { id: '24_7', label: 'Лотерея', slug: 'lottery' }
    ],
    id: '25',
    label: 'Азартные игры',
    slug: 'gambling'
  },
  {
    children: [
      { id: '26_0', label: 'Настольные игры', slug: 'boardGames' },
      { id: '26_1', label: 'Банкир и брокер', slug: 'bankerAndBroker' },
      { id: '26_2', label: 'Блеф', slug: 'bluff' },
      { id: '26_3', label: 'Блэк-джек', slug: 'blackjack' },
      { id: '26_4', label: 'Десятка', slug: 'ten' },
      { id: '26_5', label: 'Дженга', slug: 'jenga' },
      { id: '26_6', label: 'Дискит', slug: 'diskit' },
      { id: '26_7', label: 'Домино', slug: 'domino' },
      { id: '26_8', label: 'Игральные кости', slug: 'diceGames' },
      { id: '26_9', label: 'Игры миниатюр', slug: 'miniatureGames' },
      { id: '26_10', label: 'Игры с карандашом и бумагой', slug: 'pencilAndPaperGames' },
      { id: '26_11', label: 'Имаджинариум', slug: 'imaginarium' },
      { id: '26_12', label: 'Карты игральные', slug: 'playingCards' },
      { id: '26_13', label: 'Крестики-нолики', slug: 'ticTacToe' },
      { id: '26_14', label: 'Крокодил', slug: 'crocodile' },
      { id: '26_15', label: 'Маджонг', slug: 'mahjong' },
      { id: '26_16', label: 'Манчкин', slug: 'munchkin' },
      { id: '26_17', label: 'Мафия', slug: 'mafia' },
      { id: '26_18', label: 'Мельница', slug: 'mill' },
      { id: '26_19', label: 'Монополия', slug: 'monopoly' },
      { id: '26_20', label: 'Морской бой', slug: 'battleship' },
      { id: '26_21', label: 'Наперстки', slug: 'thimbles' },
      { id: '26_22', label: 'Нарды', slug: 'backgammon' },
      { id: '26_23', label: 'Ответь за 5 секунд', slug: 'answerIn5Seconds' },
      { id: '26_24', label: 'Покер', slug: 'poker' },
      { id: '26_25', label: 'Преферанс', slug: 'preferans' },
      { id: '26_26', label: 'Русская рулетка', slug: 'russianRoulette' },
      { id: '26_27', label: 'Уно', slug: 'uno' },
      { id: '26_28', label: 'Хартстоун', slug: 'hearthstone' },
      { id: '26_29', label: 'Шахматы', slug: 'chess' },
      { id: '26_30', label: 'Шашки', slug: 'checkers' }
    ],
    id: '26',
    label: 'Настольные игры',
    slug: 'boardGames'
  },
  {
    children: [
      { id: '27_0', label: 'Авто и мото', slug: 'autoAndMoto' },
      { id: '27_1', label: 'Demolition Derby', slug: 'demolitionDerby' },
      { id: '27_2', label: 'Figure 8 Racing', slug: 'figure8Racing' },
      { id: '27_3', label: 'Авто и мотогонки на льду', slug: 'autoAndMotoRacingOnIce' },
      { id: '27_4', label: 'Автогонки', slug: 'autoracing' },
      { id: '27_5', label: 'Автозвук', slug: 'autosound' },
      { id: '27_6', label: 'Автокросс', slug: 'autocross' },
      { id: '27_7', label: 'Байк-шоу', slug: 'bikeShow' },
      { id: '27_8', label: 'Внедорожные мотоциклы', slug: 'offRoadMotorcycles' },
      { id: '27_9', label: 'Гонки на снегоходе по воде', slug: 'snowmobileRacingOnWater' },
      { id: '27_10', label: 'Детейлинг', slug: 'detailing' },
      { id: '27_11', label: 'Дрифт', slug: 'drift' },
      { id: '27_12', label: 'Дрэг-рейсинг', slug: 'dragRacing' },
      { id: '27_13', label: 'Карпостинг', slug: 'carposting' },
      { id: '27_14', label: 'Картинг', slug: 'karting' },
      { id: '27_15', label: 'Квадроциклы', slug: 'quads' },
      { id: '27_16', label: 'Мотокросс', slug: 'motocross' },
      { id: '27_17', label: 'Мотоспорт', slug: 'motorsport' },
      { id: '27_18', label: 'Ралли', slug: 'rally' },
      { id: '27_19', label: 'Ретроавтомото', slug: 'retroAutomoto' },
      { id: '27_20', label: 'Стайлинг', slug: 'styling' },
      { id: '27_21', label: 'Триалы', slug: 'trials' },
      { id: '27_22', label: 'Трофи траки', slug: 'trophyTrucks' },
      { id: '27_23', label: 'Тюнинг', slug: 'tuning' },
      { id: '27_24', label: 'Уличные гонки', slug: 'streetRacing' },
      { id: '27_25', label: 'Фристайл мотокросс', slug: 'freestyleMotocross' }
    ],
    id: '27',
    label: 'Авто и мото',
    slug: 'autoAndMoto'
  },
  {
    children: [
      { id: '28_0', label: 'Изучение языков', slug: 'languageLearning' },
      { id: '28_1', label: 'Азербайджанский', slug: 'azerbaijani' },
      { id: '28_2', label: 'Албанский', slug: 'albanian' },
      { id: '28_3', label: 'Английский', slug: 'english' },
      { id: '28_4', label: 'Арабский', slug: 'arabic' },
      { id: '28_5', label: 'Армянский', slug: 'armenian' },
      { id: '28_6', label: 'Белорусский', slug: 'belarusian' },
      { id: '28_7', label: 'Болгарский', slug: 'bulgarian' },
      { id: '28_8', label: 'Венгерский', slug: 'hungarian' },
      { id: '28_9', label: 'Вьетнамский', slug: 'vietnamese' },
      { id: '28_10', label: 'Голландский', slug: 'dutch' },
      { id: '28_11', label: 'Греческий', slug: 'greek' },
      { id: '28_12', label: 'Грузинский', slug: 'georgian' },
      { id: '28_13', label: 'Дари', slug: 'dari' },
      { id: '28_14', label: 'Датский', slug: 'danish' },
      { id: '28_15', label: 'Древнегреческий', slug: 'ancientGreek' },
      { id: '28_16', label: 'Древние языки', slug: 'ancientLanguages' },
      { id: '28_17', label: 'Иврит', slug: 'hebrew' },
      { id: '28_18', label: 'Испанский', slug: 'spanish' },
      { id: '28_19', label: 'Итальянский', slug: 'italian' },
      { id: '28_20', label: 'Казахский', slug: 'kazakh' },
      { id: '28_21', label: 'Китайский', slug: 'chinese' },
      { id: '28_22', label: 'Корейский', slug: 'korean' },
      { id: '28_23', label: 'Кыргызский', slug: 'kyrgyz' },
      { id: '28_24', label: 'Латинский', slug: 'latin' },
      { id: '28_25', label: 'Латышский', slug: 'latvian' },
      { id: '28_26', label: 'Литовский', slug: 'lithuanian' },
      { id: '28_27', label: 'Малазийский', slug: 'malay' },
      { id: '28_28', label: 'Молдавский', slug: 'moldovan' },
      { id: '28_29', label: 'Монгольский', slug: 'mongolian' },
      { id: '28_30', label: 'Немецкий', slug: 'german' },
      { id: '28_31', label: 'Непальский', slug: 'nepali' },
      { id: '28_32', label: 'Нидерландский', slug: 'dutch' },
      { id: '28_33', label: 'Норвежский', slug: 'norwegian' },
      { id: '28_34', label: 'Персидский', slug: 'persian' },
      { id: '28_35', label: 'Польский', slug: 'polish' },
      { id: '28_36', label: 'Португальский', slug: 'portuguese' },
      { id: '28_37', label: 'Румынский', slug: 'romanian' },
      { id: '28_38', label: 'Русский', slug: 'russian' },
      { id: '28_39', label: 'Сербский', slug: 'serbian' },
      { id: '28_40', label: 'Словацкий', slug: 'slovak' },
      { id: '28_41', label: 'Словенский', slug: 'slovenian' },
      { id: '28_42', label: 'Таджикский', slug: 'tajik' },
      { id: '28_43', label: 'Тайский', slug: 'thai' },
      { id: '28_44', label: 'Татарский', slug: 'tatar' },
      { id: '28_45', label: 'Турецкий', slug: 'turkish' },
      { id: '28_46', label: 'Туркменский', slug: 'turkmen' },
      { id: '28_47', label: 'Узбекский', slug: 'uzbek' },
      { id: '28_48', label: 'Украинский', slug: 'ukrainian' },
      { id: '28_49', label: 'Фарси', slug: 'farsi' },
      { id: '28_50', label: 'Финский', slug: 'finnish' },
      { id: '28_51', label: 'Фламандский', slug: 'flemish' },
      { id: '28_52', label: 'Французский', slug: 'french' },
      { id: '28_53', label: 'Хинди', slug: 'hindi' },
      { id: '28_54', label: 'Хороватский', slug: 'khorvat' },
      { id: '28_55', label: 'Чешский', slug: 'czech' },
      { id: '28_56', label: 'Шведский', slug: 'swedish' },
      { id: '28_57', label: 'Эстонский', slug: 'estonian' },
      { id: '28_58', label: 'Японский', slug: 'japanese' }
    ],
    id: '28',
    label: 'Изучение языков',
    slug: 'languageLearning'
  },
  {
    children: [
      { id: '29_0', label: 'Изучение наук', slug: 'scienceLearning' },
      { id: '29_1', label: 'Агрономия', slug: 'agronomy' },
      { id: '29_2', label: 'Археология', slug: 'archaeology' },
      { id: '29_3', label: 'Архитектура', slug: 'architecture' },
      { id: '29_4', label: 'Астрология', slug: 'astrology' },
      { id: '29_5', label: 'Астрономия', slug: 'astronomy' },
      { id: '29_6', label: 'Астрофизика', slug: 'astrophysics' },
      { id: '29_7', label: 'Аэронавтика', slug: 'aeronautics' },
      { id: '29_8', label: 'Баллистика', slug: 'ballistics' },
      { id: '29_9', label: 'Биология', slug: 'biology' },
      { id: '29_10', label: 'Ботаника', slug: 'botany' },
      { id: '29_11', label: 'География', slug: 'geography' },
      { id: '29_12', label: 'Геология', slug: 'geology' },
      { id: '29_13', label: 'Геофизика', slug: 'geophysics' },
      { id: '29_14', label: 'Гидрология', slug: 'hydrology' },
      { id: '29_15', label: 'Зоология', slug: 'zoology' },
      { id: '29_16', label: 'Информатика', slug: 'informatics' },
      { id: '29_17', label: 'Исскуствоведение', slug: 'artStudies' },
      { id: '29_18', label: 'История', slug: 'history' },
      { id: '29_19', label: 'Книговедение', slug: 'bibliology' },
      { id: '29_20', label: 'Краеведение', slug: 'localHistory' },
      { id: '29_21', label: 'Криптография', slug: 'cryptography' },
      { id: '29_22', label: 'Культурная антропология', slug: 'culturalAnthropology' },
      { id: '29_23', label: 'Культурология', slug: 'culturalStudies' },
      { id: '29_24', label: 'Лингвистика', slug: 'linguistics' },
      { id: '29_25', label: 'Литературоведение', slug: 'literaryStudies' },
      { id: '29_26', label: 'Математика', slug: 'mathematics' },
      { id: '29_27', label: 'Медицина', slug: 'medicine' },
      { id: '29_28', label: 'Палонтология', slug: 'paleontology' },
      { id: '29_29', label: 'Педагогика', slug: 'pedagogy' },
      { id: '29_30', label: 'Почвоведение', slug: 'soilScience' },
      { id: '29_31', label: 'Психология', slug: 'psychology' },
      { id: '29_32', label: 'Религиоведение', slug: 'religiousStudies' },
      { id: '29_33', label: 'Робототехника', slug: 'robotics' },
      { id: '29_34', label: 'Социология', slug: 'sociology' },
      { id: '29_35', label: 'Физиагномика', slug: 'physiognomy' },
      { id: '29_36', label: 'Философия', slug: 'philosophy' },
      { id: '29_37', label: 'Химия', slug: 'chemistry' }
    ],
    id: '29',
    label: 'Изучение наук',
    slug: 'scienceLearning'
  },
  {
    children: [
      { id: '30_0', label: 'Эзотерика', slug: 'esoterics' },
      { id: '30_1', label: 'Алхимия', slug: 'alchemy' },
      { id: '30_2', label: 'Астрология', slug: 'astrology' },
      { id: '30_3', label: 'Биоэнергетика', slug: 'bioenergy' },
      { id: '30_4', label: 'Буддизм', slug: 'buddhism' },
      { id: '30_5', label: 'Гадание', slug: 'divination' },
      { id: '30_6', label: 'Гностицизм', slug: 'gnosticism' },
      { id: '30_7', label: 'Даосизм', slug: 'taoism' },
      { id: '30_8', label: 'Дизайн человека', slug: 'humanDesign' },
      { id: '30_9', label: 'Игра на глюкофоне', slug: 'glukofonGame' },
      { id: '30_10', label: 'Каббала', slug: 'kabbalah' },
      { id: '30_11', label: 'Космоэнергетика', slug: 'cosmoenergy' },
      { id: '30_12', label: 'Магия и ритуалы', slug: 'magicAndRituals' },
      { id: '30_13', label: 'Масонство', slug: 'masonry' },
      { id: '30_14', label: 'Матрица Судьбы', slug: 'matrixOfDestiny' },
      { id: '30_15', label: 'Медитации', slug: 'meditations' },
      { id: '30_16', label: 'Нумерология', slug: 'numerology' },
      { id: '30_17', label: 'Оккультизм', slug: 'occultism' },
      { id: '30_18', label: 'Осознанные сновидения', slug: 'consciousDreaming' },
      { id: '30_19', label: 'Парапсихология', slug: 'parapsychology' },
      { id: '30_20', label: 'Поющие чаши', slug: 'singingBowls' },
      { id: '30_21', label: 'Психосоматика', slug: 'psychosomatics' },
      { id: '30_22', label: 'Регресс', slug: 'regression' },
      { id: '30_23', label: 'Сакральные инструменты', slug: 'sacredInstruments' },
      { id: '30_24', label: 'Спиритизм', slug: 'spiritism' },
      { id: '30_25', label: 'Таро', slug: 'tarot' },
      { id: '30_26', label: 'Тетахилинг', slug: 'thetaHealing' },
      { id: '30_27', label: 'Трансерфинг', slug: 'transurfing' },
      { id: '30_28', label: 'Трансформационные игры', slug: 'transformationalGames' },
      { id: '30_29', label: 'Фэн-шуй', slug: 'fengShui' },
      { id: '30_30', label: 'Хиромантия', slug: 'palmistry' },
      { id: '30_31', label: 'Целительство', slug: 'healing' },
      { id: '30_32', label: 'Ченелинг', slug: 'channeling' },
      { id: '30_33', label: 'Энерготерапия', slug: 'energyTherapy' },
      { id: '30_34', label: 'Ясновидение', slug: 'clairvoyance' }
    ],
    id: '30',
    label: 'Эзотерика',
    slug: 'esoterics'
  },
  {
    children: [
      { id: '31_0', label: 'Путешествия и туризм', slug: 'travelAndTourism' },
      { id: '31_1', label: 'Бэкпэкер-туризм', slug: 'backpackerTourism' },
      { id: '31_2', label: 'Велосипедные туры', slug: 'bicycleTours' },
      { id: '31_3', label: 'Восхождения', slug: 'climbing' },
      { id: '31_4', label: 'Гастрономический туризм', slug: 'gastronomicTourism' },
      { id: '31_5', label: 'Глемпинг', slug: 'glamping' },
      { id: '31_6', label: 'Горнолыжные спуски', slug: 'skiingDownhill' },
      { id: '31_7', label: 'Деловой туризм', slug: 'businessTourism' },
      { id: '31_8', label: 'Духовный туризм', slug: 'spiritualTourism' },
      { id: '31_9', label: 'Зимние виды туризма', slug: 'winterTourism' },
      { id: '31_10', label: 'Индустриальный туризм', slug: 'industrialTourism' },
      { id: '31_11', label: 'Караванинг', slug: 'caravanning' },
      { id: '31_12', label: 'Каучсерфинг', slug: 'couchsurfing' },
      { id: '31_13', label: 'Кемпинг', slug: 'camping' },
      { id: '31_14', label: 'Кинотуризм', slug: 'filmTourism' },
      { id: '31_15', label: 'Конные и пешие прогулки', slug: 'horseAndHiking' },
      { id: '31_16', label: 'Морские круизы', slug: 'seaCruises' },
      { id: '31_17', label: 'Морской туризм', slug: 'seaTourism' },
      { id: '31_18', label: 'Оздоровительный туризм', slug: 'healthTourism' },
      { id: '31_19', label: 'Паломнический туризм', slug: 'pilgrimageTourism' },
      { id: '31_20', label: 'Познавательный туризм', slug: 'educationalTourism' },
      { id: '31_21', label: 'Походы в горы', slug: 'mountainHiking' },
      { id: '31_22', label: 'Приключенческий туризм', slug: 'adventureTourism' },
      { id: '31_23', label: 'Путешествие автостопом', slug: 'hitchhiking' },
      { id: '31_24', label: 'Рекреационный туризм', slug: 'recreationalTourism' },
      { id: '31_25', label: 'Событийный туризм', slug: 'eventTourism' },
      { id: '31_26', label: 'Сплавы', slug: 'rafting' },
      { id: '31_27', label: 'Спортивный туризм', slug: 'sportsTourism' },
      { id: '31_28', label: 'Фестивальный туризм', slug: 'festivalTourism' },
      { id: '31_29', label: 'Фототуризм', slug: 'photoTourism' },
      { id: '31_30', label: 'Шопинг-туризм', slug: 'shoppingTourism' },
      { id: '31_31', label: 'Экзотический туризм', slug: 'exoticTourism' },
      { id: '31_32', label: 'Эко-туризм', slug: 'ecoTourism' },
      { id: '31_33', label: 'Экстремальный туризм', slug: 'extremeTourism' },
      { id: '31_34', label: 'Этнический туризм', slug: 'ethnicTourism' }
    ],
    id: '31',
    label: 'Путешествия и туризм',
    slug: 'travelAndTourism'
  },
  {
    children: [
      { id: '32_0', label: 'Животный мир', slug: 'animalWorld' },
      { id: '32_1', label: 'Аквариумистика', slug: 'aquariumKeeping' },
      { id: '32_2', label: 'Бабочкарий', slug: 'butterflyWatching' },
      { id: '32_3', label: 'Бердвочинг', slug: 'birdwatching' },
      { id: '32_4', label: 'Волонтерство', slug: 'volunteering' },
      { id: '32_5', label: 'Выставка животных', slug: 'animalExhibition' },
      { id: '32_6', label: 'Грумминг', slug: 'grooming' },
      { id: '32_7', label: 'Дрессировка животных', slug: 'animalTraining' },
      { id: '32_8', label: 'Защита животных', slug: 'animalProtection' },
      { id: '32_9', label: 'Зоология', slug: 'zoology' },
      { id: '32_10', label: 'Зоопсихология', slug: 'zoopsychology' },
      { id: '32_11', label: 'Зоошоу', slug: 'zooShow' },
      { id: '32_12', label: 'Каникросс', slug: 'canicross' },
      { id: '32_13', label: 'Кинология', slug: 'canineTraining' },
      { id: '32_14', label: 'Коневодство', slug: 'horseBreeding' },
      { id: '32_15', label: 'Конный спорт', slug: 'equestrianSport' },
      { id: '32_16', label: 'Контактный зоопарк', slug: 'contactZoo' },
      { id: '32_17', label: 'Кролиководство', slug: 'rabbitBreeding' },
      { id: '32_18', label: 'Муравьиные фермы', slug: 'antFarms' },
      { id: '32_19', label: 'Одежда для питомцев', slug: 'petClothing' },
      { id: '32_20', label: 'Петушиные бои', slug: 'cockfighting' },
      { id: '32_21', label: 'Производство кормов', slug: 'feedProduction' },
      { id: '32_22', label: 'Пчеловодство', slug: 'beekeeping' },
      { id: '32_23', label: 'Разведение грызунов', slug: 'rodentBreeding' },
      { id: '32_24', label: 'Разведение домашних животных', slug: 'domesticAnimalBreeding' },
      { id: '32_25', label: 'Разведение зверей', slug: 'wildAnimalBreeding' },
      { id: '32_26', label: 'Разведение морских животных', slug: 'marineAnimalBreeding' },
      { id: '32_27', label: 'Разведение птиц', slug: 'birdBreeding' },
      { id: '32_28', label: 'Разведение экзотических животных', slug: 'exoticAnimalBreeding' },
      { id: '32_29', label: 'Собаководство', slug: 'dogBreeding' },
      { id: '32_30', label: 'Тараканьи бега', slug: 'roachRacing' },
      { id: '32_31', label: 'Террариумистика', slug: 'terrariumKeeping' }
    ],
    id: '32',
    label: 'Животный мир',
    slug: 'animalWorld'
  },
  {
    children: [
      { id: '33_0', label: 'Собачники', slug: 'dogLovers' },
      { id: '33_1', label: 'Австралийская овчарка (Аусси)', slug: 'australianShepherd' },
      { id: '33_2', label: 'Австралийский келли', slug: 'australianCattleDog' },
      { id: '33_3', label: 'Австралийский шелковистый терьер', slug: 'australianSilkyTerrier' },
      { id: '33_4', label: 'Аляскинский кликай', slug: 'alaskanKleeKai' },
      { id: '33_5', label: 'Аляскинский маламут', slug: 'alaskanMalamute' },
      { id: '33_6', label: 'Американская акита-ину', slug: 'americanAkitaInu' },
      { id: '33_7', label: 'Американский бульдог', slug: 'americanBulldog' },
      { id: '33_8', label: 'Американский голый терьер', slug: 'americanHairlessTerrier' },
      { id: '33_9', label: 'Американский кокер-спаниель', slug: 'americanCockerSpaniel' },
      { id: '33_10', label: 'Американский питбультерьер', slug: 'americanPitBullTerrier' },
      { id: '33_11', label: 'Американский стаффордширский терьер', slug: 'americanStaffordshireTerrier' },
      { id: '33_12', label: 'Американский эскимосский шпиц', slug: 'americanEskimoDog' },
      { id: '33_13', label: 'Английский бульдог', slug: 'englishBulldog' },
      { id: '33_14', label: 'Английский кокер-спаниель', slug: 'englishCockerSpaniel' },
      { id: '33_15', label: 'Английский мастиф', slug: 'englishMastiff' },
      { id: '33_16', label: 'Английский сеттер', slug: 'englishSetter' },
      { id: '33_17', label: 'Английский спрингер-спаниель', slug: 'englishSpringerSpaniel' },
      { id: '33_18', label: 'Аргентинский дог', slug: 'argentineDogo' },
      { id: '33_19', label: 'Афганская борзая', slug: 'afghanHound' },
      { id: '33_20', label: 'Аффепинчер', slug: 'affenpinscher' },
      { id: '33_21', label: 'Басенджи', slug: 'basenji' },
      { id: '33_22', label: 'Бассет-хаунд', slug: 'bassetHound' },
      { id: '33_23', label: 'Бедлингтон-терьер', slug: 'bedlingtonTerrier' },
      { id: '33_24', label: 'Белая швейцарская овчарка', slug: 'swissWhiteShepherd' },
      { id: '33_25', label: 'Бельгийская овчарка', slug: 'belgianShepherd' },
      { id: '33_26', label: 'Бивер-йорк', slug: 'beaverYork' },
      { id: '33_27', label: 'Бигль', slug: 'beagle' },
      { id: '33_28', label: 'Бишон фризе', slug: 'bichonFrise' },
      { id: '33_29', label: 'Бобтейл', slug: 'bobtail' },
      { id: '33_30', label: 'Боксер', slug: 'boxer' },
      { id: '33_31', label: 'Бордер-колли', slug: 'borderCollie' },
      { id: '33_32', label: 'Бордер-терьер', slug: 'borderTerrier' },
      { id: '33_33', label: 'Бордоский дог', slug: 'bordeauxDog' },
      { id: '33_34', label: 'Бородатый колли', slug: 'beardedCollie' },
      { id: '33_35', label: 'Босерон', slug: 'bouvierDesFlandres' },
      { id: '33_36', label: 'Бостон-терьер', slug: 'bostonTerrier' },
      { id: '33_37', label: 'Брюссельский гриффон', slug: 'brusselsGriffon' },
      { id: '33_38', label: 'Бульмастиф', slug: 'bullmastiff' },
      { id: '33_39', label: 'Бультерьер', slug: 'bullTerrier' },
      { id: '33_40', label: 'Веймаранер', slug: 'weimaraner' },
      { id: '33_41', label: 'Вельш-корги', slug: 'welshCorgi' },
      { id: '33_42', label: 'Вельштерьер', slug: 'welshTerrier' },
      { id: '33_43', label: 'Венгерская выжла (легавая)', slug: 'hungarianVizsla' },
      { id: '33_44', label: 'Венгерский кувас', slug: 'hungarianKuvasz' },
      { id: '33_45', label: 'Вест-хайленд-уайт-терьер', slug: 'westHighlandWhiteTerrier' },
      { id: '33_46', label: 'Восточно-сибирская лайка', slug: 'eastSiberianLaika' },
      { id: '33_47', label: 'Восточноевропейская овчарка', slug: 'eastEuropeanShepherd' },
      { id: '33_48', label: 'Горная пиренейская собака', slug: 'pyreneanMountainDog' },
      { id: '33_49', label: 'Грейхаунд', slug: 'greyhound' },
      { id: '33_50', label: 'Далматинец', slug: 'dalmatian' },
      { id: '33_51', label: 'Джек рассел терьер', slug: 'jackRussellTerrier' },
      { id: '33_52', label: 'Доберман', slug: 'doberman' },
      { id: '33_53', label: 'Дратхаар или немецкая жесткошерстная легавая', slug: 'drahthaar' },
      { id: '33_54', label: 'Евразиер', slug: 'eurasier' },
      { id: '33_55', label: 'Западно-сибирская лайка', slug: 'westSiberianLaika' },
      { id: '33_56', label: 'Золотистый ретривер', slug: 'goldenRetriever' },
      { id: '33_57', label: 'Ирландский волкодав', slug: 'irishWolfhound' },
      { id: '33_58', label: 'Ирландский мягкошерстный пшеничный терьер', slug: 'irishSoftCoatedWheatenTerrier' },
      { id: '33_59', label: 'Ирландский сеттер', slug: 'irishSetter' },
      { id: '33_60', label: 'Ирландский терьер', slug: 'irishTerrier' },
      { id: '33_61', label: 'Итальянская болонка (болоньез, болонский бишон)', slug: 'italianBolognese' },
      { id: '33_62', label: 'Итальянская лавретка (итальянская борзая)', slug: 'italianGreyhound' },
      { id: '33_63', label: 'Йокширский терьер', slug: 'yorkshireTerrier' },
      { id: '33_64', label: 'Каде Бо (майорский мастиф)', slug: 'cadeBou' },
      { id: '33_65', label: 'Кавалер-кинг-чарльз-спаниель', slug: 'cavalierKingCharlesSpaniel' },
      { id: '33_66', label: 'Кавапу дизайнерская порода', slug: 'cavapoo' },
      { id: '33_67', label: 'Кавказская овчарка', slug: 'caucasianShepherdDog' },
      { id: '33_68', label: 'Кане-корсо', slug: 'caneCorso' },
      { id: '33_69', label: 'Карело-финская лайка', slug: 'karelianBearDog' },
      { id: '33_70', label: 'Карельская медвежья', slug: 'karelianBearDog' },
      { id: '33_71', label: 'Кеесхонд (вольфшпиц)', slug: 'keeshond' },
      { id: '33_72', label: 'Керн-терьер', slug: 'kerryBlueTerrier' },
      { id: '33_73', label: 'Керри-блю-терьер', slug: 'kerryBlueTerrier' },
      { id: '33_74', label: 'Кисю', slug: 'kishu' },
      { id: '33_75', label: 'Китайская хохлатая', slug: 'chineseCrested' },
      { id: '33_76', label: 'Колли длинношерстный', slug: 'collieLonghaired' },
      { id: '33_77', label: 'Колли короткошерстный', slug: 'collieShorthaired' },
      { id: '33_78', label: 'Комондор (венгерская овчарка)', slug: 'komondor' },
      { id: '33_79', label: 'Континентальный той-спаниель (паийон и фален)', slug: 'continentalToySpaniel' },
      { id: '33_80', label: 'Курцхаар', slug: 'germanShorthairedPointer' },
      { id: '33_81', label: 'Лабрадор ретривер', slug: 'labradorRetriever' },
      { id: '33_82', label: 'Леонбергер', slug: 'leonberger' },
      { id: '33_83', label: 'Мальтийская болонка', slug: 'maltese' },
      { id: '33_84', label: 'Мастино наполетано', slug: 'neapolitanMastiff' },
      { id: '33_85', label: 'Мексиканская голая собака (ксоло)', slug: 'mexicanHairlessDog' },
      { id: '33_86', label: 'Миттельшнауцер', slug: 'miniatureSchnauzer' },
      { id: '33_87', label: 'Мопс', slug: 'pug' },
      { id: '33_88', label: 'Московская сторожева', slug: 'moscowWatchdog' },
      { id: '33_89', label: 'Немецкая овчарка', slug: 'germanShepherd' },
      { id: '33_90', label: 'Немецкий дог', slug: 'germanMastiff' },
      { id: '33_91', label: 'Немецкий ягдтерьер', slug: 'germanHuntingTerrier' },
      { id: '33_92', label: 'Новошотландский ретривер (толлер)', slug: 'novaScotiaDuckTollingRetriever' },
      { id: '33_93', label: 'Норвич-терьер', slug: 'norwichTerrier' },
      { id: '33_94', label: 'Ньюфаундленд', slug: 'newfoundland' },
      { id: '33_95', label: 'Пекинес', slug: 'pekingese' },
      { id: '33_96', label: 'Пойнтер', slug: 'pointer' },
      { id: '33_97', label: 'Польская подгалянская овчарка', slug: 'polishLowlandSheepdog' },
      { id: '33_98', label: 'Померанский шпиц', slug: 'pomeranian' },
      { id: '33_99', label: 'Португальская водяная собака', slug: 'portugueseWaterDog' },
      { id: '33_100', label: 'Пти брабансон', slug: 'brabant' },
      { id: '33_101', label: 'Пудель', slug: 'poodle' },
      { id: '33_102', label: 'Пуми', slug: 'pumi' },
      { id: '33_103', label: 'Ризеншнауцер', slug: 'giantSchnauzer' },
      { id: '33_104', label: 'Родезийский риджбек', slug: 'rhodesianRidgeback' },
      { id: '33_105', label: 'Ротвейлер', slug: 'rottweiler' },
      { id: '33_106', label: 'Русская пегая гончая', slug: 'russianHound' },
      { id: '33_107', label: 'Русская псовая борзая', slug: 'russianBorzoi' },
      { id: '33_108', label: 'Русская салонная собака (русалка)', slug: 'russianToyTerrier' },
      { id: '33_109', label: 'Русский охотничий спаниель', slug: 'russianSpaniel' },
      { id: '33_110', label: 'Русский той-терьер', slug: 'russianToyTerrier' },
      { id: '33_111', label: 'Русский черный терьер', slug: 'blackRussianTerrier' },
      { id: '33_112', label: 'Самоедская лайка', slug: 'samoyed' },
      { id: '33_113', label: 'Сенбернар', slug: 'berneseMountainDog' },
      { id: '33_114', label: 'Сиба-ину', slug: 'shibaInu' },
      { id: '33_115', label: 'Силихем-терьер', slug: 'silkyTerrier' },
      { id: '33_116', label: 'Скай-терьер', slug: 'skyTerrier' },
      { id: '33_117', label: 'Скотч-терьер', slug: 'scottishTerrier' },
      { id: '33_118', label: 'Среднеазиатская овчарка (алабай)', slug: 'centralAsianShepherdDog' },
      { id: '33_119', label: 'Стаффордширский бультерьер', slug: 'staffordshireBullTerrier' },
      { id: '33_120', label: 'Тайский риджбек', slug: 'thaiRidgeback' },
      { id: '33_121', label: 'Такса', slug: 'dachshund' },
      { id: '33_122', label: 'Тибетский мастиф', slug: 'tibetanMastiff' },
      { id: '33_123', label: 'Тоса-ину', slug: 'tosaInu' },
      { id: '33_124', label: 'Турецкий кангал', slug: 'kangal' },
      { id: '33_125', label: 'Уиппет', slug: 'whippet' },
      { id: '33_126', label: 'Фараоновая собака', slug: 'pharaohHound' },
      { id: '33_127', label: 'Фокстерьер', slug: 'foxTerrier' },
      { id: '33_128', label: 'Французский бульдог', slug: 'frenchBulldog' },
      { id: '33_129', label: 'Хаски', slug: 'husky' },
      { id: '33_130', label: 'Ховаварт', slug: 'hovawart' },
      { id: '33_131', label: 'Хоккайдо', slug: 'hokkaido' },
      { id: '33_132', label: 'Цвергпинчер', slug: 'miniaturePinscher' },
      { id: '33_133', label: 'Цвергшнауцер', slug: 'miniatureSchnauzer' },
      { id: '33_134', label: 'Чау-чау', slug: 'chowChow' },
      { id: '33_135', label: 'Чесапик-бей-ретривер', slug: 'chesapeakeBayRetriever' },
      { id: '33_136', label: 'Чехословацкая волчья', slug: 'czechoslovakianWolfhound' },
      { id: '33_137', label: 'Чихуахуа', slug: 'chihuahua' },
      { id: '33_138', label: 'Шарпей', slug: 'sharPei' },
      { id: '33_139', label: 'Шведский вальхунд', slug: 'swedishVallhund' },
      { id: '33_140', label: 'шведский лаппхунд', slug: 'swedishLapphund' },
      { id: '33_141', label: 'Швейцарский зенненхунд', slug: 'swissMountainDog' },
      { id: '33_142', label: 'Шелти', slug: 'sheltie' },
      { id: '33_143', label: 'Ши-тцу', slug: 'shihtzu' },
      { id: '33_144', label: 'Шипперке', slug: 'schipperke' },
      { id: '33_145', label: 'Шотландский сеттер (гордон)', slug: 'scottishSetter' },
      { id: '33_146', label: 'Эрдельтерьер', slug: 'erdelterrier' },
      { id: '33_147', label: 'Эстонская гончая', slug: 'estonianHound' },
      { id: '33_148', label: 'Южноафриканский бурбуль', slug: 'southAfricanBoerboel' },
      { id: '33_149', label: 'Южнорусская овчарка', slug: 'southRussianOvcharka' },
      { id: '33_150', label: 'Японская акита-ину', slug: 'akitaInu' },
      { id: '33_151', label: 'Японский хин', slug: 'japaneseChin' },
      { id: '33_152', label: 'Шпиц', slug: 'spitz' }
    ],
    id: '33',
    label: 'Собачники',
    slug: 'dogLovers'
  },
  {
    children: [
      { id: '34_0', label: 'Кошатники', slug: 'koshatniki' },
      { id: '34_1', label: 'Абиссинская', slug: 'abyssinian' },
      { id: '34_2', label: 'Австралийский мист', slug: 'australianMist' },
      { id: '34_3', label: 'Азиатская (табби)', slug: 'asianTabby' },
      { id: '34_4', label: 'Американсая жесткошерсная', slug: 'americanWirehair' },
      { id: '34_5', label: 'Американская короткошерстная', slug: 'americanShorthair' },
      { id: '34_6', label: 'Американский бобтей короткошерстный', slug: 'americanBobtailShorthair' },
      { id: '34_7', label: 'Американский бобтейл длинношерстный', slug: 'americanBobtailLonghair' },
      { id: '34_8', label: 'Американский кёрл длинношерстный', slug: 'americanCurlLonghair' },
      { id: '34_9', label: 'Американский кёрл короткошерстный', slug: 'americanCurlShorthair' },
      { id: '34_10', label: 'Анатолийская', slug: 'anatolian' },
      { id: '34_11', label: 'Аравийский мау', slug: 'arabianMau' },
      { id: '34_12', label: 'Балинезийская', slug: 'balinese' },
      { id: '34_13', label: 'Бенгальская', slug: 'bengal' },
      { id: '34_14', label: 'Бомбейная', slug: 'bombay' },
      { id: '34_15', label: 'Бразильская короткошерстная', slug: 'brazilianShorthair' },
      { id: '34_16', label: 'Британская длинношерстная', slug: 'britishLonghair' },
      { id: '34_17', label: 'Британская короткошерстная', slug: 'britishShorthair' },
      { id: '34_18', label: 'Бурманская', slug: 'burmese' },
      { id: '34_19', label: 'Бурмилла длинношерстный', slug: 'burmillaLonghair' },
      { id: '34_20', label: 'Бурмилла короткошерстный', slug: 'burmillaShorthair' },
      { id: '34_21', label: 'Гаванна', slug: 'havanaBrown' },
      { id: '34_22', label: 'Гималайская', slug: 'himalayan' },
      { id: '34_23', label: 'Девон рекс', slug: 'devonRex' },
      { id: '34_24', label: 'Домашняя', slug: 'domestic' },
      { id: '34_25', label: 'Донской сфинкс', slug: 'donSphynx' },
      { id: '34_26', label: 'Египетская мау', slug: 'egyptianMau' },
      { id: '34_27', label: 'Йорк', slug: 'york' },
      { id: '34_28', label: 'Каани', slug: 'kaani' },
      { id: '34_29', label: 'Калифорнийская сияющая', slug: 'californiaSpangled' },
      { id: '34_30', label: 'Карельский бобтейл длинношерстный', slug: 'karelianBobtailLonghair' },
      { id: '34_31', label: 'Карельский бобтейл короткошерстный', slug: 'karelianBobtailShorthair' },
      { id: '34_32', label: 'Кельтская', slug: 'celtic' },
      { id: '34_33', label: 'Кимрик', slug: 'kimrik' },
      { id: '34_34', label: 'Кококе', slug: 'kokoke' },
      { id: '34_35', label: 'Колорпоинт', slug: 'colorpoint' },
      { id: '34_36', label: 'Корниш рекс', slug: 'korat' },
      { id: '34_37', label: 'Курильский бобтейл', slug: 'kurilianBobtail' },
      { id: '34_38', label: 'Курильский бобтейл длинношерстный', slug: 'kurilianBobtailLonghair' },
      { id: '34_39', label: 'Курильский бобтейл короткошерстный', slug: 'kurilianBobtailShorthair' },
      { id: '34_40', label: 'Лаперм длинношерстный', slug: 'lapermLonghair' },
      { id: '34_41', label: 'Лаперм короткошерстный', slug: 'lapermShorthair' },
      { id: '34_42', label: 'Манчкин длинношерстный', slug: 'munchkinLonghair' },
      { id: '34_43', label: 'Манчкин короткошерстная', slug: 'munchkinShorthair' },
      { id: '34_44', label: 'Мейн-кун', slug: 'maineCoon' },
      { id: '34_45', label: 'Меконгский бобтейл', slug: 'mekongBobtail' },
      { id: '34_46', label: 'Минскин', slug: 'minskin' },
      { id: '34_47', label: 'Мэнкс', slug: 'munchkin' },
      { id: '34_48', label: 'Наполеон', slug: 'napoleon' },
      { id: '34_49', label: 'Невская маскарадная', slug: 'nevaMasquerade' },
      { id: '34_50', label: 'Немецкий рекс', slug: 'germanRex' },
      { id: '34_51', label: 'Нибелунг', slug: 'nebelung' },
      { id: '34_52', label: 'Норвежская лесная', slug: 'norwegianForest' },
      { id: '34_53', label: 'Орегон рекс', slug: 'oregonRex' },
      { id: '34_54', label: 'Ориентальная длинношерстная', slug: 'orientalLonghair' },
      { id: '34_55', label: 'Ориентальная короткошерстная', slug: 'orientalShorthair' },
      { id: '34_56', label: 'Охос азулес', slug: 'ochosAzules' },
      { id: '34_57', label: 'Охос азулес длинношерстный', slug: 'ochosAzulesLonghair' },
      { id: '34_58', label: 'Оцикет', slug: 'ocicat' },
      { id: '34_59', label: 'Персидская', slug: 'persian' },
      { id: '34_60', label: 'Петерболд', slug: 'peterbald' },
      { id: '34_61', label: 'Пиксибоб длинношерстный', slug: 'pixiebobLonghair' },
      { id: '34_62', label: 'Пиксибоб короткошерстный', slug: 'pixiebobShorthair' },
      { id: '34_63', label: 'Рагамаффин', slug: 'ragamuffin' },
      { id: '34_64', label: 'Русская голубая', slug: 'russianBlue' },
      { id: '34_65', label: 'Рэкдолл', slug: 'ragdoll' },
      { id: '34_66', label: 'Саванна', slug: 'savannah' },
      { id: '34_67', label: 'Саренгети', slug: 'sarekgeti' },
      { id: '34_68', label: 'Священная бирманская', slug: 'sacredBirman' },
      { id: '34_69', label: 'Сейшельская длинношерстная', slug: 'seychelloisLonghair' },
      { id: '34_70', label: 'Сейшельская короткошерстная', slug: 'seychelloisShorthair' },
      { id: '34_71', label: 'Селкирк рекс длинношерстный', slug: 'selkirkRexLonghair' },
      { id: '34_72', label: 'Селкирк рекс короткошерстный', slug: 'selkirkRexShorthair' },
      { id: '34_73', label: 'Сиамская', slug: 'siamese' },
      { id: '34_74', label: 'Сибирская', slug: 'siberian' },
      { id: '34_75', label: 'Сингапурская', slug: 'singaporean' },
      { id: '34_76', label: 'Скоттиш страйт', slug: 'scottishStraight' },
      { id: '34_77', label: 'Скоттиш фолд', slug: 'scottishFold' },
      { id: '34_78', label: 'Сноу-Шу', slug: 'snowshoe' },
      { id: '34_79', label: 'Сомали', slug: 'somali' },
      { id: '34_80', label: 'Сфинкс (канадский)', slug: 'sphinxCanadian' },
      { id: '34_81', label: 'Тайская', slug: 'thai' },
      { id: '34_82', label: 'Тойгер', slug: 'toyger' },
      { id: '34_83', label: 'Тонкинская', slug: 'tonkinese' },
      { id: '34_84', label: 'Турецкая ангора', slug: 'turkishAngora' },
      { id: '34_85', label: 'Турецкий ван', slug: 'turkishVan' },
      { id: '34_86', label: 'Украинский левкой', slug: 'ukrainianLevkoy' },
      { id: '34_87', label: 'Уральский рекс длинношерстный', slug: 'uralRexLonghair' },
      { id: '34_88', label: 'Уральский рекс короткошерстный', slug: 'uralRexShorthair' },
      { id: '34_89', label: 'Форин Вайт', slug: 'forinWhite' },
      { id: '34_90', label: 'Хайленд фолд', slug: 'highlandFold' },
      { id: '34_91', label: 'Шантильи-тиффани', slug: 'chantillyTiffany' },
      { id: '34_92', label: 'Шартрез', slug: 'chartreux' },
      { id: '34_93', label: 'Эгейская', slug: 'aegean' },
      { id: '34_94', label: 'Экзотическая', slug: 'exotic' },
      { id: '34_95', label: 'Яванез', slug: 'javanese' },
      { id: '34_96', label: 'Японский бобтейл длинношерстный', slug: 'japaneseBobtailLonghair' },
      { id: '34_97', label: 'Японский бобтейл короткошерстный', slug: 'japaneseBobtailShorthair' }
    ],
    id: '34',
    label: 'Кошатники',
    slug: 'koshatniki'
  },
  {
    children: [
      { id: '35_0', label: 'Материнство', slug: 'motherhood' },
      { id: '35_1', label: 'Детская одежда', slug: 'childrensClothing' },
      { id: '35_2', label: 'Детская психология', slug: 'childPsychology' },
      { id: '35_3', label: 'Детские книги', slug: 'childrensBooks' },
      { id: '35_4', label: 'Детские развивающие игры', slug: 'educationalGamesForChildren' },
      { id: '35_5', label: 'Детский досуг', slug: 'childrensLeisure' },
      { id: '35_6', label: 'Детское здоровье', slug: 'childrensHealth' },
      { id: '35_7', label: 'Детское питание', slug: 'babyFood' },
      {
        id: '35_8',
        label: 'Развитие эмоционального интеллекта у детей',
        slug: 'developmentOfEmotionalIntelligenceInChildren'
      },
      { id: '35_9', label: 'Стрижки, прически', slug: 'haircutsHairstylesForChildren' }
    ],
    id: '35',
    label: 'Материнство',
    slug: 'motherhood'
  },
  {
    children: [{ id: '36_0', label: 'Сад и огород', slug: 'Garder' }],
    id: '36',
    label: 'Сад и огород',
    slug: 'Garder'
  },
  {
    children: [
      { id: '37_0', label: 'Охота и Рыбалка', slug: 'HauntingFishing' },
      { id: '37_1', label: 'Охота', slug: 'Haunting' },
      { id: '37_2', label: 'Рыбалка', slug: 'Fishing' }
    ],
    id: '37',
    label: 'Охота и Рыбалка',
    slug: 'HauntingFishing'
  },
  {
    children: [
      { id: '38_0', label: 'Бизнес и предпринимательство', slug: 'businessAndEntrepreneurship' },
      { id: '38_1', label: 'Handmade', slug: 'handmade' },
      { id: '38_2', label: 'IT', slug: 'it' },
      { id: '38_3', label: 'SEO', slug: 'seo' },
      { id: '38_4', label: 'SMM', slug: 'smm' },
      { id: '38_5', label: 'Бизнес для детей', slug: 'businessForChildren' },
      { id: '38_6', label: 'Бизнес с Китаем', slug: 'businessWithChina' },
      { id: '38_7', label: 'Благотворительные фонды', slug: 'charitableFoundations' },
      { id: '38_8', label: 'Бьюти - сфера', slug: 'beautyIndustry' },
      { id: '38_9', label: 'Дизайн и пошив одежды', slug: 'designAndTailoringOfClothes' },
      { id: '38_10', label: 'Дизайн интерьеров', slug: 'interiorDesign' },
      { id: '38_11', label: 'Дропшипинг', slug: 'dropshipping' },
      { id: '38_12', label: 'Инвестиции', slug: 'investments' },
      { id: '38_13', label: 'Исскусство и творчество', slug: 'artAndCreativity' },
      { id: '38_14', label: 'Клининг', slug: 'cleaning' },
      { id: '38_15', label: 'Косметология', slug: 'cosmetology' },
      { id: '38_16', label: 'Консалтинг, бухгалтерия, юриспруденция', slug: 'consultingAccountingLegal' },
      { id: '38_17', label: 'Криптовалюта', slug: 'cryptocurrency' },
      { id: '38_18', label: 'Марафоны', slug: 'marathons' },
      { id: '38_19', label: 'Маркетплейсы', slug: 'marketplaces' },
      { id: '38_20', label: 'Медицинские услуги', slug: 'medicalServices' },
      { id: '38_21', label: 'Недвижимость', slug: 'realEstate' },
      { id: '38_22', label: 'Нутрициология', slug: 'nutrition' },
      { id: '38_23', label: 'Общепит', slug: 'catering' },
      { id: '38_24', label: 'Онлайн - обучения', slug: 'onlineEducation' },
      { id: '38_25', label: 'Подбор персонала', slug: 'staffRecruitment' },
      { id: '38_26', label: 'Продюссирование', slug: 'production' },
      { id: '38_27', label: 'Производство мебели', slug: 'furnitureManufacturing' },
      { id: '38_28', label: 'Психология', slug: 'psychology' },
      { id: '38_29', label: 'Разведение животных', slug: 'animalBreeding' },
      { id: '38_30', label: 'Разработка настольных игр', slug: 'boardGameDevelopment' },
      { id: '38_31', label: 'Реклама, продвижение', slug: 'advertisingPromotion' },
      { id: '38_32', label: 'Ремонт и строительство', slug: 'repairAndConstruction' },
      { id: '38_33', label: 'Саморазвитие', slug: 'selfDevelopment' },
      { id: '38_34', label: 'Стартапы', slug: 'startups' },
      { id: '38_35', label: 'Стилистика и мода', slug: 'stylingAndFashion' },
      { id: '38_36', label: 'Транспорт и логистика', slug: 'transportAndLogistics' },
      { id: '38_37', label: 'Туризм', slug: 'tourism' },
      { id: '38_38', label: 'Услуги и товары для животных', slug: 'goodsAndServicesForAnimals' },
      { id: '38_39', label: 'Фермерство', slug: 'farming' },
      { id: '38_40', label: 'Фото и видео', slug: 'photoAndVideo' },
      { id: '38_41', label: 'Франшизы', slug: 'franchises' },
      { id: '38_42', label: 'Эзотерика', slug: 'esoterics' },
      { id: '38_43', label: 'ЭКО-товары', slug: 'ecoProducts' },
      { id: '38_44', label: 'Языковые курсы', slug: 'languageCourses' }
    ],
    id: '38',
    label: 'Бизнес и предпринимательство',
    slug: 'businessAndEntrepreneurship'
  },
  {
    children: [{ id: '39_1', label: 'Волонтерство', slug: 'volonteer' }],
    id: '39',
    label: 'Волонтерство',
    slug: 'volonteer'
  },
  {
    children: [
      { id: '40_0', label: 'Футбольные клубы', slug: 'footballClubs' },
      { id: '40_1', label: 'Бавария', slug: 'bayern' },
      { id: '40_2', label: 'Барселона', slug: 'barcelona' },
      { id: '40_3', label: 'Динамо', slug: 'dynamo' },
      { id: '40_4', label: 'Зенит', slug: 'zenit' },
      { id: '40_5', label: 'Крылья Советов', slug: 'kryliaSovetov' },
      { id: '40_6', label: 'Локомотив', slug: 'lokomotiv' },
      { id: '40_7', label: 'Манчестер Юнайтед', slug: 'manchesterUnited' },
      { id: '40_8', label: 'Марсель', slug: 'marseille' },
      { id: '40_9', label: 'Милан', slug: 'milan' },
      { id: '40_10', label: 'Пари Сен-Жермен', slug: 'parisSaintGermain' },
      { id: '40_11', label: 'Реал Мадрид', slug: 'realMadrid' },
      { id: '40_12', label: 'Ростов', slug: 'rostov' },
      { id: '40_13', label: 'Спартак', slug: 'spartak' },
      { id: '40_14', label: 'Торпедо', slug: 'torpedo' },
      { id: '40_15', label: 'Факел', slug: 'fakel' },
      { id: '40_16', label: 'Фенербахче', slug: 'fenerbahce' },
      { id: '40_17', label: 'ЦСКА', slug: 'cska' },
      { id: '40_18', label: 'Челси', slug: 'chelsea' },
      { id: '40_19', label: 'Ювентус', slug: 'juventus' }
    ],
    id: '40',
    label: 'Футбольные клубы',
    slug: 'footballClubs'
  },
  {
    children: [
      { id: '41_0', label: 'Коллекционирование', slug: 'collecting' },
      { id: '41_1', label: 'Барбифилия', slug: 'barbiphilia' },
      { id: '41_2', label: 'Вектуризм', slug: 'vectorism' },
      { id: '41_3', label: 'Кактусофилия', slug: 'cactusophilia' },
      { id: '41_4', label: 'Киндерфилия', slug: 'kindophilia' },
      { id: '41_5', label: 'Книгофилия', slug: 'bookophilia' },
      { id: '41_6', label: 'Коллекционирование предметов исскуства', slug: 'artCollecting' },
      { id: '41_7', label: 'Коллекционирование разных групп', slug: 'collectingVariousGroups' },
      { id: '41_8', label: 'Лотеристика', slug: 'loteristics' },
      { id: '41_9', label: 'Мемомагнетика', slug: 'memomagnetism' },
      { id: '41_10', label: 'Нумизматика', slug: 'numismatics' },
      { id: '41_11', label: 'Сакулумистика', slug: 'saculmistics' },
      { id: '41_12', label: 'Скрипофилия', slug: 'scripophilia' },
      { id: '41_13', label: 'Стилофилия', slug: 'stilophilia' },
      { id: '41_14', label: 'Токенофилия', slug: 'tokenophilia' },
      { id: '41_15', label: 'Фалеристика', slug: 'phaleristics' },
      { id: '41_16', label: 'Филателия', slug: 'philately' },
      { id: '41_17', label: 'Филобутонистика', slug: 'philobutonistics' },
      { id: '41_18', label: 'Филография', slug: 'philography' },
      { id: '41_19', label: 'Филокартия', slug: 'philokartia' },
      { id: '41_20', label: 'Экзонумия', slug: 'exonumia' },
      { id: '41_21', label: 'Экслибристика', slug: 'exlibrism' },
      { id: '41_22', label: 'Эрроризм', slug: 'errorism' }
    ],
    id: '41',
    label: 'Коллекционирование',
    slug: 'collecting'
  }
]
