import { useMemo, useState } from 'react'
import { ActivityIndicator, Image, Pressable, StyleSheet, View } from 'react-native'
import ImageView from 'react-native-image-viewing'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import moment from 'moment'

import { AttachmentSvg, BrokenImageSvg, DoubleTickSvg, TickSvg } from '@assets/icons'
import { Avatar, ModalWrapper, SButton, Typography } from '@components'
import { isWeb } from '@components/utils'
import { useDataLayer } from '@redux/dataLayer'
import { SERVER_URL } from '@services/apiService'
import { MessagesAttributes, ShortUser } from '@types'

const styles = StyleSheet.create({
  attachmentsIcon: {
    backgroundColor: 'rgba(255,255,255, 0.5)',
    borderRadius: 8,
    height: 40,
    opacity: 0.5,
    padding: 5,
    position: 'absolute',
    right: 15,
    top: 15,
    width: 40,
    zIndex: 1
  },
  container: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20
  },
  doubleTickIcon: {
    height: 16,
    width: 16
  },
  image: {
    height: '100%',
    width: '100%'
  },
  imageBroken: {
    height: 100,
    width: 100
  },
  imageWrapper: {
    alignItems: 'center',
    borderRadius: 20,
    height: 220,
    justifyContent: 'center',
    minWidth: 220,
    overflow: 'hidden',
    width: '100%'
  },
  loader: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  message: {
    color: '#000',
    fontWeight: '300',
    paddingLeft: 5
  },
  messageFromMe: {
    backgroundColor: '#2f2f2f',
    borderBottomLeftRadius: 20,
    marginLeft: 'auto',
    marginRight: 0
  },
  messageFromOther: {
    backgroundColor: '#e8e7ed',
    borderBottomRightRadius: 20,
    marginLeft: 0,
    marginRight: 'auto'
  },
  messageInfoWrapper: {
    alignItems: 'center',
    borderRadius: 20,
    bottom: 8,
    flexDirection: 'row',
    gap: 4,
    paddingHorizontal: 6,
    paddingVertical: 2,
    position: 'absolute',
    right: 8
  },
  option: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
    padding: 18,
    width: '100%'
  },
  tickIcon: {
    height: 10,
    width: 10
  },
  white: {
    color: '#fff'
  }
})

interface MessageProps {
  message: MessagesAttributes
  ownerId: number
  sender?: ShortUser
}

export const Message = ({ message, ownerId, sender }: MessageProps) => {
  const [isMessagePressed, setIsMessagePressed] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAttachmentFullScreen, setIsAttachmentFullScreen] = useState(false)
  const [imageLoadingError, setImageLoadingError] = useState(false)
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)

  const {
    attachments = [],
    chat_id: chatId,
    message_id: messageId,
    content,
    createdAt,
    message_type: type,
    user_id: userId,
    read
  } = message

  const { deleteMessages } = useDataLayer()
  const insets = useSafeAreaInsets()

  const isContent = !!content?.length
  const isAttachment = type === 'attachment'
  const isMyMessage = userId === ownerId

  const openModal = () => {
    setIsModalOpen(true)
  }

  const deleteMessage = () => {
    deleteMessages({ chat_id: chatId, messages: [messageId] })
    setIsModalOpen(false)
  }

  const images = useMemo(
    () =>
      attachments.map(a => {
        return { uri: `${SERVER_URL}/${a.attachment_url}` }
      }),
    [attachments.length]
  )

  const readAt = useMemo(() => {
    if (!read?.length) {
      return null
    }

    const readTimestamp = read.find(r => r.user_id !== ownerId)?.read_at
    return moment(readTimestamp).format('HH:mm')
  }, [read])

  const ImageViewFooterComponent = () => (
    <View style={{ alignItems: 'center', bottom: insets.bottom, flex: 1 }}>
      <Typography color='#fff' f18>{`${imageIndex + 1} / ${images.length}`}</Typography>
    </View>
  )

  const renderMessage = () => (
    <Pressable
      onLongPress={openModal}
      onPress={() => isAttachment && !imageLoadingError && setIsAttachmentFullScreen(true)}
      onPressIn={() => setIsMessagePressed(true)}
      onPressOut={() => setIsMessagePressed(false)}
      style={[
        styles.container,
        isMyMessage ? styles.messageFromMe : styles.messageFromOther,
        isMessagePressed && { opacity: 0.5 }
      ]}
    >
      {isAttachment && !!attachments.length && (
        <View
          style={[
            styles.imageWrapper,
            isMyMessage ? styles.messageFromMe : styles.messageFromOther,
            isContent && { borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }
          ]}
        >
          {imageLoadingError && (
            <BrokenImageSvg fill={isMyMessage ? '#e8e7ed' : '#2f2f2f'} style={styles.imageBroken} />
          )}
          <Image
            style={[styles.image, imageLoadingError && { display: 'none' }]}
            source={{ uri: `${SERVER_URL}/${attachments[0].attachment_url}` }}
            onError={() => setImageLoadingError(true)}
            onLayout={() => setIsImageLoading(true)}
            onLoadEnd={() => setIsImageLoading(false)}
          />
          {attachments.length > 1 && (
            <View style={styles.attachmentsIcon}>
              <AttachmentSvg fill='#000' />
            </View>
          )}
          {isImageLoading && !imageLoadingError && <ActivityIndicator style={styles.loader} size='large' animating />}
        </View>
      )}
      {isContent && (
        <View
          style={[
            { padding: 10 },
            isMyMessage ? { paddingRight: sender ? 86 : 70 } : { paddingRight: sender ? 66 : 50 }
          ]}
        >
          <Typography f16 style={[styles.message, isMyMessage && styles.white]}>
            {content}
          </Typography>
        </View>
      )}
      <View
        style={[
          styles.messageInfoWrapper,
          !isContent && { backgroundColor: isMyMessage ? 'rgba(0,0,0, 0.5)' : 'rgba(255,255,255, 0.5)' }
        ]}
      >
        {sender && <Avatar uri={sender.profile_photo?.[0]} size={14} />}
        <Typography f10 style={isMyMessage && { color: '#fff' }}>
          {moment(createdAt).format('HH:mm')}
        </Typography>
        {isMyMessage &&
          (readAt ? (
            <DoubleTickSvg style={styles.doubleTickIcon} fill='#fff' />
          ) : (
            <TickSvg style={styles.tickIcon} fill='#fff' />
          ))}
      </View>
    </Pressable>
  )

  return (
    <>
      {renderMessage()}
      <ImageView
        images={images}
        imageIndex={0}
        visible={isAttachmentFullScreen}
        doubleTapToZoomEnabled
        onImageIndexChange={index => setImageIndex(index)}
        onRequestClose={() => {
          setIsAttachmentFullScreen(false)
          setImageIndex(0)
        }}
        FooterComponent={!isWeb && attachments.length > 1 ? ImageViewFooterComponent : undefined}
      />
      <ModalWrapper isVisible={isModalOpen} close={() => setIsModalOpen(false)}>
        <SButton styleBtn={styles.option} onPress={deleteMessage}>
          <Typography f16 style={{ color: '#F64A3F', fontWeight: '700' }}>
            Удалить
          </Typography>
        </SButton>
      </ModalWrapper>
    </>
  )
}
