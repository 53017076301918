import { ReactNode, useEffect, useRef, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { GooglePlaceData, GooglePlaceDetail } from 'react-native-google-places-autocomplete'
import MapView, { MapPressEvent, Marker } from 'react-native-maps'

import { MapAutocomplete } from '@components'
import { AUTOCOMPLETE_INPUT_HEIGHT, isWeb } from '@components/utils'

interface LocationProps {
  name: string
  placeId: string
  position: {
    lat: number
    lng: number
  }
}

interface CustomMapsProps {
  placeholder: string
  isVisible: boolean
  location: LocationProps
  setLocation: (location: LocationProps) => void
  types: string | undefined
  selectedNodes?: ReactNode
  clearInputOnSelect?: boolean
}

interface MarkerType {
  latitude: number
  longitude: number
  title?: string
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  mapWrapper: {
    borderRadius: 26,
    bottom: 0,
    left: 0,
    maxHeight: 300,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: AUTOCOMPLETE_INPUT_HEIGHT + 10,
    zIndex: -1
  },
  selectedNodes: {
    flexDirection: 'row',
    position: 'absolute',
    top: AUTOCOMPLETE_INPUT_HEIGHT + 8,
    width: '100%',
    zIndex: -1
  }
})

export const CustomMaps = ({
  placeholder,
  isVisible,
  location,
  selectedNodes,
  setLocation,
  types,
  clearInputOnSelect
}: CustomMapsProps) => {
  const [marker, setMarker] = useState<MarkerType>()
  const mapRef = useRef<MapView | null>(null)

  useEffect(() => {
    if (!location) {
      return
    }

    setMarker({
      latitude: location.position.lat,
      longitude: location.position.lng,
      title: location.name
    })

    if (!isWeb) {
      return
    }

    mapRef.current?.animateToRegion({
      latitude: location.position.lat,
      latitudeDelta: 0.0922,
      longitude: location.position.lng,
      longitudeDelta: 0.0421
    })
  }, [location])

  const mapOnPressHandler = (e: MapPressEvent) => {
    setMarker({
      latitude: e.nativeEvent.coordinate.latitude,
      longitude: e.nativeEvent.coordinate.longitude
    })
  }

  const onPlaceSelect = (data: GooglePlaceData, details: GooglePlaceDetail | null = null) => {
    if (!data?.description || !details) {
      return
    }

    setMarker({
      latitude: details.geometry.location.lat,
      longitude: details.geometry.location.lng,
      title: data.description
    })

    if (!isVisible || !mapRef.current) {
      return
    }

    mapRef.current.animateToRegion(
      {
        latitude: details.geometry.location.lat,
        latitudeDelta: 0.0922,
        longitude: details.geometry.location.lng,
        longitudeDelta: 0.0421
      },
      2000
    )

    setLocation({
      name: data.description,
      placeId: data.place_id,
      position: {
        lat: details.geometry.location.lat,
        lng: details.geometry.location.lng
      }
    })
  }

  const renderMap = () => (
    <View style={[styles.mapWrapper, !!selectedNodes && { top: AUTOCOMPLETE_INPUT_HEIGHT * 2 }]}>
      <MapView
        ref={mapRef}
        provider='google'
        style={{ flex: 1 }}
        initialRegion={{
          latitude: location.position.lat,
          latitudeDelta: 0.0922,
          longitude: location.position.lng,
          longitudeDelta: 0.0421
        }}
        region={{
          latitude: location.position.lat,
          latitudeDelta: 0.0922,
          longitude: location.position.lng,
          longitudeDelta: 0.0421
        }}
        minZoomLevel={4}
        maxZoomLevel={10}
        onPress={mapOnPressHandler}
      >
        {marker && <Marker coordinate={marker} title={marker.title} />}
      </MapView>
    </View>
  )

  return (
    <View style={styles.container}>
      <MapAutocomplete
        location={location.name}
        types={types}
        onPlaceSelect={onPlaceSelect}
        placeholder={placeholder}
        clearInputOnSelect={clearInputOnSelect}
      />
      <View style={styles.selectedNodes}>
        <ScrollView horizontal showsHorizontalScrollIndicator={isWeb}>
          {selectedNodes}
        </ScrollView>
      </View>
      {isVisible && renderMap()}
    </View>
  )
}
