import { useState } from 'react'
import { Share, StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'

import { UploadSvg } from '@assets/icons'
import { Button, ModalWrapper } from '@components'
import { isWeb } from '@components/utils'
import { selectCurrentUserId } from '@redux/selectors'

const styles = StyleSheet.create({
  header: {
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 20,
    marginTop: 5
  },
  link: {
    color: 'rgba(47, 47, 47, 0.4)',
    fontSize: 14
  },
  linkHeader: {
    fontSize: 16,
    marginBottom: 8
  },
  mainInfo: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 14,
    marginBottom: 20,
    padding: 12,
    width: '100%'
  }
})

interface ShareModalProps {
  isVisible: boolean
  title?: string
  close: () => void
}

export const ShareModal = ({ isVisible, close, title = 'Пригласить' }: ShareModalProps) => {
  const userId = useSelector(selectCurrentUserId)
  const defaultButtonText = isWeb ? 'Скопировать' : 'Отправить'
  const [buttonText, setButtonText] = useState(defaultButtonText)

  const onShare = async () => {
    if (isWeb) {
      const url = `${window.location.hostname}/user/${userId}`
      navigator.clipboard.writeText(url)
      setButtonText('Скопировано!')
      return
    }

    try {
      await Share.share({ message: 'Welcome to connect!' })
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const onClose = () => {
    if (isWeb) {
      setButtonText(defaultButtonText)
    }

    close()
  }

  return (
    <ModalWrapper isVisible={isVisible} close={onClose} middle>
      <Text style={styles.header}>{title}</Text>
      <View style={styles.mainInfo}>
        <Text style={styles.linkHeader}>Ccылка на профиль</Text>
        <Text style={styles.link}>{userId}</Text>
      </View>
      <Button text={buttonText} onPress={onShare}>
        {!isWeb && <UploadSvg />}
      </Button>
    </ModalWrapper>
  )
}
