import { useState } from 'react'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { ImageEditor } from 'expo-crop-image'
import * as ImagePicker from 'expo-image-picker'

import { Close, Plus } from '@assets/images'
import { screenWidth } from '@components/utils'
import { useUploadImage } from '@hooks'

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#E8E7ED',
    borderRadius: 11,
    height: screenWidth * 0.27,
    justifyContent: 'center',
    position: 'relative',
    width: screenWidth * 0.27
  },
  deleteBlock: {
    position: 'absolute',
    right: -12,
    top: -12,
    zIndex: 3
  },
  icon: {
    height: 30,
    width: 30,
    zIndex: 10
  },
  imageContainer: {
    backgroundColor: 'transparent',
    borderRadius: 11,
    height: '100%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 3
  },
  uploadBlock: {
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.4,
    zIndex: 3
  }
})

interface ProfilePhotoProps {
  image: string
  onClickIcon: () => void
}

export const ProfilePhoto = ({ image, onClickIcon }: ProfilePhotoProps) => {
  const [uri, setUri] = useState('')
  const [isEditorVisible, setIsEditorVisible] = useState(false)
  const { uploadPhoto } = useUploadImage()

  const pickImage = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality: 1
      })

      if (!result.canceled) {
        setUri(result.assets[0].uri)
        setIsEditorVisible(true)
      }
    } catch (err) {
      console.log('IMAGE: ', err)
    }
  }

  const icon = image ? Close : Plus
  const action = image ? onClickIcon : pickImage
  const style = image ? styles.deleteBlock : styles.uploadBlock

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={pickImage} style={styles.imageContainer}>
        {image && <Image style={{ flex: 1 }} source={{ uri: image }} />}
      </TouchableOpacity>
      <TouchableOpacity onPress={action} style={style}>
        <Image source={icon} style={[styles.icon, !!image && { height: 24, width: 24 }]} />
      </TouchableOpacity>
      {uri && (
        <ImageEditor
          imageUri={uri}
          isVisible={isEditorVisible}
          fixedAspectRatio={9 / 16}
          onEditingCancel={() => {
            setIsEditorVisible(false)
            setUri('')
          }}
          onEditingComplete={img => {
            setIsEditorVisible(false)
            setUri('')
            uploadPhoto(img.uri)
          }}
        />
      )}
    </View>
  )
}
