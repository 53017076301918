/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects'

import { getMyChatsSuccess } from '@redux/actions'
import { RootState } from '@redux/reducer'
import { ChatInterlocutor, READ_NOTIFICATIONS_REQUEST, ReadNotificationsRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect | SelectEffect

function* readNotifications(action: ReadNotificationsRequestAction): Generator<SagaEffects, void, any> {
  try {
    yield call([apiService, apiService.readNotifications], action.payload)
    const data: ChatInterlocutor[] = yield select((state: RootState) => state.notificationHistory.data)
    yield put(getMyChatsSuccess({ counter: 0, data }))
  } catch (error: any) {
    console.log('getMyChatsSaga', error)
  }
}

export function* readNotificationsSaga() {
  yield takeLatest<ReadNotificationsRequestAction>(READ_NOTIFICATIONS_REQUEST, readNotifications)
}
