import { useEffect, useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import { MailSvg } from '@assets/icons'
import { Button, ModalWrapper, TextInput, Typography } from '@components'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserId } from '@redux/selectors'

const styles = StyleSheet.create({
  button: {
    marginTop: 40
  },
  input: {
    marginTop: 40
  }
})

interface ConnectionModalProps {
  isVisible: boolean
  id: string
  removeUser: ((phone: string) => void) | undefined
  close: () => void
}

export const ConnectionModal = ({ isVisible, close, id, removeUser }: ConnectionModalProps) => {
  const { sendLike, clearAllUsers } = useDataLayer()
  const currentUserId = useSelector(selectCurrentUserId)
  const { navigation } = useNavigator<'Tabs'>()
  const [redirect, setRedirect] = useState(false)
  const [text, setText] = useState('')

  const handleClick = async () => {
    setRedirect(true)
    sendLike({
      liked: id,
      message: { from: currentUserId, text, time: new Date().toString() },
      sender: currentUserId,
      type: 'like'
    })
    clearAllUsers()

    if (removeUser) {
      removeUser(id)
    }
  }

  useEffect(() => {
    if (!redirect) {
      return
    }

    setRedirect(false)
    navigation.navigate('Tabs')
  }, [redirect])

  return (
    <ModalWrapper isVisible={isVisible} close={close}>
      <ScrollView>
        <MailSvg style={{ alignSelf: 'center' }} />
        <Typography f20 semibold textAlign='center' color='#2C2C2C'>
          Отправка коннекта
        </Typography>
        <Typography f15 normal textAlign='center' marginTop={12} color='#7b7b7d'>
          Мы отправим ваше предложение пользователю, так же вы можете отправить приветственное сообщение
        </Typography>
        <TextInput
          style={styles.input}
          label='Введите сообщение'
          placeholder='Введите сообщение'
          onChange={e => setText(e)}
        />
        <Button style={styles.button} onPress={handleClick} text='Отправить коннект' />
      </ScrollView>
    </ModalWrapper>
  )
}
