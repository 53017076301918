import { ProfileOptions } from '@utils'

export const RELIGION: ProfileOptions = [
  { id: 0, label: 'Агностицизм', slug: 'agnosticism' },
  { id: 1, label: 'Атеизм', slug: 'atheism' },
  { id: 2, label: 'Буддизм', slug: 'buddhism' },
  { id: 3, label: 'Индуизм', slug: 'hinduism' },
  { id: 4, label: 'Ислам', slug: 'islam' },
  { id: 5, label: 'Иудаизм', slug: 'judaism' },
  { id: 6, label: 'Христианство', slug: 'christianity' },
  { id: 7, label: 'Бабизм', slug: 'bábism' },
  { id: 8, label: 'Мистицизм', slug: 'mysticism' },
  { id: 9, label: 'Оккультизм', slug: 'occultism' },
  { id: 10, label: 'Агни-йога', slug: 'agniYoga' },
  { id: 11, label: 'Айявари', slug: 'ayyavari' },
  { id: 12, label: 'Вишнуизм', slug: 'vaishnavism' },
  { id: 13, label: 'Даосизм', slug: 'taoism' },
  { id: 14, label: 'Джайнизм', slug: 'jainism' },
  { id: 15, label: 'Джедаизм', slug: 'jediism' },
  { id: 16, label: 'Дудеизм', slug: 'dudeism' },
  { id: 17, label: 'Зороастризм', slug: 'zoroastrianism' },
  { id: 18, label: 'Иудеохристианство', slug: 'judeoChristianity' },
  { id: 19, label: 'Католицизм', slug: 'catholicism' },
  { id: 20, label: 'Конфуцианство', slug: 'confucianism' },
  { id: 21, label: 'Модекнгеи', slug: 'modenkgei' },
  { id: 22, label: 'Неошаманизм', slug: 'neoshamanism' },
  { id: 23, label: 'Ошо-движение', slug: 'oshoMovement' },
  { id: 24, label: 'Православие', slug: 'orthodoxy' },
  { id: 25, label: 'Протестантизм', slug: 'protestantism' },
  { id: 26, label: 'Растафарианство', slug: 'rastafari' },
  { id: 27, label: 'Рэйки', slug: 'reiki' },
  { id: 28, label: 'Сатанизм', slug: 'satanism' },
  { id: 29, label: 'Сикхизм', slug: 'sikhism' },
  { id: 30, label: 'Шаманизм', slug: 'shamanism' },
  { id: 31, label: 'Шиваизм', slug: 'shaivism' },
  { id: 32, label: 'Эзотеризм', slug: 'esotericism' },
  { id: 33, label: 'Другое', slug: 'other' }
]
