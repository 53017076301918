/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getFriendsChannelsMessagesSuccess } from '@redux/actions'
import { GET_FRIENDS_CHANNELS_MESSAGES_REQUEST, GetFriendsChannelsMessagesRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { ChannelMessage } from '@types'

type SagaEffects = CallEffect | PutEffect

function* getFriendsChannelsMessages(
  action: GetFriendsChannelsMessagesRequestAction
): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.getFriendsChannelsMessages], action.payload.chatUserId)
    const posts: ChannelMessage[] = response.data
    yield put(getFriendsChannelsMessagesSuccess(posts))
  } catch (error: any) {
    console.log('getFriendsChannelsMessagesSaga', error)
  }
}

export function* getFriendsChannelsMessagesSaga() {
  yield takeLatest<GetFriendsChannelsMessagesRequestAction>(
    GET_FRIENDS_CHANNELS_MESSAGES_REQUEST,
    getFriendsChannelsMessages
  )
}
