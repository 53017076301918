import { Fragment, useEffect, useMemo, useState } from 'react'
import { KeyboardAvoidingView, ScrollView, StyleSheet, View } from 'react-native'

import { ArrowDownBlackSvg, ArrowDownWhiteSvg, ArrowUpWhiteSvg } from '@assets/icons'
import { Button, Chip, TextInput, Typography } from '@components'
import { isIOS } from '@components/utils'
import { LANG_HOBBIES } from '@utils'
import { apiService } from '@services/apiService'

const styles = StyleSheet.create({
    categoriesWrapper: {
        alignItems: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
        marginTop: 24,
        paddingVertical: 10
    },
    centerWrapper: {
        flex: 1
    },
    hobbiesDivider: {
        backgroundColor: '#A2A0AC',
        height: 1,
        marginVertical: 8,
        minWidth: '100%',
        opacity: 0.1
    },
    hobbiesWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
        width: '100%'
    },
    mainWrapper: {
        flex: 1,
        justifyContent: 'space-between',
        paddingBottom: 10,
        paddingLeft: 26,
        paddingRight: 26,
        paddingTop: isIOS ? 50 : 15,
        backgroundColor: 'white'
    },
    button: {
        backgroundColor: 'black',
        borderColor: '#f00',
    }
})

interface Props {
    telegramId: string
    lang: 'ru' | 'en' | 'uk'
}

type OptionId = string | number

interface IOption {
    id: OptionId
    label: any
}

interface ProfileOption extends IOption {
    slug: string
    children?: ProfileOption[]
}

type ProfileOptions = ProfileOption[]

const langText = {
    title: {
        ru: "Увлечения",
        uk: "Захоплення",
        en: "Hobby"
    },
    notFound: {
        ru: "Увлечения не найдены",
        uk: "Захоплення не знайдені",
        en: "Hobbies not found"
    },
    search: {
        ru: "Поиск по хобби",
        uk: "Пошук по захопленням",
        en: "Search hobbies"
    },
    button: {
        ru: "Готово",
        uk: "Готово",
        en: "Done"
    }
}

export const TelegramHobbies = ({ telegramId, lang }: Props) => {

    const [allHobbies, setAllHobbies] = useState<any[]>([])
    const [activeCategoryId, setActiveCategoryId] = useState('')
    const [isDark, setIsDark] = useState(false)
    const [filterValue, setFilterValue] = useState('')
    const [selectedHobbyIds, setSelectedHobbyIds] = useState<OptionId[]>([])
    const [selectedHobbiesCountByCategory, setSelectedHobbiesCountByCategory] = useState<Record<string, number>>({})

    useEffect(() => {
        // @ts-ignore
        if (window.Telegram && window.Telegram.WebApp) {
            // @ts-ignore
            window.Telegram.WebApp.ready()
            // @ts-ignore
            const dark = window.Telegram.WebApp.themeParams?.is_dark || false
            setIsDark(dark)
        } else {
            console.warn('Telegram Web App not found')
        }
        const hobbies: ProfileOption[] = []
        LANG_HOBBIES.forEach(hobby => hobbies.push(...(hobby.children ?? [])))
        setAllHobbies(hobbies)
    }, [])

    const submit = async () => {
        await apiService.telegramHobbies(telegramId, selectedHobbyIds)
        // @ts-ignore
        window.Telegram.WebApp.close()
    }

    const selectCategory = (_categoryId: string) =>
        setActiveCategoryId(activeCategoryId === _categoryId ? '' : _categoryId)

    const toggleHobby = (hobbyId: OptionId, categoryId: OptionId) => {
        const isHobbyIncluded = selectedHobbyIds.includes(hobbyId.toString())

        if (isHobbyIncluded) {
            setSelectedHobbyIds(selectedHobbyIds.filter(id => id !== hobbyId))
            setSelectedHobbiesCountByCategory({
                ...selectedHobbiesCountByCategory,
                [categoryId]: selectedHobbiesCountByCategory[categoryId] - 1
            })
        } else {
            setSelectedHobbyIds([...selectedHobbyIds, hobbyId])
            setSelectedHobbiesCountByCategory({
                ...selectedHobbiesCountByCategory,
                [categoryId]: (selectedHobbiesCountByCategory[categoryId] || 0) + 1
            })
        }
    }

    const isHobbySelected = (hobbyId: OptionId) => selectedHobbyIds.includes(hobbyId.toString())

    const categories = useMemo(() => {
        const allFilter = allHobbies.filter(el => el.label[lang].toLocaleLowerCase().includes(filterValue))
        const setOfCategories = new Set(allFilter.map(el => +(el.id as string).split('_')[0]))
        const categoriesArr: ProfileOptions = []
        setOfCategories.forEach((idx: number) => {
            categoriesArr.push({
                ...LANG_HOBBIES[idx],
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                children: LANG_HOBBIES[idx].children!.filter(el => el.label[lang].toLocaleLowerCase().includes(filterValue))
            })
        })
        return categoriesArr
    }, [filterValue, allHobbies])

    const onChangeText = (text: string) => setFilterValue(text.toLocaleLowerCase())

    const CategoryArrow = ({ categoryId }: { categoryId: OptionId }) => {
        const ArrowDown = selectedHobbiesCountByCategory[categoryId] > 0 ? ArrowDownWhiteSvg : ArrowDownBlackSvg
        const ActiveArrow = activeCategoryId === categoryId ? ArrowUpWhiteSvg : ArrowDown
        return <ActiveArrow width={18} height={18} />
    }

    const renderHobbies = (category: ProfileOption) =>
        category.children?.map(hobby => (
            <Chip
                key={hobby.id}
                label={hobby.label[lang]}
                onPress={() => toggleHobby(hobby.id, category.id)}
                bgColor={isHobbySelected(hobby.id) ? '#2F2F2F' : undefined}
                textColor={isHobbySelected(hobby.id) ? '#FFFFFF' : undefined}
            />
        ))

    const renderCategories = () =>
        categories.map(category => {
            const isCategoryActive = activeCategoryId === category.id || selectedHobbiesCountByCategory[category.id] > 0
            return (
                <Fragment key={category.id}>
                    <Chip
                        onPress={() => selectCategory(category.id as string)}
                        bgColor={isCategoryActive ? '#2F2F2F' : undefined}
                        textColor={isCategoryActive ? '#FFFFFF' : undefined}
                        label={category.label[lang]}
                        leftIcon={<CategoryArrow categoryId={category.id} />}
                    />
                    {activeCategoryId === category.id && category.children && (
                        <>
                            <View style={styles.hobbiesWrapper}>{renderHobbies(category)}</View>
                            <View style={styles.hobbiesDivider} />
                        </>
                    )}
                </Fragment>
            )
        })

    return (
        <View style={styles.mainWrapper}>
            <KeyboardAvoidingView behavior={isIOS ? 'padding' : 'height'} style={{ flex: 1 }}>
                <View style={styles.centerWrapper}>
                    <Typography f27 bold marginTop={28} marginBottom={8}>
                        {langText.title[lang]}
                    </Typography>
                    <TextInput placeholder={langText.search[lang]} onChange={onChangeText} />
                    {categories.length ? (
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <View style={styles.categoriesWrapper}>{renderCategories()}</View>
                        </ScrollView>
                    ) : (
                        <Typography marginTop={16} f22 textAlign='center'>
                            {langText.notFound[lang]}
                        </Typography>
                    )}
                </View>
            </KeyboardAvoidingView>
            <Button style={styles.button} onPress={submit} text={langText.button[lang]} />
        </View>
    )
}
