import {
  GET_CHANNEL_BY_USER_ID_REQUEST,
  GET_CHANNEL_BY_USER_ID_SUCCESS,
  GetChannelByUserIdRequestAction,
  GetChannelByUserIdSuccessAction
} from '@redux/types'
import { Channel } from '@types'

export const getChannelByUserIdRequest = (chatUserId: number): GetChannelByUserIdRequestAction => ({
  payload: { chatUserId },
  type: GET_CHANNEL_BY_USER_ID_REQUEST
})

export const getChannelByUserIdSuccess = (channel: Channel): GetChannelByUserIdSuccessAction => ({
  payload: channel,
  type: GET_CHANNEL_BY_USER_ID_SUCCESS
})
