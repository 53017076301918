import { ColorValue, Dimensions, Platform, StatusBarStyle, StyleSheet } from 'react-native'

export const STATUS_BAR_BACKGROUND: ColorValue = 'transparent'
export const STATUS_BAR_STYLE: StatusBarStyle = 'dark-content'

export enum Colors {
  White = 'rgba(255, 255, 255, 1)',
  WhiteTransparent = 'rgba(255, 255, 255, 0.8)',
  InactiveDot = 'rgba(255, 255, 255, 0.12)',
  LightGray = 'rgba(135, 135, 135, 0.12)',
  Gray = 'rgba(162, 160, 172, 1)',
  DarkGray = '#2F2F2F',
  GrayBg = '#E8E7ED',
  Text = '#2C2C2C',
  Red = 'rgba(182, 48, 48, 1)',
  Green = 'rgba(100, 202, 122, 1)',
  Background = '#f3f2f9',
  BackgroundModal = '#F3F2F9',
  transparent = 'transparent'
}

export enum Fonts {
  'light' = 'Manrope-Light',
  'regular' = 'Manrope-Regular',
  'semiBold' = 'Manrope-SemiBold',
  'bold' = 'Manrope-Bold',
  'medium' = 'Manrope-Medium'
}

export const isIOS = Platform.OS === 'ios'
export const isAndroid = Platform.OS === 'android'
export const isWeb = Platform.OS === 'web'

export const WEB_CONTAINER_WIDTH = 480
export const WEB_CONTAINER_HEIGHT = WEB_CONTAINER_WIDTH * 2
export const AUTOCOMPLETE_INPUT_HEIGHT = 52

export const dimensionsWidth = Dimensions.get('window').width
export const dimensionsHeight = Dimensions.get('window').height

export const screenWidth = isWeb && dimensionsWidth > WEB_CONTAINER_WIDTH ? WEB_CONTAINER_WIDTH : dimensionsWidth
export const screenHeight = isWeb && dimensionsHeight > WEB_CONTAINER_HEIGHT ? WEB_CONTAINER_HEIGHT : dimensionsHeight

export const baseStyle = StyleSheet.create({
  screenView: {
    backgroundColor: Colors.Background,
    flex: 1
  },
  scrollView: {
    backgroundColor: Colors.Background,
    flexGrow: 1
  }
})
