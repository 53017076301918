import { useEffect, useRef, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, View } from 'react-native'

import {
  CompatibilityTab,
  Feed,
  InfoTab,
  ModelBottomSheetFooterAccept,
  ModelBottomSheetFooterSendRequest,
  Tabs
} from '@components'
import { screenWidth } from '@components/utils'
import BottomSheet, { BottomSheetFooterProps } from '@gorhom/bottom-sheet'
import { UserProfile } from '@types'

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 'auto',
    maxWidth: screenWidth,
    paddingVertical: 12
  },
  handler: {
    backgroundColor: '#a5a4a9',
    borderRadius: 2,
    height: 4,
    width: 40
  },
  handlerWrapper: {
    alignItems: 'center',
    flex: 1,
    paddingVertical: 6
  }
})

interface ModelBottomSheetProps {
  user: UserProfile
  hideButtons?: boolean
  isRequest?: boolean
  goBack: () => void
  close?: () => void
  openConnection?: () => void
}

export const ModelBottomSheet = ({
  user,
  hideButtons,
  isRequest,
  goBack,
  close,
  openConnection
}: ModelBottomSheetProps) => {
  const [activeTab, setActiveTab] = useState(0)
  const bottomSheetRef = useRef<BottomSheet>(null)
  const [isRequestState, setIsRequestState] = useState(isRequest)
  const [currentSnapIndex, setCurrentSnapIndex] = useState(0)

  useEffect(() => {
    setIsRequestState(isRequest)
  }, [isRequest])

  const FooterComponent = (props: BottomSheetFooterProps) => {
    if (isRequestState) {
      return <ModelBottomSheetFooterAccept user={user} {...props} goBack={goBack} close={close} />
    }

    return (
      <ModelBottomSheetFooterSendRequest
        {...props}
        close={close}
        id={user._id}
        openConnection={() => {
          bottomSheetRef.current?.snapToIndex(0)
          openConnection?.()
        }}
      />
    )
  }

  const toggleBottomSheet = () => {
    const nextIndex = currentSnapIndex < 1 ? 1 : 0 // Простой переключатель между закрытым и полуоткрытым
    bottomSheetRef.current?.snapToIndex(nextIndex)
    setCurrentSnapIndex(nextIndex)
  }

  const TABS = [
    { component: Feed, id: 0, title: 'Лента' },
    { component: CompatibilityTab, id: 1, title: 'Совместимость' },
    { component: InfoTab, id: 2, title: 'Инфо' }
  ]

  const Tab = TABS[activeTab].component

  return (
    <BottomSheet
      ref={bottomSheetRef}
      index={0}
      snapPoints={['17%', '82%']}
      style={styles.container}
      backgroundStyle={{ backgroundColor: '#F3F2F9' }}
      footerComponent={!hideButtons && !user.hide ? FooterComponent : undefined}
      handleComponent={() => (
        <Pressable style={styles.handlerWrapper} onPress={toggleBottomSheet}>
          <View style={styles.handler} />
        </Pressable>
      )}
    >
      <Tabs items={TABS} activeTab={activeTab} setActiveTab={e => setActiveTab(e)} />
      <ScrollView showsVerticalScrollIndicator={false}>
        <Tab user={user} />
      </ScrollView>
    </BottomSheet>
  )
}
