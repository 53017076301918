import { SEND_ATTACHMENTS_TO_CHAT_REQUEST, SendAttachmentsToChatRequestAction } from '@redux/types'
import { AttachmentMessageData, Base64File } from '@types'

export const sendAttachmentsToChatRequest = (
  data: AttachmentMessageData,
  files: Base64File[]
): SendAttachmentsToChatRequestAction => ({
  payload: { data, files },
  type: SEND_ATTACHMENTS_TO_CHAT_REQUEST
})
